import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Stack,
    Button,
    Alert,
} from "react-bootstrap";
import logo from "../../assets/logo.png";
import axios from "axios";
import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";
import AlertModal from "../elements/AlertModal";
require("dotenv").config();

export default function Login(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            username: "",
            password: "",
            mfa: "",
        },
    });
    const cookies = new Cookies();
    const [mfa, setMFA] = useState({
        status: false,
        mobile: "",
    });
    const [user, setUser] = useState({
        username: "",
        password: "",
    });

    const [error, setError] = useState({
        show: false,
        text: "",
    });

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const navigate = useNavigate();

    const handleChange = (prop) => (event) => {
        setUser({ ...user, [prop]: event.target.value });
    };

    const handleLogin = (data) => {
        var bodyFormData = new FormData();
        bodyFormData.append("username", data.username);
        bodyFormData.append("password", data.password);
        bodyFormData.append("mfatoken", data.mfa);
        axios({
            method: "post",
			url: "https://api.vciso.jp/login",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                ////console.log(response);
                if (response.status === 200) {
                    //console.log(response.data.access_token)
                    //cookies.set("user", data.username)
                    //cookies.set("access_token", response.data.access_token)
                    //cookies.set("refresh", response.data.refresh_token)
                    sessionStorage.setItem("user", data.username);
                    sessionStorage.setItem(
                        "access_token",
                        response.data.access_token
                    );
                    sessionStorage.setItem(
                        "refresh",
                        response.data.refresh_token
                    );
                    ////console.log(response.data.refresh_token)
                    ////console.log(cookies.get("user"));
                    navigate("/");
                    props.setLogin(true);
                    //window.location.reload();
                }
            })
            .catch(function (response) {
                if (response.response.status === 401) {
                    setError({
                        show: true,
                        text: "多要素認証用コードが間違っています。",
                    });
                } else {
                    setError({
                        show: true,
                        text: response.message,
                    });
                }
            });
    };

    const handleResend = () => {
        var data = getValues();
        var bodyFormData = new FormData();
        bodyFormData.append("username", data.username);
        bodyFormData.append("password", data.password);
        var body = {
            username: data.username,
            password: data.password,
        };
        axios({
            method: "post",
			url: "https://api.vciso.jp/api/auth/mfa",
            data: body,
        })
            .then(function (response) {
                //console.log(response)
                if (response.status === 200) {
                    if (
                        response.data.apiCode === 0 &&
                        response.data.data.mfaStatus
                    ) {
                        setMFA({
                            ...mfa,
                            status: true,
                            mobile: response.data.data.mobile,
                        });
                        setAlert({
                            ...alert,
                            show: true,
                            message: "多要素認証コードを再送信しました。",
                        });
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "多要素認証コードの再送信が失敗しました。",
                });
            });
    };

    const onSubmit = (data) => {
        //event.preventDefault();
        ////console.log(data)
        var bodyFormData = new FormData();
        bodyFormData.append("username", data.username);
        bodyFormData.append("password", data.password);
        var body = {
            username: data.username,
            password: data.password,
        };
        //http://localhost:8081/api/auth/mfa
        axios({
            method: "post",
			url: "https://api.vciso.jp/api/auth/mfa",
            data: body,
        })
            .then(function (response) {
                //console.log(response)
                if (response.status === 200) {
                    if (
                        response.data.apiCode === 0 &&
                        response.data.data.mfaStatus
                    ) {
                        setMFA({
                            ...mfa,
                            status: true,
                            mobile: response.data.data.mobile,
                        });
                    } else if (
                        response.data.apiCode === 0 &&
                        !response.data.data.mfaStatus
                    ) {
                        //"http://localhost:8081/login"
                        axios({
                            method: "post",
							url: "https://api.vciso.jp/login",
                            data: bodyFormData,
                            headers: { "Content-Type": "multipart/form-data" },
                        })
                            .then(function (response) {
                                //handle success
                                ////console.log(response);
                                if (response.status === 200) {
                                    //console.log(response.data.access_token)
                                    //cookies.set("user", data.username)
                                    //cookies.set("access_token", response.data.access_token)
                                    //cookies.set("refresh", response.data.refresh_token)
                                    sessionStorage.setItem(
                                        "user",
                                        data.username
                                    );
                                    sessionStorage.setItem(
                                        "access_token",
                                        response.data.access_token
                                    );
                                    sessionStorage.setItem(
                                        "refresh",
                                        response.data.refresh_token
                                    );
                                    ////console.log(response.data.refresh_token)
                                    ////console.log(cookies.get("user"));
                                    navigate("/");
                                    props.setLogin(true);
                                    //window.location.reload();
                                }
                            })
                            .catch(function (response) {
                                if (response.response.status === 401) {
                                    setError({
                                        show: true,
                                        text: "メールアドレス又はパスワードが異なります",
                                    });
                                } else {
                                    setError({
                                        show: true,
                                        text: response.message,
                                    });
                                }
                            });
                    } else {
                        setError({
                            show: true,
                            text: "メールアドレス又はパスワードが異なります",
                        });
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
                setError({
                    show: true,
                    text: response.message,
                });
            });
        ////console.log(bodyFormData)

        /*
        axios({
            method: "get",
            url: "http://localhost:8081/api/started"  
        })
        .then(function (response) {
            if (response.status === 200 && global.config.auth[user.username] === user.password ) {
                cookies.set("user", user)
                //console.log(cookies.get("user"));
                navigate("/");
                window.location.reload();
            } else {
                console.error("Incorrect credentials")
            }
        })
        .catch(function (response) {
            //console.log(response)
        })
        
        */
    };

    return (
        <Row className="g-0" style={{ height: "calc(100vh - 269px + 130px)" }}>
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Col md={3} lg={4}></Col>
            <Col
                sm={12}
                md={6}
                lg={4}
                className="h-100"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    gap={3}
                    style={{
                        maxWidth: "450px",
                        marginBottom: "10vh",
                        borderRadius: ".5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                    className="px-4 py-5 shadow-lg Login"
                >
                    <img src={logo} height="30rem" />
                    {mfa.status ? (
                        <Form
                            //className="form-area"
                            onSubmit={handleSubmit(handleLogin)}
                        >
                            <Form.Group className="edit-lable-form-area">
                                <Row className="g-0">
                                    <Form.Label style={{ fontSize: "14px" }}>
                                        {"****" +
                                            mfa.mobile.slice(-6).slice(4) +
                                            "へSMS送信された6桁の番号を入力してください"}
                                    </Form.Label>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-form-area">
                                <Row className="g-0">
                                    <Form.Control
                                        type="text"
                                        {...register("mfa", {
                                            required: {
                                                value: true,
                                                message:
                                                    "多要素認証用コードを入力してください",
                                            },
                                        })}
                                    />
                                    {errors.mfa && (
                                        <ValidationError
                                            message={errors.mfa.message}
                                        />
                                    )}
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-2  button-size-2 button-margin"
                                            onClick={handleResend}
                                        >
                                            再送信
                                        </Button>
                                        <Button
                                            className="rounded-pill btn-blue-2  button-size-2"
                                            type="submit"
                                        >
                                            ログイン
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </Form>
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="edit-lable-form-area">
                                <Row className="g-0">
                                    <Form.Label>メールアドレス</Form.Label>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-form-area">
                                <Row className="g-0">
                                    <Form.Control
                                        type="email"
                                        {...register("username", {
                                            required: {
                                                value: true,
                                                message:
                                                    "メールアドレスを入力してください",
                                            },
                                            pattern: {
                                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message:
                                                    "メールフォーマットは無効です。",
                                            },
                                        })}
                                    />
                                    <div className="error-area">
                                        {errors.username && (
                                            <ValidationError
                                                message={
                                                    errors.username.message
                                                }
                                            />
                                        )}
                                    </div>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-lable-form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-password ">
                                        パスワード
                                    </Form.Label>
                                    <Form.Label className="label-forgot-password">
                                        <a
                                            href="#"
                                            onClick={() =>
                                                navigate("/forgotpassword")
                                            }
                                        >
                                            パスワードを忘れた方はこちら
                                        </a>
                                    </Form.Label>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-form-area">
                                <Row className="g-0">
                                    <Form.Control
                                        type="password"
                                        {...register("password", {
                                            required: {
                                                value: true,
                                                message:
                                                    "パスワードを入力してください",
                                            },
                                            minLength: {
                                                value: 8,
                                                message:
                                                    "パスワードを入力してください",
                                            },
                                        })}
                                    />
                                    <div className="error-area">
                                        {errors.password && (
                                            <ValidationError
                                                message={
                                                    errors.password.message
                                                }
                                            />
                                        )}
                                    </div>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-md button-size"
                                            type="submit"
                                        >
                                            ログイン
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </Form>
                    )}
                    <Alert
                        show={error.show}
                        style={{ width: "90%", fontSize: ".75rem" }}
                        onClose={() => setError({ show: false, text: "" })}
                        variant="danger"
                        dismissible
                    >
                        {error.text}
                    </Alert>
                </Stack>
            </Col>
            <Col md={3} lg={4}></Col>
        </Row>
    );
}
