import React from "react";
import {
    Col,
    Row,
    Stack,
    Form,
    Container,
    InputGroup,
    Button,
    Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function EmailTrainingCheck(props) {
    const { emails, handleKakutei, handleReturn } = props;

    const navigate = useNavigate();

    return (
        <div className="EmailTrainingForm">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　合同メール訓練受付確認</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <h2 className="content_title">
                            <div>合同メール訓練対象者リスト</div>
                        </h2>

                        {/* <div style={{ height: "300px", overflowY: "auto" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr style={{ backgroundColor: "#f2f7fa" }}>
                                        <th style={{ textAlign: "center" }}>
                                            No
                                        </th>
                                        <td style={{ textAlign: "left" }}>
                                            メール訓練対象者名
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            メールアドレス
                                        </td>
                                    </tr>
                                </thead>
                                <tbody >
                                    {emails.map((email, index) => {
                                        return (
                                            <tr key={`${index}_${email[0]}`}>
                                                <th
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >{`${index + 1}`}</th>
                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {email[1]}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${email[0]}`}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div> */}
                        <div style={{ height: "400px", overflowY: "auto" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>
                                            No
                                        </th>
                                        <th style={{ textAlign: "left" }}>
                                            メール訓練対象者名
                                        </th>
                                        <th style={{ textAlign: "left" }}>
                                            メールアドレス
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    style={{
                                        height: "300px",
                                        overflowY: "auto",
                                    }}
                                >
                                    {emails.map((email, index) => {
                                        return (
                                            <tr key={`${index}_${email[0]}`}>
                                                <th
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >{`${index + 1}`}</th>
                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${email[1]}`}</td>
                                                <td
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {email[0]}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        <br />
                        <br />
                        <Row className="template-customize-area button-flex">
                            <Button
                                className="btn btn-primary btn-margin"
                                onClick={handleReturn}
                            >
                                戻る
                            </Button>
                            <Button
                                className="btn btn-primary"
                                onClick={handleKakutei}
                            >
                                確定
                            </Button>
                        </Row>
                        <br />
                        <br />
                        <br />
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
        </div>
    );
}
