import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Alert, Modal
} from 'react-bootstrap';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from 'react-hook-form';
import AlertModal from '../AlertModal';
import { ReactComponent as Download } from '../../../assets/svg_icons/download-icon.svg';
import { ReactComponent as Edit } from '../../../assets/svg_icons/edit_icon.svg';
import downloadFile from '../../../utils/downloadFile';

const placeholderGenerator = quantity => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
        items.push({ itemplate_id: i, template_type: " ", document_name: " ", created_at: " "})
    }
    return items;
}

export default function DocTemplatesList(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [templates, setTemplates] = useState([])
    const [types, setTypes] = useState([])
    const [filteredTemplates, setFilteredTemplates] = useState(() => placeholderGenerator(25))

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({})

    const navigate = useNavigate();

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const downloadButton = (e, row, rowIndex) => {

        /*
        var incident = incidents.find(x => x.id === row.id);
        console.log(incident)
        if (incident === undefined) {
            return;
        }
        */
        return (
            
            <div style={{ "cursor": "pointer"}} onClick={() => downloadFile("template", row.document_name)}>
                <Download style={{ "height": "20px"}} />
            </div>               
            
        )
    }

    const editButton = (e, row, rowIndex) => {

        
        var template = templates.find(x => x.template_id === row.template_id);
      //  console.log(template)
        if (template === undefined) {
            return;
        }
        return (
            
            <div style={{ "cursor": "pointer"}} onClick={() => navigate("details", { state: { template: template} })}>
                <Edit style={{ "height": "20px"}} />
            </div>               
            
        )
    }

    const columns = [
        {
            dataField: "template_id",
            text: "id",
            hidden: true
        },
        {
            dataField: "template_type",
            text: "テンプレートの種類",
            sort: true,
            headerStyle: () => {
                return { width: "30%" }
            }
        },
        {
            dataField: "document_name",
            text: "文書テンプレート名",
            sort: true,
            headerStyle: () => {
                return { width: "30%" }
            }
        },
        {
            dataField: "download",
            text: "DL",
            formatter: downloadButton,
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "created_at",
            text: "登録日",
            sort: true,
            headerStyle: () => {
                return { width: "20%" }
            }
        },
        {
            dataField: "edit",
            text: "詳細",
            formatter: editButton,
            headerStyle: () => {
                return { width: "10%"}
            }
        }
    ]

    const handleReset = () => {
        setValue("template_type", "全て")
        setFilteredTemplates(rowGenerator(templates));
    }

    const onSubmit = (data) => {
        var values = getValues();

        var filtered = JSON.parse(JSON.stringify(templates));

        if (values.template_type !== "全て") {
            filtered = filtered.filter(x => x.template_type === values.template_type)
        }

        setFilteredTemplates(rowGenerator(filtered))
    }

    const rowEvents = {
        /*
        onClick: (e, row, rowIndex) => {
            
            var incident = incidents.find(x => x.id === row.id);
            navigate("details", {
                state: {
                    incident: incident
                },
                reaplce: true                
            })
        }
        */
    }

    const rowGenerator = (data) => {
        const items = [];
        data.forEach(template => {
            items.push({ template_id: template.template_id, template_type: template.template_type, document_name: template.document_name, created_at: template.created_at})
        })
        return items;
    }

    useEffect(() => {
        axiosPrivate.get("/api/document/templates")
        .then(function(response1) {
           // console.log(response1)
            if (response1.status === 200) {
                if (response1.data.apiCode === 0) {
                   // console.log(response1.data.data)
                    var templates = rowGenerator(response1.data.data)
                   // console.log(templates)
                    setTemplates(response1.data.data)
                    var uniqueTypes = [...new Set(response1.data.data.map(item => item.template_type))];
                    setTypes(uniqueTypes);
                    setFilteredTemplates(templates)
                }
            }
        })
        .catch(function(response1) {
           // console.log(response1)
        })

    }, [])

    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <Row className="g-0">
                        <Col />
                        <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                            <h2 style={{ "alignSelf": "center" }}>文書テンプレート登録</h2>
                        </Col>
                        <Col />
                    </Row>
                    
                    <hr style={{ "width": "100%" }} />
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row classname="g-0">
                            <Col xs={6}>
                                <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                    <Form.Label>
                                        テンプレートの種類
                                    </Form.Label>
                                    <Form.Select { ...register("template_type")}>
                                        <option>全て</option>
                                        {
                                            types.map(type => {
                                                return <option>{type}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                                <Button className="my-4" style={{ "width": "fit-content", "alignSelf": "end"}} onClick={() => navigate("/admin/doctemplates/upload")}>テンプレートを新規追加する</Button>
                            </Col>
                        </Row>
                        
                        <Row className="g-0">
                            <Col xs={2}>
                                <Button className="my-4" type="submit">検索する</Button>
                            </Col>
                            <Col xs={2}>
                                <Button className="my-4" onClick={handleReset} variant="outline-primary">リセット</Button>
                            </Col>
                        </Row>
                        </Form>
                    </Container>
                    <div style={{ "minHeight": "600px" }}>
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="id"
                            data={filteredTemplates}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 25 })}
                            rowStyle={{ overflowWrap: "break-word" }}
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    )
}