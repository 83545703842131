import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import {ReactComponent as Logout} from "../../../assets/svg_icons/icon-logout.svg";

export default function LogoutButton(props) {

    const logout = () => {
        props.logoutFunc();
    }
    return (
      <div className="logout-button navbar">
        <a onClick={logout} style={{ "color": "#4383E1", "fontSize": "10px", "display": "flex", "flexDirection":"column", "justifyContent": "center"}}>
          <div style={{ "textAlign": "center" }}><Logout /></div><span>ログアウト</span>
        </a>
      </div>

        // <Navbar className="logout-button">
        //     <Nav.Link href="/" className="btn">
        //         <Button className="rounded-pill mx-2" onClick={logout} size="sm">ログアウト</Button>
        //     </Nav.Link>
        // </Navbar>
    )
}