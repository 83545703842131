import React from 'react';
import { 
    Container, Row, Col, Button
} from 'react-bootstrap';
import WebService from './WebService';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Cookies from 'universal-cookie';
import {Route, useNavigate} from "react-router-dom";
import Logo from '../../../assets/vciso_logo_en.png';
import Chat  from '../../../assets/service-chat.png';
import DocIcon from '../../../assets/service-doc.png';
import Page from '../../../assets/service-page.png';
import Computer from '../../../assets/service-computer.png';
import Email from '../../../assets/service-email.png';
import Insurance from '../../../assets/service-insurance.png';
import Meeting from '../../../assets/service-meeting.png';


export default function WebServiceGrid(props) {

    const navigate = useNavigate();

    const sampleService = <WebService title="セキュリティチェックリスト" text="サンプルテキストサンプルテキストサンプルテキストサンプルテキスト"  image={faPenToSquare} link="#" />
    const cookies = new Cookies();
    
    const chat = <WebService
                    class="service-icon"
                    title="よろづ相談" 
                    text="セキュリティに関する様々なお悩みにセキュリティのスペシャリストであるGSX専門コンサルタントがチャットにてお答え致します。"
                    image={<img src={Chat} />}
                    plans={ ["lite", "standard","premium"] }
                />
    const insurance = <WebService
                        class="service-icon"
                        title="サイバーリスク保険"
                        text="vCISO会員のお客様については、不正アクセス、不正プログラム感染の際、事故調査費用として、200万円までを補償致します。情報漏洩事故の調査費用を社内で予算化していなくとも、確保する事ができます。"
                        image={<img src={Insurance} />}
                        plans={ ["lite", "standard","premium"] }
                      />
    const checklist = <WebService
                        title="セキュリティ健康診断"
                        text="SecurityScoreCardによる自社のセキュリティ評価を行います。ドメインの登録によりお客様の業務を止めたりする事なく自動で評価を行います。また、評価結果に対するお問い合わせ窓口も用意しております"
                        image={<img src={Page} id="service-page" />}
                        plans={ ["premium"] }
                    />
    const meeting = <WebService
                      class="service-icon"
                      title="個別カウンセリング"
                      text="GSXのセキュリティコンサルタントが年間2回お客様と直接お話をしながらセキュリティに関するご相談を承ります。"
                      image={<img src={Meeting} id="service-meeting" />}
                      plans={ ["premium"] }
                    />
    const elearning = <WebService
                        class="service-icon"
                        title="Eラーニング"
                        text="日常業務のなかで、留意を頂きたいセキュリティ対策をまとめた「情報セキュリティ研修コース」、IPAの情報セキュリティについて分かり易く学ぶ事ができる「IPA情報セキュリティ10大脅威2022組織編」から抜粋版を受講する事ができます。"
                        image={<img src={Computer} />}
                        plans={ ["standard","premium"] }
                    />
    
    const email = <WebService
                    class="service-icon"
                    title="合同メール訓練"
                    text="標的型メール訓練が会員価格でご利用可能です。従業員に対して、攻撃メールへの意識向上並びに初動対応について、教育訓練する事ができます。"
                    image={<img src={Email} />}
                    plans={ ["standard","premium"] }
                />

    const docs = <WebService
                    title="文書テンプレート"
                    text="テレワーク、クラウドサービス、スマートデバイス等、これまでにない新たなIT利用についても、ポリシー・ルールの整備が求められています。vCISOでは各種セキュリティポリシーに関する文書雛形（テンプレート）をご提供致します。"
                    image={<img src={DocIcon} />}
                    plans={ ["lite", "standard","premium"] }
                />


    let services = [chat, insurance, checklist, meeting, elearning, email, docs ]

    return (
        <Container className="">
          <div className="section-title-block">
            <h2 className="section-title">
              <img src={Logo} className="title-logo" alt="vCISO" style={{ "marginBottom": "15px", "marginRight": "5px" }}/>安心相談サービス<br />（サイバーリスク保険付き）
            </h2>
            <p className="subtext">
              企業として最低限必要であると考えられるセキュリティ対策サービスを取り揃えました</p>
          </div>

          <div className="section-subtitle-block">
            <h2 className="section-title">
              提供機能一覧
            </h2>
            <Row className="g-1 service-plan-types" style={{ "marginBottom": "50px" }}>
                <Col md={3} xs={6}>
                  <Row className="g-1 service-plan-type-item">
                    <Col md={2} xs={2} style={{ "display": "flex", "alignItems": "center" }}><span className="plan-point lite"></span></Col>
                    <Col md={10} xs={10} style={{ "textAlign": "left" }}><span>Liteプランで提供</span></Col>
                  </Row></Col>
                <Col md={3} xs={6}>
                  <Row className="g-1 service-plan-type-item">
                    <Col md={2} xs={2} style={{ "display": "flex", "alignItems": "center" }}><span className="plan-point standard"></span></Col>
                    <Col md={10} xs={10} style={{ "textAlign": "left" }}><span>Standardプランで提供</span></Col>
                  </Row>
                </Col>
                <Col md={3} xs={6}>
                  <Row className="g-1 service-plan-type-item">
                    <Col md={2} xs={2} style={{ "display": "flex", "alignItems": "center" }}><span className="plan-point premium"></span></Col>
                    <Col md={10} xs={10} style={{ "textAlign": "left" }}><span>Premiumプランで提供</span></Col>
                  </Row>
                </Col>
              </Row>
          </div>

            <Row className="g-3 service-list">
                {
                    services.map((service, index) => (
                        <Col xs={6} md={3} className="list-item" key={`${index}`}>
                            {service}
                        </Col>
                    ))
                }
            </Row>
            <br />
            {
                !sessionStorage.getItem("user") ?

            <Row className="g-0">
                <Container className="justify-content-center" style={{ 'display': 'flex'}}>
                    <Button className="rounded-pill btn-arrow-blue" onClick={() => navigate("services")}><span>サービス詳細はこちら</span></Button>
                </Container>
            </Row> :
            null
            }
        </Container>
    )
}