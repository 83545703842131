import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Button,
    Stack,
    Container,
    InputGroup,
    Table,
    Toast,
    ToastContainer,
} from "react-bootstrap";
import EmailTrainingEntry from "../emailtraining/EmailTrainingEntry";
import EmailTrainingCheck from "../emailtraining/EmailTrainingCheck";
import EventTable from "../emailtraining/EventTable";
import axios from "axios";
import * as XLSX from "xlsx";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../utils/axiosPrivate";

export default function EmailTraining(props) {
    const [trainings, setTrainings] = useState([]);
    const [selected, setSelected] = useState({
        clicked: false,
        trainingEvent: {},
        finished: false,
        started: false,
    });

    const [emails, setEmails] = useState([]);

    const [parsed, setParsed] = useState(false);

    const [file, setFile] = useState(null);

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(() => {
        let isSubscribed = true;
        //  //console.log(cookies.get("user"))
        axiosPrivate
            .get("/api/emailtraining/current")
            .then(function (response) {
                if (response.status === 200) {
                    if (isSubscribed) {
                        setTrainings(response.data);
                    }
                }
            })
            .catch(function (response) {
                console.error(response);
            });
        return () => (isSubscribed = false);
    }, []);

    const clickEvent = (training, over, started) => (event) => {
        //console.log(training)
        //console.log("over: " + over)
        //console.log("started: " + started)
        if (!over) {
            var selected = {
                trainingEvent: training,
                finished: over,
                started: started,
            };
            navigate("/emailtraining/registration", { state: selected });
        }
    };

    return (
        <Row className="EmailTraining">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　合同メール訓練受付</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            {/* <Col />
            <Col
                sm={12}
                md={10}
                lg={10}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }} */}
            {/* > */}
            <EventTable
                trainings={trainings}
                clickEvent={clickEvent}
                user={sessionStorage.getItem("user")}
            />
            {/* </Col>
            <Col /> */}
 
        </Row>
        
    );
}
