import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, Container, Form
} from 'react-bootstrap'
import AlertModal from '../AlertModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import ValidationError from "../ValidationError";

export default function EmailTrainingEventRegister(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const navigate = useNavigate();

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({})

    const onSubmit = (data) => {
       // console.log(data)
        var body = {
            "title": data.title,
            "recep_start": new Date(data.recep_start),
            "recep_end": new Date(data.recep_end),
            "event_start": new Date(data.event_start),
            "event_end": new Date(data.event_end)
        }

        axiosPrivate.post("/api/admin/emailtraining", body)
        .then(function(response) {
           // console.log(response)
            if (response.status === 200 && response.data.apiCode === 0){
                navigate(-1)
            } else {
                setAlert({ ...alert, show: true, message: "イベントの追加が失敗しました。"})
            }
        })
        .catch(function(response) {
           // console.log(response)
            setAlert({ ...alert, show: true, message: "イベントの追加が失敗しました。"})
        })

    }

    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <Row className="g-0">
                        <Col />
                        <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                            <h2 style={{ "alignSelf": "center" }}>メール訓練　イベント作成</h2>
                        </Col>
                        <Col />
                    </Row>
                    <hr style={{ "width": "100%" }} />
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-0">
                            <Col xs={6}>
                                <Form.Group className="my-4">
                                    <Form.Label>
                                        タイトル
                                    </Form.Label>
                                    <Form.Control style={{ "width": "60%"}} type="text" {...register("title", { required: { value: true, message: "タイトルを入力してください。"}})} />
                                    {
                                        errors.title && (
                                        <ValidationError style={{ "width": "60%"}} message={errors.title.message} />
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs={3}>
                                <Form.Group className="my-4">
                                    <Form.Label>
                                        募集開始日
                                    </Form.Label>
                                    <Form.Control type="date" style={{ "width": "70%"}} {...register("recep_start", { required: { value: true, message: "日時を選択してください。"}})} />
                                    {
                                        errors.recep_start && (
                                        <ValidationError style={{ "width": "70%"}} message={errors.recep_start.message} />
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="my-4">
                                    <Form.Label>
                                        募集終了日
                                    </Form.Label>
                                    <Form.Control type="date" style={{ "width": "70%"}} {...register("recep_end", { required: { value: true, message: "日時を選択してください。"}})} />
                                    {
                                        errors.recep_end && (
                                        <ValidationError style={{ "width": "70%"}} message={errors.recep_end.message} />
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs={3}>
                                <Form.Group className="my-4">
                                    <Form.Label>
                                        実施期間
                                    </Form.Label>
                                    <Form.Control type="date" style={{ "width": "70%"}} {...register("event_start", { required: { value: true, message: "日時を選択してください。"}})} />
                                    {
                                        errors.event_start && (
                                        <ValidationError style={{ "width": "70%"}} message={errors.event_start.message} />
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={3}>
                                <Form.Group className="my-4" style={{ "paddingTop": "32px" }}>
                                    <Form.Control type="date"  style={{ "width": "70%"}} {...register("event_end", { required: { value: true, message: "日時を選択してください。"}})} />
                                    {
                                        errors.event_end && (
                                        <ValidationError style={{ "width": "70%"}} message={errors.event_end.message} />
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs={12}>
                                <Button type="submit" className="my-4">イベントを追加する</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
            <Col />
        </Row>
    )
}