import React, { useState } from "react";
import { Row, Col, Button, Alert, Form, Stack } from "react-bootstrap";
import ValidationError from "../ValidationError";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import { useNavigate } from "react-router";
import AlertModal from "../AlertModal";

export default function SubAccountSignup(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [success, setSuccess] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        ////console.log(data)
        axiosPrivate
            .post("/api/register", data)
            .then(function (response) {
                ////console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setSuccess(true);
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "エラーが発生しました。",
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "メールの送信が失敗しました。",
                });
            });
    };

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const handleResend = (event) => {
        var data = getValues();
        axiosPrivate
            .post("/api/register", data)
            .then(function (response) {
                ////console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "メールを再送信しました。",
                    });
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "エラーが発生しました。",
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "メールの送信が失敗しました。",
                });
            });
    };

    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            {/* <Col />
            <Col
                sm={12}
                md={6}
                lg={6}
                className="h-100"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }} */}
            {/* > */}
            <Stack
                gap={4}
                // style={{
                //     width: "100%",
                //     height: "100%",
                //     display: "flex",
                //     alignItems: "center",
                //     alignSelf: "center",
                // }}
                className="p-4 SubAccountSignup"
            >
                <h2>サブアカウントメールアドレス登録</h2>
                <div className="shadow-lg p-4">
                    {success ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "justify",
                                justifyContent: "center",
                            }}
                        >
                            <div>
                                {getValues("mail")}
                                に仮登録メールを送信いたしました。
                            </div>
                            <br />
                            <div>
                                {" "}
                                もし、ご連絡メールが届かない場合には迷惑メールフォルダのチェックをお願いいたします。{" "}
                            </div>
                            <Row className="g-0">
                                <Col xs={3} />
                                <Col xs={3}>
                                    <Button
                                        size="sm"
                                        style={{
                                            alignSelf: "center",
                                            width: "fit-content",
                                            marginTop: "1rem",
                                        }}
                                        onClick={handleResend}
                                    >
                                        再送信する
                                    </Button>
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        size="sm"
                                        style={{
                                            alignSelf: "center",
                                            width: "fit-content",
                                            marginTop: "1rem",
                                        }}
                                        onClick={() => navigate("/user")}
                                    >
                                        会員情報へ
                                    </Button>
                                </Col>
                                <Col xs={3} />
                            </Row>
                        </div>
                    ) : (
                        <Form
                            className="form-area"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Form.Group className="edit-form-area">
                                <Row className="g-0">
                                    <Form.Label>メールアドレス</Form.Label>
                                    <Form.Control
                                        type="email"
                                        {...register("mail", {
                                            required: {
                                                value: true,
                                                message:
                                                    "メールアドレスを入力してください。",
                                            },
                                            pattern: {
                                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message:
                                                    "メールフォーマットは無効です。",
                                            },
                                        })}
                                    />
                                    {errors.mail && (
                                        <ValidationError
                                            message={errors.mail.message}
                                        />
                                    )}
                                </Row>
                            </Form.Group>
                            <div className="button-area-2">
                                <Button
                                    className="rounded-pill btn-blue-2"
                                    type="submit"
                                >
                                    仮登録する
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            </Stack>
        </Row>
    );
}
