import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Table, Modal, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { NavigationType, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import AlertModal from "../AlertModal";

const planName = {
    2: "Lite",
    3: "Standard",
    4: "Premium",
};

const planCostMonthly = {
    2: "15,000円",
    3: "30,000円",
    4: "50,000円",
};

const planCostYearly = {
    2: "180,000円",
    3: "360,000円",
    4: "600,000円",
};

export default function UserPlan(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const [subscription, setSubscription] = useState({
        planId: "",
        companyId: "",
        recurringType: "",
    });

    const [responseSub, setResponseSub] = useState("");

    const [show, setShow] = useState(false);
    const [insurance, setInsurance] = useState(true);

    const navigate = useNavigate();

    const handleChange = (prop) => (event) => {
        setSubscription({ ...subscription, [prop]: event.target.value });
    };

    const handleCheck = (event) => {
        setInsurance(event.target.checked);
    };

    const handleAlertShow = (prop) => setAlert({ ...alert, show: prop });
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };
    const handlePlanChange = () => {
        // //console.log(subscription);
        var body = {
            companyId: subscription.companyId,
            planId: subscription.planId,
        };
        ////console.log(body)
        axiosPrivate
            .put("/api/subscription", body)
            .then(function (response) {
                // //console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "料金プランを変更しました。",
                    });
                } else {
                    //console.log(response)
                    setAlert({
                        ...alert,
                        show: true,
                        message: "料金プランの変更が失敗しました。",
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "料金プランの変更が失敗しました。",
                });
            });
    };

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate
            .get("/api/subscription")
            .then(function (response) {
                //console.log(response)
                if (response.status === 200) {
                    var data = response.data;
                    if (isSubscribed) {
                        setSubscription({
                            ...subscription,
                            planId: `${data.data.planId}`,
                            companyId: `${data.data.companyId}`,
                            recurringType: `${data.data.recurringType}`,
                        });
                        setResponseSub(`${data.data.planId}`);
                    }
                }
            })
            .catch(function (response) {
                setAlert({
                    ...alert,
                    show: true,
                    message: "エラーが発生しました。",
                });
            });
        return () => (isSubscribed = false);
    }, []);

    return (
        <Stack className="Common RyokinPlan">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　料金プラン</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <AlertModal
                show={alert.show}
                setShow={handleAlertShow}
                message={alert.message}
            />
            <br />
            <Row className="g-0">
                <div className="g-0 row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <section>
                            <div
                                className="template-customize-mypage-area"
                                style={{
                                    justifyContent: "right",
                                    display: "grid",
                                }}
                            >
                                <Button
                                    className="rounded-pill btn-blue-2"
                                    onClick={handleMypage}
                                >
                                    マイページへ戻る
                                </Button>
                            </div>
                            <ul className="price_mypage">
                                <li
                                    className="price_table g-0 row"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <div
                                        className="plan_category col-lg-3 col-12"
                                        style={{ fontSize: "30px" }}
                                    >
                                        プラン選択
                                    </div>
                                    <div
                                        className="plan_lite col-lg-3 col-4"
                                        style={{ fontSize: "40px" }}
                                    >
                                        <Form.Check
                                            name="standard-plan"
                                            type="radio"
                                            value="3"
                                            checked={
                                                subscription.planId === "3"
                                            }
                                            disabled={responseSub === "4"}
                                            onChange={handleChange("planId")}
                                        />
                                    </div>
                                    <div
                                        className="plan_standard col-lg-3 col-4"
                                        style={{ fontSize: "40px" }}
                                    >
                                        <Form.Check
                                            name="standard-plan"
                                            type="radio"
                                            value="3"
                                            checked={
                                                subscription.planId === "3"
                                            }
                                            disabled={responseSub === "4"}
                                            onChange={handleChange("planId")}
                                        />
                                    </div>
                                    <div
                                        className="plan_premium col-lg-3 col-4"
                                        style={{ fontSize: "40px" }}
                                    >
                                        <Form.Check
                                            name="premium-plan"
                                            type="radio"
                                            value="4"
                                            checked={
                                                subscription.planId === "4"
                                            }
                                            onChange={handleChange("planId")}
                                        />
                                    </div>
                                </li>
                      
                                
                                <li className="price_header g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        サービスメニュー
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <div className="plan_box_mypage">
                                            <h2 className="plan_name_mypage">
                                                <div>Liteプラン</div>
                                            </h2>
                                            <div className="plan_body_mypage">
                                                <p>
                                                    とにかくリーズナブルにセキュリティ対策の第一歩を踏み出したいというお客様にピッタリ
                                                </p>
                                                <div className="monthly">
                                                    15,000
                                                </div>
                                                <div className="yearly">
                                                    180,000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="plan_standard_mypage col-lg-3 col-4">
                                        <div className="plan_box_mypage">
                                            <h2 className="plan_name_mypage"
                                            >
                                                <div>
                                                    Standard
                                                    <br />
                                                    プラン
                                                </div>
                                            </h2>
                                            <div className="plan_body_mypage">
                                                <p>
                                                    Liteプランに加えて当社自信の「教育メニュー」を加えたバランスの取れたオススメプラン
                                                </p>
                                                <div className="monthly">
                                                    30,000
                                                </div>
                                                <div className="yearly">
                                                    360,000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="plan_premium_mypage col-lg-3 col-4">
                                        <div className="plan_box_mypage">
                                            <h2 className="plan_name_mypage">
                                                <div>
                                                    Premium
                                                    <br />
                                                    プラン
                                                </div>
                                            </h2>
                                            <div className="plan_body_mypage">
                                                <p>
                                                    より規模の大きなお客様、外部評価や本格対策のご検討があるお客様向け
                                                </p>
                                                <div className="monthly">
                                                    50,000
                                                </div>
                                                <div className="yearly">
                                                    600,000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        よろづ相談
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        文書テンプレート
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        Eラーニング
                                        <br />
                                        （管理者、履歴管理機能付）
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        合同メール訓練
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        セキュリティ健康診断
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        個別カウンセリング
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        2回（60分）/年間
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        サイバーリスク保険
                                        <br />
                                        （200万円補償）
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        対象企業規模
                                        <br />
                                        （原則300名まで）
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                            </ul>
                        </section>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </Row>
            <br />

            <div className="template-customize-area">
                <Button className="btn btn-primary" onClick={handlePlanChange}>
                    プランを変更する
                </Button>
            </div>
            <br />
            <br />
        </Stack>
    );
}
