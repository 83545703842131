import React, { useState } from "react";
import { Row, Col, Button, Form, Container, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ValidationError from "../ValidationError";
import { useNavigate } from "react-router";
import AlertModal from "../AlertModal";
import { axiosPrivate } from "../../../utils/axiosPrivate";

export default function SecCheckInquiry(props) {
    const [sent, setSent] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const [confirm, setConfirm] = useState(false);

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = () => {
        setConfirm(true);
    };

    const handleSubmitData = () => {
        var data = getValues();
        var body = {
            title: data.title,
            content: data.content,
        };

        axiosPrivate
            .post("/api/seccheck/inquiry", body)
            .then(function (response) {
                //console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setSent(true);
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.message,
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "お問い合わせの送信が失敗しました。",
                });
            });
    };

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    return (
        <Row className="g-0 SecurityHealthCheck">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />

            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>
                        {sent ? (
                            <h1>　セキュリティ健康診断お問い合わせ結果</h1>
                        ) : !sent && confirm ? (
                            <h1>　セキュリティ健康診断お問い合わせ確認</h1>
                        ) : (
                            <h1>　セキュリティ健康診断お問い合わせ入力</h1>
                        )}
                    </h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        {sent ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0 10px",
                                }}
                                className="inquiry-width customize-width"
                            >
                                <br />
                                <br />

                                <div>
                                    セキュリティ健康診断結果のお問い合わせを受付けました。
                                </div>
                                <br />
                                <div>
                                    ご返信には数日程度かかることがあります。
                                </div>
                                <div>
                                    以後のやり取りについてはメールにて承ります。
                                </div>
                                <br />
                                <Row className="template-customize-area button-flex">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() =>
                                            navigate("/securitycheck", {
                                                replace: true,
                                            })
                                        }
                                    >
                                        セキュリティ健康診断へ戻る
                                    </Button>
                                </Row>
                                <br />
                                <br />
                            </div>
                        ) : (
                            <Form
                                style={{
                                    borderRadius: ".5rem",
                                    display: "flex",
                                    flexDirection: "column",
                                    // width: "94%",
                                }}
                                className="inquiry-width"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Form.Group className="my-3">
                                    <Form.Label>タイトル</Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={confirm}
                                        {...register("title", {
                                            required: {
                                                value: true,
                                                message:
                                                    "タイトルを入力してください。",
                                            },
                                        })}
                                    />
                                    {errors.title && (
                                        <ValidationError
                                            message={errors.title.message}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group className="my-3">
                                    <Form.Label>お問い合わせ</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={5}
                                        disabled={confirm}
                                        {...register("content", {
                                            required: {
                                                value: true,
                                                message:
                                                    "お問い合わせ内容を入力してください。",
                                            },
                                        })}
                                    />
                                    {errors.content && (
                                        <ValidationError
                                            message={errors.content.message}
                                        />
                                    )}
                                </Form.Group>
                                <br />
                                <Row className="template-customize-area button-flex">
                                    {confirm ? (
                                        <Button
                                            className="btn btn-primary button-margin"
                                            onClick={() => setConfirm(false)}
                                        >
                                            再入力
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn btn-primary button-margin"
                                            // style={{ marginBottom: "10px" }}
                                            onClick={() => navigate(-1)}
                                        >
                                            セキュリティ健康診断へ戻る
                                        </Button>
                                    )}
                                    {confirm ? (
                                        <Button
                                            onClick={handleSubmitData}
                                            className="btn btn-primary"
                                        >
                                            送信
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn btn-primary"
                                            type="submit"
                                        >
                                            確認画面へ
                                        </Button>
                                    )}
                                </Row>
                                <br />
                                <br />
                            </Form>
                        )}
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Row>
    );
}
