import React, { useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Incident from "./Incident";

export default function IncidentHistory(props) {
    const { incidents } = props;

    return (
        <div>
            <section style={{ padding: "0 10px" }}>
                <h2 className="content_title">
                    <div>送信記録</div>
                </h2>
                <div style={{ height: "400px", overflowY: "auto" }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>No</th>
                                <th style={{ textAlign: "left" }}>送信日時</th>
                                <th style={{ textAlign: "left" }}>
                                    インシデントの種類
                                </th>
                                <th style={{ textAlign: "left" }}>状況</th>
                                <th style={{ textAlign: "left" }}>発生日時</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incidents.length > 0
                                ? incidents.map((incident, index) => {
                                      var created_at = new Date(
                                          incident.created_at
                                      );
                                      var occurred = new Date(
                                          incident.occurred_at
                                      );

                                      if (created_at.getHours() < 12) {
                                          created_at.setDate(
                                              created_at.getDate() - 1
                                          );
                                      } else {
                                          created_at.setHours(
                                              created_at.getHours() - 12
                                          );
                                      }

                                      if (occurred.getHours() < 12) {
                                          occurred.setDate(
                                              occurred.getDate() - 1
                                          );
                                      } else {
                                          occurred.setHours(
                                              occurred.getHours() - 12
                                          );
                                      }
                                      return (
                                          <tr
                                              key={`${index}_${incidents[0]}`}
                                              id="incidenthistory"
                                              style={{
                                                  maxHeight: "35vh",
                                                  overflowY: "auto",
                                              }}
                                          >
                                              <th
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >{`${index + 1}`}</th>
                                              <td
                                                  className="td-1"
                                              >
                                                  {created_at.toLocaleDateString()}
                                              </td>
                                              <td
                                                  className="td-2"
                                              >
                                                  {incident.type}
                                              </td>
                                              <td
                                                  className="td-3"
                                              >
                                                  {incident.content}
                                              </td>
                                              <td
                                                  className="td-4"
                                              >
                                                  {occurred
                                                      .toLocaleString("ja-JP")
                                                      .slice(
                                                          0,
                                                          occurred.toLocaleString(
                                                              "ja-JP"
                                                          ).length - 3
                                                      )}
                                              </td>
                                          </tr>
                                          //   <Incident
                                          //       incident={incident}
                                          //       key={`${index}_${incident.type}`}
                                          //   />
                                      );
                                  })
                                : null}
                        </tbody>
                    </Table>
                </div>
                {/* <div style={{ height: "400px", overflowY: "auto" }}>
                    <Table striped bordered hover>
                        <tbody>
                            <tr style={{ backgroundColor: "#f2f7fa" }}>
                                <th
                                    style={{
                                        backgroundColor: "#f2f7fa",
                                        textAlign: "center",
                                    }}
                                >
                                    No
                                </th>
                                <td style={{ textAlign: "left" }}>送信日時</td>
                                <td style={{ textAlign: "left" }}>
                                    インシデントの種類
                                </td>
                                <td style={{ textAlign: "left" }}>状況</td>
                                <td style={{ textAlign: "left" }}>発生日時</td>
                            </tr>
                            {incidents.length > 0
                                ? incidents.map((incident, index) => {
                                      var created_at = new Date(
                                          incident.created_at
                                      );
                                      var occurred = new Date(
                                          incident.occurred_at
                                      );

                                      if (created_at.getHours() < 12) {
                                          created_at.setDate(
                                              created_at.getDate() - 1
                                          );
                                      } else {
                                          created_at.setHours(
                                              created_at.getHours() - 12
                                          );
                                      }

                                      if (occurred.getHours() < 12) {
                                          occurred.setDate(
                                              occurred.getDate() - 1
                                          );
                                      } else {
                                          occurred.setHours(
                                              occurred.getHours() - 12
                                          );
                                      }
                                      return (
                                          <tr
                                              key={`${index}_${incidents[0]}`}
                                              id="incidenthistory"
                                              style={{
                                                  maxHeight: "35vh",
                                                  overflowY: "auto",
                                              }}
                                          >
                                              <th
                                                  style={{
                                                      textAlign: "center",
                                                  }}
                                              >{`${index + 1}`}</th>
                                              <td style={{ textAlign: "left" }}>
                                                  {created_at.toLocaleDateString()}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                  {incident.type}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                  {incident.content}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                  {occurred
                                                      .toLocaleString("ja-JP")
                                                      .slice(
                                                          0,
                                                          occurred.toLocaleString(
                                                              "ja-JP"
                                                          ).length - 3
                                                      )}
                                              </td>
                                          </tr>
                                          //   <Incident
                                          //       incident={incident}
                                          //       key={`${index}_${incident.type}`}
                                          //   />
                                      );
                                  })
                                : null}
                        </tbody>
                    </Table>
                </div> */}
                <br />
                <br />
            </section>
        </div>
    );
}
