import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import { Form } from "react-bootstrap";

import LoginButton from "../elements/button/login";
import LogOutButton from "../elements/button/logout";
import registrationButton from "../elements/button/registration";
import {
    Navbar,
    Container,
    Nav,
    Button,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/vciso_logo.png";
import Cookies from "universal-cookie";
import withRouter from "../withRouter";
import $ from "jquery";

function Navigation(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (prop) => (event) => {
        navigate(prop);
    };

    const handleLogout = () => {
        setShow(false);
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh");
        navigate("/");
        props.setUserInfo({
            name: "",
            plan: "",
        });
        props.setLogin(false);
        window.location.reload();
    };

    useEffect(() => {
        if ($("chat").length > 0 && location.pathname !== "/chatservice") {
            //console.log("chat is displayed")
            $("chat").css("display", "none");
        }
        window.scrollTo(0, 0);
    }, [location]);

    const cookies = new Cookies();

    const plan =
        props.userInfo.plan.charAt(0) +
        props.userInfo.plan.slice(1).toLowerCase();
    const login = (
        <Button
            className="rounded-pill mx-2 btn-blue-border"
            size="sm"
            onClick={handleClick("login")}
        >
            ログイン
        </Button>
    );
    const logout = (
        <Button
            className="rounded-pill mx-2 btn-blue-border"
            size="sm"
            onClick={handleShow}
        >
            ログアウト
        </Button>
    );
    const userInfo = (
        <div
            className="px-2 user-info-sp"
            style={{ fontSize: "14px", fontWeight: "bold" }}
        >{`${props.userInfo.name} (プラン: ${plan})`}</div>
    );

    const menuBarInfo = {
        navFunc: navigate,
        userInfo: props.userInfo.name,
        logoutFunc: handleLogout,
    };

    return (
        <>
            <div id="topnav">
                <Navbar
                    expand="lg"
                    style={{
                        fontSize: "14px",
                        alignItems: "stretch",
                        minHeight: "50px",
                    }}
                >
                    <Navbar.Brand
                        style={{
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <a href="#">
                            <img
                                src={logo}
                                id="vciso-logo"
                                height="31px"
                                onClick={() => navigate("/")}
                            />
                        </a>
                    </Navbar.Brand>
                    <Nav className="sp-nav">
                        {!props.userInfo.name &&
                            registrationButton(menuBarInfo)}
                        {!props.userInfo.name && LoginButton(menuBarInfo)}
                        {props.userInfo.name && LogOutButton(menuBarInfo)}
                    </Nav>
                    <Nav
                        className="ms-auto header-menu"
                        style={{ flexDirection: "row" }}
                        activeKey={0}
                    >
                        <Nav.Link onClick={() => navigate("/cyberarticles")}>
                            セキュリティNEWS
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/originalcontent")}>
                            {"GSXオリジナルコンテンツ"}
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/inquiry")}>
                            お問い合わせ
                        </Nav.Link>
                        {sessionStorage.getItem("access_token") ? (
                            <Nav.Link onClick={() => navigate("/manual")}>
                                マニュアル
                            </Nav.Link>
                        ) : null}
                    </Nav>
                    <div id="nav-controls">
                        {props.userInfo.name.length > 0 ? userInfo : null}
                        <Nav className="nav-buttons">
                            {sessionStorage.getItem("access_token") ? null : (
                                <Button
                                    className="rounded-pill mx-2 btn-green"
                                    size="sm"
                                    onClick={() => navigate("magazine")}
                                >
                                    メルマガ登録
                                </Button>
                            )}
                            {sessionStorage.getItem("access_token") ? null : (
                                <Button
                                    className="rounded-pill mx-2 btn-blue"
                                    size="sm"
                                    onClick={() =>
                                        navigate("inquiry", {
                                            state: {
                                                type: "30日間無料お試し申込",
                                            },
                                        })
                                    }
                                >
                                    30日無料お試し
                                </Button>
                            )}
                            <Button
                                className="rounded-pill btn-yellow mx-2"
                                size="sm"
                                onClick={
                                    sessionStorage.getItem("user")
                                        ? handleClick("mypage")
                                        : handleClick("signup")
                                }
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />
                                {sessionStorage.getItem("user")
                                    ? "マイページ"
                                    : "新規申込"}
                            </Button>
                            {sessionStorage.getItem("access_token")
                                ? logout
                                : login}
                        </Nav>
                    </div>
                </Navbar>
                <Modal
                    size="lg"
                    centered
                    className="p-5"
                    show={show}
                    onHide={handleClose}
                >
                    <Modal.Body>
                        <Form className="form-area">
                            <Form.Group className="edit-form-area">
                                <Row className="g-0">
                                    <Form.Label>
                                        ログアウトしてよろしいですか？
                                    </Form.Label>
                                </Row>
                            </Form.Group>

                            <div
                                className="button-area-2"
                                style={{ display: "flex" }}
                            >
                                <Button
                                    className="modal-confrim-btn button-margin"
                                    onClick={handleClose}
                                >
                                    キャンセル
                                </Button>
                                <Button
                                    className="modal-confrim-btn"
                                    onClick={handleLogout}
                                >
                                    ログアウト
                                </Button>
                            </div>
                            <br />
                            <br />
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            {Menubar(menuBarInfo, plan)}
        </>
    );
}

function Menubar(props, plan) {
    const menuBarRef = useRef();
    const menuItemsRef = useRef();
    const navFunc = props.navFunc;

    const openMenubar = () => {
        const isOpen = menuBarRef.current.classList.contains("menu-open")
            ? true
            : false;
        closeMenuBar();
        if (!isOpen) {
            menuBarRef.current.classList.add("menu-open");
            menuItemsRef.current.classList.add("show-menubar");
        }
    };

    const closeMenuBar = () => {
        menuBarRef.current.classList.remove("menu-open");
        menuItemsRef.current.classList.remove("show-menubar");
    };

    let menuItems = [];

    menuItems = [
        { itemName: "vCISOとは", url: "/about", isDisable: true },
        { itemName: "サービス詳細", url: "/services", isDisable: true },
        { itemName: "料金プラン", url: "/ryokinplan", isDisable: true },
        { itemName: "導入事例", url: "/", isDisable: false },
        { itemName: "よくある質問", url: "/faq", isDisable: true },
    ];

    if (props.userInfo) {
        switch (plan) {
            case "Lite":
                menuItems = [
                    {
                        itemName: "文書テンプレート",
                        url: "/doctemplates",
                        isDisable: true,
                    },
                ];
                break;
            case "Standard":
                menuItems = [
                    {
                        itemName: "文書テンプレート",
                        url: "/doctemplates",
                        isDisable: true,
                    },
                    {
                        itemName: "Eラーニング",
                        url: "/elearning",
                        isDisable: true,
                    },
                    {
                        itemName: "合同メール訓練",
                        url: "/emailtraining",
                        isDisable: true,
                    },
                ];
                break;
            case "Premium":
                menuItems = [
                    {
                        itemName: "文書テンプレート",
                        url: "/doctemplates",
                        isDisable: true,
                    },
                    {
                        itemName: "Eラーニング",
                        url: "/elearning",
                        isDisable: true,
                    },
                    {
                        itemName: "合同メール訓練",
                        url: "/emailtraining",
                        isDisable: true,
                    },
                    {
                        itemName: "セキュリティ診断",
                        url: "/securitycheck",
                        isDisable: true,
                    },
                    {
                        itemName: "個別カウンセリング",
                        url: "/counseling",
                        isDisable: true,
                    },
                ];
                break;
        }
        menuItems.push(
            { itemName: "人材マッチング", url: "/", isDisable: false },
            { itemName: "インシデント対応", url: "/incident", isDisable: true }
        );
    }

    const trialButton = (
        <div className="header-button">
            <Button
                className="btn-blue rounded-pill"
                style={{ padding: "1.5em 0" }}
                onClick={() => {
                    navFunc("inquiry");
                    closeMenuBar();
                }}
            >
                30日間無料お試し申込はこちら
            </Button>
        </div>
    );
    const mailMagazine = (
        <div className="header-button">
            <Button
                className="btn-green rounded-pill"
                style={{ padding: "1.5em 0" }}
                onClick={() => {
                    navFunc("magazine");
                    closeMenuBar();
                }}
            >
                メールマガジン登録
            </Button>
        </div>
    );

    const myPageButton = (
        <div className="header-button">
            <Button
                className="btn-yellow rounded-pill"
                style={{ padding: "1.5em 0" }}
                onClick={() => {
                    navFunc("mypage");
                    closeMenuBar();
                }}
            >
                マイページ
            </Button>
        </div>
    );

    return (
        <>
            <div
                className="icon-hamburger"
                onClick={openMenubar}
                ref={menuBarRef}
            >
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
            </div>
            <div className="nav-menu-area-sp" ref={menuItemsRef}>
                <ul className="nav-list">
                    {props.userInfo && (
                        <li
                            key="chat-service"
                            className="nav-item chat-service"
                        >
                            <a href="/chatservice">よろづ相談</a>
                        </li>
                    )}
                    {menuItems.map((items, index) => (
                        <li key={index} className="nav-item">
                            <div
                                className={`${
                                    items.isDisable ? "" : "disabled"
                                }`}
                                onClick={() => {
                                    closeMenuBar();
                                    navFunc(items.url);
                                }}
                            >
                                {items.itemName}
                            </div>
                        </li>
                    ))}
                </ul>
                <hr className="section-hr" />
                {!props.userInfo ? (
                    <div className="sp-menu-buttons">
                        {trialButton}
                        {mailMagazine}
                    </div>
                ) : (
                    <div className="sp-menu-buttons">{myPageButton}</div>
                )}
            </div>
        </>
    );
}

export default withRouter(Navigation);
