import React from "react";
import { Row, Col, Form, Button, Stack } from "react-bootstrap";
import ValidationError from "../ValidationError";

export default function InquiryEntry(props) {
    const {
        handleCheck,
        inquiry,
        handleConfirm,
        register,
        errors,
        handleSubmit,
        state,
    } = props;

    const confirmSubmit = (data) => {
        //console.log(data)
    };

    return (
        <Stack className="Common">
            <br />
            <br />

            <Form noValidate onSubmit={handleSubmit(handleConfirm)}>
                <Form.Group className="my-3">
                    <Form.Label>お問い合わせ区分　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Select
                        {...register("type", {
                            required: {
                                value: true,
                                message: "お問い合わせ内容を選択してください",
                            },
                            validate: (value) =>
                                value !== "選択してください" ||
                                "お問い合わせ内容を選択してください",
                        })}
                    >
                        い
                        <option key="defaultType" hidden>
                            選択してください
                        </option>
                        <option>サービスの詳細を知りたい​</option>
                        <option>販売代理店を紹介してほしい​</option>
                        <option>
                            vCISOを通したビジネスアライアンスの相談がしたい
                        </option>
                        <option>30日間無料お試し申込</option>
                        <option>その他</option>
                    </Form.Select>
                </Form.Group>
                {errors.type && (
                    <ValidationError message={errors.type.message} />
                )}
                <Form.Group>
                    <Form.Label>会社名　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        type="text"
                        {...register("company", {
                            required: {
                                value: true,
                                message: "会社名を入力してください。",
                            },
                        })}
                    />
                </Form.Group>
                {errors.company && (
                    <ValidationError message={errors.company.message} />
                )}
                <Form.Group className="my-3">
                    <Form.Label>部署名　</Form.Label>
                    <Form.Label className="any-lable"></Form.Label>
                    <Form.Control type="text" {...register("dept")} />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>お名前　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        type="text"
                        {...register("name", {
                            required: {
                                value: true,
                                message: "お名前を入力してください。",
                            },
                        })}
                    />
                </Form.Group>
                {errors.name && (
                    <ValidationError message={errors.name.message} />
                )}
                <Form.Group className="my-3">
                    <Form.Label>メールアドレス　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        type="text"
                        {...register("email", {
                            required: {
                                value: true,
                                message: "メールアドレスを入力してください。",
                            },
                            pattern: {
                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "メールフォーマットは無効です。",
                            },
                        })}
                    />
                </Form.Group>
                {errors.email && (
                    <ValidationError message={errors.email.message} />
                )}
                <Form.Group className="my-3">
                    <Form.Label>電話番号　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        type="text"
                        {...register("mobile", {
                            required: {
                                value: true,
                                message: "電話番号を入力してください。",
                            },
                        })}
                    />
                </Form.Group>
                {errors.mobile && (
                    <ValidationError message={errors.mobile.message} />
                )}
                <Form.Group className="my-3">
                    <Form.Label>お問い合わせ内容　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        type="text"
                        as="textarea"
                        rows={6}
                        {...register("content", {
                            required: {
                                value: true,
                                message: "お問い合わせ内容を入力してください。",
                            },
                        })}
                    />
                </Form.Group>
                {errors.content && (
                    <ValidationError message={errors.content.message} />
                )}
                <div
                    className="my-3"
                    style={{ whiteSpace: "pre-wrap", fontSize: "16px" }}
                >
                    個人情報の取り扱いについて{`\n`}
                    詳しくはGSX「
                    <a
                        href="https://www.gsx.co.jp/privacy.html"
                        target="_blank"
                        // className="mb-1"
                    >
                        個人情報の取り扱いについて
                    </a>
                    」をご覧ください。{`\n`}
                    上記の個人情報のお取り扱いにご同意頂ける場合、下記チェックボックスにチェックをお願い致します。
                </div>
                <Form.Group className="my-3">
                    <Form.Check
                        checked={inquiry.agree}
                        className="check-inline"
                        type="checkbox"
                        id="info-check"
                        name="info-check"
                        {...register("agree", {
                            onChange: handleCheck,
                            validate: (value) =>
                                value === true ||
                                "個人情報の取り扱いに同意をお願いします。",
                        })}
                    />
                    <Form.Label>個人情報の取り扱いに同意します。　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    {errors.agree && (
                        <ValidationError message={errors.agree.message} />
                    )}
                </Form.Group>

                <Row className="template-customize-area">
                    <Button className="btn btn-primary" type="submit">
                        確認画面へ
                    </Button>
                </Row>

                <br />
                <br />
            </Form>
        </Stack>
    );
}
