import React, { useState } from 'react';
import {
    Container, Row, Col, Form, Stack, Button, Alert
} from 'react-bootstrap';
import logo from '../../assets/logo.png';

export default function MFAConfirm(props) {

    const [mfa, setMFA] = useState("");

    const [error, setError] = useState({
        show: false,
        text: ""
    })

    const handleChange = (event) => {
        setMFA(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setError({ show: true, text: "Error"})
    }

    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <Col md={3} lg={4}>
            </Col>
            <Col sm={12} md={6} lg={4} className="h-100" style={{ "display": "flex", "justifyContent":"center", "alignItems": "center"}}>
                <Stack gap={3} style={{ "maxWidth": "450px", "marginBottom": "10vh", "height": "30vh", "borderRadius": ".5rem", "display": "flex", "justifyContent": "center", "alignItems": "center", "alignSelf": "center"}} className="p-4 shadow-lg">
                    <img src={logo} height="30rem"/>
                    <Form style={{ "display": "flex", "flexDirection": "column"}}>
                        <Form.Group>
                            <Form.Control className="my-3" type="text" placeholder="ワンタイムパスワード" onChange={handleChange} />
                        </Form.Group>
                        <Button size="sm w-100" style={{ "alignSelf": "center"}} onClick={handleSubmit}>送信</Button>
                    </Form>
                    <Alert show={error.show} style={{ "width": "207px"}} onClose={() => setError({ show: false })} variant="danger" dismissible>
                        {error.text}
                    </Alert>
                </Stack>
            </Col>
            <Col md={3} lg={4}>
            </Col>
        </Row>
    )
}