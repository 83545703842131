import axios from 'axios';
import logo from '../assets/logo.png';
const cheerio = require('cheerio');

export default async function getImageFromRSS(feedName, url) {
    var result;
    return axios.get(`https://14rphqgebl.execute-api.us-east-1.amazonaws.com/dev/?url=${url}`)
    .then(response => {
    ////console.log(response)
    
        if (response.status === 200) {
            ////console.log(response);
            let html = response.data;
            const $ = cheerio.load(html);
            if (feedName === 'ScanNetSecurity') {
                result = $('meta[name="thumbnail"]').attr('content');
            } else {
                result = $('meta[property="og:image"]').attr('content');
            }
            if (result === undefined) {
                result = logo;
            }
            ////console.log(result)
            return result;
        }
    
    })

}