import React, { useState } from 'react' ;
import PaymentPlan from './PaymentPlan';
import RegisterCheck from './RegisterCheck';
import RegisterEntry from './RegisterEntry';

export default function RegisterSubscriber(props) {

    const { 
        confirm, 
        validated,
        plan,
        handlePlanChange,
        setConfirm,
        goToPayment,
        checkValues,
        subsChecked,
        confirmPass,
        handleSubmitConfirm,
        handleReentry,
        addAddress,
        getValues,
        handleObjChange,
        handleSubmitInfo,
        values,
        setAddress,
        handleChange,
        handleCheck,
        confirmPassword,
        handleConfirmPass,
        register,
        handleSubmit,
        errors,
        policyAgree,
        status
    } = props;


    return (
        <>
        {
            confirm ?
            <PaymentPlan plan={plan} handleChange={handlePlanChange} setConfirm={setConfirm} goToPayment={goToPayment} status={status}/> : 
        
            validated ? 
            <RegisterCheck
                subsChecked={subsChecked} 
                values={checkValues}
                objValues={values} 
                confirmPass={confirmPass} 
                handleSubmit={handleSubmitConfirm} 
                handleClick={handleReentry} 
                addAddress={addAddress} 
                policyAgree={policyAgree}
            /> :
            
            <RegisterEntry 
                subsChecked={subsChecked}
                getValues={getValues}
                handleObjChange={handleObjChange}
                validated={validated}
                onSubmit={handleSubmitInfo} 
                values={values} 
                setAddress={setAddress} 
                handleChange={handleChange} 
                handleCheck={handleCheck}
                confirmPass={confirmPassword}
                setConfirmPass={handleConfirmPass}
                addAddress={addAddress}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                policyAgree={policyAgree}
            /> 
        }
        </>
    )
}