import React, { useState, useEffect } from "react";
import { Row, Col, Stack, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import ValidationError from "../ValidationError";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import privacyPolicy from "../../../assets/vCISOサービス約款Ver1.1.pdf";

export default function RegisterEntry(props) {
    const [compSize, setCompSize] = useState([]);
    const [source, setSource] = useState([]);
    const [industryType, setIndustryType] = useState([]);
    const [pass, setPass] = useState("");

    var jpPrefecture = require("jp-prefecture");
    const prefectures = jpPrefecture.getAll("pref", ["id", "name"]);
    const {
        values,
        handleCheck,
        handleObjChange,
        setAddress,
        handleChange,
        handleSubmit,
        onSubmit,
        register,
        errors,
        validated,
        addAddress,
        handlePaymentChange,
        getValues,
        subsChecked,
        policyAgree,
    } = props;

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate
            .get("/api/master/datasource/0")
            .then(function (response) {
                if (response.status === 200) {
                    var data = response.data.data;
                    // //console.log(data)
                    let sizes = [];
                    let sources = [];
                    let types = [];
                    data.forEach((item) => {
                        if (item.typeId === 1) {
                            types.push(item);
                        } else if (item.typeId === 2) {
                            sizes.push(item);
                        } else if (item.typeId === 3) {
                            sources.push(item);
                        }
                    });
                    if (isSubscribed) {
                        setCompSize(sizes);
                        setSource(sources);
                        setIndustryType(types);
                    }
                }
            })
            .catch(function (response) {
                console.error(response);
            });
        return () => (isSubscribed = false);
    }, []);
    const validateMessage = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "担当者姓名を入力してください。";
        } else {
            if (firstName) {
                errorMessage = "担当者名を入力してください。";
            }
            if (lastName) {
                errorMessage = "担当者姓を入力してください。";
            }
        }
        return errorMessage;
    };
    const validateMessageKana = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "担当者セイメイを入力してください。";
        } else {
            if (firstName) {
                errorMessage = "担当者メイを入力してください。";
            }
            if (lastName) {
                errorMessage = "担当者セイを入力してください。";
            }
        }
        return errorMessage;
    };
    const validatePaymentMessage = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "請求担当者姓名を入力してください。";
        } else {
            if (firstName) {
                errorMessage = "請求担当者名を入力してください。";
            }
            if (lastName) {
                errorMessage = "請求担当者姓を入力してください。";
            }
        }
        return errorMessage;
    };
    const validatePaymentMessageKana = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "請求担当者セイメイを入力してください。";
        } else {
            if (firstName) {
                errorMessage = "請求担当者メイを入力してください。";
            }
            if (lastName) {
                errorMessage = "請求担当者セイを入力してください。";
            }
        }
        return errorMessage;
    };
    return (
        <Stack gap={5} className="py-4 EditUser">
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
                className="form-area"
                // className="form-area shadow-lg"
            >
                <Form.Group className="edit-form-area">
                    <Form.Label className="label-sub-title">
                        基本情報
                    </Form.Label>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>会社名</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="new-password"
                            {...register("company", {
                                required: {
                                    value: true,
                                    message: "会社名を入力してください。",
                                },
                                pattern: {
                                    value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/,
                                    message: "全角で入力してください。",
                                },
                            })}
                        />
                        {errors.company && (
                            <ValidationError message={errors.company.message} />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>部署名</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="new-password"
                            {...register("dept", {
                                pattern: {
                                    value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/,
                                    message: "全角で入力してください。",
                                },
                            })}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-manager-form-area">
                    <Row className="g-0">
                        <Form.Label>担当者（全角）</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="姓"
                            className="contract-lastname-input input-lastName"
                            autoComplete="new-password"
                            {...register("lastName", {
                                required: {
                                    value: true,
                                    message: "担当者姓を入力してください。",
                                },
                                pattern: {
                                    value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/,
                                    message: "全角で入力してください。",
                                },
                            })}
                        />

                        <Form.Control
                            type="text"
                            placeholder="名"
                            className="contract-firstname-input input-firstName"
                            autoComplete="new-password"
                            {...register("firstName", {
                                required: {
                                    value: true,
                                    message: "担当者名を入力してください。",
                                },
                                pattern: {
                                    value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/,
                                    message: "全角で入力してください。",
                                },
                            })}
                        />

                        {(errors.firstName || errors.lastName) && (
                            <ValidationError
                                message={validateMessage(
                                    errors.firstName,
                                    errors.lastName
                                )}
                            />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-manager-kana-form-area">
                    <Row className="g-0">
                        <Form.Label>担当者名（全角カナ）</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="セイ"
                            className="contract-lastname-input input-lastName"
                            autoComplete="new-password"
                            {...register("lastNameKana", {
                                required: {
                                    value: true,
                                    message: "担当者セイを入力してください。",
                                },
                                pattern: {
                                    value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/,
                                    message: "全角で入力してください。",
                                },
                            })}
                        />
                        <Form.Control
                            type="text"
                            placeholder="メイ"
                            className="contract-firstname-input input-firstName"
                            autoComplete="new-password"
                            {...register("firstNameKana", {
                                required: {
                                    value: true,
                                    message: "担当者メイを入力してください。",
                                },
                                pattern: {
                                    value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/,
                                    message: "全角で入力してください。",
                                },
                            })}
                        />
                        {(errors.firstNameKana || errors.lastNameKana) && (
                            <ValidationError
                                message={validateMessageKana(
                                    errors.firstNameKana,
                                    errors.lastNameKana
                                )}
                            />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>郵便番号</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            className="input-post-code"
                            type="text"
                            autoComplete="new-password"
                            {...register("postcode")}
                        />
                        <Button
                            className="rounded-pill btn-blue-2 button-post"
                            onClick={() => setAddress("addressOne")}
                        >
                            郵便番号から住所入力
                        </Button>
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>都道府県</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Select
                            className="select-area"
                            {...register("state", {
                                validate: (value) =>
                                    value !== "都道府県を選択する" ||
                                    "都道府県を選択してください。",
                            })}
                        >
                            <option key="defaultPrefecture" hidden>
                                都道府県を選択する
                            </option>
                            {prefectures.map((pref) => {
                                return (
                                    <option value={pref.name}>
                                        {pref.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                        {errors.state && (
                            <ValidationError
                                message={"都道府県を入力してください。"}
                            />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>市区町村</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="new-password"
                            {...register("city", {
                                required: {
                                    value: true,
                                    message: "市区町村を入力してください。",
                                },
                            })}
                        />
                        {errors.city && (
                            <ValidationError message={errors.city.message} />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>番地等　</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="new-password"
                            {...register("address", {
                                required: {
                                    value: true,
                                    message: "番地等を入力してください。",
                                },
                            })}
                        />
                        {errors.address && (
                            <ValidationError message={errors.address.message} />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>マンション・建物名</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="new-password"
                            {...register("buldingName")}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>担当者電話番号</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            autoComplete="new-password"
                            {...register("contactInfo", {
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message:
                                        "ハイフンなしの電話番号を入力してください。",
                                },
                            })}
                        />
                        {errors.contactInfo && (
                            <ValidationError
                                message={errors.contactInfo.message}
                            />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>業種</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Select
                            className="select-area-jobs"
                            {...register("companyTypeId")}
                            onChange={handleObjChange("companyTypeId")}
                            value={JSON.stringify(values.companyTypeId)}
                        >
                            <option key="defaultIndustryType" hidden>
                                業種を選択する
                            </option>
                            {industryType.map((item) => {
                                return (
                                    <option value={JSON.stringify(item)}>
                                        {item.keyValueJp}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>企業規模</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Select
                            className="select-area-jobs"
                            {...register("companySizeId")}
                            onChange={handleObjChange("companySizeId")}
                            value={JSON.stringify(values.companySizeId)}
                        >
                            <option key="defaultSize" hidden>
                                企業規模を選択する
                            </option>
                            {compSize.map((item) => {
                                return (
                                    <option value={JSON.stringify(item)}>
                                        {item.keyValueJp}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>メールマガジン購読</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Check
                            checked={subsChecked}
                            className="mb-3 checkbox-area"
                            type="checkbox"
                            id="magazine-check"
                            name="magazine-check"
                            {...register("newsletterSubs", {
                                onChange: handleCheck("newsletter"),
                            })}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>アンケート</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Select
                            className="select-area-question"
                            {...register("referrerId")}
                            onChange={handleObjChange("referrerId")}
                            value={JSON.stringify(values.referrerId)}
                        >
                            <option key="defaultSource" hidden>
                                当サイトをどこでお知りになりましたか
                            </option>
                            {source.map((item) => {
                                return (
                                    <option value={JSON.stringify(item)}>
                                        {item.keyValueJp}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>
                            <a href="/yakkan" target="_blank">
                                サービス約款
                            </a>
                            に同意する
                        </Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Check
                            checked={policyAgree}
                            className="mb-3 checkbox-area"
                            type="checkbox"
                            id="policy-check"
                            name="policy-check"
                            {...register("policyAgree", {
                                validate: (value) =>
                                    value === true ||
                                    "サービス約款に同意してください",
                                onChange: handleCheck("policy"),
                            })}
                        />
                        {errors.policyAgree && (
                            <ValidationError
                                message={errors.policyAgree.message}
                            />
                        )}
                    </Row>
                </Form.Group>
                <br />
                <Form.Group className="edit-form-area">
                    <Form.Label className="label-sub-title">
                        ログイン用パスワード設定
                    </Form.Label>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>

                        <Form.Control
                            type="password"
                            autoComplete="new-password"
                            {...register("password", {
                                required: {
                                    value: true,
                                    message:
                                        "英数特殊記号混在8文字以上を入力してください。",
                                },
                                onChange: (e) => setPass(e.target.value),
                                minLength: {
                                    value: 8,
                                    message:
                                        "英数特殊記号混在8文字以上を入力してください。",
                                },
                                pattern: {
                                    value: /^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/,
                                    message:
                                        "英数特殊記号混在8文字以上を入力してください。",
                                },
                            })}
                        />
                        {errors.password && (
                            <ValidationError
                                message={errors.password.message}
                            />
                        )}
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>パスワード確認用</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="password"
                            autoComplete="new-password"
                            {...register("confirmPass", {
                                required: {
                                    value: true,
                                    message: "パスワードと一致していません。",
                                },
                                validate: (value) =>
                                    value === pass ||
                                    "パスワードと一致していません。",
                            })}
                        />
                        {errors.confirmPass && (
                            <ValidationError
                                message={errors.confirmPass.message}
                            />
                        )}
                    </Row>
                </Form.Group>
                <br />
                <Form.Group className="edit-form-area">
                    <Form.Label className="label-sub-title">
                        請求書送付先設定
                    </Form.Label>
                </Form.Group>
                <div style={{ alignSelf: "left", fontSize: "16px" }}>
                    請求書送付先情報を追加したい場合はチェックボックスをクリックしてください。
                </div>
                <br />

                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>請求書送付先情報の追加</Form.Label>
                        <Form.Check
                            checked={addAddress}
                            className="mb-3 checkbox-area"
                            type="checkbox"
                            id="addAddress-check"
                            name="addAddress-check"
                            onChange={handleCheck("addAddress")}
                        />
                    </Row>
                </Form.Group>
                {addAddress ? (
                    <>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>会社名</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.company", {
                                        required: {
                                            value: true,
                                            message:
                                                "会社名を入力してください。",
                                        },
                                    })}
                                />
                                {addAddress
                                    ? errors.paymentInfo && (
                                          <ValidationError
                                              message={
                                                  errors.paymentInfo.company
                                                      .message
                                              }
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>部署名</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.dept")}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-payment-form-area">
                            <Row className="g-0">
                                <Form.Label>請求担当者</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="姓"
                                    className="contract-lastname-input input-lastName"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.lastName", {
                                        required: {
                                            value: true,
                                            message:
                                                "請求担当者姓を入力してください。",
                                        },
                                    })}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="名"
                                    className="contract-firstname-input input-firstName"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.firstName", {
                                        required: {
                                            value: true,
                                            message:
                                                "請求担当者名を入力してください。",
                                        },
                                    })}
                                />
                                {addAddress
                                    ? errors.paymentInfo && (
                                          <ValidationError
                                              message={validatePaymentMessage(
                                                  errors.paymentInfo.firstName,
                                                  errors.paymentInfo.lastName
                                              )}
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-payment-kana-form-area">
                            <Row className="g-0">
                                <Form.Label>
                                    請求担当者（全角カナ）
                                </Form.Label>
                                <Form.Label className="required-lable"></Form.Label>

                                <Form.Control
                                    type="text"
                                    placeholder="セイ"
                                    className="contract-lastname-input input-lastName"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.lastNameKana", {
                                        required: {
                                            value: true,
                                            message:
                                                "請求担当者セイを入力してください。",
                                        },
                                    })}
                                />
                                <Form.Control
                                    type="text"
                                    placeholder="メイ"
                                    className="contract-firstname-input input-firstName"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.firstNameKana", {
                                        required: {
                                            value: true,
                                            message:
                                                "請求担当者メイを入力してください。",
                                        },
                                    })}
                                />
                                {addAddress
                                    ? errors.paymentInfo && (
                                          <ValidationError
                                              message={validatePaymentMessageKana(
                                                  errors.paymentInfo
                                                      .firstNameKana,
                                                  errors.paymentInfo
                                                      .lastNameKana
                                              )}
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>郵便番号</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    className="input-post-code"
                                    {...register("paymentInfo.postcode", {
                                        required: {
                                            value: true,
                                            message:
                                                "郵便番号を入力してください。",
                                        },
                                    })}
                                />
                                <Button
                                    className="rounded-pill btn-blue-2 button-post"
                                    onClick={() => setAddress("addressTwo")}
                                >
                                    郵便番号から住所入力
                                </Button>
                                {addAddress
                                    ? errors.paymentInfo && (
                                          <ValidationError
                                              message={
                                                  errors.paymentInfo.postcode
                                                      .message
                                              }
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>都道府県</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Select
                                    className="select-area"
                                    {...register("paymentInfo.state", {
                                        validate: (value) =>
                                            value !== "都道府県を選択する" ||
                                            "都道府県を選択してください。",
                                    })}
                                >
                                    <option key="defaultPrefecture" hidden>
                                        都道府県を選択する
                                    </option>
                                    {prefectures.map((pref) => {
                                        return (
                                            <option value={pref.name}>
                                                {pref.name}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                {addAddress
                                    ? errors.paymentInfo && (
                                          <ValidationError
                                              message={
                                                  errors.paymentInfo.state
                                                      .message
                                              }
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>市区町村</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.city", {
                                        required: {
                                            value: true,
                                            message:
                                                "市区町村を入力してください。",
                                        },
                                    })}
                                />
                                {addAddress
                                    ? errors.paymentInfo && (
                                          <ValidationError
                                              message={
                                                  errors.paymentInfo.city
                                                      .message
                                              }
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>番地等</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.address", {
                                        required: {
                                            value: true,
                                            message:
                                                "番地等を入力してください。",
                                        },
                                    })}
                                />
                                {addAddress
                                    ? errors.paymentInfo &&
                                      errors.paymentInfo.address && (
                                          <ValidationError
                                              message={
                                                  errors.paymentInfo.address
                                                      .message
                                              }
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>マンション・建物名</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    {...register("paymentInfo.buldingName")}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>電話番号</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    {...register("paymentInfo.contactInfo", {
                                        required: {
                                            value: true,
                                            message:
                                                "電話番号を入力してください。",
                                        },
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message:
                                                "ハイフンなしの電話番号を入力してください。",
                                        },
                                    })}
                                />
                                {addAddress
                                    ? errors.paymentInfo &&
                                      errors.paymentInfo.contactInfo && (
                                          <ValidationError
                                              message={
                                                  errors.paymentInfo.contactInfo
                                                      .message
                                              }
                                          />
                                      )
                                    : null}
                            </Row>
                        </Form.Group>
                    </>
                ) : null}
                <hr />
                <div className="button-area">
                    <Button className="rounded-pill btn-blue-2" type="submit">
                        確認画面に進む
                    </Button>
                </div>
            </Form>
        </Stack>
    );
}
