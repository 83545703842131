import React, { useState } from "react";
import { Row, Col, Stack, Form, Button } from "react-bootstrap";
import privacyPolicy from "../../../assets/vCISOサービス約款Ver1.1.pdf";

export default function RegisterCheck(props) {
    const { values, handleSubmit, handleClick, invoiceValues, addAddress } =
        props;

    return (
        <Stack gap={5} className="p-4 EditUser">
            <Form className="form-area">
                <Form.Group className="edit-form-area">
                    <Form.Label className="label-sub-title">
                        基本情報
                    </Form.Label>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>会社名</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>

                        <Form.Control
                            className="mb-3"
                            type="text"
                            plaintext
                            readOnly
                            value={values.company}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>部署名</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            plaintext
                            readOnly
                            className="mb-3"
                            type="text"
                            value={values.dept}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-manager-form-area">
                    <Row className="g-0">
                        <Form.Label>担当者（漢字）</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            plaintext
                            className="contract-lastname-input input-lastName"
                            type="text"
                            value={values.lastName}
                        />
                        <Form.Control
                            className="contract-firstname-input input-firstName"
                            type="text"
                            plaintext
                            readOnly
                            value={values.firstName}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-manager-kana-form-area">
                    <Row className="g-0">
                        <Form.Label>担当者（全角カナ）</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            className="contract-lastname-input input-lastName"
                            type="text"
                            plaintext
                            readOnly
                            value={values.lastNameKana}
                        />

                        <Form.Control
                            className="contract-firstname-input input-firstName"
                            type="text"
                            plaintext
                            readOnly
                            value={values.firstNameKana}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>郵便番号</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            className="input-post-code"
                            type="text"
                            plaintext
                            readOnly
                            value={values.postcode}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>都道府県</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            className="select-area"
                            plaintext
                            readOnly
                            value={values.state}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>市区町村</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            plaintext
                            readOnly
                            value={values.city}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>番地等　</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            plaintext
                            readOnly
                            value={values.address}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>マンション・建物名</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            plaintext
                            readOnly
                            value={values.buldingName}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>担当者電話番号</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            plaintext
                            readOnly
                            value={values.contactInfo}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>業種</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            plaintext
                            readOnly
                            className="mb-3"
                            value={props.objValues.companyTypeId.keyValueJp}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>企業規模</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            plaintext
                            readOnly
                            className="select-area-jobs"
                            value={props.objValues.companySizeId.keyValueJp}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>メールマガジン購読</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Check
                            readOnly
                            checked={props.subsChecked}
                            className="mb-3 checkbox-area"
                            type="checkbox"
                            id="magazine-check"
                            name="magazine-check"
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>アンケート</Form.Label>
                        <Form.Label className="any-lable"></Form.Label>
                        <Form.Control
                            type="text"
                            className="select-area-question"
                            plaintext
                            readOnly
                            value={props.objValues.referrerId.keyValueJp}
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>
                            <a href="/yakkan" target="_blank">
                                サービス約款
                            </a>
                            に同意する
                        </Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Check
                            readOnly
                            checked={props.policyAgree}
                            className="mb-3 checkbox-area"
                            type="checkbox"
                            id="policy-check"
                            name="policy-check"
                        />
                    </Row>
                </Form.Group>
                <br />
                <Form.Group className="edit-form-area">
                    <Form.Label className="label-sub-title">
                        ログイン用パスワード設定
                    </Form.Label>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            type="password"
                            value={values.password}
                            readOnly
                        />
                    </Row>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>パスワード確認用</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            readOnly
                            className="mb-3"
                            type="password"
                            value={props.confirmPass}
                        />
                    </Row>
                </Form.Group>
                <br />
                <Form.Group className="edit-form-area">
                    <Form.Label className="label-sub-title">
                        請求書送付先設定
                    </Form.Label>
                </Form.Group>
                <Form.Group className="edit-form-area">
                    <Row className="g-0">
                        <Form.Label>請求書送付先情報の追加</Form.Label>
                        <Form.Check
                            checked={addAddress}
                            className="mb-3 checkbox-area"
                            type="checkbox"
                            id="addAddress-check"
                            name="addAddress-check"
                            disabled
                        />
                    </Row>
                </Form.Group>
                {addAddress ? (
                    <>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>会社名</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.company}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>部署名</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.dept}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-payment-form-area">
                            <Row className="g-0">
                                <Form.Label>請求担当者（漢字）</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    className="contract-lastname-input input-lastName"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.lastName}
                                />

                                <Form.Control
                                    className="contract-firstname-input input-firstName"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.firstName}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-payment-kana-form-area">
                            <Row className="g-0">
                                <Form.Label>請求担当者（全角カナ）</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    className="contract-lastname-input input-lastName"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.lastNameKana}
                                />
                                <Form.Control
                                    className="contract-firstname-input input-firstName"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.firstNameKana}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>郵便番号</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    className="input-post-code"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.postcode}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>都道府県</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    className="select-area"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.state}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>市区町村</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.city}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>番地等</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.address}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>マンション・建物名</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.buldingName}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className="edit-form-area">
                            <Row className="g-0">
                                <Form.Label>請求担当者電話番号</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    plaintext
                                    value={values.paymentInfo.contactInfo}
                                />
                            </Row>
                        </Form.Group>
                    </>
                ) : null}
                <hr />
                <div className="edit-phone-form-area">
                    <Button
                        className="rounded-pill btn-blue-2 btn-primary button-margin"
                        onClick={handleClick}
                    >
                        再入力をする
                    </Button>
                    <Button
                        className="rounded-pill btn-blue-2 btn-primary"
                        onClick={handleSubmit}
                    >
                        登録をする
                    </Button>
                </div>
            </Form>
        </Stack>
    );
}
