import React, { useState } from "react";
import uuid from "react-uuid";
import { Row, Form } from "react-bootstrap";

export default function LabelWithPulldown(props, changedValue) {
    const data = props.item;
    const [selectValue, setSelectedValue] = useState();

    const handleObjChange = (prop) => (event) => {
        setSelectedValue(event.target.value);
        data.parentFunction({key:data.key, selectedValue: event.target.value})
    };

    return (
        <Form.Group className="edit-form-area">
            <Row className="g-0">
                <Form.Label>{data.labelName}</Form.Label>
                <Form.Select
                    className="mypage-margin"
                    key={uuid()}
                    defaultValue={data.selectedValue}
                    onChange={handleObjChange()}
                    value={selectValue}
                >
                    <option value="">{data.placeHolder}</option>
                    {data.pulldownValues.map((item, index) => {
                        return (
                            <option key={index} value={item.value}>
                                {item.value}
                            </option>
                        );
                    })}
                </Form.Select>
            </Row>
        </Form.Group>
    );
}
