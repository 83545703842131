import React, { useState, useEffect } from 'react';
import {
    Navbar, Container, Nav, Col, Row, Button
} from 'react-bootstrap';
import getWindowDimensions from '../../utils/getWindowDimenions';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Chat } from '../../assets/svg_icons/nav-icon-chat.svg';
import { ReactComponent as DocIcon } from '../../assets/svg_icons/nav-icon-doc.svg';
import { ReactComponent as Headset } from '../../assets/svg_icons/nav-icon-headset.svg';
import { ReactComponent as Page } from '../../assets/svg_icons/nav-icon-page.svg';
import { ReactComponent as Computer } from '../../assets/svg_icons/nav-icon-computer.svg';
import { ReactComponent as Email } from '../../assets/svg_icons/nav-icon-email.svg';
import { ReactComponent as Handshake } from '../../assets/svg_icons/nav-icon-handshake.svg';
import { ReactComponent as Meeting } from '../../assets/svg_icons/nav-icon-meeting.svg';
import {ReactComponent as Information} from "../../assets/svg_icons/information.svg";
import {ReactComponent as Calculator} from "../../assets/svg_icons/calculator.svg";
import {ReactComponent as Folder} from "../../assets/svg_icons/folder.svg";
import {ReactComponent as QA} from "../../assets/svg_icons/q_a.svg";


export default function ServiceNav(props) {
    const [collapse, setCollapse] = useState(false)
    const [disabled, setDisabled] = useState({
        chat: false,
        docs: false,
        incident: false,
        secCheck: false,
        elearn: false,
        email: false,
        match: false,
        counseling: false,
    })

    const { height, width } = getWindowDimensions();

    const location = useLocation();

    const navigate = useNavigate();

    const toggleCollapse = (event) => {
        setCollapse(!collapse);
    }

    useEffect(() => {
        let isSubscribed = true;
        if (props.userInfo.plan.length > 0) {

            if (isSubscribed) {
                switch (props.userInfo.plan) {
                    case 'LITE':
                        setDisabled({ ...disabled, email: true, elearn: true, secCheck: true, match: true, counseling: true})
                        break;
                    case 'STANDARD':
                        setDisabled({ ...disabled, secCheck: true, match: true, counseling: true })
                        break;
                    default:
                        setDisabled({ ...disabled, match: true })
                        break;
                }
            }
        }
        return () => isSubscribed = false;
    }, [props.userInfo])

    return (
        <>
            {
                width < 768 ? null :
                  <Navbar id="before-nav" className="navbar-sp">
                      <Row className="g-0" style={{ 'width': '100%', 'justifyContent': 'center'}}>
                          <Col md={10}>
                              <div className="gsx-beforeapp-nav">
                                  <Nav style={{ 'width': '100%', 'dislpay': 'flex'}} activeKey={location.pathname}>
                                      <Nav.Link className="custom-nav" eventKey="/chatservice" href="/chatservice" disabled={disabled.chat}>
                                          <Chat style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">よろづ相談</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav" onClick={() => navigate("doctemplates")} eventKey="/doctemplates" disabled={disabled.docs}>
                                          <DocIcon style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">文書<br />テンプレート</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav" onClick={() => navigate("elearning")} eventKey="/elearning" disabled={disabled.elearn}>
                                          <Computer style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">Eラーニング</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav" onClick={() => navigate("emailtraining")} eventKey="/emailtraining" disabled={disabled.email}>
                                          <Email style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">合同メール訓練</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav" onClick={() => navigate("securitycheck")} eventKey="/securitycheck" disabled={disabled.secCheck}>
                                          <Page style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">セキュリティ<br />健康診断</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav" onClick={() => navigate("counseling")} eventKey="/counseling" disabled={disabled.counseling}>
                                          <Meeting style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">個別<br />カウンセリング</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav matching" href="https://my.vciso.jp/login" target="_blank" disabled={disabled.match}>
                                          <Handshake style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">人材<br />マッチング</div>
                                      </Nav.Link>
                                      <Nav.Link className="custom-nav" onClick={() => navigate("incident")} eventKey="/incident" disabled={disabled.incident}>
                                          <Headset style={{ "alignSelf": "center" }} />
                                          <div className="nav-div">インシデント<br />対応</div>
                                      </Nav.Link>
                                  </Nav>
                              </div>
                          </Col>
                      </Row>
                  </Navbar>
                    // <Navbar id="before-nav" style={{ "backgroundColor": "#fff" }}>
                    //     <Row className="g-0" style={{ 'width': '100%' }}>
                    //         <Col md={1} />
                    //         <Col md={10}>
                    //             <div id="beforeapp-nav-div" className="gsx-beforeapp-nav">
                    //                 <Nav style={{ 'width': '100%', 'display': 'flex' }} activeKey={location.pathname}>
                    //                     <Nav.Link className="custom-nav" eventKey="/chatservice" href="/chatservice" disabled={disabled.chat}>
                    //                         <Chat style={{ "alignSelf": "center" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>よろづ相談</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'textAlign': 'center', 'borderLeft': "2px solid darkgray", "fontSize": ".8rem" }} onClick={() => navigate("doctemplates")} eventKey="/doctemplates" disabled={disabled.docs}>
                    //                         <Lock id="nav-lock" style={{ "alignSelf": "center", "width": "36px" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>文書テンプレート</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex',
                //                     'flexDirection': 'column', 'justifyContent': 'center', 'textAlign': 'center', 'borderLeft': "2px solid darkgray",
                //                     "fontSize": ".8rem" }} onClick={() => navigate("elearning")} eventKey="/elearning" disabled={disabled.elearn}>
                    //                         <Computer style={{ "alignSelf": "center" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>Eラーニング</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column',
                //                     'justifyContent': 'center', 'textAlign': 'center', 'borderLeft': "2px solid darkgray", "fontSize": "1rem" }}
                //                     onClick={() => navigate("emailtraining")} eventKey="/emailtraining" disabled={disabled.email}>
                    //                         <Email style={{ "alignSelf": "center" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>合同メール訓練</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center',
                //                     'textAlign': 'center', 'borderLeft': "2px solid darkgray", "fontSize": "1rem" }}
                //                     onClick={() => navigate("securitycheck")} eventKey="/securitycheck" disabled={disabled.secCheck}>
                    //                         <Page id="nav-page" style={{ "alignSelf": "center" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>セキュリティ健康診断</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center',
                //                     'textAlign': 'center', 'borderLeft': "2px solid darkgray", "fontSize": "1rem" }}
                //                     onClick={() => navigate("counseling")} eventKey="/counseling" disabled={disabled.counseling}>
                    //                         <Meeting style={{ "alignSelf": "center", "height": "40px", "width": "auto" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>個別カウンセリング</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex', 'flexDirection': 'column',
                //                     'justifyContent': 'center', 'textAlign': 'center', 'borderLeft': "2px solid darkgray", "fontSize": "1rem" }}
                //                     href="https://my.vciso.jp/login" target="_blank" disabled={disabled.match}>
                    //                         <Handshake style={{ "alignSelf": "center" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>人材マッチング</div>
                    //                     </Nav.Link>
                    //                     <Nav.Link className="custom-nav" style={{ 'width': '100%', 'display': 'flex',
                //                     'flexDirection': 'column', 'justifyContent': 'center', 'textAlign': 'center', 'borderLeft': "2px solid darkgray",
                //                     "borderRight": "2px solid darkgray", "fontSize": "1rem" }}
                //                     onClick={() => navigate("incident")} eventKey="/incident" disabled={disabled.incident}>
                    //                         <Headset style={{ "alignSelf": "center" }} />
                    //                         <div className="nav-div" style={{ "fontSize": ".875rem", "fontWeight": "bold" }}>インシデント対応</div>
                    //                     </Nav.Link>
                    //                 </Nav>
                    //             </div>
                    //
                    //         </Col>
                    //         <Col md={1} />
                    //     </Row>
                    // </Navbar>
            }
        </>
    )
}