import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
    Row, Col, Button, Form, Card, Modal
} from 'react-bootstrap';
import $ from 'jquery';
import signupPayment from '../../utils/signupPayment';
import amex from '../../assets/creditcards/amex.jpg';
import diners from '../../assets/creditcards/diners.gif';
import jcb from '../../assets/creditcards/jcb.webp';
import master from '../../assets/creditcards/mastercard.svg'
import visa from '../../assets/creditcards/visa.svg'
import AlertModal from '../elements/AlertModal';
import { useForm } from 'react-hook-form';
import ValidationError from '../elements/ValidationError';

export default function CreditCardDetails(props) {

    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })
    const [userId, setUserId] = useState("");
    const [planId, setPlanId] = useState("");
    const [recurringType, setRecurringType] = useState("");
    const [show, setShow] = useState(false)

    const [details, setDetails] = useState({
        number: "",
        name: "",
        securityCode: "",
        expiry: ""
    })

    const [token, setToken] = useState("");

    const { register, getValues, handleSubmit, formState: { errors } } = useForm();

    const handleChange = (prop) => (event) => {
        
        setDetails({ ...details, [prop]: event.target.value })
        
    }

    const { state } = useLocation();

    const navigate = useNavigate();

    const SECOND_MS = 1000;

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const onSubmit = () => {    
        var details = getValues();
        window.doPurchase(details)
        setShow(true)
        const interval = setInterval(() => {
            
            if($("#gmo_result").attr("value").length > 0) {
                if ($("#gmo_result").attr("value") === "000") {
                    //console.log('success?')
                    var gmo_token = $("#gmo_token").attr("value");
                    $("#gmo_token").attr("value", "");
                    $("#gmo_result").attr("value", "");
                    setDetails({
                        number: "",
                        name: "",
                        securityCode: "",
                        expiry: ""
                    })
                    var results = signupPayment(userId, planId, recurringType, gmo_token)
                    results.then(res => {
                        if (res.status === 200) {
                            if (res.data.apiCode === 0) {
                                navigate("/register/complete")
                            } else {
                                setAlert({ ...alert, show: true, message: "エラーが発生しました。" + res.data.message })
                            }
                        } else {
                            setAlert({ ...alert, show: true, message: "エラーが発生しました。" + res.data.message })
                        }
                    })
                    .catch(res => {
                        setAlert({ ...alert, show: true, message: "エラーが発生しました。" + res.data.message })
                    })
                    setShow(false)
                } else {
                    $("#gmo_token").attr("value", "");
                    $("#gmo_result").attr("value", "");
                    setDetails({
                        number: "",
                        name: "",
                        securityCode: "",
                        expiry: ""
                    })
                    setShow(false)
                }
                clearInterval(interval);
            } else {
                setAlert({ ...alert, show: true, message: "決済が失敗しました。" })
            }
        }, SECOND_MS)

        return () => clearInterval(interval);
    }


    useEffect(() => {
        if (token.length > 0) {
            ////console.log(token)
        }
    }, [token])

    useEffect(() => {
        let isSubscribed = true;
        if (state.userId) {
            if (isSubscribed) {
                setUserId(state.userId)
                setPlanId(state.planId)
                setRecurringType(state.recurringType)
            }
        }
        return () => isSubscribed = false;
    }, [state])

    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10} style={{ "display": "flex", "flexDirection": "column", "justifyContent":"center" }}>
                <div style={{ "textAlign": "center"}}>
                    <p style={{ "fontSize": "18px"}}>
                        決済に使用するクレジットカードを登録します。
                    </p>
                </div>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column", "alignItems": "center"}} className="fill-height-sm fill-height-md fill-height-xl">
                    <Card style={{ "width": "35%", "marginBottom": "4rem"}}>
                        <Card.Header>
                            クレジットカード
                        </Card.Header>
                        <Card.Body>
                            <Form id="purchaseForm" onSubmit={handleSubmit(onSubmit)}>
                                <Row className="g-0">
                                    <Col>
                                        <div style={{ "height": "40px", "textAlign": "center"}}>
                                            <img src={amex} height="100%" width="auto"/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ "height": "40px", "textAlign": "center"}}>
                                            <img src={diners} height="100%" width="auto"/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ "height": "40px", "textAlign": "center"}}>
                                            <img src={jcb} height="100%" width="auto"/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ "height": "40px", "textAlign": "center"}}>
                                            <img src={master} height="100%" width="auto"/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ "height": "40px", "textAlign": "center"}}>
                                            <img src={visa} height="100%" width="auto"/>
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Group className="my-3">
                                    <Form.Control 
                                        type="text" 
                                        {
                                            ...register("number", 
                                            { 
                                                required: { value: true, message: "カード番号を入力してください。"},
                                                minLength: { value: 14, message: "カード番号は無効です。"},
                                                maxLength: { value: 16, message: "カード番号は無効です。"},
                                                pattern: { value: /^[0-9]*$/, message: "カード番号欄に数字のみを入力してください。"}
                                            })
                                        }
                                        placeholder="カード番号"
                                    />
                                    {errors.number && <ValidationError message={errors.number.message}/>}
                                </Form.Group>
                                
                                <Form.Group className="my-3">
                                    <Form.Control
                                        type="text" 
                                        {
                                            ...register("name", 
                                            { 
                                                required: { value: true, message: "カード名義人を入力してください。"},
                                            })
                                        }
                                        placeholder="カード名義人"
                                    />
                                    {errors.name && <ValidationError message={errors.name.message}/>}
                                </Form.Group>
                                
                                <Form.Group className="my-3">
                                    <Form.Control 
                                        type="text"
                                        {
                                            ...register("expiry", 
                                            { 
                                                required: { value: true, message: "有効期限を入力してください。"},
                                                pattern: { value: /[0-9]{2}\/[0-9]{2}/, message: "有効期限を「YY/MM」のフォーマットで入力してください。"},
                                                maxLength: { value: 5, message: "有効期限のフォーマットは無効です。"}
                                            })
                                        }
                                        placeholder="有効期限（YY/MM）​"
                                    />
                                    {errors.expiry && <ValidationError message={errors.expiry.message}/>}
                                </Form.Group>
                                
                                <Form.Group className="my-3">
                                    <Form.Control 
                                        type="text"
                                        {
                                            ...register("securityCode", 
                                            { 
                                                required: { value: true, message: "セキュリティコードを入力してください。"},
                                                pattern: { value: /^[0-9]*$/, message: "セキュリティコード欄に数字のみを入力してください。"},
                                                maxLength: { value: 4, message: "セキュリティコードのフォーマットは無効です。"},
                                                minLength: { value: 3, message: "セキュリティコードのフォーマットは無効です。"}
                                            })
                                        }
                                        placeholder="セキュリティコード"
                                    />
                                    {errors.securityCode && <ValidationError message={errors.securityCode.message}/>}
                                </Form.Group>
                                
                                <Button style={{ "width": "106px"}} type="submit">確定</Button>
                            </Form> 
                        </Card.Body>
                    </Card>
                    <input id="gmo_token" type="text" hidden value="" />
                    <input id="gmo_result" type="number" hidden value={0} />
                    <Modal show={show} centered>
                        <Modal.Header>決済中</Modal.Header>
                        <Modal.Body>
                            <div>決済が完了したら、自動で切り替わります。</div>
                        </Modal.Body>
                    </Modal>
                </div>
            </Col>
            <Col />
        </Row>
    )
}