import React from "react";
import { Table } from "react-bootstrap";

export default function EventTable(props) {
    const { trainings, clickEvent, user } = props;

    return (
        <div
            className="EmailTraining striped bordered hover"
            // style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <div className="doc_lede">
                            <br />
                            <br />
                            <div>
                                標的型攻撃を模した訓練用メールを従業員に送信して、攻撃メールの開封結果並びに初動対応可否について教育、訓練するサービスです。マルウェア感染やビジネスメール詐欺（BEC）被害のリスクを低減し、インシデントへの対応力向上を図るだけでなく、組織全体のリスクコントロールの改善が期待できます。
                            </div>
                            <br />
                            <div style={{ color: "#444" }}>
                                ※応募頂いた訓練メールは実施期間に順次配信する為、日程のご指定が出来かねますので、予めご了承ください。
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="email_method">
                            <div className="sub_title">
                                合同メール訓練へのお申込み方法
                            </div>
                            <ol>
                                <li>
                                    下の「現在受付及び実施のメール訓練」より募集期間中の訓練を選択します。
                                    <br />
                                    ⇒各メール訓練の画面に移動します。
                                </li>
                                <li>
                                    以降の手順は各メール訓練の画面に記載しておりますので、ご参照ください。
                                </li>
                            </ol>
                        </div>
                    </section>

                    <h2 className="content_title">
                        <div>現在受付及び実施のメール訓練</div>
                    </h2>

                    <section>
                        <div className="download-area email-type g-0 row">
                            {emailTrainingCard(trainings, user, clickEvent)}
                        </div>
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>
    );
}

function emailTrainingCard(cardInfo, user, clickEvent) {
    return cardInfo.map((event, index) => {
        var endDate = new Date(event.recep_end);
        var startDate = new Date(event.recep_start);
        var now = new Date();
        var recepOver = endDate <= now ? true : false;
        var recepStarted = startDate < now ? true : false;
        var applied = event.emailTrainingRegistrations.find(
            (x) => x.user.mailId === user
        );
        const isClickable = !recepOver ? "abled" : "disabled";

        return (
            <div
                className={`col-md-4 col-0 email-card ${isClickable}`}
                key={index}
                onClick={clickEvent(event, recepOver, recepStarted)}
            >
                <div className="dl_list">
                    <h3>
                        {event.event_name}
                        <span className="email_tag">
                            {applied ? "応募済み" : "未応募"}
                        </span>
                    </h3>
                    <div>
                        <div className="e_pro">
                            <h4>募集期間</h4>
                            <p>
                                {recepOver
                                    ? "募集終了"
                                    : `${event.recep_start.split("T")[0].replaceAll("-", "/")} 〜 ${
                                          event.recep_end.split("T")[0].replaceAll("-", "/")
                                      }`}
                            </p>
                            <h4>実施期間</h4>
                            <p>{`${event.event_start.split("T")[0].replaceAll("-", "/")} 〜 ${
                                event.event_end.split("T")[0].replaceAll("-", "/")
                            }`}</p>
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    className="btn btn-outline-primary btn-sm report"
                    style={{ cursor: "not-allowed" }}
                >
                    訓練実施後にレポートをDLできます
                </button>
            </div>
        );
    });
}
