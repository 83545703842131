import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";

export default function CounselingCheck(props) {
    const {
        values,
        firstTime,
        secondTime,
        handleSubmit,
        onSubmit,
        handleConfirm,
    } = props;
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-0">
                <div>
                    <Form.Group className="my-4 me-4">
                        <Row className="g-0">
                            <div className="contract-info-label">
                                <Form.Label className="pe-3">
                                    第1希望日時
                                </Form.Label>
                            </div>
                            <div className="input-date">
                                <Form.Control
                                    type="date"
                                    value={values.firstDate}
                                    readOnly
                                    // plaintext
                                />
                            </div>
                            <div className="input-time">
                                <Form.Group>
                                    <Form.Select
                                        disabled
                                        className="contract-time-input"
                                        value={firstTime}
                                    >
                                        <option value={36000}>
                                            10:00 〜 11:00
                                        </option>
                                        <option value={39600}>
                                            11:00 〜 12:00
                                        </option>
                                        <option value={43200}>
                                            12:00 〜 13:00
                                        </option>
                                        <option value={46800}>
                                            13:00 〜 14:00
                                        </option>
                                        <option value={50400}>
                                            14:00 〜 15:00
                                        </option>
                                        <option value={54000}>
                                            15:00 〜 16:00
                                        </option>
                                        <option value={57600}>
                                            16:00 〜 17:00
                                        </option>
                                        <option value={61200}>
                                            17:00 〜 18:00
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Row>
                    </Form.Group>
                </div>
            </Row>
            <Row className="g-0">
                <div>
                    <Form.Group className="my-4 me-4">
                        <Row className="g-0">
                            <div className="contract-info-label">
                                <Form.Label className="pe-3">
                                    第2希望日時
                                </Form.Label>
                            </div>
                            <div className="input-date">
                                <Form.Control
                                    type="date"
                                    value={values.secondDate}
                                    readOnly
                                    // plaintext
                                />
                            </div>
                            <div className="input-time">
                                <Form.Group>
                                    <Form.Select
                                        disabled
                                        className="contract-time-input"
                                        value={secondTime}
                                    >
                                        <option value={36000}>
                                            10:00 〜 11:00
                                        </option>
                                        <option value={39600}>
                                            11:00 〜 12:00
                                        </option>
                                        <option value={43200}>
                                            12:00 〜 13:00
                                        </option>
                                        <option value={46800}>
                                            13:00 〜 14:00
                                        </option>
                                        <option value={50400}>
                                            14:00 〜 15:00
                                        </option>
                                        <option value={54000}>
                                            15:00 〜 16:00
                                        </option>
                                        <option value={57600}>
                                            16:00 〜 17:00
                                        </option>
                                        <option value={61200}>
                                            17:00 〜 18:00
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Row>
                    </Form.Group>
                </div>
            </Row>
            <Row className="g-0">
                <Form.Group className="my-4 me-4">
                    <Row className="g-0">
                        <div className="contract-info-label">
                            <Form.Label
                                className="pe-3"
                                // style={{ lineHeight: "38px" }}
                            >
                                事務局への伝達事項
                            </Form.Label>
                        </div>
                        <div className="contract-info-textarea-result">
                            <Form.Control
                                type="text"
                                as="textarea"
                                rows={5}
                                value={values.content}
                                readOnly
                            />
                        </div>
                    </Row>
                </Form.Group>
                <Col xs={3} />
            </Row>
            <Row className="template-customize-area button-flex">
                <Button
                    className="btn btn-primary btn-margin"
                    onClick={handleConfirm}
                >
                    再入力
                </Button>
                <Button className="btn btn-primary" type="submit">
                    送信
                </Button>
            </Row>

            {/* <Row className="g-0">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        className="me-4"
                        style={{
                            width: "90%",
                            textAlign: "right",
                            paddingleft: "30px",
                        }}
                    >
                        <Button
                            onClick={handleConfirm}
                            style={{
                                width: "90px",
                                alignSelf: "end",
                                marginRight: "10px",
                            }}
                        >
                            再入力
                        </Button>
                        <Button
                            type="submit"
                            style={{ width: "90px", alignSelf: "end" }}
                        >
                            送信
                        </Button>
                    </div>
                </div>
            </Row> */}
            <br />
            <br />
        </Form>
    );
}
