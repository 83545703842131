import React, { useState, useEffect } from 'react';
import {
    Row, Col, Stack, Form, Button
} from 'react-bootstrap';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import ValidationError from '../ValidationError';
import { Link } from 'react-router-dom';
import { axiosPrivate } from '../../../utils/axiosPrivate';

export default function RegisterEntry(props) {

    const [pass, setPass] = useState("");

    const { 
        handleSubmit,
        onSubmit,
        register,
        errors,
        validated, 
        confirm,
        setConfirm,
        registered
    } = props;

    const handleConfirmSubmit = (data) => {
        ////console.log(data)
        setConfirm(true)
    }



    return (
        <div style={{ "width": "100%"}}>
            {
                registered ?
                <Row className="g-0">
                    <Col />
                    <Col sm={12} md={10} lg={10} style={{ "display": "flex", "flexDirection": "column", "justifyContent":"center" }}>
                        <div style={{ "width": "100%", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center"}} className="p-4 fill-height-sm fill-height-md fill-height-xl">
                            <div className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl" style={{ "textAlign": "center", "width": "100%"}}>
                                <br />
                                <div style={{ "fontSize": "1.2rem", "alignSelf": "center"}}>本登録が完了しました。</div>
                                <br />
                                <Link to="/login">
                                    <Button style={{ "width": "fit-content", "alignSelf": "center"}}>ログイン画面へ</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col />
                </Row> :
                <Stack gap={5} style={{ "width": "100%", "display": "flex", "alignItems": "center", "alignSelf": "center"}} className="p-4">
                    <Form noValidate validated={validated} onSubmit={ confirm ? handleSubmit(onSubmit) : handleSubmit(handleConfirmSubmit)} style={{"borderRadius": ".5rem", "display": "flex", "flexDirection": "column", "width": "90%" }} className="p-4 shadow-lg">
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                        会社名
                                    </Form.Label>
                                </Col>
                                <Col xs={7} className="px-4">
                                    <Form.Control 
                                        className="mb-3" 
                                        type="text" 
                                        readOnly
                                        plaintext
                                        value={props.corpName}
                                    />
                                </Col>

                            </Row>
                            
                        </Form.Group>
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                        メールアドレス
                                    </Form.Label>
                                </Col>
                                <Col xs={7} className="px-4">
                                    <Form.Control 
                                        className="mb-3" 
                                        type="text" 
                                        readOnly
                                        plaintext
                                        value={props.email}
                                    />
                                </Col>

                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                        姓（必須）
                                    </Form.Label>
                                </Col>
                                <Col xs={5} className="px-4">
                                    { 
                                    confirm ?
                                    <Form.Control 
                                        className="mb-3" 
                                        type="text"
                                        value={props.values.lastName}
                                        readOnly
                                        plaintext
                                    /> :
                                    <div>
                                        <Form.Control 
                                            className="mb-3" 
                                            type="text"
                                            autoComplete='new-password'
                                            {
                                                ...register("lastName", {
                                                    required: { value: true, message: "姓を入力してください。"}, 
                                                    pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}
                                                })
                                            }
                                            
                                        />
                                        {errors.lastName && <ValidationError message={errors.lastName.message}/>}
                                    </div>
                                }
                                </Col>
                                <Col xs={4}>
                                    <div style={{ "lineHeight": "38px"}}>（全角）</div>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                        名（必須）
                                    </Form.Label>
                                </Col>
                                <Col xs={5} className="px-4">
                                    {
                                        confirm ?
                                        <Form.Control 
                                            className="mb-3" 
                                            type="text" 
                                            value={props.values.firstName}
                                            readOnly
                                            plaintext
                                        /> :
                                        <div>
                                            <Form.Control 
                                                className="mb-3" 
                                                type="text" 
                                                autoComplete='new-password'
                                                {
                                                    ...register("firstName", {
                                                        required: { value: true, message: "名を入力してください。"}, 
                                                        pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}

                                                    })
                                                } 
                                            />
                                            {errors.firstName && <ValidationError message={errors.firstName.message}/>} 
                                        </div>
                                    }
                                </Col>
                                <Col xs={4}>
                                    <div style={{ "lineHeight": "38px"}}>（全角）</div>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                    セイ（必須）
                                    </Form.Label>
                                </Col>
                                <Col xs={5} className="px-4">
                                    {
                                        confirm ?
                                            <Form.Control 
                                                className="mb-3" 
                                                type="text"
                                                value={props.values.lastNameKana} 
                                                readOnly
                                                plaintext
                                            /> :
                                            <div>
                                                <Form.Control 
                                                    className="mb-3" 
                                                    type="text" 
                                                    autoComplete='new-password'
                                                    {
                                                        ...register("lastNameKana", {
                                                            required: { value: true, message: "セイを入力してください。"}, 
                                                            pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}
                                                        })
                                                    } 
                                                />
                                                {errors.lastNameKana && <ValidationError message={errors.lastNameKana.message}/>} 
                                            </div>
                                    }
                                </Col>
                                <Col xs={4}>
                                    <div style={{ "lineHeight": "38px"}}>（全角）</div>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                    メイ（必須）
                                    </Form.Label>
                                </Col>
                                <Col xs={5} className="px-4">
                                    {
                                        confirm ?
                                        <Form.Control 
                                            className="mb-3" 
                                            type="text"
                                            value={props.values.firstNameKana}
                                            readOnly
                                            plaintext
                                        /> :
                                        <div>
                                            <Form.Control 
                                                className="mb-3" 
                                                type="text"
                                                autoComplete='new-password'
                                                {
                                                    ...register("firstNameKana", {
                                                        required: { value: true, message: "メイを入力してください。"}, 
                                                        pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}
                                                    })
                                                } 
                                            />
                                            {errors.firstNameKana && <ValidationError message={errors.firstNameKana.message}/>} 
                                        </div>
                                    } 
                                </Col>
                                <Col xs={4}>
                                    <div style={{ "lineHeight": "38px"}}>（全角）</div>
                                </Col>
                            </Row>
                        </Form.Group>
                        <hr />
                        <div style={{ "alignSelf": "center"}}>ログイン用パスワード設定</div>
                        <br />
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                        パスワード（必須）
                                    </Form.Label>
                                </Col>
                                <Col xs={5} className="px-4">
                                    {
                                        confirm ?
                                        <Form.Control className="mb-3" type="password" readOnly plaintext value={props.values.password} /> :
                                        <div>
                                            <Form.Control className="mb-3" type="password" autoComplete='new-password'
                                            {
                                                ...register("password", 
                                                { 
                                                    required: {value: true, message: "英数特殊記号混在8文字以上を入力してください。"},
                                                    onChange: (e) => setPass(e.target.value),
                                                    minLength: { value: 8, message: "英数特殊記号混在8文字以上を入力してください。"},
                                                    pattern: { value: /^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/, message: "英数特殊記号混在8文字以上を入力してください。"}
                                                })
                                            } />
                                            {errors.password && <ValidationError message={errors.password.message}/>}
                                        </div>
                                    }
                                </Col>
                                <Col xs={4}>
                                    <div style={{ "lineHeight": "38px"}}>（半角英数）</div>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="g-0">
                                <Col xs={3}>
                                    <Form.Label style={{ "fontSize": ".8rem"}}>
                                        パスワード確認用（必須）
                                    </Form.Label>
                                </Col>
                                <Col xs={5} className="px-4">
                                    {
                                        confirm ?
                                        <Form.Control 
                                            className="mb-3" 
                                            type="password"
                                            readOnly
                                            plaintext
                                            value={props.values.confirmPass}
                                        /> :
                                        <div>
                                            <Form.Control 
                                                className="mb-3" 
                                                type="password"
                                                autoComplete='new-password'
                                                {
                                                    ...register("confirmPass", {
                                                        required: { value: true, message: "パスワードと一致していません。"},
                                                        validate: value => value === pass || "パスワードと一致していません。"
                                                    })
                                                }
                                            />
                                            {errors.confirmPass && <ValidationError message={errors.confirmPass.message}/>}
                                        </div>
                                    }
                                </Col>
                                <Col xs={4}>
                                    <div style={{ "lineHeight": "38px"}}>（半角英数）</div>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Row className="g-0">
                        { confirm ? 
                            <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                                <Button size="sm" style={{ "alignSelf" : "center", "width": "fit-content"}} onClick={() => setConfirm(false)}>再入力</Button> 
                            </Col>
                            : null }
                            <Col xs={confirm ? 6 : 12} style={{ "display": "flex", "flexDirection": "column"}}>
                                <Button size="sm" style={{ "alignSelf" : "center", "width": "fit-content"}} type="submit">{confirm ? "登録をする" : "確認画面に進む"}</Button>
                            </Col>
                        </Row>
                            
                    </Form>
                </Stack>
            }
        </div>
    )

}