import React, { useState, useEffect } from 'react';
import {
 Row, Col, Stack, Form, Button
} from 'react-bootstrap';
import RegisterEntry from '../elements/register/RegisterEntry';
import RegisterCheck from '../elements/register/RegisterCheck';
import RegisterSubAccount from '../elements/register/RegisterSubAccount';
import PaymentPlan from '../elements/register/PaymentPlan';
import Cookies from 'universal-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import ValidationError from '../elements/ValidationError';
import { axiosPublic } from '../../utils/axiosPublic';
import RegisterSubscriber from '../elements/register/RegisterSubscriber';
import AlertModal from '../elements/AlertModal';

export default function Register(props) {

    const { user } = props;
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })
    const [token, setToken] = useState("")
    const [registered, setRegistered] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [subsChecked, setSubsChecked] = useState(true);
    const [policyAgree, setPolicyAgree] = useState(false);
    const [addAddress, setAddAddress] = useState(false);
    const [userReg, setUserReg] = useState({
        type: "",
        corpName: "",
        email: "",
        status: ""
    })
    const [userId, setUserId] = useState("");
    const [plan, setPlan] = useState({
        id: "3",
        recurringType: "0"
    });

    const [confirm, setConfirm] = useState(false)

    const [validated, setValidated] = useState(false);

    const [values, setValues] = useState({
        userId:"0",
        firstName:"",
        lastName:"",   
        firstNameKana:"",
        lastNameKana:"",
        company:"",
        dept:"",
        password:"", 
        companyTypeId:{},
        newsletterSubs: true,
        companySizeId:{},
        referrerId: {},
        postCode:"",
        state:"",
        city:"",
        address:"",
        buldingName:"",
        contactInfo:"",
        paymentInfo: {
            company:"",
            dept: "",
            firstName: "",
            lastName: "",
            firstNameKana: "",
            lastNameKana: "",
            postCode:"",
            state:"",
            city:"",
            address:"",
            buldingName:"",
            contactInfo:""
        }
    })

    const [confirmPass, setConfirmPass] = useState("")

    const cookies = new Cookies();
    
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    const navigate = useNavigate();
    const location = useLocation();

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value })
       // //console.log(values[prop])
    }

    const handleCheck = (prop) => (event) => {
        if (prop === "newsletter") {
            setValue("newsletterSubs", event.target.checked)
            setSubsChecked(event.target.checked)
        } else if (prop === "policy") {
            setValue("policyAgree", event.target.checked)
            setPolicyAgree(event.target.checked)
        } else {
            setAddAddress(event.target.checked)
        }
        
    }

    const goToPayment = () => {
        navigate("/payment", {
            state: {
                userId: userId,
                planId: plan.id,
                recurringType: plan.recurringType
            }
        })
        window.scrollTo(0,0)
    }

  

    const handlePaymentChange = (prop) => (event) => {
        setValues({...values, paymentInfo: {
            ...values.paymentInfo, [prop]: event.target.value
        }})
    }

    const handleObjChange = (prop) => (event) => {
        let obj = JSON.parse(event.target.value);
        setValues({...values, [prop]: obj})
        setValue(prop, obj)
    }

    const handleSubmitInfo = (data) => {
        ////console.log(data)
        ////console.log(errors)
       
        
        setValidated(true);
        
        
    };

    const handleSubSubmit = (event) => {

        var formValues = getValues();
        var body={
            "firstName": formValues.firstName,
            "lastName": formValues.lastName,
            "firstNameKana": formValues.firstNameKana,
            "lastNameKana": formValues.lastNameKana,
            "corpName": userReg.corpName,
            "password": formValues.password
        }
       // //console.log(body)
        //console.log(token)
		const subAxios = axios.create({ baseUrl: "https://api.vciso.jp"})
        subAxios.post("/api/user", body, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
        .then(function(response) {
         //   //console.log(response)
            if (response.data.apiCode === 0) {
                setRegistered(true)
            }
        })
        .catch(function(response) {
            //console.log(response)
            setConfirm(false)
            setAlert({ ...alert, show: true, message: "登録が失敗しました。" })
        })
    }

    const handleSubmitConfirmInfo = (event) => {
        event.preventDefault();

        var formValues = getValues();

        

        var paymentInfo = addAddress ? {
            "company": formValues.paymentInfo.company,
            "dept": formValues.paymentInfo.dept ,
            "firstName": formValues.paymentInfo.firstName, 
            "lastName": formValues.paymentInfo.lastName,
            "firstNameKana": formValues.paymentInfo.firstNameKana,
            "lastNameKana": formValues.paymentInfo.lastNameKana,
            "postcode": formValues.paymentInfo.postcode, 
            "state": formValues.paymentInfo.state,
            "city": formValues.paymentInfo.city,
            "address": formValues.paymentInfo.address,
            "buldingName": formValues.paymentInfo.buldingName,
            "contactInfo": formValues.paymentInfo.contactInfo
        } :
        null
        
        //console.log(paymentInfo)
        const body = {
            "userId": values.userId,
            "firstName": formValues.firstName,
            "lastName": formValues.lastName,
            "firstNameKana": formValues.firstNameKana,
            "lastNameKana": formValues.lastNameKana,
            "corpName": formValues.company,
            "dept": formValues.dept,
            "password": formValues.password,
            "corpTypeId": typeof values.companyTypeId.id === 'undefined' ? null : `${values.companyTypeId.id}`,
            "corpSizeId": typeof values.companySizeId.id === 'undefined' ? null : `${values.companySizeId.id}`,
            "referrerId": typeof values.referrerId.id === 'undefined' ? null : `${values.referrerId.id}`,
            "newsletterSubs": `${formValues.newsletterSubs}`,
            "postcode": formValues.postcode, 
            "state": formValues.state,
            "city": formValues.city,
            "address": formValues.address,
            "buldingName": formValues.buldingName,
            "contactInfo": formValues.contactInfo,
            "paymentInfo": paymentInfo           
        }       

        //console.log("posting user info")
        //console.log(paymentInfo)
        //console.log(body)
        //console.log(paymentInfo)
		const postAxios = axios.create({ baseUrl: "https://api.vciso.jp"})
        postAxios.post("/api/user", body, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
        .then(function(response) {
            if (response.status === 200) {
                ////console.log(response);
                
                if (response.data.apiCode === 0) {
                    setConfirm(true)
                    setUserId(`${response.data.data}`)
                    window.scrollTo(0,0)
                } else {
                    //console.log(response)
                    setAlert({ ...alert, show: true, message: "エラーが発生しました。" + response.data.message })
                }
            }
        })
        .catch(function(response) {
            console.error(response)
            setAlert({ ...alert, show: true, message: "エラーが発生しました" })
        })
        
       
       //setConfirm(true)
       ////console.log(body)
       
    }

    const handlePlanChange = (prop) => (event) => {
        setPlan({ ...plan, [prop]: event.target.value })
    }

    const handleReentry = (event) => {
        event.preventDefault();
        setValidated(false);
        setValue("password", "")
        setValue("confirmPass", "")
    }

    const handleConfirmPass = (event) => {
        setConfirmPass(event.target.value);
    }

    const setAddress = (prop) => {

        var postal_code = require('japan-postal-code');
        var zip = prop === "addressOne" ? getValues("postcode") : getValues("paymentInfo.postcode");
        postal_code.get(zip, function(address) {
            if (prop === "addressOne") {
               // //console.log("addressOne")
                setValue("state", address.prefecture)
                setValue("city", address.city)
                setValue("address", address.area)
                
            } else {
              //  //console.log("paymentInfo")
                setValue("paymentInfo.state", address.prefecture)
                setValue("paymentInfo.city", address.city)
                setValue("paymentInfo.address", address.area)
            }
        });
        /*
        address.then(address => {
            //console.log("it's been called")
            setValues({ ...values, addressOne: { 
                ...values.addressOne, 
                state: address.prefecture, 
                city: address.city, 
                address: address.area
            }})
        })
        */
    }

    

    useEffect(() => {
        let isSubscribed = true;
       // //console.log(location);
       
        setToken(location.search.split("=")[1])
       
        //console.log(location.search.split("=")[1])
      //  //console.log(cookies.get("vciso_email"))

		const registerAxios = axios.create({ baseURL: "https://api.vciso.jp"})
        registerAxios.get("/api/register", {
            headers: {
                "Authorization": "Bearer " + location.search.split("=")[1]
            }
        })
        .then(function(response) {
           
            if (response.data.apiCode === 0 && response.status === 200 && response.data.data.status !== "SUCCESS") {
                if (isSubscribed) {
                    
                    setUserReg({
                        corpName: response.data.data.corpName,
                        email: response.data.data.email,
                        type: response.data.data.regType,
                        status: response.data.data.status
                    })
                    if (response.data.data.status === "PAYMENTPENDING") {
                        setConfirm(true);
                        setValidated(true);
                        setUserId(response.data.data.userId)
                    }
                }
                
            } else {
                setAlert({ ...alert, show: true, message: "このアカウントは登録済みです。" })
                navigate("/")
                window.location.reload();
            }
            setLoaded(true)
        })
        .catch(function(response) {
            //console.log(response)
            setLoaded(true)
        })
        return () => isSubscribed = false;
    }, [])


    //console.log(typeof values.companyTypeId)
    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={6} style={{ "display": "flex", "flexDirection": "column", "justifyContent":"center", "alignItems": "center", "height": "fit-content", "minHeight": "750px"}}>
                
                {   loaded  ?
                        userReg.type === "NEWUSER" ?
                            <>
                            <h2 className="mt-4">{registered ? "登録完了" :confirm ? "登録確認": "サブアカウント登録"}</h2>
                            <RegisterSubAccount 
                                email={userReg.email} 
                                corpName={userReg.corpName} 
                                handleSubmit={handleSubmit} 
                                onSubmit={handleSubSubmit}
                                register={register} 
                                errors={errors}
                                confirm={confirm}
                                setConfirm={setConfirm}
                                registered={registered}
                                values={getValues()}
                            /></> :
                            <>
                            <h2 className="mt-4">{confirm ? "料金プラン": "本登録"}</h2>
                            <RegisterSubscriber
                                status={userReg.status}
                                confirm={confirm}
                                validated={validated}
                                plan={plan}
                                handlePlanChange={handlePlanChange}
                                setConfirm={setConfirm}
                                goToPayment={goToPayment}
                                checkValues={getValues()}
                                objValues={values}
                                confirmPass={getValues("confirmPass")}
                                handleSubmitConfirm={handleSubmitConfirmInfo}
                                handleReentry={handleReentry}
                                addAddress={addAddress}
                                subsChecked={subsChecked}
                                getValues={getValues}
                                handleObjChange={handleObjChange}
                                handleSubmitInfo={handleSubmitInfo}
                                values={values}
                                setAddress={setAddress}
                                handleChange={handleChange}
                                handleCheck={handleCheck}
                                confirmPassword={confirmPass}
                                handleConfirmPass={handleConfirmPass}
                                handleSubmit={handleSubmit}
                                register={register}
                                errors={errors}
                                policyAgree={policyAgree}
                            />
                            </>
                        :
                        <div className="fill-height-xs fill-height-md fill-height-xl" />
                }
            </Col>
            <Col />
        </Row>
    )
}