import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, Container, Form, Modal
} from 'react-bootstrap'
import AlertModal from '../AlertModal';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { FileUploader } from "react-drag-drop-files";
import ValidationError from "../ValidationError";
import { useNavigate } from 'react-router-dom';

export default function DocTemplateEdit(props) {
    const [show, setShow] = useState(false)
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [file, setFile] = useState(null)

    const [template, setTemplate] = useState({})

    const { state } = useLocation();

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({})

    const navigate = useNavigate();

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const onFileChange = (file) => {
        setFile(file)
        setValue("file", file.name)
    }

    const handleDelete = () => {
        setShow(false)
        axiosPrivate.delete("/api/admin/document/templates/" + template.template_id)
        .then(function(response) {
           // console.log(response)
            if (response.status === 200) {
                navigate(-1)
            }
        })
        .catch(function(response) {
            setAlert({ ...alert, show: true, message: "文書テンプレートの削除が失敗しました。"})
        })
    }

    const onSubmit = (data) => {

        var formData = new FormData();
        formData.append("template_id", template.template_id)
        formData.append("document_name", getValues().document_name)
        formData.append("template_type", getValues().template_type)
        formData.append("description", getValues().description)
        if (file !== null) { formData.append("file", file) }
        axiosPrivate.put("/api/admin/document/templates/update", formData)
        .then(function(response) {
           // console.log(response)
            navigate(-1)
        })
        .catch(function(response) {
            //console.log(response)
            setAlert({ ...alert, show: true, message: response.data.message})
        })
    }

    const fileTypes = ["xlsx", "docx", "pdf"]


    useEffect(() => {
        let isSubscribed = true;
        if (state && state.template !== null) {
            if (isSubscribed) {
                setTemplate(state.template)
                setValue("template_type", state.template.template_type)
                setValue("document_name", state.template.document_name)
                setValue("description", state.template.description)
            }
        }
        return () => isSubscribed = false;
    }, [state])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-0">
                <Modal show={show} onHide={() => setShow(false)} centered>
                    <Modal.Body className="mt-2">
                        <p>{template.document_name}</p>
                        <p>上記の文書テンプレートを削除いたします。</p>
                        <p>よろしいですか。</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="mx-4" onClick={handleDelete}>
                            はい
                        </Button>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            いいえ
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
                <Col />
                <Col sm={12} md={10} lg={10}>
                    <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                        <Row className="g-0">
                            <Col />
                            <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                                <h2 style={{ "alignSelf": "center" }}>文書テンプレートの編集</h2>
                            </Col>
                            <Col />
                        </Row>
                        <hr style={{ "width": "100%" }} />
                        <Row className="g-0">
                            <Col xs={6}>
                                <Form.Group className="my-4">
                                    <Form.Label>テンプレートの種類</Form.Label>
                                    <Form.Select { ...register("template_type")} style={{ "width": "fit-content"}} defaultValue="">
                                        <option>セキュリティポリシー</option>
                                        <option>クラウドサービス利用及び委託先管理</option>
                                        <option>スマートデバイス管理</option>
                                        <option>テレワーク規程及びテレワークガイドライン</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="my-4">
                                    <Form.Label>表示名（拡張子含め）</Form.Label>
                                    <Form.Control type="text" 
                                        { 
                                            ...register("document_name", 
                                            { 
                                                required: { value: true, message: "表示名を入力してください。"},
                                                pattern: {
                                                    value: /^.*\.(docx|pdf|xlsx|DOCX|PDF|XLSX)$/,
                                                    message: "拡張子付き表示名を入力してください。"
                                                }
                                            })
                                        } 
                                    />
                                    {
                                        errors.document_name && (
                                        <ValidationError message={errors.document_name.message} />
                                    )}
                                </Form.Group>
                                <Form.Group className="my-4">
                                    <Form.Label>文書テンプレートの説明</Form.Label>
                                    <Form.Control as="textarea" rows={5} { ...register("description", { required: {value: true, message: "文書テンプレートの説明を入力してください。"}})} />
                                    {
                                        errors.description && (
                                        <ValidationError message={errors.description.message} />
                                    )}
                                </Form.Group>
                                <br />
                                <div className="my-4">
                                    <p><a href="#">{template.document_name}</a></p>
                                    <p>更新した日時: {template.updated_at}</p>
                                </div>
                                <br />
                                <div className="my-4" id="admin-template-upload">
                                    <p>ダウンロード用テンプレートファイル</p>
                                    <FileUploader
                                        
                                        handleChange={onFileChange}
                                        types={fileTypes}
                                        style={{ "width": "100%" }}
                                    >
                                        <Form>
                                            <Form.Group>
                                                <Form.Control value={file ? file.name : "ファイルを選択してください"} 
                                            
                                                style={{ "width": "100%", "cursor": "pointer" }} 
                                                />
                                            </Form.Group>
                                          
                                        </Form>
                                    </FileUploader>
                                </div>
                                <Row className="g-0">
                                    <Col xs={3}>
                                        <Button style={{ "backgroundColor": "transparent", "color": "#0d6efd"}} onClick={() => setShow(true)}>削除する</Button>
                                    </Col>
                                    <Col>
                                        <Button type="submit">更新する</Button>
                                    </Col>
                                </Row>
                               
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col />
            </Row>
        </Form>
    )
}