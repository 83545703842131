import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function CustomizeSent(props) {
    const navigate = useNavigate();
    return (
        <Row className="g-0 ">
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box"></div>

                <section>
                    <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding:"0 10px"
                    }}
                    >
                        <br />
                        <br />
                        <div>依頼が完了しました。</div>
                        <br />
                        <div>
                            ご返信にお時間が掛かる場合がありますのでご了承ください。
                        </div>
                        <div>
                            今後の本カスタマイズ依頼のご連絡はメールにて送付致します。
                        </div>
                        <br />

                        <Row className="template-customize-area button-flex">
                            <Button
                                className="btn btn-primary"
                                onClick={() =>
                                    navigate("/doctemplates", { replace: true })
                                }
                            >
                                文書テンプレートへ
                            </Button>
                        </Row>
                    </div>
                    <br />
                    <br />
                </section>
            </div>
        </Row>
    );
}
