import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function EmailTrainingSent(props) {
    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };
    return (
        <Row className="g-0 ">
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box"></div>

                <div
                    className="under_title g-0 row"
                    style={{ textAlign: "left" }}
                >
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <h1>　合同メール訓練受付完了</h1>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
                <section style={{ padding: "0 10px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="inquiry-width customize-width"
                    >
                        <br />
                        <br />
                        <div>送信リストのアップロードおよび合同メール訓練の受付を致しました。​</div>
                        <br />
                        <div>
                            今後の本合同メール訓練受付に関するご連絡はお客様メールアドレスへご連絡致します。
                        </div>
                        <br />
                        <br />
                        <Row className="template-customize-area button-flex">
                            <Button
                                className="btn btn-primary"
                                onClick={() => handleNavigate("/emailtraining")}
                            >
                                合同メール訓練へ
                            </Button>
                        </Row>
                        <br />
                        <br />
                    </div>
                </section>
            </div>
        </Row>

        // <div className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl">
        //     <div>インシデントのご相談を受け付け致しました。​</div>
        //     <br />
        //     <div>
        //         ご返信までお時間が掛かる場合がありますので、ご了承だくさい
        //     </div>
        //     <br />
        //     <div>
        //         今後の本インシデントに関するご連絡はお客様メールアドレスへご連絡致します。
        //     </div>
        //     <br />
        //     <Button
        //         style={{ width: "fit-content" }}
        //         onClick={() => navigate("/")}
        //     >
        //         トップページへ
        //     </Button>
        // </div>
    );
}
