import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Stack,
    Accordion,
    Button,
    Container,
    Spinner,
} from "react-bootstrap";
import $ from "jquery";
import Faq from "../elements/chatservice/Faq";
import axios from "axios";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

function chatCreate() {
    axios
        .post("https://app.chatplus.jp/api/v1/chat_create", {
            id: "9",
            accessToken: "3b75915ff640e41d019bfcda04da45923cfd9c70",
            siteId: 1,
        })
        .then(function (response) {
            //console.log(response);
        })
        .catch(function (error) {
            //console.log(error);
        });
}

export default function ChatService(props) {
    const [loaded, setLoaded] = useState(false);
    const [chatWindow, setChatWindow] = useState(false);

    const SECOND_MS = 1000;

    useEffect(() => {
        let isSubscribed = true;
        const interval = setInterval(() => {
            //console.log("trying to mount chat")
            $("chat").css("display", "none");
            ////console.log($('#chatScript').html())
            //var chatScript = $("#chatScript").html();
            ////console.log(chatScript)
            // //console.log(chatScript.indexOf(`d["__cp_c"]="9da4a496_1";`))
            if (props.user) {
                var name = props.user;
                //console.log(name)
                //console.log(props.id)

                window.updateChat({ name: name, id: props.id });

                //var index = chatScript.indexOf(`d["__cp_c"]="9da4a496_1";`)
                //var newChatScript = chatScript.slice(0, index) + `d["__cp_p"] = { "externalKey": "57", "chatName": "test" };\n` + chatScript.slice(index)
                ////console.log(newChatScript)
                // $("#chatScript").html(newChatScript);
                //$("#name_form_group > div:first > input:first").attr("value", name)
                //$("#name_form_group > div:first > input:first").attr("placeholder", name)
                //console.log($('input[name="visitor_name"]').val())
                //console.log($('input[name="visitor_name"]').val() === name)
                $('input[name="visitor_name"]').val(name);
                ////console.log($('input[name="visitor_name"]').val())
                //if($('input[name="visitor_name"]').val() === name) {
                //fetch(`https://app.chatplus.jp/api/v1/update_user?exKey=${props.id}&perhapsName=${name}&siteId=1&accessToken=d211b9e4ec2c3baf7cfb115b902a1d3a8980b455`, { method: "POST"})
                //.then(function(response) {
                //    //console.log(response)
                //})
                //.catch(function(response) {
                //    if (response.status === 404) {
                //        //console.log("404")
                //    }
                //})
                if (isSubscribed) {
                    $("chat").appendTo($("#chat-window"));
                    if ($("chat").parent("#chat-window").length === 1) {
                        $("chat").css("display", "block");
                        setChatWindow(true);
                        clearInterval(interval);
                    }
                }
            }
        }, SECOND_MS);

        return () => {
            isSubscribed = false;
            clearInterval(interval);
        };
    }, []);

    const getChat = () => {
        // //console.log("button pressed")
        if ($("body").find("chat").length) {
            if (props.user) {
                ////console.log(props.user)

                var name = props.user.username;
                ////console.log(name)
                $("#name_form_group > div:first > input:first").attr(
                    "value",
                    name
                );

                if (
                    $("#name_form_group > div:first > input:first").attr(
                        "value"
                    ) === name
                ) {
                    $("chat").appendTo($("#chat-window"));
                    if ($("chat").parent("#chat-window").length === 1) {
                        setChatWindow(true);
                    }
                }
            }
        }
    };
    // //console.log(props.user);
    /*
    useEffect(() => {
        if (loaded) {
            var chat = document.getElementsByTagName("chat")[0];
            if (!chat) {
                setLoaded(!loaded)
                
            } else {
                document.getElementById("test").appendChild(chat);
            }
        } else {
            setLoaded(!loaded)
        }
    }, [loaded])
    */

    // <Button style={{ "alignSelf": "center", "width": "30%"}} onClick={() => getChat()}>再ロード</Button>
    //console.log(sessionStorage.getItem("user"))

    const [isShowModal, setIsShowModal] = useState(false);

    const chatServiceModal = () => {
        return (
            <Modal
                show={isShowModal}
                onHide={setIsShowModal}
                size="xl"
                centered
                className="p-4"
            >
                <Modal.Body className="chat-service-modal">
                    <h2 className="content_title fst_text">
                        <div style={{ textAlign: "center" }}>ご利用案内</div>
                    </h2>
                    <section>
                        <div className="chat-column-height-sm">
                            <div className="g-0 row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="col-sm-12 col-sm pr-sm-2">
                                            <table className="table table-striped table-bordered table-hover">
                                                <thead></thead>
                                                <tr>
                                                    <th>受付時間</th>
                                                    <td>
                                                        チャット窓口対応時間帯：平日10時～17時
                                                        <br />
                                                        問合せフォーム：随時受付
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>ご相談内容</th>
                                                    <td>
                                                        原則、情報セキュリティ・サイバーセキュリティに関する内容に限ります。特定のセキュリティ製品、IT製品の挙動、仕様に関する内容については、お答えしかねますので、ご容赦ください。
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>対応方法 回答時間</th>
                                                    <td>
                                                        チャット窓口対応時間帯であれば、ご相談を受け付けてから5分以内を目処に受付のご連絡、ご相談内容の確認をし、即時回答もしくは、専門担当者からご連絡する旨のご案内を致します。問い合わせフォームからのご相談は、ご相談を受け付けた後、平日10時～17時の時間帯でメールでご連絡差し上げます。
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        専門担当者からの連絡
                                                    </th>
                                                    <td>
                                                        専門担当者からはご相談を受け付けてから12時間以内を目処に電子メールにてご一報差し上げます。出来る限り、速やか且つ適切なアドバイスを心がけますが、場合によっては、お時間を要する事もありますので、ご了承ください。
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>注意事項</th>
                                                    <td>
                                                        本サービスは、お客様企業における情報セキュリティ・サイバーセキュリティに関するお悩み、課題に対するアドバイス（助言）をするサービスです。課題に対する根本解決を導くのは、お客様となりますので、ご承知おきください。
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <tr
                                            style={{
                                                backgroundColor: "lightgray",
                                            }}
                                        ></tr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div
                        className="chat-service-confrim-btn"
                        onClick={() => setIsShowModal(false)}
                    >
                        確認
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <Row className="g-0 ChatService">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　よろづ相談</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            {chatServiceModal()}
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box"></div>

                <section>
                    <div className="chat-column-height-sm">
                        <div className="g-0 row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <br />
                                <br />
                                <div
                                    className="chat-link"
                                    onClick={() => setIsShowModal(true)}
                                >
                                    ご利用案内はこちら
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section>
                <div className="chat-window-height-sm">
                    {/* <div style="height:500px;" class="vstack gap-2">
                        <div id="chat-window" style="height:100%;"></div>
                    </div> */}
                    <Row
                        className="g-0"
                        style={{
                            height: "100%",
                            width: "100%",
                            minHeight: "500px",
                        }}
                    >
                        <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="padding-sm"
                        >
                            <Stack
                                gap={2}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    alignSelf: "center",
                                }}
                                //className="chat-window-height-sm"
                            >
                                <div
                                    id="chat-window"
                                    className="vstack gap-2"

                                    // style={{
                                    //     width: "100%",
                                    //     height: "100%",
                                    //     display: "flex",
                                    //     flexDirection: "column",
                                    //     justifyContent: "center",
                                    // }}
                                >
                                    {chatWindow ? null : (
                                        <Spinner
                                            animation="border"
                                            style={{
                                                height: "5rem",
                                                width: "5rem",
                                                alignSelf: "center",
                                            }}
                                        />
                                    )}
                                </div>
                            </Stack>
                        </Col>
                    </Row>
                </div>
            </section>

            <Row>
                <Faq />
            </Row>
        </Row>
    );
}
