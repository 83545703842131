import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Modal
} from 'react-bootstrap';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from 'react-hook-form';
import AlertModal from '../AlertModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { ReactComponent as DeleteIcon } from '../../../assets/svg_icons/delete-x.svg';


const placeholderGenerator = quantity => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({ userId: i, companyName: " ", nameKana: " ", mailId: " ", phonenumber: " ", plan: " ", created_at: " "});
    }
    return items;
};

export default function UserList(props) {

    const [modal, setModal] = useState({
        open: false,
        message: ""
    })

    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(() => placeholderGenerator(25))


    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            terms: "",
        }
    });

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const columns = [
        {
            dataField: "userId",
            text: "id",
            hidden: true,
        },
        {
          dataField: "companyName",
          text: "会社名",
          sort: true,
          headerStyle: () => {
            return { width: "23%"}
          }
        },
        {
          dataField: "nameKana",
          text: "代表者氏名（カナ）",
          sort: true,
          headerStyle: () => {
            return { width: "20%" }
          }
        },
        {
          dataField: "mailId",
          text: "メールアドレス",
          sort: true,
          headerStyle: () => {
            return {width: "20%"}
          }
        },
        {
            dataField: "phonenumber",
            text: "連絡先",
            headerStyle: () => {
                return { width: "10%"}
            }
        },
        {
            dataField: "plan",
            text: "料金プラン",
            sort: true,
            headerStyle: () => {
                return { width: "10%"}
            }
        },
        {
            dataField: "created_at",
            text: "会員登録日時",
            sort: true,
            headerStyle: () => {
               return {width: "10%"}
            }
        },
        {
            dataField: "delete",
            text: "削除",
            headerStyle: () => {
                return { width: "7%" }
            }
        }
    ];

    
    const deleteModalButton = (user) => {
        console.log(user)

        return (
            <Button size="sm" variant="outline-danger" onClick={() => setModal({ ...modal, show: true, message: user})}>
                <DeleteIcon style={{ "width" : "24px", "height": "24px"}} fill="darkred" />
            </Button>
        )
    }

    const handleModalClose = () => setModal({ open: false, message: ""});

    const rowGenerator = data => {
        const items = [];
        data.forEach(user => {
          items.push({ userId: user.userId, companyName: user.companyName, nameKana: `${user.lastNameKana} ${user.firstNameKana}`, mailId: user.mailId, phonenumber: user.phonenumber, plan: `${user.plan.slice(0,1).toUpperCase() + user.plan.slice(1).toLowerCase()}`, created_at: user.createdDate, delete: deleteModalButton(user)});
        })
        return items;
     };
    
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log(row)
        }
    };
      
    const handleReset = () => {
        setValue("terms", "")
        setValue("plan", "料金プランを選択してください")
        setValue("startDate", "")
        setValue("endDate", "")
        setFilteredUsers(users);
    }

    const handleDelete = (user) => {
		
		handleModalClose();
		
		
        axiosPrivate.delete("/api/admin/user/delete/" + user.userId)
        .then(function(response) {
			if (response.status === 200) {
				if (response.data.apiCode === 0) {
					setAlert({ ...alert, show: true, message: '「' + user.mailId + '」のメールを持つユーザを削除しました。' });
					axiosPrivate.get("/api/admin/users")
						.then(function (response) {
							if (response.status === 200) {
								if (response.data.apiCode === 0) {
									var users = rowGenerator(response.data.data)
									setUsers(users)
									setFilteredUsers(users)
								}
							}
						})
						.catch(function (response) {
							//console.log(response)
						})
				}
			} else {
				setAlert({ ...alert, show: true, message: "削除が失敗しました。" })
			}
        })
        .catch(function(response) {
			console.log(response)
            setAlert({ ...alert, show: true, message: "削除が失敗しました。"})
		})
		
		
    }

    const onSubmit = (data) => {
        var values = getValues();
        //console.log(values)
        var filtered = JSON.parse(JSON.stringify(users));
        if (values.plan !== "料金プランを選択してください") {
            filtered = filtered.filter(x => x.plan.toLowerCase() === values.plan.toLowerCase());
        }
        //console.log(filtered)
        if (values.terms !== "") {
            var terms = values.terms.toLowerCase();
            filtered = filtered.filter(x => x.mailId.toLowerCase().includes(terms) || x.companyName.toLowerCase().includes(terms) || x.nameKana.toLowerCase().includes(terms))
        }
        //console.log(filtered)

        if (values.startDate !== "") {
            var start = new Date(values.startDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) >= start)
            
        }

        if (values.endDate !== "") {
            var end = new Date(values.endDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) <= end)
        }

        if (filtered.length === 0 || filtered.length === users.length) {
            setAlert({ ...alert, show: true, message: "キーワード検索に合致するユーザーが見つかりませんでした。"})
        } else {
            setFilteredUsers(filtered)
        }
    }

    useEffect(() => {
        //console.log("calling")
        axiosPrivate.get("/api/admin/users")
        .then(function(response) {
            if (response.status === 200) {
                if (response.data.apiCode === 0) {
                    var users = rowGenerator(response.data.data)
                    setUsers(users)
                    setFilteredUsers(users)
                }
            }
        })
        .catch(function(response) {
            //console.log(response)
        })
    }, [])
    return (
        <Row className="g-0">
            <Modal show={modal.show} onHide={handleModalClose} centered>
                <Modal.Header>
                    <Modal.Title>以下のユーザを削除しますか。</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modal.message.userId ?
                        <>
                        <div>
                            ユーザのID: {modal.message.userId}
                        </div>
                        <div>
                            会社名: {modal.message.companyName}
                        </div>
                        <div>
                            メール: {modal.message.mailId}
                        </div>
                        <div>
                            お名前: {modal.message.lastNameKana} {modal.message.firstNameKana}
                        </div>
                        </> :
                        <div></div>
                    }
                    
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>キャンセル</Button>
                    <Button variant="danger" onClick={() => handleDelete(modal.message)}>削除</Button>
                </Modal.Footer>
            </Modal>
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center"}}>顧客情報一覧</h2>
                    <hr style={{ "width": "100%"}}/>
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="g-0">
                                <Col xs={6}>
                                    <Form.Group className="my-4" style={{ "width": "50%"}}>
                                        <Form.Label>絞り込みたい文字列を入力</Form.Label>
                                        <Form.Control type="text" {...register("terms")}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            料金プランで絞り込み
                                        </Form.Label>
                                        <Form.Select {...register("plan")}>
                                            <option hidden>料金プランを選択してください</option>
                                            <option>Lite</option>
                                            <option>Standard</option>
                                            <option>Premium</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="g-0">
                                <Col xs={6}>
                                    
                                    <div className="my-4">
                                        <Form.Group style={{ "width": "fit-content", "float": "left"}}>
                                            <Form.Label>期間で絞り込み</Form.Label>
                                            <Form.Control type="date" {...register("startDate")} />
                                        </Form.Group>
                                        <div style={{ "float": "left", "marginTop": "32px"}}>
                                            <div className="mx-2" style={{ "float": "left", "lineHeight": "38px"}}>〜</div>
                                            <Form.Group style={{ "width": "fit-content","float": "left"}}>
                                                <Form.Control type="date" {...register("endDate")} />
                                            </Form.Group>
                                        </div>
                                    </div> 
                                        
                                   

                                    
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col xs={2}>
                                    <Button className="my-4" type="submit">検索する</Button>
                                </Col>
                                <Col xs={2}>
                                    <Button className="my-4" onClick={handleReset} variant="outline-primary">リセット</Button>
                                </Col>
                            </Row>
                            
                        </Form>
                    </Container>
                    <div style={{ "minHeight": "600px"}}>
                    <BootstrapTable     
                        hover
                        bootstrap4
                        keyField='id'
                        data={filteredUsers}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 25})}
                        rowStyle={{ overflowWrap: 'break-word' }}
                        rowEvents={rowEvents}
                    />
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    );
}