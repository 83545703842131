import {
    Row, Col
} from 'react-bootstrap'
import logo from '../../assets/logo.png'

function Case(props) {
    return (
        <div className="shadow p-3" style={{ "borderRadius": '.5rem'}}>
            <Row className="g-0">
                <Col xs={6}>
                    <div style={{ "display": "flex", "flexDirection": "column", "height": "100%", "justifyContent": "center"}}>
                        <img src={props.img} style={{ "alignSelf": "center"}}/>
                    </div>
                </Col>
                <Col xs={6} className="px-3">
                    <div>{props.company}</div>
                    <div>{`業種: ${props.type}`}</div>
                    <div>{`社員数: ${props.number}`}</div>
                    <br />
                    <div>{props.additional}</div>
                </Col>
            </Row>
        </div>
    )
}

export default function Cases(props) {

    return (
        <Row className="g-0">
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center"}}>導入事例</h2>
                    <hr style={{ "width": "100%"}} />
                    <Row className="g-0">
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                        <Col xs={6} className="p-4">
                            <Case img={logo} company="株式会社ダミー" type="テキスト" number="100人" additional="ダミーテキストダミーテキストダミーテキスト" />
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col />
        </Row>
    )
}