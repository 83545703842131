import React, { useState } from 'react';
import {
    Button, Row, Col
} from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { axiosPrivate } from '../../utils/axiosPrivate';
import Cookies from 'universal-cookie';

export default function CancelSub(props) {
    const [success, setSuccess] = useState(false)

    const navigate = useNavigate();
    const cookies = new Cookies();
    const handleCancelSub = () => {
        axiosPrivate.put("/api/subscription/cancel")
        .then(function(response) {
            ////console.log(response)
            if (response.status === 200) {
                setSuccess(true)
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("refresh")
                sessionStorage.removeItem("access_token")
            }
        })
        .catch(function(response) {
            //console.log(response)
        })
    }

    const handleTop = () => {
        setSuccess(false)
        navigate("/", { replace: true})
        window.location.reload();
    }

    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <Col />
            <Col sm={12} md={6} lg={6} style={{ "display": "flex", "flexDirection": "column", "alignItems": "center"}}>
                <h2 className="mt-3">{success ? "退会完了" : "退会手続き"}</h2>
                <hr style={{ "width": "100%"}} />
                <br />
                <Row className="g-0" style={{ "width": "100%"}}>
                    <Col />
                    {
                        /*
                        <Col xs={8}>
                            { success ?
                                <>
                                    <div>vCISOの退会手続きが完了しました。</div>
                                    <div>ご利用ありがとうございました。</div>
                                </> :
                                <>
                                    <div>退会手続きを行います。</div>
                                    <div>退会後は本サービス及びサービス内の全てのデータにアクセス出来なくなりますのでご注意ください。</div>
                                    <br />
                                </>
                            }
                        </Col>
                        */
                        <Col xs={10}>
                            <div>退会をご希望の場合は、vcisojimukyoku@gsx.co.jpへ退会の旨、メール送付をお願い致します。</div>
                            <div>尚、ご契約期間は1年間となりますので、月額でのお支払い且つ1年未満での退会の場合は残存期間</div>
                            <div>分のご請求となります旨、ご了承ください。</div>
                        </Col>
                    }
                    <Col />
                </Row>
                <br />
                <br />
                <Row className='g-0' style={{ "width": "100%"}}> 
                {
                    /*
                {   
                    success ?
                    <Col style={{ "textAlign": "center"}}>
                        <Button onClick={handleTop}>トップページへ</Button>
                    </Col> :
                    <>
                        <Col xs={6} style={{ "textAlign": "center"}}>
                            <Button onClick={() => navigate("/user", { replace: true})}>キャンセル</Button>
                        </Col>
                        <Col xs={6} style={{ "textAlign": "center"}}>
                            <Button onClick={handleCancelSub}>退会する</Button>
                        </Col>
                    </>
               
                }
                */
               <>
                    
                    <Col xs={6} style={{ "textAlign": "center"}}>
                    </Col>
                    <Col xs={6} style={{ "textAlign": "center"}}>
                        <Button style={{ "width": "150px"}} onClick={() => navigate("/user", { replace: true})}>戻る</Button>
                    </Col>
                </>
                }
                 </Row>
            </Col>
            <Col />
        </Row>
    )
}