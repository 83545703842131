import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const linkNames = new Map();
linkNames.set("ryokinplan", "料金プラン");
linkNames.set("chatservice", "よろづ相談");
linkNames.set("doctemplates", "文書テンプレート");
linkNames.set("emailtraining", "合同メール訓練");
linkNames.set("elearning", "Eラーニング");
linkNames.set("login", "ログイン");
linkNames.set("securitycheck", "セキュリティ健康診断");
linkNames.set("incident", "インシデント対応窓口");
linkNames.set("signup", "会員仮登録");
linkNames.set("customize", "文書カスタマイズ依頼");
linkNames.set("inquiry", "お問い合わせ");
linkNames.set("services", "サービス詳細");
linkNames.set("payment", "決済");
linkNames.set("register", "会員登録");
linkNames.set("about", "vCISOとは");
linkNames.set("cyberarticles", "サイバーセキュリティNews");
linkNames.set("interviews", "インタビュー");
linkNames.set("user", "会員情報");
linkNames.set("registration", "合同メール訓練応募");
linkNames.set("subaccount", "サブアカウント登録");
linkNames.set("faq", "よくある質問");
linkNames.set("cases", "導入事例");
linkNames.set("forgotpassword", "パスワード忘れ");
linkNames.set("resetPassword", "パスワードリセット");
linkNames.set("cancel", "退会手続き");
linkNames.set("tokusyou", "特定商取引法に基づく表記​");
linkNames.set("admin", "管理");
linkNames.set("counseling", "個別カウンセリング");
linkNames.set("manual", "マニュアル");
linkNames.set("yakkan", "サービス/サイバーリスク保険約款");
linkNames.set("userlist", "顧客情報一覧");
linkNames.set("magazine", "メールマガジン登録");
linkNames.set("originalcontent", "GSXオリジナルコンテンツ");
linkNames.set("incidents", "インシデント対応一覧");
linkNames.set("details", "詳細");
linkNames.set("doccustomize", "イ文書カスタマイズ依頼一覧");
linkNames.set("upload", "新規追加");
linkNames.set("emailregistrations", "メール訓練一覧");
linkNames.set("inquiries", "お問い合わせ一覧");
linkNames.set("counselingsessions", "個別カウンセリング一覧");
linkNames.set("seccheck", "セキュリティ健康診断一覧");
linkNames.set("mypage", "マイページ");
linkNames.set("useredit", "会員情報編集");
linkNames.set("changepassword", "パスワード変更");
linkNames.set("setMFA", "多要素認証");
linkNames.set("userplan", "料金プラン");
linkNames.set("paymentInfo", "料金明細");
linkNames.set("subAccounts", "サブアカウント");
linkNames.set("chart", "カルテ");
linkNames.set("securityAction", "SECURITY ACTION");
export default function BreadcrumbNav(props) {
    const [path, setPath] = useState("");
    const [linkName, setLinkName] = useState([]);
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        ////console.log(location.pathname)
        var newPath = location.pathname.replace(/\//, "");
        // //console.log(newPath)
        if (newPath !== path) {
            var newPaths = newPath.split("/");
            var paths = [];
            newPaths.map((path) => {
                paths.push([path, linkNames.get(path)]);
            });
            // //console.log(paths)
            setPath(newPath);
            setLinkName(paths);
        }
    }, [location]);

    ////console.log(linkName)
    return (
        <>
            {path !== "" ? (
                <Row
                    id="breadcrumb-row"
                    className="g-0 px-4"
                    style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor: "rgba(248, 249, 250, 1)",
                        lineHeight: "50px",
                    }}
                >
                    <Col md={1} />
                    <Col md={10}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/")}>
                                トップページ
                            </Breadcrumb.Item>
                            {linkName.map((link, index) => {
                                //(link)
                                if (index === linkName.length - 1) {
                                    return (
                                        <Breadcrumb.Item
                                            active
                                            key={`${link}_${index}`}
                                        >
                                            {link[1]}
                                        </Breadcrumb.Item>
                                    );
                                } else {
                                    return (
                                        <Breadcrumb.Item
                                            key={`${link}_${index}`}
                                            onClick={() =>
                                                navigate(
                                                    index - linkName.length + 1
                                                )
                                            }
                                        >
                                            {link[1]}
                                        </Breadcrumb.Item>
                                    );
                                }
                            })}
                        </Breadcrumb>
                    </Col>
                    <Col md={1} />
                </Row>
            ) : null}
        </>
    );
}
