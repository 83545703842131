import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Stack, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";
import ReCAPTCHA from "react-google-recaptcha";
import AlertModal from "../elements/AlertModal";
import { useNavigate } from "react-router";
import $ from "jquery";

export default function ForgotPassword(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const [validated, setValidated] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [formError, setFormError] = useState("");
    const [show, setShow] = useState(false);

    const captchaRef = useRef(null);

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const cookies = new Cookies();

    const navigate = useNavigate();

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    function validateEmail(email) {
        ////console.log(email)
        var validatedEmail = email.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        ////console.log(validatedEmail)
        var error =
            validatedEmail && email.length > 0
                ? ""
                : "メールフォーマットは無効です。";
        setFormError(error);
        return validatedEmail;
    }

    const onSubmit = (data) => {
        //const form = event.currentTarget;
        const token = captchaRef.current.getValue();
        //console.log(token);

        if (token.length > 0) {
            const passAxios = axios.create({
				baseUrl: "https://api.vciso.jp",
            });
            passAxios
                .put("/api/user/resetpassword", {
                    mail: data.email,
                    recaptchaResponse: token,
                })
                .then(function (response) {
                    if (response.status === 200) {
                        //console.log(response)
                        setSuccess(true);
                    } else if (response.status === 403) {
                        //console.log(response)
                        setAlert({
                            ...alert,
                            show: true,
                            message: "Captcha invalid",
                        });
                    }
                })
                .catch(function (response) {
                    console.error(response);
                });
        } else {
            setShow(true);
            setFormError("ロボットでない事の確認チェックを行ってください。​");
        }
        captchaRef.current.reset();
    };

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    function onChange(value) {
        //console.log("Captcha value: " + value)
        setCaptcha(value);
    }

    const handleResend = (event) => {
        event.preventDefault();
        //console.log("clicked")
        axios
			.post("https://api.vciso.jp/api/register", {
                mail: email,
            })
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.message.includes("invalid")) {
                        setFormError(response.data.message);
                        setShow(true);
                    } else {
                        ////console.log(response)
                        setSuccess(true);
                        sessionStorage.setItem("vciso_email", email);
                        setAlert({
                            ...alert,
                            show: true,
                            message: "仮登録メールを再送しました。",
                        });
                    }
                }
            })
            .catch(function (response) {
                console.error(response);
            });
    };
    /*
    useEffect(() => {
        $("iframe[title='reCAPTCHA']").on("load", function() {
            var containerWidth = $('#pass-container').width();
            //console.log(containerWidth)
            var captchaWidth = $("iframe[title='reCAPTCHA']").width()
            //console.log(captchaWidth)
            if (captchaWidth > containerWidth) {
                //console.log("here")
                var scale = containerWidth / captchaWidth;
                $("iframe[title='reCAPTCHA']").css({
                    'transform':'scale('+scale+')',
                    '-webkit-transform':'scale('+scale+')',
                    'transform-origin':'0 0',
                    '-webkit-transform-origin':'0 0'
                })
            }
        })
    }, [])
    
*/
    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Col />
            <Col>
                <Stack
                    gap={4}
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                    className="p-4 LoginForgot"
                >
                    <h2>パスワード再設定</h2>

                    <Alert
                        show={show}
                        variant="danger"
                        onClose={() => setShow(false)}
                        dismissible
                        style={{ width: "80%", height: "50px" }}
                    >
                        <p>{formError}</p>
                    </Alert>
                    <div className="shadow-lg p-4">
                        {success ? (
                            <Form className="form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password">
                                        {getValues().email}
                                        にパスワードリセット用メールを送信いたしました。
                                    </Form.Label>
                                </Row>
                                <br />
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password">
                                        もし、ご連絡メールが届かない場合には迷惑メールフォルダのチェックをお願いいたします。
                                    </Form.Label>
                                </Row>

                                <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-2 button-size"
                                            onClick={() =>
                                                navigate("/", { replace: true })
                                            }
                                        >
                                            トップページへ戻る
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                            </Form>
                        ) : (
                            <Form
                                className="form-area"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Form.Group className="edit-forgot-form-area">
                                    <Row className="g-0">
                                        <Form.Label className="label-forgot-password">
                                            こちらのアドレスにパスワード再設定用のメールを送信致しますので、アカウント登録しているメールアドレスを入力ください。
                                        </Form.Label>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="edit-forgot-form-area">
                                    <Row className="g-0">
                                    <Form.Label className="label-forgot-password required-lable">
                                            メールアドレス　
                                        </Form.Label>
                                    </Row>
                                    <Form.Control
                                        type="email"
                                        className="input-mail"
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message:
                                                    "メールアドレスを入力してください",
                                            },
                                            pattern: {
                                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message:
                                                    "メールフォーマットは無効です。",
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <ValidationError
                                            message={errors.email.message}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group className="edit-roboter-form-area">
                                    <Row className="g-0">
                                        <div id="pass-container">
                                            {window.innerWidth <= 1280 ? (
                                                <ReCAPTCHA
                                                    sitekey="6LeObzUhAAAAAMVfgN4V0eFy_Cwm_jxKV_7GdMug"
                                                    ref={captchaRef}
                                                    size="compact"
                                                />
                                            ) : (
                                                <ReCAPTCHA
                                                    sitekey="6LeObzUhAAAAAMVfgN4V0eFy_Cwm_jxKV_7GdMug"
                                                    ref={captchaRef}
                                                />
                                            )}
                                        </div>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="edit-phone-form-area">
                                    <Row className="g-0">
                                        <div className="button-area">
                                            <Button
                                                className="rounded-pill btn-blue-2 button-size"
                                                type="submit"
                                            >
                                                送信する
                                            </Button>
                                        </div>
                                    </Row>
                                </Form.Group>
                            </Form>
                        )}
                    </div>
                </Stack>
            </Col>
            <Col />
        </Row>
    );
}
