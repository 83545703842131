import axios from 'axios';


let Parser = require('rss-parser');
let parser = new Parser({
    customFields: {
        item: [
            'description'
        ]
    }
});


export default async function getRSSFeed(url) {

    /*
    axios.get("https://14rphqgebl.execute-api.us-east-1.amazonaws.com/dev/?url=https://scan.netsecurity.ne.jp/article/2022/06/20/47763.html")
        .then(response => {
            //console.log(response)
            
            if (response.status === 200) {
                let html = response.data;
                const $ = cheerio.load(html);
                var imgSrc = $('meta[name="thumbnail"]').attr('content');
                //console.log(imgSrc);
            }
            
        })
   */
    
    let feed = await parser.parseURL(url);
    return feed;

    //var feed;
    //await fetch(`https://vciso.gsx-its.com/feed`)
    //.then(function (response) {
        ////console.log( response.text());
   //     return response.text();
    //}).then(function (data) {
    //    feed = data;
    //})
    //.catch(console.error);
    //return feed;
}