import React, { useState, useRef } from "react";
import { Row, Col, Stack, Form, Button, Container } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import AlertModal from "../elements/AlertModal";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";
import axios from "axios";
import { useNavigate } from "react-router";

export default function Magazine(props) {
    const [policy, setPolicy] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [sent, setSent] = useState(false);

    const captchaRef = useRef(null);
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const navigate = useNavigate();

    const handleCheck = (event) => {
        setPolicy(event.target.checked);
    };

    const onSubmit = (data) => {
        const token = captchaRef.current.getValue();
        const magazineAxios = axios.create({
			baseUrl: "https://api.vciso.jp",
        });
        var body = {
            companyName: data.company,
            name: data.name,
            email: data.email,
            recaptchaResponse: token,
        };

        magazineAxios
            .post("/api/magazine", body)
            .then(function (response) {
                //console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setSent(true);
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.message,
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "メールマガジン登録が失敗しました。",
                });
            });
    };

    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Stack
                gap={4}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                }}
                className="p-4 Magazine"
            >
                <h2>メールマガジン登録</h2>
                <div className="shadow-lg p-4">
                    {sent ? (
                        <Form className="form-area">
                            <Row className="g-0">
                                <Form.Label className="label-forgot-password">
                                    {`${
                                        getValues().email
                                    }に登録完了メールを送信致しました。`}
                                </Form.Label>
                            </Row>
                            <br />
                            <Row className="g-0">
                                <Form.Label className="label-forgot-password">
                                    もし、ご連絡メールが届かない場合には迷惑メールフォルダのチェックをお願いいたします。
                                </Form.Label>
                            </Row>

                            <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-2 button-size"
                                            onClick={() =>
                                                navigate("/", { replace: true })
                                            }
                                        >
                                            トップページへ戻る
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </Form>
                    ) : (
                        <Form
                            className="form-area"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Form.Group className="edit-forgot-form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password required-lable">
                                        会社名　
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="register-input"
                                        {...register("company", {
                                            required: {
                                                value: true,
                                                message:
                                                    "会社名を入力してください。",
                                            },
                                        })}
                                    />
                                    {errors.company && (
                                        <ValidationError
                                            message={errors.company.message}
                                        />
                                    )}
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-forgot-form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password required-lable">
                                        お名前　
                                    </Form.Label>

                                    <Form.Control
                                        type="text"
                                        {...register("name", {
                                            required: {
                                                value: true,
                                                message:
                                                    "お名前を入力してください。",
                                            },
                                        })}
                                    />
                                    {errors.name && (
                                        <ValidationError
                                            message={errors.name.message}
                                        />
                                    )}
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-forgot-form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password required-lable">
                                        メールアドレス　
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message:
                                                    "メールアドレスを入力してください。",
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <ValidationError
                                            message={errors.email.message}
                                        />
                                    )}
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row className="g-0 margin-top">
                                    <Form.Check
                                        checked={policy}
                                        className="checkbox-area"
                                        type="checkbox"
                                        id="policy-check"
                                        name="policy-check"
                                        {...register("policy", {
                                            validate: (value) =>
                                                value === true ||
                                                "個人情報の取り扱いに同意してください。",
                                            onChange: handleCheck,
                                        })}
                                    />
                                    <Form.Label className="required-lable">
                                        　
                                        <a
                                            href="https://gsx.co.jp/privacy.html"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            個人情報の取り扱い
                                        </a>
                                        について同意する　
                                    </Form.Label>
                                </Row>
                                <Row className="g-0 mb-3">
                                    <Col xs={3} />
                                    <Col>
                                        {errors.policy && (
                                            <ValidationError
                                                message={errors.policy.message}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-roboter-form-area">
                                <Row className="g-0">
                                    <div id="captcha-container">
                                        <ReCAPTCHA
                                            sitekey="6LeObzUhAAAAAMVfgN4V0eFy_Cwm_jxKV_7GdMug"
                                            ref={captchaRef}
                                        />
                                    </div>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-2 button-size"
                                            type="submit"
                                        >
                                            送信する
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </Form>
                    )}
                </div>
            </Stack>
        </Row>
    );
}
