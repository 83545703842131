import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import white_logo from '../../assets/white_logo.png';
import privacyPolicy from '../../assets/vCISOサービス約款Ver1.1.pdf';
import { useNavigate }from "react-router-dom";

export default function Footer(props) {
    const [disabled, setDisabled] = useState({
        chat: false,
        docs: false,
        incident: false,
        secCheck: false,
        elearn: false,
        email: false,
        match: false,
        counseling:false
    })

    const { user } = props;
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
        window.scrollTo(0,0);
    }

    useEffect(() => {
        if (props.userInfo.plan.length > 0) {
            switch(props.userInfo.plan) {
                case 'LITE':
                    setDisabled({ ...disabled, email: true, elearn: true, secCheck: true, match: true, counseling: true });
                    break;
                case 'STANDARD':
                    setDisabled({ ...disabled, chat: false, docs: false, incident: false, elearn: false, email: false, secCheck: true, match: true, counseling:true });
                    break;
                default: 
                   setDisabled({ ...disabled, chat: false, docs: false, incident: false, elearn: false, email: false, secCheck: false, match: true });
                   break;
            }
        }
    }, [props.userInfo]);

    return (
        <div id="footer">
            <Container>
                <Row className="g-0">
                    <Col xs={12} md={4} lg={6} className="vcisoLogo">
                        <a href="#"><img src={white_logo} style={{'width': '-webkit-fill-available', 'maxWidth': '115px'}} onClick={() => navigate("/")}/></a>
                    </Col>
                    <Col xs={12} md={8} lg={6} className="buttons-list">
                        {!user ?
                          <Container  style={{ 'height': '100%', 'display': 'flex', 'justifyContent' : 'end', 'position': 'relative'}}>
                              <Button className="btn-white-border rounded-pill mb-2 mb-md-0 me-0 me-md-2"  onClick={() => navigate("signup")}>新規申込はこちら</Button>
                              <Button className="btn-white rounded-pill" onClick={() => navigate("inquiry", { state: { type: "30日間無料お試し申込" } })}>30日間お試し登録</Button>
                          </Container>
                          : null
                        }
                    </Col>
                </Row>
                <Row className="footer-nav g-0">
                    <Col className="nav-box mb-5 mb-lg-0" xl={3} md={3} xs={6}>
                        <h5 className="nav-title mb-4">vCISO</h5>
                        {!user ?
                          <ul className="nav-list">
                              <li className="list-item mb-2"><a href="#" className="mb-1" onClick={() => handleNavigate("/")}>トップページ</a></li>
                              <li className="list-item mb-2"><a href="#" className="mb-1" onClick={() => handleNavigate("about")}>vCISOとは</a></li>
                              <li className="list-item mb-2"><a href="#" className="mb-1" onClick={() => handleNavigate("services")}>サービス詳細</a></li>
                              <li className="list-item mb-2"><a href="#" className="mb-1" onClick={() => handleNavigate("ryokinplan")}>料金プラン</a></li>
                              <li className="list-item mb-2"><a href="#" className="mb-1"
                                 style={{'color': 'darkgray', 'textDecoration': 'none', "pointerEvents": "none"}}
                                 onClick={() => handleNavigate("cases")}>導入事例</a></li>
                              <li className="list-item mb-2"><a href="#" className="mb-1" onClick={() => handleNavigate("faq")}>よくある質問</a></li>
                          </ul> :
                          <ul className="nav-list">
                            <li className="list-item mb-2"><a href="#" className="mb-1" onClick={() => handleNavigate("/")}>トップページ</a></li>
                            <li className="list-item mb-2"><a href="/chatservice" className={disabled.chat ? "disabled" : ""} >よろづ相談</a></li>
                            <li className="list-item mb-2"><a href="#" className={disabled.docs ? "disabled" : ""} onClick={() => handleNavigate("doctemplates")}>文書テンプレート</a></li>
                            <li className="list-item mb-2"><a href="#" className={disabled.elearn ? "disabled" : ""} onClick={() => handleNavigate("elearning")}>Eラーニング</a></li>
                            <li className="list-item mb-2"><a href="#" className={disabled.email ? "disabled" : ""} onClick={() => handleNavigate("emailtraining")}>合同メール訓練</a></li>
                            <li className="list-item mb-2"><a href="#" className={disabled.secCheck ? "disabled" : ""} onClick={() => handleNavigate("securitycheck")}>セキュリティ健康診断</a></li>
                            <li className="list-item mb-2"><a href="#" className={disabled.counseling ? "disabled" : ""} onClick={() => handleNavigate("counseling")}>個別カウンセリング</a></li>
                            <li className="list-item mb-2"><a className={disabled.match ? "disabled" : ""} href="https://my.vciso.jp/login" target="_blank" style={{ "pointerEvents" : "none" }}>人材マッチング</a></li>
                            <li className="list-item mb-2"><a href="#" className={disabled.incident ? "disabled" : "" } onClick={() => handleNavigate("incident")}>インシデント対応</a></li>
                            {props.userInfo.roles && props.userInfo.roles.includes("ADMINISTRATOR") ?
                              <li className="list-item mb-2"><a href="#" onClick={() => handleNavigate("/admin")}>管理</a></li>
                              : null
                            }
                          </ul>
                        }
                    </Col>
                    <Col className="nav-box" xl={3} md={3} xs={6}>
                        <h5 className="nav-title mb-4">記事コンテンツ</h5>
                        <ul className="nav-list">
                            <li className="list-item mb-2"><a href="#" onClick={() => handleNavigate("cyberarticles")}>サイバーセキュリティNEWS</a></li>
                            <li className="list-item mb-2"><a href="#" onClick={() => handleNavigate("originalcontent")}>GSXオリジナルコンテンツ</a></li>
                        </ul>
                    </Col>
                    <Col className="nav-box" xl={3} md={3} xs={6}>
                        <h5 className="nav-title mb-4">サイト情報</h5>
                        <ul className="nav-list">
                            <li className="list-item mb-2"><a href="#" onClick={() => handleNavigate("yakkan")}>サービス約款</a></li>
                            <li className="list-item mb-2"><a href="https://www.gsx.co.jp/privacy.html" target="_blank">プライバシーポリシー</a></li>
                        </ul>
                    </Col>
                    <Col className="nav-box" xl={3} md={3} xs={6}>
                        <h5 className="nav-title mb-4">運営会社情報</h5>
                        <ul className="nav-list">
                            <li className="list-item mb-2"><a href="https://gsx.co.jp/about/#outline" target="_blank" >運営会社情報</a></li>
                            <li className="list-item mb-2"><a href="#" onClick={() => handleNavigate("inquiry")}>お問い合わせ</a></li>
                            <li className="list-item mb-2"><a href="#" onClick={() => handleNavigate("tokusyou")}>特定商取引法に基づく表記</a></li>
                        </ul>
                    </Col>
                </Row>
                <Row className="g-0">
                    <Container style={{ 'textAlign': 'right', "color": "white", "fontSize": "12px"}}>
                        2022 GLOBAL SECURIY EXPERTS Inc. All Rights Reserved
                    </Container>
                </Row>
            </Container>
        </div>
    )
}
