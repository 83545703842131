import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Alert, Modal
} from 'react-bootstrap';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from 'react-hook-form';
import AlertModal from '../AlertModal';
import { ReactComponent as Edit } from '../../../assets/svg_icons/edit_icon.svg';
import { ReactComponent as Upload } from '../../../assets/svg_icons/upload_icon.svg';
import axios from 'axios';
import SecCheckReportModal from './SecCheckReportModal';

const placeholderGenerator = quantity => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
        items.push({ id: i, company: " ", name: " ", type: " ", status: " ", pic: " ", memo: " ", created_at: " "})
    }
    return items;
}

export default function SecCheckList(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [uploadModal, setUploadModal] = useState({
        show: false,
        id: "",
        company: "",
        name: "",
        nameKana: "",
        email: ""
    })

    const [modal, setModal] = useState({
        open: false,
        company: "",
        name: "",
        nameKana: "",
        phone: "",
        address: "",
        email: ""
    })

    const [file, setFile] = useState(null)

    const [pics, setPICS] = useState([])
    const [secChecks, setSecChecks] = useState([])
    const [filteredSecChecks, setFilteredSecChecks] = useState(() => placeholderGenerator(25))

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({
        defaultValues: {
            terms: ""
        }
    })

    const navigate = useNavigate();

    const handleModalClose = () => setModal({ open: false, message: ""});
    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const handleUploadModalClose = () => {
        setUploadModal({ show: false })
        setFile(null)
    }

    const onFileChange = (file) => {
        setFile(file)
        setValue("file", file.name)
    }
    const memoModalButton = (company, name, nameKana, phone, address, email) => {
        
        return (
            <Button onClick={() => setModal({ ...modal, show: true, company: company, name: name, nameKana: nameKana, phone: phone, address: address, email: email})}>
                会社情報
            </Button>
        )
    }

    const handleReportUpload = (id, date) => {
        var formData = new FormData();
        formData.append("file", file)
        formData.append("upload_date", date)

        axiosPrivate.post(`/seccheck/${id}/upload`, formData)
        .then(function(response) {
            setUploadModal({ uploadModal, show: false})
            if (response.status === 200 && response.data.apiCode === 0) {
                setAlert({ ...alert, show: true, message: "レポートをアップロードしました。"})
            } else {
                setAlert({ ...alert, show: true, message: "レポートのアップロードが失敗しました。"})
            }
        })
        .catch(function(response) {
            setAlert({ ...alert, show: true, message: "レポートのアップロードが失敗しました。"})
        })

    }

    const handleChange = (id, prop) => (event) => {

        var body = {
            [prop]: event.target.value,
        }
        axios.put(`/api/admin/seccheck/${id}`, body)
        .then(function(response) {
            //console.log(response)
        })
        .catch(function(response) {
           // console.log(response)
        })
    }


    const picMenu = (e, row, rowIndex) => {


        var defaultVal = row.pic !== null ? row.pic.id : "未選択";

        //onChange={handleChange(row.id, "pic")}
        return (
            <Form.Select onChange={handleChange(row.id, "pic")} defaultValue={defaultVal}>
                <option value={null}>未選択</option>
                {
                    pics.map(pic => {
                        return <option value={pic.pic_id}>{`${pic.last_name} ${pic.first_name}`}</option>
                    })
                }
            </Form.Select>
        )
    }

    const statusMenu = (e, row, rowIndex) => {

        //onChange={handleChange(row.id, "status")}

        return (
            <Form.Select onChange={handleChange(row.id, "status")}  defaultValue={row.status}>
                <option value="未確認">未確認</option>
                <option value="確定">確定</option>
            </Form.Select>
        )
    }

    
    const uploadButton = (e, row, rowIndex) => {

        
        var secCheck = secChecks.find(x => x.id === row.id);

        if (secCheck === undefined) {
            return;
        }
        return (
            
            <div onClick={() => setUploadModal({ ...uploadModal, id: secCheck.id, show: true, company: row.company, name: row.name, nameKana: row.nameKana, email: row.email})} style={{ "cursor": "pointer"}}>
                <Upload style={{ "height": "25px"}} />
            </div>               
            
        )
        
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true
        },
        {
            dataField: "company",
            text: "会社名",
            sort: true,
            headerStyle: () => {
                return { width: "20%" }
            }
        },
        {
            dataField: "companyInfo",
            text: "会社情報",
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "domain",
            text: "ドメイン",
            headerStyle: () => {
                return { width: "15%"}
            }
        },
        {
            dataField: "status",
            text: "対応状況",
            formatter: statusMenu,
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "pic",
            text: "対応者",
            sort: true,
            formatter: picMenu,
            headerStyle: () => {
                return { width: "12%"}
            }
        },
        {
            dataField: "upload",
            text: "レポートアップロード",
            formatter: uploadButton,
            headerStyle: () => {
                return { width: "18%" }
            }
        },
        {
            dataField: "created_at",
            text: "日付",
            sort: true,
            headerStyle: () => {
                return { width: "15%" }
            }
        }
    ]

    const handleReset = () => {
        setValue("status", "未選択")
       // setValue("pic", "未選択")
        setValue("terms", "")
        setValue("startDate", "")
        setValue("endDate", "")
        setFilteredSecChecks(rowGenerator(secChecks));
    }

    const onSubmit = (data) => {
        var values = getValues();

        var filtered = JSON.parse(JSON.stringify(secChecks));

        if (values.status !== "未選択") {

            filtered = filtered.filter(x => x.status === values.status)

        }

        /*

        if (values.pic !== "未選択") {
            console.log(filtered)
            filtered = filtered.filter(x => x.pic !== null && x.pic.last_name.toLowerCase() + " " + x.pic.first_name.toLowerCase() === values.pic.toLowerCase())
        }
        */
   

        if (values.terms !== "") {
            var terms = values.terms.toLowerCase();
            filtered = filtered.filter(x => x.status.toLowerCase().includes(terms) || x.company.toLowerCase().includes(terms) || x.domain.toLowerCase().includes(terms))
        }
      

        if (values.startDate !== "") {
            var start = new Date(values.startDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) >= start)
            
        }


        if (values.endDate !== "") {
            var end = new Date(values.endDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) <= end)
        }

        //console.log(filtered);
        setFilteredSecChecks(rowGenerator(filtered));
        /*
        if (filtered.length === 0 || filtered.length === incidents.length) {
            setAlert({ ...alert, show: true, message: "キーワード検索に合致するインシデントが見つかりませんでした。"})
        } else {
            setFilteredIncidents(filtered);
        }
        */
    }

    const rowEvents = {
        /*
        onClick: (e, row, rowIndex) => {
            
            var incident = incidents.find(x => x.id === row.id);
            navigate("details", {
                state: {
                    incident: incident
                },
                reaplce: true                
            })
        }
        */
    }

    const rowGenerator = (data) => {
        const items = [];
        data.forEach(secCheck => {
            //var pic = inquiry.pic;
            items.push({ id: secCheck.id, company: secCheck.company, name: secCheck.name, nameKana: secCheck.nameKana, email: secCheck.email, companyInfo: memoModalButton(secCheck.company, secCheck.name, secCheck.nameKana, secCheck.phone, secCheck.address, secCheck.email),  phone: secCheck.phone, address: secCheck.address, domain: secCheck.domain, status: secCheck.status, created_at: secCheck.created_at, pic: secCheck.pic})
        })
        return items;
    }

    useEffect(() => {
        let isSubscribed = true;

        axiosPrivate.get("/api/admin/seccheck")
        .then(function(response1) {
            //console.log(response1)
            if (response1.status === 200) {
                if (response1.data.apiCode === 0) {
                    axiosPrivate.get("/api/pic")
                    .then(function(response) {
                        if (response.status === 200) {
                            if (isSubscribed) {
                               // console.log(response)
                                setPICS(response.data)
                               // console.log(response1.data.data)
                                var secChecks = rowGenerator(response1.data.data)
                               // console.log(secChecks)
                                setSecChecks(response1.data.data)
                                setFilteredSecChecks(secChecks)
                            } 
                        }
                    })
                    .catch(function(response) {
                        //console.log(response)
                    })
                    
                }
            }
        })
        .catch(function(response1) {
           // console.log(response1)
        })

    
        return () => isSubscribed = false;
    }, [])

    return (
        <Row className="g-0">
            <SecCheckReportModal 
                show={uploadModal.show} 
                file={file} 
                onFileChange={onFileChange} 
                handleModalClose={handleUploadModalClose} 
                company={uploadModal.company} 
                name={uploadModal.name} 
                nameKana={uploadModal.nameKana} 
                email={uploadModal.email}
                setUploadModal={setUploadModal}
                setAlert={setAlert}
                id={uploadModal.id}
            />
            <Modal show={modal.show} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>会社情報</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                会社名：
                            </Col>
                            <Col>
                                {modal.company}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                法人代表者名：
                            </Col>
                            <Col>
                                {modal.name}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                法人代表者名（カナ）：
                            </Col>
                            <Col>
                                {modal.nameKana}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                メールアドレス：
                            </Col>
                            <Col>
                                {modal.email}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                電話番号：
                            </Col>
                            <Col>
                                {modal.phone}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                住所：
                            </Col>
                            <Col>
                            {modal.address}
                            </Col>
                        </Row>
                    </p>
                </Modal.Body>
            </Modal>
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center" }}>セキュリティ健康診利用申請受付</h2>
                    <hr style={{ "width": "100%" }} />
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row classname="g-0">
                                <Col xs={6}>
                                    <Form.Group className="my-4" style={{ "width": "50%"}}>
                                        <Form.Label>絞り込みたい文字列を入力</Form.Label>
                                        <Form.Control type="text" {...register("terms")}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応状況
                                        </Form.Label>
                                        <Form.Select { ...register("status")}>
                                            <option hidden>未選択</option>
                                            <option>未確認</option>
                                            <option>確定</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>                                    
                                <Col xs={3}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応者
                                        </Form.Label>
                                        <Form.Select { ...register("pic")}>
                                            <option hidden>未選択</option>
                                            {
                                                pics.map(pic => {
                                                    return <option>{`${pic.last_name} ${pic.first_name}`}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>                                
                            </Row>
                            <Row className="g-0">
                                <Col xs={6}>
                                    
                                    <div className="my-4">
                                        <Form.Group style={{ "width": "fit-content", "float": "left"}}>
                                            <Form.Label>期間で絞り込み</Form.Label>
                                            <Form.Control type="date" {...register("startDate")} />
                                        </Form.Group>
                                        <div style={{ "float": "left", "marginTop": "32px"}}>
                                            <div className="mx-2" style={{ "float": "left", "lineHeight": "38px"}}>〜</div>
                                            <Form.Group style={{ "width": "fit-content","float": "left"}}>
                                                <Form.Control type="date" {...register("endDate")} />
                                            </Form.Group>
                                        </div>
                                    </div> 
                                                                            
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col xs={2}>
                                    <Button className="my-4" type="submit">検索する</Button>
                                </Col>
                                <Col xs={2}>
                                    <Button className="my-4" onClick={handleReset} variant="outline-primary">リセット</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                    <div style={{ "minHeight": "600px" }}>
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="id"
                            data={filteredSecChecks}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 25 })}
                            rowStyle={{ overflowWrap: "break-word" }}
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    )
}