import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "../../../assets/vciso_logo.png";
import topImg from "../../../assets/top_img.png";
import topText from "../../../assets/top_text.png";
import topTextSp from "../../../assets/top_text_sp.png";
import { useNavigate } from "react-router";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Modal from "react-bootstrap/Modal";
import origin_sm from "../../../assets/images/origin_sm.png";
import origin_md from "../../../assets/images/origin_md.png";
import origin_sp from "../../../assets/images/origin_sp.png";
import youtube_icon from "../../../assets/images/youtube_icon.png";

export default function HomeCarousel(props) {
    const navigate = useNavigate();
    const { user, message } = props;
    const [isShowModal, setIsShowModal] = useState(false);

    const [isBackGroundShow, setIsBackGroundShow] = useState(true);

    const closeModal = () => {
        setIsShowModal(false);
    };

    const showModal = () => {
        setIsShowModal(true);
    };

    const delay = 5000;

    const tempRow = () => {
        return (
            <Row className="wrapper g-0 slides active home-screen" key="home">
                <Col
                    md={7}
                    xs={12}
                    className="home-mv-1"
                    style={{ padding: "1rem .75rem" }}
                    key="home-mv-1"
                >
                    <img src={logo} className="vciso-logo" />
                    <Row>
                        <Col xs={12}>
                            <img
                                src={topText}
                                alt="セキュリティの重要性は認識している。しかし専属のセキュリティ担当者は配置できない。"
                                style={{ marginBottom: "35px" }}
                                className="pc"
                            />
                            <img
                                src={topTextSp}
                                alt="セキュリティの重要性は認識している。しかし専属のセキュリティ担当者は配置できない。"
                                style={{ marginBottom: "35px" }}
                                className="sp"
                            />
                            <div
                                className="top-text"
                                style={{ marginBottom: "50px" }}
                            >
                                <p>
                                    という企業様に向けた、安心できるちょうど良いサイバーセキュリティ相談サービスです。
                                    <br></br>
                                    必要な事案にだけセキュリティ専門家のアドバイス・支援を提供します。
                                </p>
                            </div>

                            <Row className="g-1 buttons-list">
                                <Col md={6} xs={6}>
                                    <AnchorLink
                                        href="#web-service"
                                        offset={() => 100}
                                    >
                                        <Button
                                            variant="success"
                                            className="rounded-pill btn-blue-2"
                                            size="lg"
                                        >
                                            サービス概要
                                        </Button>
                                    </AnchorLink>
                                </Col>
                                <Col md={6} xs={6}>
                                    <Button
                                        variant="success"
                                        className="rounded-pill btn-yellow"
                                        size="lg"
                                        onClick={() => navigate("/signup")}
                                    >
                                        新規申込
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col
                    md={5}
                    className="home-mv-2"
                    style={{ height: "100%", marginTop: "60px" }}
                    key="home-mv-2"
                >
                    <div style={{ textAlign: "center" }}>
                        <img src={topImg} />
                    </div>
                </Col>
            </Row>
        );
    };

    const tempIframe = () => {
        return (
            <Row className="wrapper g-0 slides" key="home2">
                <Col className="youtube-png-area" onClick={() => showModal()}>
                    <div className="youtube-png">
                        <img src={currentYoutubeImage} className=""></img>
                    </div>
                    <div className="yotube-png-text-area">
                        <h3 className="title-area">
                            vCISO安心相談サービスのご紹介
                        </h3>
                        <div className="youtube-btn-image">
                            <img src={youtube_icon}></img>
                        </div>
                        <div className="contents-area">
                            <p className="contents">
                                vCISO安心相談サービスでご提供するサービス内容について
                            </p>
                            <p className="contents">動画でご紹介致します。</p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    const slideRef = useRef();
    const indicatorRef = useRef();

    const [currentSlide, setCurrentSlide] = useState(0);
    const [autoCarouselFunc, setAutoCarouselFunc] = useState(false);

    const slides = [tempRow, tempIframe];

    const hasCarousel = () => {
        if (slideRef.current || indicatorRef.current) {
            return true;
        }
        return false;
    };

    const carouselInitHandler = () => {
        if (!hasCarousel()) return;
        const carousels = slideRef.current.children;
        const indicator = indicatorRef.current.children;
        for (let i = 0; i < slides.length; i++) {
            carousels[i].classList.remove("active");
            carousels[i].classList.remove("right-to-left-anim");
            carousels[i].classList.remove("left-to-right-anim");
            indicator[i].classList.remove("active");
        }
    };

    const showSlider = () => {
        return (
            <>
                {slides.map((el) => {
                    return el();
                })}
            </>
        );
    };

    const backGroundShowingHandler = (slideIndex) => {
        if (slideIndex === currentSlide) return;
        if (isBackGroundShow && currentSlide === 0) {
            setIsBackGroundShow(false);
            return;
        }
        setIsBackGroundShow(true);
    };

    const indicaterHandler = (slideIndex) => {
        if (!hasCarousel()) return;
        const carouselRef = slideRef.current.children;
        const indicator = indicatorRef.current.children;
        backGroundShowingHandler(slideIndex);

        if (slideIndex < currentSlide) {
            carouselInitHandler();
            carouselRef[slideIndex].classList.add("active");
            carouselRef[slideIndex].classList.add("left-to-right-anim");
            indicator[slideIndex].classList.add("active");
            setCurrentSlide(slideIndex);
        }

        if (slideIndex > currentSlide) {
            carouselInitHandler();
            carouselRef[slideIndex].classList.add("active");
            carouselRef[slideIndex].classList.add("right-to-left-anim");
            indicator[slideIndex].classList.add("active");
            setCurrentSlide(slideIndex);
        }
    };

    const tempNextButton = () => {
        if (!hasCarousel()) return;
        carouselInitHandler();
        backGroundShowingHandler();
        const carouselRef = slideRef.current.children;
        const indicator = indicatorRef.current.children;
        if (currentSlide === carouselRef.length - 1) {
            carouselRef[0].classList.add("active");
            carouselRef[0].classList.add("right-to-left-anim");
            indicator[0].classList.add("active");
            setCurrentSlide(0);
            return;
        }
        carouselRef[currentSlide + 1].classList.add("active");
        carouselRef[currentSlide + 1].classList.add("right-to-left-anim");
        indicator[currentSlide + 1].classList.add("active");
        setCurrentSlide(currentSlide + 1);
    };
    const videoModal = () => {
        return (
            <Modal
                show={isShowModal}
                onHide={setIsShowModal}
                size="lg"
                centered
                className="p-5"
            >
                <Modal.Body className="youtube-modal">
                    <div className="iframe-wrapper">
                        <iframe
                            className="iframe-area"
                            src="https://www.youtube.com/embed/vH58C1z8ew8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div
                        className="btn-close"
                        onClick={() => closeModal()}
                    ></div>
                </Modal.Body>
            </Modal>
        );
    };

    const autoChangeCarousel = () => {
        if (autoCarouselFunc) {
            clearTimeout(autoCarouselFunc);
        }
        const setFunc = setTimeout(tempNextButton, delay);
        setAutoCarouselFunc(setFunc);
    };

    useEffect(() => {
        autoChangeCarousel();
    }, [currentSlide]);

    const [currentYoutubeImage, setCurrentYoutubeImage] = useState(origin_md);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 391) {
                setCurrentYoutubeImage(origin_sp);
                return;
            }
            if (window.innerWidth < 501) {
                setCurrentYoutubeImage(origin_sm);
                return;
            }

            setCurrentYoutubeImage(origin_md);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const makeClassName = (user, isBackGroundShow) => {
        const userClassName = user ? "logged-in" : "";
        const backgroundClassName = isBackGroundShow ? "" : "hide-background";

        return `${userClassName} ${backgroundClassName}`;
    };

    const loginHomePage = (logo, message) => {
        return (
            <div className="home-user-top">
                <Row className="g-0">
                    <Col lg={2} />
                    <Col
                        lg={9}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <div>
                            <img
                                src={logo}
                                id="vciso-logo"
                                style={{ marginBottom: "35px" }}
                            />
                        </div>

                        <div className="section-title-block">
                            <p className="section-title">
                                事務局からのお知らせ
                            </p>
                        </div>

                        <Row className="g-0">
                            <Col xs={12}>
                                <div
                                    className="pe-4"
                                    style={{ whiteSpace: "pre" }}
                                >
                                    {message}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={1} />
                </Row>
            </div>
        );
    };

    return (
        <>
            <div id="bg-test" className={makeClassName(user, isBackGroundShow)}>
                {!user ? (
                    <div className="home-top">
                        <div className="slides-area" ref={slideRef}>
                            {showSlider()}
                        </div>
                        <ul className="indicators" ref={indicatorRef}>
                            {slides.map((el, index) => {
                                return (
                                    <li
                                        className={index === 0 ? "active" : ""}
                                        onClick={() => {
                                            indicaterHandler(index);
                                        }}
                                    ></li>
                                );
                            })}
                        </ul>
                        {videoModal()}
                    </div>
                ) : (
                    loginHomePage(logo, message)
                )}
            </div>
        </>
    );
}
