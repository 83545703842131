import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Tabs,
    Tab,
    Spinner,
    Placeholder,
    Form,
} from "react-bootstrap";
import { axiosPrivate } from "../../utils/axiosPrivate";

import ChangePassword from "../elements/userinfo/ChangePassword";
import EditUser from "../elements/userinfo/EditUser";
import PaymentInfo from "../elements/userinfo/PaymentInfo";
import SetMFA from "../elements/userinfo/SetMFA";
import SubAccounts from "../elements/userinfo/SubAccounts";
import Chart from "../elements/userinfo/Chart";
import UserPlan from "../elements/userinfo/UserPlan";
import EditSubAccount from "../elements/userinfo/EditSubAccount";
import SecurityAction from "../elements/userinfo/SecurityAction";
// import { ReactComponent as Calculator } from "../../assets/svg_icons/EditUser.svg";
import { ReactComponent as iconEditUser } from "../../assets/svg_icons/EditUser.svg";
import { ReactComponent as iconPassword } from "../../assets/svg_icons/Password.svg";
import { ReactComponent as iconMfa } from "../../assets/svg_icons/Mfa.svg";
import { ReactComponent as iconFeePlan } from "../../assets/svg_icons/FeePlan.svg";
import { ReactComponent as iconFeeDetails } from "../../assets/svg_icons/FeeDetails.svg";
import { ReactComponent as iconSubAccount } from "../../assets/svg_icons/SubAccount.svg";
import { ReactComponent as iconChart } from "../../assets/svg_icons/Chart.svg";
import { ReactComponent as iconSecurity } from "../../assets/svg_icons/Security.svg";

import ImageButton from "../elements/button/ImageButton";

export default function MyPage(props) {
    const [block, setBlock] = useState(true);
    const [roles, setRoles] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [contractPeriod, setContractPeriod] = useState("");
    const [corpName, setCorpName] = useState("");

    useEffect(() => {
        axiosPrivate
            .get("/api/user/1")
            .then(function (response) {
                if (response.data.apiCode === 0) {
                    setContractPeriod(
                        response.data.data.contractPeriod
                            .split("：")[1]
                            .slice(0, -1)
                    );
                }
            })
            .catch(function (response) {
                //console.log(response)
            });

        let isSubscribed = true;
        axiosPrivate("/api/user/0")
            .then(function (response) {
                if (response.status === 200 && response.data.apiCode === 0) {
                    var data = response.data.data;
                    if (isSubscribed) {
                        setRoles(data.roles);
                        setCorpName(data.corpName);
                        setLoaded(true);
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
        return () => (isSubscribed = false);
    }, []);

    const status =
        roles.includes("SUBSCRIBER") && roles.includes("ADMINISTRATOR");

    return (
        <div className="g-0 row Mypage Common">
            <Row className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　マイページ</h1>
                </div>
                <div className="col-lg-1"></div>
            </Row>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <br />
                    <br />

                    <section>
                        <div style={{ alignSelf: "center" }}>
                            {roles.includes("SUBSCRIBER") ||
                            roles.includes("ADMINISTRATOR") ? (
                                loaded ? (
                                    <div
                                        style={{
                                            "text-align": "center",
                                            fontWeight: "bold",
                                            fontSize: "28px",
                                        }}
                                    >
                                        <Form.Label>
                                            ご契約期間：{contractPeriod}
                                        </Form.Label>
                                    </div>
                                ) : (
                                    <Placeholder as="div" animation="glow">
                                        <Placeholder
                                            xs={4}
                                            className="me-2"
                                            size="lg"
                                        />
                                    </Placeholder>
                                )
                            ) : null}
                        </div>
                        <br />

                        <div>
                            {loaded ? (
                                roles.includes("SUBSCRIBER") &&
                                roles.includes("ADMINISTRATOR") ? (
                                    <div
                                        id="tab-container"
                                        style={{
                                            width: "100%",
                                            background: "read",
                                        }}
                                    >
                                        {mypageButtons()}
                                    </div>
                                ) : (
                                    <div id="tab-container">
                                        {mypageButtons(status)}
                                    </div>
                                )
                            ) : (
                                <div
                                    className="fill-height-xs fill-height-md fill-height-xl"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "700px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Placeholder as="div" animation="glow">
                                        <Placeholder xs={2} className="me-2" />
                                        <Placeholder xs={2} className="me-2" />
                                        <Placeholder xs={2} className="me-2" />
                                        <Placeholder xs={2} className="me-2" />
                                        <Placeholder xs={2} />
                                    </Placeholder>
                                    <Placeholder
                                        as="hr"
                                        animation="glow"
                                        style={{
                                            width: "90%",
                                            alignSelf: "center",
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: "90%",
                                            display: "flex",
                                            flexDirection: "column",
                                            textAlign: "left",
                                            alignSelf: "center",
                                        }}
                                    >
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={4}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={6}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={5}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={4}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={5}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={6}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={5}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder as="div" animation="glow">
                                            <Placeholder
                                                xs={3}
                                                className="me-2"
                                                size="lg"
                                            />
                                            <Placeholder
                                                xs={4}
                                                className="me-2"
                                                size="lg"
                                            />
                                        </Placeholder>
                                        <br />
                                        <Placeholder
                                            as="div"
                                            animation="glow"
                                            style={{ textAlign: "center" }}
                                        >
                                            <Placeholder xs={3} size="lg" />
                                        </Placeholder>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                    <br />
                    <br />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>
    );
}

function mypageButtons(status) {
    if (status) {
        const items = [
            {
                icon: iconEditUser,
                menu_name: "会員情報編集",
                link: "/mypage/useredit",
            },
            {
                icon: iconPassword,
                menu_name: "パスワード変更",
                link: "/mypage/changepassword",
            },
            {
                icon: iconMfa,
                menu_name: "多要素認証",
                link: "/mypage/setMFA",
            },
        ];
        return (
            <ul className="mypage-buttons-area">
                {items.map((item) => {
                    return (
                        <li className="btn mypage-button-container">
                            <ImageButton data={item} />
                        </li>
                    );
                })}
            </ul>
        );
    } else {
        const items = [
            {
                icon: iconEditUser,
                menu_name: "会員情報編集",
                link: "/mypage/useredit",
            },
            {
                icon: iconPassword,
                menu_name: "パスワード変更",
                link: "/mypage/changepassword",
            },
            {
                icon: iconMfa,
                menu_name: "多要素認証",
                link: "/mypage/setMFA",
            },
            {
                icon: iconFeePlan,
                menu_name: "料金プラン",
                link: "/mypage/userplan",
            },
            {
                icon: iconFeeDetails,
                menu_name: "料金明細",
                link: "/mypage/paymentInfo",
            },
            {
                icon: iconSubAccount,
                menu_name: "サブアカウント",
                link: "/mypage/subAccounts",
            },
            {
                icon: iconChart,
                menu_name: "カルテ",
                link: "/mypage/chart",
            },
            {
                icon: iconSecurity,
                menu_name: "SECURITY ACTION",
                link: "/mypage/securityAction",
            },
        ];
        return (
            <ul className="mypage-buttons-area">
                {items.map((item) => {
                    return (
                        <li className="btn mypage-button-container">
                            <ImageButton data={item} />
                        </li>
                    );
                })}
            </ul>
        );
    }
}
