import React, { useEffect, useState, useMemo } from 'react';
import Article from '../elements/home/Article';
import {
    Row, Col, Placeholder, Form, Button, InputGroup, Container
} from 'react-bootstrap';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertModal from '../elements/AlertModal';
import getImageFromRSS from '../../utils/getImageFromRSS';
import getRSSFeed from '../../utils/getRSSFeed';
import logo from '../../assets/logo.png';

const companyNames = new Map();
companyNames.set("netsecurity", "ScanNetSecurity");
companyNames.set("itmedia", "ITmedia NEWS");
companyNames.set("mynavi", "マイナビニュース");
companyNames.set("jpcert", "JPCERT")
companyNames.set("ipa", "IPA")

function PlaceholderArticle() {
    return (
        <div style={{ "height": "340px", "width": "100%"}}>
            <Placeholder as="div" animation="glow" >
                <Placeholder xs={12} style={{ "height": "150px", "width": "250px"}}/>
            </Placeholder>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={5} size="sm"/>
            </Placeholder>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={7} size="sm" className="me-1"/>
                <Placeholder xs={3} size="sm" className="me-1"/>
                <Placeholder xs={6} size="sm" className="me-1"/>
                <Placeholder xs={5} size="sm" className="me-1"/>
                <Placeholder xs={4} size="sm" className="me-1"/>
            </Placeholder>
        </div>
    )
}

function filterArray(array) {
    const uniqueValuesSet = new Set();

    // array of objects with duplicate values

    const filteredArr = array.filter((obj) => {
        // check if name property value is already in the set
        const isPresentInSet = uniqueValuesSet.has(obj.link);

        // add name property value to Set
        uniqueValuesSet.add(obj.link);

        // return the negated value of
        // isPresentInSet variable
        return !isPresentInSet;
    });

    return filteredArr;
}

export default function SecArticles(props) {
    const [articleList, setArticleList] = useState([]);
    const [genArticles, setGenArticles] = useState([]);
    const [results, setResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searched, setSearched] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [toggle, setToggle] = useState(false)
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    //const { articles } = props;

    const placeholders = Array(12).fill(<PlaceholderArticle />);

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const handleSubmit = (event) => {
        event.preventDefault();
        var filteredArr = filterArray(genArticles)
        var searchResults = filteredArr.filter(x => x.title.toLowerCase().includes(search.toLowerCase()))
        if (searchResults.length > 0) {
            //console.log(searchResults);
            var sortedArticles = searchResults.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            });
            var temparticles = [];
            sortedArticles.forEach(article => {
                let newArticle = <Article image={article.image} title={article.title} date={article.date.split("T")[0]} link={article.link} companyName={article.companyName}/>
                temparticles.push(newArticle)
            })
            //console.log(temparticles)
            setResults(temparticles)
            setSearched(true)
        } else {
            setAlert({ ...alert, show: true, message: "キーワード検索に合致する記事がありませんでした"})
        }
    }

    const handleChange = (event) => {
        var terms = event.target.value;
        setSearch(terms)
    }

    const handleReset = (event) => {
        event.preventDefault();
        var filteredArr = filterArray(genArticles);
        var sortedArticles = filteredArr.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
        });
        var temparticles = []
        sortedArticles.forEach(article => {
            let newArticle = <Article image={article.image} title={article.title} date={article.date.split("T")[0]} link={article.link} companyName={article.companyName}/>
            temparticles.push(newArticle)
        })
        setArticleList(temparticles)
        setResults(temparticles)
        setSearch("")
        setSearched(false)
    }

    useMemo(() => {

        //console.log("gen articles")
        let feed = getRSSFeed("https://articles.vciso.jp/feed");

        var articleList = []
        feed.then(feed => {

            //console.log(feed.items.length)
            ////console.log(genArticleList)
             //console.log(feed)
            feed.items.forEach((item, index) => {
                //console.log(item)
                var imgRegex = /([^\s]+)/
                var imgSrc = item.description.substring(item.description.indexOf("src")).match(imgRegex)[1]
                var img = imgSrc.substring(imgSrc.indexOf("=") + 2, imgSrc.length - 1)
                //console.log(img)
                
                var articleObj = {
                    key: 20000 + index,
                    image: img,
                    title: item.title,
                    date: item.isoDate.split("T")[0],
                    link: item.link
                }
                ////console.log(articleObj)
                //let article = <Article image={img} title={item.title} date={date} link={item.link} />
                if (!item.categories.includes("interviews")) {
                    //articleList.push(articleObj)
                    setGenArticles(existing => [ ...existing, articleObj])
                }

            })

            ////console.log(genArticleList)    

            //setGenArticles(articles => [...articles, genArticleList.flat()])
        })


        var webRegex = /(\.)(.*?)\1/;
        const testFeed = async (articleObj) => {
            var result = await getImageFromRSS(articleObj.companyName, articleObj.link);
            if (result) {
                articleObj.image = result
            } else {
                articleObj.image = logo
            }
            setGenArticles(existing => [...existing, articleObj])
        }

        let secondFeed = getRSSFeed("https://articles.vciso.jp/wprss");
        secondFeed.then(feed => {
            ////console.log(feed)
            var genArticleList = JSON.parse(JSON.stringify(genArticles))
            feed.items.forEach((item, index) => {
                var companyName = item.link.match(webRegex);
                //var itemImage = getImageFromRSS(companyNames.get(companyName[2]), item.link)
                ////console.log(itemImage)
                var articleObj = {
                    key: 10000 + index,
                    title: item.title,
                    image: '',
                    date: item.pubDate,
                    link: item.link,
                    companyName: companyNames.get(companyName[2])
                }

                if (!articleList.some(x => x.link === articleObj.link)) {
                    testFeed(articleObj).catch(console.error);
                }
            })
        })

        

    }, [])

    const SECOND_MS = 1000;
    useEffect(() => {
        //console.log("checking")
        //console.log(genArticles.length)
        let isSubscribed = true;
       // const interval = setInterval(() => {
        if (genArticles.length > 0) {
            //console.log("greater than 1")
            if (isSubscribed) {
                var filteredArr = filterArray(genArticles);
                var sortedArticles = filteredArr.sort(function(a,b){
                    return new Date(b.date) - new Date(a.date);
                });
                var temparticles = []
                sortedArticles.forEach(article => {
                    let newArticle = <Article image={article.image} title={article.title} date={article.date.split("T")[0]} link={article.link} companyName={article.companyName}/>
                    temparticles.push(newArticle)
                })
                setArticleList(temparticles)
                setResults(temparticles)
                //clearInterval(interval)
                setLoaded(true)
            }
        }
       // }, SECOND_MS)
        return () => { 
            isSubscribed = false;
           // clearInterval(interval) 
        };
    }, [genArticles])

    return (
        <Row className="g-0 py-3">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col lg={8} md={12} sm={12} xs={12}>
            <Row >
                <Col xs={12}>
                <Form className="ms-2" onSubmit={handleSubmit}>
                    <Row className="g-0">
                        <Col xs={4}>
                        <div style={{ "height": "100%", "display": "flex"}}>
                            <h6 style={{ "color": "#385093", "fontSize": "1rem", "fontWeight": "bold", "alignSelf": "end"}}>
                            サイバーセキュリティNews一覧
                            </h6>
                        </div>
                        </Col>
                        <Col xs={3}>
                        <Form.Group>
                            <Form.Control type="text" placeholder="キーワード検索" onChange={handleChange} value={search} />
                        </Form.Group>
                        </Col>
                        <Col>
                            <div style={{ "display": "flex", "flexDirection": "row"}}>
                                <Button className="mx-3" type="submit">検索</Button>
                                {
                                    results.length !== genArticles.length && searched ?
                                    <div style={{ "lineHeight": "38px", "cursor": "pointer"}}>
                                        <FontAwesomeIcon onClick={handleReset} icon={faCircleXmark} />
                                    </div>
                                    :
                                    null
                                }
                                
                            </div>
                            
                           
                            
                        </Col>              
                   
                    </Row>
                </Form>
                </Col>
                
            </Row>
            <hr />
            
            <Row className="g-0">

                <Container className="article-list">
                    <Row className="g-4 articles" style={{ "marginBottom": "60px" }}>
                        { loaded ?
                          results.map((article, index) => (
                            <Col className="article-item" xs={6} md={4} lg={3} xl={3} key={`${article.title}_${index}`}>
                                {article}
                            </Col>
                          )) :
                          placeholders.map((article, index) => (
                            <Col xs={6} md={4} lg={3} xl={3} key={`${article.title}_${index}`}>
                                {article}
                            </Col>
                          ))
                        }
                    </Row>
                </Container>

            </Row>
            </Col>
            
            <Col lg={2} md={11} sm={11} xs={11}
                className="ms-lg-3 mx-lg-0 mx-3"
                style={{ "border": "2px solid #cdcdcd","borderTop": "2px solid #FFA500"}}>
                <div className='top-5' style={{ "marginLeft": "12px", "marginRight": "12px"}}>
                    <div style={{ "color": "#385093", "fontSize": "1rem", "fontWeight": "bold"}} 
                        className="mt-3">よく読まれている記事</div>
                    <hr style={{ "marginTop": "11px"}}/>
                    <Container className="article-list">
                        <Row className="articles">
                            {
                                articleList.slice(10,15).map((article, index) => (
                                <Col lg={12} md={4} xs={6} key={`ranking_${index}`}>
                                    {article}
                                </Col>
                                ))
                            }
                        </Row>
                    </Container>

                </div>
            </Col>
            <Col />
        </Row>
    )
}
