import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Tabs,
    Tab,
    Spinner,
    Placeholder,
} from "react-bootstrap";
import { axiosPrivate } from "../../utils/axiosPrivate";

import ChangePassword from "../elements/userinfo/ChangePassword";
import EditUser from "../elements/userinfo/EditUser";
import PaymentInfo from "../elements/userinfo/PaymentInfo";
import SetMFA from "../elements/userinfo/SetMFA";
import SubAccounts from "../elements/userinfo/SubAccounts";
import Chart from "../elements/userinfo/Chart";
import UserPlan from "../elements/userinfo/UserPlan";
import EditSubAccount from "../elements/userinfo/EditSubAccount";

export default function UserInfo(props) {
    const [block, setBlock] = useState(true);
    const [roles, setRoles] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [contractPeriod, setContractPeriod] = useState("");
    const [corpName, setCorpName] = useState("");

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate("/api/user/0")
            .then(function (response) {
                // //console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    var data = response.data.data;
                    if (isSubscribed) {
                        setRoles(data.roles);
                        setCorpName(data.corpName);
                        setLoaded(true);
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
        return () => (isSubscribed = false);
    }, []);

    return (
        <Row className="g-0">
            <Col />
            <Col
                sm={12}
                md={10}
                lg={10}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    className="p-4"
                >
                    <h3 style={{ alignSelf: "center" }}>会員情報</h3>
                    <div style={{ alignSelf: "center" }}>
                        {roles.includes("SUBSCRIBER") ||
                        roles.includes("ADMINISTRATOR") ? (
                            loaded ? (
                                contractPeriod
                            ) : (
                                <Placeholder as="div" animation="glow">
                                    <Placeholder
                                        xs={4}
                                        className="me-2"
                                        size="lg"
                                    />
                                </Placeholder>
                            )
                        ) : null}
                    </div>
                    <br />

                    <div className="UserInfo">
                        {loaded ? (
                            roles.includes("SUBSCRIBER") &&
                            roles.includes("ADMINISTRATOR") ? (
                                <div
                                    id="tab-container"
                                    style={{
                                        width: "100%",
                                        background: "read",
                                    }}
                                >
                                    <Tabs
                                        fill
                                        justify
                                        className="my-4 userinfo-tab"
                                        style={{
                                            flexWrap: "nowrap",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "stretch",
                                        }}
                                    >
                                        <Tab
                                            eventKey="edit"
                                            title="会員情報編集"
                                            className=""
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <EditSubAccount />
                                        </Tab>
                                        <Tab
                                            eventKey="pass"
                                            title="パスワード変更"
                                            className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl"
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <ChangePassword />
                                        </Tab>
                                        <Tab
                                            eventKey="mfa"
                                            title="多要素認証"
                                            className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl"
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <SetMFA />
                                        </Tab>
                                    </Tabs>
                                </div>
                            ) : (
                                <div id="tab-container">
                                    <Tabs
                                        fill
                                        justify
                                        className="my-4 userinfo-tab"
                                        style={{
                                            flexWrap: "nowrap",
                                            width: "100%",
                                            alignItems: "stretch",
                                        }}
                                    >
                                        <Tab
                                            eventKey="edit"
                                            title="会員情報編集"
                                            className=""
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <EditUser
                                                key="edit-user"
                                                setContractPeriod={
                                                    setContractPeriod
                                                }
                                            />
                                        </Tab>
                                        <Tab
                                            eventKey="pass"
                                            title="パスワード変更"
                                            className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl"
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <ChangePassword />
                                        </Tab>
                                        <Tab
                                            eventKey="mfa"
                                            title="多要素認証"
                                            className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl"
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <SetMFA />
                                        </Tab>
                                        <Tab
                                            eventKey="plan"
                                            title="料金プラン"
                                            className=""
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <UserPlan />
                                        </Tab>
                                        <Tab
                                            eventKey="receipt"
                                            title="料金明細"
                                            className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl"
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <PaymentInfo />
                                        </Tab>
                                        <Tab
                                            eventKey="subaccount"
                                            title="サブアカウント"
                                            className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl"
                                            style={{
                                                fontSize: "1.1rem",
                                                width: "100%",
                                            }}
                                        >
                                            <SubAccounts corpName={corpName} />
                                        </Tab>
                                        <Tab
                                            eventKey="chart"
                                            title="カルテ"
                                            className="Chart fill-height-incident-sm"
                                            style={{
                                                fontSize: "1.1rem",
                                            }}
                                        >
                                            <Chart />
                                        </Tab>
                                    </Tabs>
                                </div>
                            )
                        ) : (
                           null
                        )
                        }
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    );
}
