import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function IncidentSent(props) {
    const navigate = useNavigate();
    const handleTop = () => {
        // setSuccess(false)
        navigate("/", { replace: true})
        window.location.reload();
    }
    return (
        <Row className="g-0 ">
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box"></div>

                <section style={{ padding: "0 10px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="inquiry-width customize-width"
                    >
                        <br />
                        <br />
                        <div>インシデントのご相談を受け付け致しました。​</div>
                        <br />
                        <div>
                            ご返信までお時間が掛かる場合がありますので、ご了承だくさい。
                        </div>
                        <br />
                        <div>
                            今後の本インシデントに関するご連絡はお客様メールアドレスへご連絡致します。
                        </div>
                        <br />
                        <br />
                        <Row className="template-customize-area button-flex">
                            <Button
                                className="btn btn-primary"
                                onClick={handleTop}
                            >
                                トップページへ
                            </Button>
                        </Row>
                        <br />
                        <br />
                    </div>
                </section>
            </div>
        </Row>

        // <div className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl">
        //     <div>インシデントのご相談を受け付け致しました。​</div>
        //     <br />
        //     <div>
        //         ご返信までお時間が掛かる場合がありますので、ご了承だくさい
        //     </div>
        //     <br />
        //     <div>
        //         今後の本インシデントに関するご連絡はお客様メールアドレスへご連絡致します。
        //     </div>
        //     <br />
        //     <Button
        //         style={{ width: "fit-content" }}
        //         onClick={() => navigate("/")}
        //     >
        //         トップページへ
        //     </Button>
        // </div>
    );
}
