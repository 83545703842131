import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Alert, Modal
} from 'react-bootstrap';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from 'react-hook-form';
import AlertModal from '../AlertModal';
import { ReactComponent as Edit } from '../../../assets/svg_icons/edit_icon.svg';
import axios from 'axios';

const placeholderGenerator = quantity => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
        items.push({ id: i, company: " ", name: " ", type: " ", status: " ", pic: " ", memo: " ", created_at: " "})
    }
    return items;
}

export default function CounselingList(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [modal, setModal] = useState({
        open: false,
        company: "",
        name: "",
        nameKana: "",
        phone: "",
        address: "",
        email: ""
    })

    const [pics, setPICS] = useState([])
    const [sessions, setSessions] = useState([])
    const [filteredSessions, setFilteredSessions] = useState(() => placeholderGenerator(25))

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({
        defaultValues: {
            terms: ""
        }
    })

    const navigate = useNavigate();

    const handleModalClose = () => setModal({ open: false, message: ""});
    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const memoModalButton = (company, name, nameKana, phone, address, email) => {
        
        return (
            <Button onClick={() => setModal({ ...modal, show: true, company: company, name: name, nameKana: nameKana, phone: phone, address: address, email: email})}>
                会社情報
            </Button>
        )
    }

    const handleChange = (id, prop) => (event) => {
       // console.log(event.target.value)
       // console.log(id)
       // console.log(prop)
        var body = {
            [prop]: event.target.value,
        }
        axios.put(`/api/admin/counseling/${id}`, body)
        .then(function(response) {
           // console.log(response)
        })
        .catch(function(response) {
           // console.log(response)
        })
    }

    const picMenu = (e, row, rowIndex) => {

        var defaultVal = row.pic !== null ? row.pic.id : "未選択";

        return (
            <Form.Select onChange={handleChange(row.id, "pic")} defaultValue={defaultVal}>
                <option value={null}>未選択</option>
                {
                    pics.map(pic => {
                        return <option value={pic.pic_id}>{`${pic.last_name} ${pic.first_name}`}</option>
                    })
                }
            </Form.Select>
        )
    }

    const dateMenu = (e, row, rowIndex) => {

    

        return (
            <Form.Select onChange={handleChange(row.id, "session_date")} defaultValue={row.session_date}>
                <option value={null}>未選択</option>
                <option value={row.first_choice}>{row.first_choice}</option>
                <option value={row.second_choice}>{row.second_choice}</option>
            </Form.Select>
        )
    }

    const statusMenu = (e, row, rowIndex) => {

        return (
            <Form.Select onChange={handleChange(row.id, "status")} defaultValue={row.status}>
                <option value="未確認">未確認</option>
                <option value="確認">確認</option>
            </Form.Select>
        )
    }

    const editButton = (e, row, rowIndex) => {

        /*
        var session = sessions.find(x => x.id === row.id);
        console.log(inquiry)
        if (inquiry === undefined) {
            return;
        }
        return (
            
            <div onClick={() => navigate("details", { state: { inquiry: inquiry} })} style={{ "cursor": "pointer"}}>
                <Edit />
            </div>               
            
        )
        */
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true
        },
        {
            dataField: "company",
            text: "会社名",
            sort: true,
            headerStyle: () => {
                return { width: "13%" }
            }
        },
        {
            dataField: "companyInfo",
            text: "会社情報",
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "first_choice",
            text: "日程１",
            headerStyle: () => {
                return { width: "15%" }
            }
        },
        {
            dataField: "second_choice",
            text: "日程２",
            headerStyle: () => {
                return { width: "15%" }
            }
        },
        {
            dataField: "status",
            text: "対応状況",
            formatter: statusMenu,
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "pic",
            text: "対応者",
            sort: true,
            formatter: picMenu,
            headerStyle: () => {
                return { width: "9%"}
            }
        },
        {
            dataField: "session_date",
            text: "セッション日付",
            formatter: dateMenu,
            headerStyle: () => {
                return { width: "14%" }
            }
        }, 
        {
            dataField: "created_at",
            text: "日付",
            sort: true,
            headerStyle: () => {
                return { width: "14%" }
            }
        }
    ]

    const handleReset = () => {
        setValue("status", "未選択")
        setValue("pic", "未選択")
        setValue("terms", "")
        setValue("startDate", "")
        setValue("endDate", "")
        setFilteredSessions(rowGenerator(sessions));
    }

    const onSubmit = (data) => {
        var values = getValues();

        var filtered = JSON.parse(JSON.stringify(sessions));

        if (values.status !== "未選択") {

            filtered = filtered.filter(x => x.status === values.status)

        }

        

        if (values.pic !== "未選択") {
          //  console.log(filtered)
            filtered = filtered.filter(x => x.pic !== null && x.pic.last_name.toLowerCase() + " " + x.pic.first_name.toLowerCase() === values.pic.toLowerCase())
        }
        
   

        if (values.terms !== "") {
            var terms = values.terms.toLowerCase();
            filtered = filtered.filter(x => x.status.toLowerCase().includes(terms) || x.company.toLowerCase().includes(terms))
        }
      

        if (values.startDate !== "") {
            var start = new Date(values.startDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) >= start)
            
        }


        if (values.endDate !== "") {
            var end = new Date(values.endDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) <= end)
        }

      //  console.log(filtered);
        setFilteredSessions(rowGenerator(filtered));
        /*
        if (filtered.length === 0 || filtered.length === incidents.length) {
            setAlert({ ...alert, show: true, message: "キーワード検索に合致するインシデントが見つかりませんでした。"})
        } else {
            setFilteredIncidents(filtered);
        }
        */
    }

    const rowEvents = {
        /*
        onClick: (e, row, rowIndex) => {
            
            var incident = incidents.find(x => x.id === row.id);
            navigate("details", {
                state: {
                    incident: incident
                },
                reaplce: true                
            })
        }
        */
    }

    const rowGenerator = (data) => {
        const items = [];
        data.forEach(session => {
            //var pic = inquiry.pic;
            items.push({ id: session.id, company: session.company, name: session.name, nameKana: session.nameKana, companyInfo: memoModalButton(session.company, session.name, session.nameKana, session.phone, session.address, session.email),  phone: session.phone, address: session.address, first_choice: session.first_choice, second_choice: session.second_choice, status: session.status, created_at: session.created_at, pic: session.pic, session_date: session.session_date})
        })
        return items;
    }

    useEffect(() => {
        axiosPrivate.get("/api/admin/counseling")
        .then(function(response1) {
           // console.log(response1)
            if (response1.status === 200) {
                if (response1.data.apiCode === 0) {
                    axiosPrivate.get("/api/pic")
                    .then(function(response) {
                        if (response.status === 200) {
                          //  console.log(response)
                            setPICS(response.data)
                          //  console.log(response1.data.data)
                            var counseling = rowGenerator(response1.data.data)
                           // console.log(counseling)
                            setSessions(response1.data.data)
                            setFilteredSessions(counseling)
                        }
                    })
                    .catch(function(response) {
                       // console.log(response)
                    })
                    
                }
            }
        })
        .catch(function(response1) {
           // console.log(response1)
        })

        
        
        
    }, [])

    return (
        <Row className="g-0">
            <Modal show={modal.show} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>会社情報</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                会社名：
                            </Col>
                            <Col>
                                {modal.company}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                法人代表者名：
                            </Col>
                            <Col>
                                {modal.name}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                法人代表者名（カナ）：
                            </Col>
                            <Col>
                                {modal.nameKana}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                メールアドレス：
                            </Col>
                            <Col>
                                {modal.email}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                電話番号：
                            </Col>
                            <Col>
                                {modal.phone}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                住所：
                            </Col>
                            <Col>
                            {modal.address}
                            </Col>
                        </Row>
                    </p>
                </Modal.Body>
            </Modal>
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center" }}>個別カウンセリング一覧</h2>
                    <hr style={{ "width": "100%" }} />
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row classname="g-0">
                                <Col xs={6}>
                                    <Form.Group className="my-4" style={{ "width": "50%"}}>
                                        <Form.Label>絞り込みたい文字列を入力</Form.Label>
                                        <Form.Control type="text" {...register("terms")}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応状況
                                        </Form.Label>
                                        <Form.Select { ...register("status")}>
                                            <option hidden>未選択</option>
                                            <option>未確認</option>
                                            <option>確認</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>                                    
                                <Col xs={3}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応者
                                        </Form.Label>
                                        <Form.Select { ...register("pic")}>
                                            <option hidden>未選択</option>
                                            {
                                                pics.map(pic => {
                                                    return <option>{`${pic.last_name} ${pic.first_name}`}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>                                
                            </Row>
                            <Row className="g-0">
                                <Col xs={6}>
                                    
                                    <div className="my-4">
                                        <Form.Group style={{ "width": "fit-content", "float": "left"}}>
                                            <Form.Label>期間で絞り込み</Form.Label>
                                            <Form.Control type="date" {...register("startDate")} />
                                        </Form.Group>
                                        <div style={{ "float": "left", "marginTop": "32px"}}>
                                            <div className="mx-2" style={{ "float": "left", "lineHeight": "38px"}}>〜</div>
                                            <Form.Group style={{ "width": "fit-content","float": "left"}}>
                                                <Form.Control type="date" {...register("endDate")} />
                                            </Form.Group>
                                        </div>
                                    </div> 
                                                                            
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col xs={2}>
                                    <Button className="my-4" type="submit">検索する</Button>
                                </Col>
                                <Col xs={2}>
                                    <Button className="my-4" onClick={handleReset} variant="outline-primary">リセット</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                    <div style={{ "minHeight": "600px" }}>
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="id"
                            data={filteredSessions}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 25 })}
                            rowStyle={{ overflowWrap: "break-word" }}
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    )
}