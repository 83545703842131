import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, Container, Form
} from 'react-bootstrap'
import AlertModal from '../AlertModal';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { FileUploader } from "react-drag-drop-files";
import ValidationError from "../ValidationError";

export default function DocTemplateRegister(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [file, setFile] = useState(null)

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({})

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const onFileChange = (file) => {
        setFile(file)
        setValue("file", file.name)
    }

    const onSubmit = (data) => {
        if (file === null) {
            setAlert({ ...alert, show: true, message: "ファイルを選択してください"})
            return;
        }

        var formData = new FormData();
        formData.append("document_name", getValues().document_name)
        formData.append("template_type", getValues().template_type)
        formData.append("description", getValues().description)
        formData.append("file", file)
        axiosPrivate.post("/api/admin/document/templates/upload", formData)
        .then(function(response) {
          //  console.log(response)
            setAlert({ ...alert, show: true, message: response.data.message})
        })
        .catch(function(response) {
           // console.log(response)
            setAlert({ ...alert, show: true, message: response.data.message})
        })
    }

    const fileTypes = ["xlsx", "docx", "pdf"]

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-0">
                <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
                <Col />
                <Col sm={12} md={10} lg={10}>
                    <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                        <Row className="g-0">
                            <Col />
                            <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                                <h2 style={{ "alignSelf": "center" }}>文書テンプレート登録</h2>
                            </Col>
                            <Col />
                        </Row>
                        <hr style={{ "width": "100%" }} />
                        <Row className="g-0">
                            <Col xs={6}>
                                <Form.Group className="my-4">
                                    <Form.Label>テンプレートの種類</Form.Label>
                                    <Form.Select { ...register("template_type")} style={{ "width": "fit-content"}}>
                                        <option>セキュリティポリシー</option>
                                        <option>クラウドサービス利用及び委託先管理</option>
                                        <option>スマートデバイス管理</option>
                                        <option>テレワーク規程及びテレワークガイドライン</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="my-4">
                                    <Form.Label>表示名（拡張子含め）</Form.Label>
                                    <Form.Control type="text" 
                                        { 
                                            ...register("document_name", 
                                            { 
                                                required: { value: true, message: "表示名を入力してください。"},
                                                pattern: {
                                                    value: /^.*\.(docx|pdf|xlsx|DOCX|PDF|XLSX)$/,
                                                    message: "拡張子付き表示名を入力してください。"
                                                }
                                            })
                                        } 
                                    />
                                    {
                                        errors.document_name && (
                                        <ValidationError message={errors.document_name.message} />
                                    )}
                                </Form.Group>
                                <Form.Group className="my-4">
                                    <Form.Label>文書テンプレートの説明</Form.Label>
                                    <Form.Control as="textarea" rows={5} { ...register("description", { required: {value: true, message: "文書テンプレートの説明を入力してください。"}})} />
                                    {
                                        errors.description && (
                                        <ValidationError message={errors.description.message} />
                                    )}
                                </Form.Group>
                                <div className="my-4" id="admin-template-upload">
                                    <FileUploader
                                        
                                        handleChange={onFileChange}
                                        types={fileTypes}
                                        style={{ "width": "100%" }}
                                    >
                                        <Form>
                                            <Form.Group>
                                                <Form.Control value={file ? file.name : "ファイルを選択してください"} 
                                            
                                                style={{ "width": "100%", "cursor": "pointer" }} 
                                                />
                                            </Form.Group>
                                          
                                        </Form>
                                    </FileUploader>
                                </div>
                                
                                <Button type="submit">新規追加する</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col />
            </Row>
        </Form>
    )
}