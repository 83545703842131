import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Toast, ToastContainer } from "react-bootstrap";
import IncidentHistory from "../elements/incidentresponse/IncidentHistory";
import IncidentEntry from "../elements/incidentresponse/IncidentEntry";
import IncidentSent from "../elements/incidentresponse/IncidentSent";
import axios from "axios";
import { axiosPrivate } from "../../utils/axiosPrivate";
import Cookies from "universal-cookie";
import IncidentCheck from "../elements/incidentresponse/IncidentCheck";
import getIncidents from "../../utils/getIncidents";
import { useForm } from "react-hook-form";

export default function IncidentResponse(props) {
    const [time, setTime] = useState(0);
    const [incident, setIncident] = useState({
        email: "",
        type: "",
        occurred_at: "",
        content: "",
    });

    const [incidents, setIncidents] = useState([]);

    const [toast, setToast] = useState({
        show: false,
        header: "",
        message: "",
        variant: "success",
    });

    const [check, setCheck] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            type: "",
            occurred_at: "",
            content: "",
        },
    });

    const cookies = new Cookies();

    const handleTime = (time) => {
        //  //console.log(time)
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setSeconds(time);
        // //console.log(date.toLocaleTimeString('ja'))
        setTime(time);
    };

    const toggleToast = () =>
        setToast({
            show: !toast.show,
            message: "",
            variant: "success",
            header: "",
        });

    const handleChange = (prop) => (event) => {
        setIncident({ ...incident, [prop]: event.target.value });
    };

    const checkIncident = (data) => {
        //setCheck(!check)
        //console.log(data)
        setCheck(!check);
        window.scrollTo(0, 0);
    };

    const submitIncident = () => {
        var values = getValues();
        var newDate = new Date(values.occurred_at);
        newDate.setHours(0, 0, 0, 0);
        newDate.setSeconds(time);
        //console.log(values)
        var formData = new FormData();
        if (sessionStorage.getItem("user")) {
            formData.append("content", values.content);
            formData.append("email", values.email);
            formData.append("occurred_at", newDate);
            formData.append("type", values.type);
        } else {
            formData.append("name", values.name);
            formData.append("company", values.company);
            formData.append("dept", values.dept);
            formData.append("content", values.content);
            formData.append("email", values.email);
            formData.append("phoneNumber", values.phoneNumber);
            formData.append("occurred_at", newDate);
            formData.append("type", values.type);
            formData.append("address", values.address);
        }

        var body = {
            email: values.email,
            occurred_at: newDate,
            type: values.type,
            content: values.content,
        };

        //console.log(formData.get("content"))
        axiosPrivate
            .post("/api/incidents/request", formData)
            .then(function (response) {
                ////console.log(response)
                if (response.status === 200) {
                    formData.append("requestToken", response.data);
                    axiosPrivate
                        .post("/api/incidents", formData)
                        .then(function (response) {
                            if (response.status === 200) {
                                setIncident({
                                    type: "",
                                    occurred_at: "",
                                    content: "",
                                });
                                setTime(0);

                                setToast({
                                    show: true,
                                    header: "送信",
                                    message:
                                        "インシデント対応窓口へ送信されました。",
                                    variant: "success",
                                });

                                setSubmitted(true);

                                //var email = cookies.get("user");
                                //var results = getIncidents(email)
                                //results.then(res => setIncidents(res))
                            }
                        })
                        .catch(function (response) {
                            setToast({
                                show: true,
                                header: "エラー発生",
                                message:
                                    "インシデント対応窓口への送信が失敗しました。" +
                                    response.message,
                                variant: "danger",
                            });
                        });
                    window.scrollTo(0, 0);
                }
            })
            .catch(function (response) {
                //console.log(response)
                setToast({
                    show: true,
                    header: "エラー発生",
                    message:
                        "インシデント対応窓口への送信が失敗しました。" +
                        response.message,
                    variant: "danger",
                });
            });
    };

    useEffect(() => {
        let isSubscribed = true;
        var email = sessionStorage.getItem("user");
        if (email) {
            if (isSubscribed) {
                setIncident({ ...incident, email: email });
                setValue("email", email);
                var results = getIncidents();
                results.then((res) => {
                    var sortedIncidents = res.sort(function (a, b) {
                        return new Date(b.created_at) - new Date(a.created_at);
                    });
                    setIncidents(sortedIncidents);
                });
            }
        }

        return () => (isSubscribed = false);
    }, []);

    return (
        <Row className="g-0 Common">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    {check && submitted ? (
                        <h1>　インシデント対応窓口結果</h1>
                    ) : check && !submitted ? (
                        <h1>　インシデント対応窓口確認</h1>
                    ) : (
                        <h1>　インシデント対応窓口</h1>
                    )}
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
            <br />

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <selection>
                        <div>
                            {check && submitted ? (
                                <Row className="g-0">
                                    <IncidentSent
                                        checkIncident={checkIncident}
                                    />
                                </Row>
                            ) : check && !submitted ? (
                                <Row className="g-0">
                                    <IncidentCheck
                                        time={time}
                                        incident={getValues()}
                                        handleSubmit={handleSubmit}
                                        submitIncident={submitIncident}
                                        checkIncident={checkIncident}
                                        user={sessionStorage.getItem("user")}
                                    />
                                    :
                                </Row>
                            ) : (
                                <Row className="g-0">
                                    <IncidentEntry
                                        time={time}
                                        handleTime={handleTime}
                                        handleChange={handleChange}
                                        incident={incident}
                                        register={register}
                                        errors={errors}
                                        handleSubmit={handleSubmit}
                                        checkIncident={checkIncident}
                                        user={sessionStorage.getItem("user")}
                                    />
                                    {sessionStorage.getItem("user") ? (
                                        <IncidentHistory
                                            incidents={incidents}
                                        />
                                    ) : null}
                                </Row>
                            )}
                        </div>
                        <ToastContainer
                            position="bottom-center"
                            className="p-5"
                        >
                            <Toast
                                show={toast.show}
                                onClose={toggleToast}
                                bg={toast.variant}
                                delay={3000}
                                autohide
                            >
                                <Toast.Body
                                    style={{
                                        color: "white",
                                        textAlign: "center",
                                    }}
                                >
                                    {toast.message}
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </selection>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Row>
    );
}
