import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router";

export default function CounselingSent(props) {
    const { handleReturn, sentMessage } = props;

    return (
        <Row className="g-0">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "600px",
                }}
            >
                <div className="mt-4" style={{ alignSelf: "center" }}>
                    {sentMessage}
                </div>
                <div className="mt-4" style={{ alignSelf: "center" }}>
                    コンサルタント日程調整の上、ご登録メールアドレスへ結果をご返信致します。
                </div>
                <div className="mt-4" style={{ alignSelf: "center" }}>
                    尚、ご返信までお時間がかかる場合がありますので、ご了承ください。
                </div>
                <br />
                <br />
                
                <Row
                    className="template-customize-area button-flex"
                    style={{ padding: "0 10px" }}
                >
                    <Button className="btn btn-primary" type="submit" onClick={handleReturn}>
                    個別カウンセリングへ
                    </Button>
                </Row>
            </div>
        </Row>
    );
}
