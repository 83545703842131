import React, { useState } from "react";
import { Row, Form } from "react-bootstrap";

export default function LabelWithTextarea(props) {
    const [defaultValue, setDefaultValue] = useState();

    const handleObjChange = (prop) => (event) => {
        setDefaultValue(event.target.value);
        props.item.parentFunctionEditArea({
            key: props.item.key,
            defaultValue: event.target.value,
        });
    };

    return (
        <Form.Group className="edit-form-area">
            <Row className="g-0">
                <Form.Label>{props.item.labelName}</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={5}
                    defaultValue={props.item.defaultValue}
                    onChange={handleObjChange()}
                />
            </Row>
        </Form.Group>
    );
}
