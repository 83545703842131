import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Form, Stack } from "react-bootstrap";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import AlertModal from "../AlertModal";
import { useForm } from "react-hook-form";
import ValidationError from "../ValidationError";
import { Link, useNavigate } from "react-router-dom";

export default function SetMFA(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [values, setValues] = useState({
        phoneNumber: "",
        code: "",
    });

    const [registered, setRegistered] = useState({
        phoneNumber: "",
        state: false,
    });

    const [showDelete, setShowDelete] = useState(false);

    const [sent, setSent] = useState(false);

    const [showSend, setShowSend] = useState(false);
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleAlertShow = (prop) => setAlert({ ...alert, show: prop });

    const handleClose = () => setShowSend(false);
    const handleShow = () => setShowSend(true);

    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);

    const handleCancel = () => {
        setSent(false);
        setValues({
            phoneNumber: "",
            code: "",
        });
    };

    const navigate = useNavigate();
    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };

    const handleRegister = () => {
        var values = getValues();
        var body = {
            secret: `${values.code}`,
        };
        axiosPrivate
            .post("/api/user/mfa/verify", body)
            .then(function (response) {
                //console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setRegistered({
                        ...registered,
                        state: true,
                    });
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "確認コードが一致しません。",
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "多要素認証の登録が失敗しました。",
                });
            });
    };

    const handleSend = () => {
        setShowSend(false);
        var values = getValues();
        if (values.phoneNumber.length > 0) {
            var body = {
                mobile: `+81-${values.phoneNumber}`,
            };
            axiosPrivate
                .put("/api/user/mfa", body)
                .then(function (response) {
                    //console.log(response)
                    if (
                        response.status === 200 &&
                        response.data.apiCode === 0
                    ) {
                        setSent(true);
                    } else {
                        setAlert({
                            ...alert,
                            show: true,
                            message: "多要素認証の送信が失敗しました。",
                        });
                    }
                })
                .catch(function (response) {
                    //console.log(response)
                    setAlert({
                        ...alert,
                        show: true,
                        message: "多要素認証の送信が失敗しました。",
                    });
                });
        }
    };

    const handleDelete = () => {
        axiosPrivate
            .delete("/api/user/mfa")
            .then(function (response) {
                //console.log(response)
                if (response.status === 200 && response.data.apiCode === 0) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "多要素認証の情報を削除しました。",
                    });
                    setRegistered({
                        phoneNumber: "",
                        state: false,
                    });
                    setValues({
                        phoneNumber: "",
                        code: "",
                    });
                    setSent(false);
                    setShowDelete(false);
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "多要素認証の削除が失敗しました。",
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "多要素認証の削除が失敗しました。",
                });
            });
    };

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate
            .get("/api/user/mfa/status")
            .then(function (response) {
                if (response.status === 200) {
                    if (isSubscribed) {
                        setRegistered({
                            ...registered,
                            state: response.data.data.flag,
                            phoneNumber: response.data.data.mobile,
                        });
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
        return () => (isSubscribed = false);
    }, []);

    //console.log(alert)
    return (
        <Stack className="Common">
            <AlertModal
                show={alert.show}
                setShow={handleAlertShow}
                message={alert.message}
            />
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　多要素認証</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            {registered.state ? (
                <>
                    <div className="g-0 row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10 box">
                            <section style={{ maxWidth: "600px" }}>
                                <br />
                                <br />
                                <Form.Label>
                                    多要素認証用携帯電話番号を変更したい場合、現在設定されている携帯電話番号の削除を行い再登録をお願いいたします。
                                </Form.Label>
                                <br />
                                <br />
                                <Row className="g-0">
                                    <Form.Label>
                                        多要素認証状況：
                                        {registered.state ? (
                                            <>
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    登録済み
                                                </span>
                                                　　　電話番号：
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    {
                                                        registered.phoneNumber.split(
                                                            "-"
                                                        )[1]
                                                    }
                                                </span>
                                            </>
                                        ) : (
                                            "未登録"
                                        )}
                                    </Form.Label>

                                    <br />
                                    <br />
                                    <div className="template-customize-area">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={handleShowDelete}
                                        >
                                            多要素認証を削除する
                                        </Button>
                                    </div>
                                </Row>
                                <Modal
                                    show={showDelete}
                                    onHide={handleCloseDelete}
                                    size="lg"
                                    centered
                                    className="p-5"
                                >
                                    <Modal.Body className="p-5">
                                        <div>多要素認証の電話番号を削除</div>
                                        <div className="mb-4">
                                            SMS認証用電話番号の情報を削除してもよろしいでしょうか。多要素認証は無効になります。
                                        </div>
                                        <Row className="g-0">
                                            <Col xs={3} />
                                            <Col xs={3}>
                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={handleCloseDelete}
                                                >
                                                    キャンセル
                                                </Button>
                                            </Col>
                                            <Col xs={3}>
                                                <Button
                                                    size="sm"
                                                    onClick={handleDelete}
                                                >
                                                    削除する
                                                </Button>
                                            </Col>
                                            <Col xs={3} />
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </section>
                            <br />
                            <br />
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </>
            ) : sent ? (
                <div className="g-0 row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <section style={{ maxWidth: "600px" }}>
                            <Form
                                className="form-area"
                                onSubmit={handleSubmit(handleRegister)}
                            >
                                <Form.Group className="my-4">
                                    <Row className="g-0">
                                        <Form.Label>
                                            SMS確認用携帯番号
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={getValues().phoneNumber}
                                            disabled
                                            style={{
                                                backgroundColor: "#cccccc",
                                            }}
                                        />
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row className="g-0">
                                        <Form.Label>
                                            確認コード（携帯電話番号で受信したコードを入力します）
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register("code", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "受信した確認コードを入力してください。",
                                                },
                                            })}
                                        />
                                        {errors.code && (
                                            <ValidationError
                                                message={errors.code.message}
                                            />
                                        )}
                                    </Row>
                                </Form.Group>
                                <Row className="template-customize-area button-flex">
                                    <Button
                                        className="btn btn-primary btn-margin"
                                        style={{ width: "250px" }}
                                        onClick={handleCancel}
                                    >
                                        キャンセル
                                    </Button>
                                    <Button
                                        className="btn btn-primary"
                                        style={{ width: "250px" }}
                                        type="submit"
                                    >
                                        送信する
                                    </Button>
                                </Row>
                            </Form>
                        </section>
                        <br />
                        <br />
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            ) : (
                <div className="g-0 row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <section style={{ maxWidth: "800px" }}>
                            <div
                                className="template-customize-mypage-area"
                                style={{
                                    justifyContent: "right",
                                    display: "grid",
                                }}
                            >
                                <Button
                                    className="rounded-pill btn-blue-2"
                                    onClick={handleMypage}
                                >
                                    マイページへ戻る
                                </Button>
                            </div>

                            <Form className="form-area">
                                <br />
                                <Form.Label>
                                    携帯電話番号を入力すると、認証コードが記載されたショートメールが送信されます。このコードを使って電話番号を認証します。
                                </Form.Label>

                                <div className="template-customize-area">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={handleShow}
                                    >
                                        多要素認証を設定する
                                    </Button>
                                </div>

                                <Modal
                                    show={showSend}
                                    onHide={handleClose}
                                    size="lg"
                                    centered
                                    className="p-5"
                                >
                                    <Modal.Body className="form-area">
                                        <Form
                                            onSubmit={handleSubmit(handleSend)}
                                        >
                                            <Form.Group className="edit-form-area">
                                                <Row className="g-0">
                                                    <Form.Label className="label-phone-area">
                                                        SMS確認用携帯番号
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="input-phone-area"
                                                        type="text"
                                                        {...register(
                                                            "phoneNumber",
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message:
                                                                        "有効な携帯番号を入力ください。",
                                                                },
                                                            }
                                                        )}
                                                    />
                                                    <Form.Label className="label-hyphen-area">
                                                        ハイフンなし
                                                    </Form.Label>
                                                    {errors.phoneNumber && (
                                                        <ValidationError
                                                            message={
                                                                errors
                                                                    .phoneNumber
                                                                    .message
                                                            }
                                                        />
                                                    )}
                                                </Row>
                                            </Form.Group>
                                            <div className="edit-phone-form-area">
                                                <Button
                                                    //variant="outline-secondary"
                                                    className="rounded-pill btn-blue-2 btn-primary button-margin button-size"
                                                    onClick={handleClose}
                                                >
                                                    キャンセル
                                                </Button>
                                                <Button
                                                    className="rounded-pill btn-blue-2 btn-primary button-size"
                                                    type="submit"
                                                >
                                                    送信する
                                                </Button>
                                            </div>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            </Form>
                        </section>
                        <br />
                        <br />
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            )}
        </Stack>
    );
}
