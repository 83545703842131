import axios from 'axios';
import fileDownload from 'js-file-download';
import { axiosPrivate } from './axiosPrivate';


export default function downloadInvoice(filename) {

    axiosPrivate.get("/api/invoice/" + filename, {
        responseType: 'blob',
    })
    .then(res => {
        fileDownload(res.data, `invoice_${filename}`);
    })
}