import React, { useEffect, useState } from "react";
import { Stack, Row, Col, Form, Button } from "react-bootstrap";
import { Prompt } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import ValidationError from "../ValidationError";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import { useNavigate } from "react-router-dom";
import AlertModal from "../AlertModal";

export default function EditUser(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [block, setBlock] = useState(false);
    const [compSize, setCompSize] = useState([]);
    const [source, setSource] = useState([]);
    const [industryType, setIndustryType] = useState([]);
    const [subsChecked, setSubsChecked] = useState(true);
    const [tempIds, setTempIds] = useState({
        companyTypeId: "",
        companySizeId: "",
        referrerId: "",
    });

    const [validated, setValidated] = useState(false);

    const [addAddress, setAddAddress] = useState(false);

    const [values, setValues] = useState({
        userId: "0",
        firstName: "",
        lastName: "",
        firstNameKana: "",
        lastNameKana: "",
        company: "",
        dept: "",
        password: "",
        companyTypeId: "",
        newsletterSubs: true,
        companySizeId: {},
        referrerId: {},
        postcode: "",
        state: "",
        city: "",
        address: "",
        buldingName: "",
        contactInfo: "",
        paymentInfo: {
            company: "",
            dept: "",
            firstName: "",
            lastName: "",
            firstNameKana: "",
            lastNameKana: "",
            postcode: "",
            state: "",
            city: "",
            address: "",
            buldingName: "",
            contactInfo: "",
        },
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            userId: "0",
            firstName: "",
            lastName: "",
            firstNameKana: "",
            lastNameKana: "",
            company: "",
            dept: "",
            password: "",
            companyTypeId: {},
            newsletterSubs: true,
            companySizeId: {},
            referrerId: {},
            postcode: "",
            state: "",
            city: "",
            address: "",
            buldingName: "",
            contactInfo: "",
            paymentInfo: {
                company: "",
                dept: "",
                firstName: "",
                lastName: "",
                firstNameKana: "",
                lastNameKana: "",
                postcode: "",
                state: "",
                city: "",
                address: "",
                buldingName: "",
                contactInfo: "",
            },
        },
    });

    const [yobiMail, setYobiMail] = useState({
        email: "",
        yobiMagazine: true,
        yobiNotifications: true,
    });

    const navigate = useNavigate();
    const cookies = new Cookies();
    var jpPrefecture = require("jp-prefecture");
    const prefectures = jpPrefecture.getAll("pref", ["id", "name"]);

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const handlePaymentChange = (prop) => (event) => {
        setValues({
            ...values,
            paymentInfo: {
                ...values.paymentInfo,
                [prop]: event.target.value,
            },
        });
    };

    const handleObjChange = (prop) => (event) => {
        let obj = JSON.parse(event.target.value);
        setValues({ ...values, [prop]: obj });
        setValue(prop, obj);
    };

    const handleCheck = (prop) => (event) => {
        if (prop === "newsletter") {
            setValue("newsletterSubs", event.target.checked);
            setSubsChecked(event.target.checked);
        } else {
            setAddAddress(event.target.checked);
        }
    };

    const yobiSubmit = (data) => {
        var body = {
            mail: data.yobiMail,
            newsletterSubs: `${data.yobiMagazine}`,
            serviceSubs: `${data.yobiNotifications}`,
        };

        axiosPrivate
            .put("/api/user/altmail", body)
            .then(function (response) {
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message:
                            "予備メールアドレスに仮登録メールを送りました。",
                    });
                    setBlock(false);
                }
            })
            .catch(function (response) {
                setAlert({
                    ...alert,
                    show: true,
                    message: "予備メールアドレスの登録が失敗しました。",
                });
            });
    };

    const handleYobiChange = (prop) => (event) => {
        if (prop === "yobiNotifications" || prop === "yobiMagazine") {
            setYobiMail({ ...yobiMail, [prop]: event.target.checked });
            setValue(prop, event.target.checked);
        } else {
            setYobiMail({ ...yobiMail, [prop]: event.target.value });
        }
    };

    const setAddress = (prop) => {
        var postal_code = require("japan-postal-code");
        var zip =
            prop === "addressOne"
                ? getValues("postcode")
                : getValues("paymentInfo.postcode");
        postal_code.get(zip, function (address) {
            if (prop === "addressOne") {
                setValue("state", address.prefecture);
                setValue("city", address.city);
                setValue("address", address.area);
            } else {
                setValue("paymentInfo.state", address.prefecture);
                setValue("paymentInfo.city", address.city);
                setValue("paymentInfo.address", address.area);
            }
        });
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const blockChange = (event) => {
        setBlock(true);
    };

    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };

    const onSubmit = (data) => {
        var formValues = getValues();
        var paymentInfo = addAddress
            ? {
                  company: formValues.paymentInfo.company,
                  dept: formValues.paymentInfo.dept,
                  firstName: formValues.paymentInfo.firstName,
                  lastName: formValues.paymentInfo.lastName,
                  firstNameKana: formValues.paymentInfo.firstNameKana,
                  lastNameKana: formValues.paymentInfo.lastNameKana,
                  postcode: formValues.paymentInfo.postcode,
                  state: formValues.paymentInfo.state,
                  city: formValues.paymentInfo.city,
                  address: formValues.paymentInfo.address,
                  buldingName: formValues.paymentInfo.buldingName,
                  contactInfo: formValues.paymentInfo.contactInfo,
              }
            : null;

        const body = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            firstNameKana: formValues.firstNameKana,
            lastNameKana: formValues.lastNameKana,
            corpName: formValues.company,
            dept: formValues.dept,
            corpTypeId:
                typeof values.companyTypeId.id === "undefined"
                    ? null
                    : `${values.companyTypeId.id}`,
            corpSizeId:
                typeof values.companySizeId.id === "undefined"
                    ? null
                    : `${values.companySizeId.id}`,
            referrerId:
                typeof values.referrerId.id === "undefined"
                    ? null
                    : `${values.referrerId.id}`,
            newsletterSubs: `${formValues.newsletterSubs}`,
            postcode: formValues.postcode,
            state: formValues.state,
            city: formValues.city,
            address: formValues.address,
            buldingName: formValues.buldingName,
            contactInfo: formValues.contactInfo,
            paymentInfo: paymentInfo,
        };

        // //console.log(body)

        axiosPrivate
            .put("/api/user", body)
            .then(function (response) {
                if (response.status === 200 && response.data.apiCode === 0) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "会員情報を編集しました。",
                    });
                    axiosPrivate
                        .get("/api/user/1")
                        .then(function (response) {
                            if (
                                response.status === 200 &&
                                response.data.apiCode === 0
                            ) {
                                var data = response.data.data;
                                setValue("company", data.corpName);
                                setValue("firstName", data.firstName);
                                setValue("lastName", data.lastName);
                                setValue("firstNameKana", data.firstNameKana);
                                setValue("lastNameKana", data.lastNameKana);
                                setValue("dept", data.dept);
                                setValue("referrerId", data.referrerId);
                                setValue("postcode", data.postcode);
                                setValue("state", data.state);
                                setValue("city", data.city);
                                setValue("address", data.address);
                                setValue("buldingName", data.buldingName);
                                setValue("contactInfo", data.contactInfo);
                                setValue("paymentInfo", data.paymentInfo);
                                setValue("newsletterSubs", data.newsletterSubs);
                                setTempIds({
                                    ...tempIds,
                                    companyTypeId: data.corpTypeId,
                                    companySizeId: data.corpSizeId,
                                    referrerId: data.referrerId,
                                });

                                setBlock(false);
                            } else {
                                setAlert({
                                    ...alert,
                                    show: true,
                                    message: "会員情報の編集が失敗しました。",
                                });
                            }
                        })
                        .catch(function (response) {
                            console.error(response);
                            setAlert({
                                ...alert,
                                show: true,
                                message: "会員情報の編集が失敗しました。",
                            });
                        });
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "会員情報の編集が失敗しました。",
                    });
                }
            })
            .catch(function (response) {
                console.error(response);
            });
    };

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate
            .get("/api/master/datasource/0")
            .then(function (response) {
                if (response.status === 200) {
                    var data = response.data.data;
                    let sizes = [];
                    let sources = [];
                    let types = [];
                    data.forEach((item) => {
                        if (item.typeId === 1) {
                            types.push(item);
                        } else if (item.typeId === 2) {
                            sizes.push(item);
                        } else if (item.typeId === 3) {
                            sources.push(item);
                        }
                    });
                    if (isSubscribed) {
                        setCompSize(sizes);
                        setSource(sources);
                        setIndustryType(types);
                    }
                }
            })
            .catch(function (response) {
                console.error(response);
            });

        axiosPrivate
            .get("/api/user/1")
            .then(function (response) {
                if (response.status === 200 && response.data.apiCode === 0) {
                    var data = response.data.data;
                    if (isSubscribed) {
                        setValue("company", data.corpName);
                        setValue("firstName", data.firstName);
                        setValue("lastName", data.lastName);
                        setValue("firstNameKana", data.firstNameKana);
                        setValue("lastNameKana", data.lastNameKana);
                        setValue("dept", data.dept);
                        setValue("referrerId", data.referrerId);
                        setValue("postcode", data.postcode);
                        setValue("state", data.state);
                        setValue("city", data.city);
                        setValue("address", data.address);
                        setValue("buldingName", data.buldingName);
                        setValue("contactInfo", data.contactInfo);
                        setValue("newsletterSubs", data.newsletterSubs);
                        if (
                            typeof data.paymentInfo !== "undefined" &&
                            data.paymentInfo !== null
                        ) {
                            setValue("paymentInfo", data.paymentInfo);
                            setAddAddress(true);
                        }

                        if (
                            typeof data.userAltinfo !== "undefined" &&
                            data.userAltinfo !== null
                        ) {
                            setValue("yobiMail", data.userAltinfo.mailId);
                            setValue(
                                "yobiMagazine",
                                data.userAltinfo.newsletterSubs
                            );
                            setValue(
                                "yobiNotifications",
                                data.userAltinfo.serviceSubs
                            );
                            setValue("yobiVerify", data.userAltinfo.isVerified);
                            setYobiMail({
                                ...yobiMail,
                                yobiMagazine: data.userAltinfo.newsletterSubs,
                                yobiNotifications: data.userAltinfo.serviceSubs,
                            });
                        }

                        setTempIds({
                            ...tempIds,
                            companyTypeId: data.corpTypeId,
                            companySizeId: data.corpSizeId,
                            referrerId: data.referrerId,
                        });
                    }
                }
            })
            .catch(function (response) {
                console.error(response);
            });
        return () => (isSubscribed = false);
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        if (
            industryType.length > 0 &&
            tempIds.companyTypeId &&
            source.length > 0 &&
            tempIds.referrerId &&
            compSize.length > 0 &&
            tempIds.companySizeId
        ) {
            if (isSubscribed) {
                var type = industryType.find(
                    (x) => x.id === tempIds.companyTypeId
                );

                setValue("companyTypeId", type);

                var referrer = source.find((x) => x.id === tempIds.referrerId);

                setValue("referrerId", referrer);

                var size = compSize.find((x) => x.id === tempIds.companySizeId);
                setValues({
                    ...values,
                    companySizeId: size,
                    companyTypeId: type,
                    referrerId: referrer,
                });
                setValue("companySizeId", size);
            }
        }
        return () => (isSubscribed = false);
    }, [industryType, compSize, source, tempIds]);

    const validateMessage = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "担当者姓名を入力してください。";
        } else {
            if (firstName) {
                errorMessage = "担当者名を入力してください。";
            }
            if (lastName) {
                errorMessage = "担当者姓を入力してください。";
            }
        }
        return errorMessage;
    };
    const validateMessageKana = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "担当者セイメイを入力してください。";
        } else {
            if (firstName) {
                errorMessage = "担当者メイを入力してください。";
            }
            if (lastName) {
                errorMessage = "担当者セイを入力してください。";
            }
        }
        return errorMessage;
    };
    const validatePaymentMessage = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "請求担当者姓名を入力してください。";
        } else {
            if (firstName) {
                errorMessage = "請求担当者名を入力してください。";
            }
            if (lastName) {
                errorMessage = "請求担当者姓を入力してください。";
            }
        }
        return errorMessage;
    };
    const validatePaymentMessageKana = (firstName, lastName) => {
        let errorMessage = "";

        if (firstName && lastName) {
            errorMessage = "請求担当者セイメイを入力してください。";
        } else {
            if (firstName) {
                errorMessage = "請求担当者メイを入力してください。";
            }
            if (lastName) {
                errorMessage = "請求担当者セイを入力してください。";
            }
        }
        return errorMessage;
    };

    return (
        <Stack className="Common">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Prompt
                when={block}
                message="更新内容が保存されていませんが、移動しますか。"
            />

            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　会員情報編集</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section style={{ maxWidth: "600px" }}>
                        <div
                            className="template-customize-mypage-area"
                            style={{
                                justifyContent: "right",
                                display: "grid",
                            }}
                        >
                            <Button
                                className="rounded-pill btn-blue-2"
                                onClick={handleMypage}
                            >
                                マイページへ戻る
                            </Button>
                        </div>

                        <Form
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            validated={validated}
                            className="form-area"
                        >
                            <h2
                                id="counseling_overview"
                                className="content_title"
                            >
                                <div>基本情報</div>
                            </h2>
                            <Form.Group className="my-3">
                                <Form.Label>会社名　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="new-password"
                                    {...register("company", {
                                        required: {
                                            value: true,
                                            message:
                                                "会社名を入力してください。",
                                        },
                                        onChange: blockChange,
                                    })}
                                />
                                {errors.company && (
                                    <ValidationError
                                        message={errors.company.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>部署名　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("dept")}
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>担当者（漢字）</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <div style={{ display: "flex" }}>
                                    <Form.Control
                                        type="text"
                                        placeholder="姓"
                                        style={{ marginRight: "8px" }}
                                        {...register("lastName", {
                                            required: {
                                                value: true,
                                                message:
                                                    "担当者姓名を入力してください。",
                                            },
                                            onChange: blockChange,
                                        })}
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="名"
                                        className="contract-firstname-input input-firstName"
                                        {...register("firstName", {
                                            required: {
                                                value: true,
                                                message:
                                                    "担当者姓名を入力してください。",
                                            },
                                            onChange: blockChange,
                                        })}
                                    />
                                </div>
                                {(errors.firstName || errors.lastName) && (
                                    <ValidationError
                                        message={validateMessage(
                                            errors.firstName,
                                            errors.lastName
                                        )}
                                    />
                                )}
                            </Form.Group>

                            <Form.Group className="my-3">
                                <Form.Label>担当者（全角カナ）</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <div style={{ display: "flex" }}>
                                    <Form.Control
                                        type="text"
                                        placeholder="セイ"
                                        style={{ marginRight: "8px" }}
                                        {...register("lastNameKana", {
                                            required: {
                                                value: true,
                                                message:
                                                    "担当者セイメイを入力してください。",
                                            },
                                            onChange: blockChange,
                                        })}
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="メイ"
                                        {...register("firstNameKana", {
                                            required: {
                                                value: true,
                                                message:
                                                    "担当者セイメイを入力してください。",
                                            },
                                            onChange: blockChange,
                                        })}
                                    />
                                </div>
                                {(errors.firstNameKana ||
                                    errors.lastNameKana) && (
                                    <ValidationError
                                        message={validateMessageKana(
                                            errors.firstNameKana,
                                            errors.lastNameKana
                                        )}
                                    />
                                )}
                            </Form.Group>

                            <Form.Group className="my-3">
                                <Form.Label>郵便番号 </Form.Label>
                                <Form.Label className="any-lable"></Form.Label>

                                <div style={{ display: "flex" }}>
                                    <Form.Control
                                        style={{
                                            marginRight: "8px",
                                            maxWidth: "380px",
                                        }}
                                        type="text"
                                        {...register("postcode", {
                                            onChange: blockChange,
                                        })}
                                    />
                                    <Button
                                        className="rounded-pill btn-blue-2 button-post"
                                        onClick={() => setAddress("addressOne")}
                                    >
                                        郵便番号から住所入力
                                    </Button>
                                </div>
                                {errors.postcode && (
                                    <ValidationError
                                        message={errors.postcode.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>都道府県　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Select
                                    className="select-area"
                                    {...register("state", {
                                        validate: (value) =>
                                            value !== "都道府県を選択する" ||
                                            "都道府県を選択してください。",
                                    })}
                                >
                                    <option key="defaultPrefecture" hidden>
                                        都道府県を選択する
                                    </option>
                                    {prefectures.map((pref) => {
                                        return (
                                            <option value={pref.name}>
                                                {pref.name}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                {errors.state && (
                                    <ValidationError
                                        message={"都道府県を入力してください。"}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>市区町村　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("city", {
                                        required: {
                                            value: true,
                                            message:
                                                "市区町村を入力してください。",
                                        },
                                        onChange: blockChange,
                                    })}
                                />
                                {errors.city && (
                                    <ValidationError
                                        message={errors.city.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="edit-form-area">
                                <Row className="g-0">
                                    <Form.Label>番地等　</Form.Label>
                                    <Form.Label className="required-lable"></Form.Label>
                                    <Form.Control
                                        type="text"
                                        {...register("address", {
                                            required: {
                                                value: true,
                                                message:
                                                    "番地等を入力してください。",
                                            },
                                            onChange: blockChange,
                                        })}
                                    />
                                    {errors.address && (
                                        <ValidationError
                                            message={errors.address.message}
                                        />
                                    )}
                                </Row>
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>マンション・建物名　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("buldingName", {
                                        onChange: blockChange,
                                    })}
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>電話番号　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("contactInfo", {
                                        onChange: blockChange,
                                    })}
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>業種　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Select
                                    className="select-area-jobs"
                                    {...register("companyTypeId")}
                                    onChange={handleObjChange("companyTypeId")}
                                    value={JSON.stringify(values.companyTypeId)}
                                >
                                    <option key="defaultIndustryType" hidden>
                                        業種を選択する
                                    </option>
                                    {industryType.map((item, index) => {
                                        return (
                                            <option
                                                value={JSON.stringify(item)}
                                                key={`${index}_${item.id}`}
                                            >
                                                {item.keyValueJp}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>企業規模　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Select
                                    className="select-area-jobs"
                                    {...register("companySizeId")}
                                    onChange={handleObjChange("companySizeId")}
                                    value={JSON.stringify(values.companySizeId)}
                                >
                                    <option key="defaultSize" hidden>
                                        企業規模を選択する
                                    </option>
                                    {compSize.map((item, index) => {
                                        return (
                                            <option
                                                value={JSON.stringify(item)}
                                                key={`${index}_${item.id}`}
                                            >
                                                {item.keyValueJp}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="my-3">
                                <div style={{ display: "flex" }}>
                                    <Form.Check
                                        checked={subsChecked}
                                        className="mb-3 checkbox-area"
                                        type="checkbox"
                                        id="magazine-check"
                                        name="magazine-check"
                                        {...register("newsletterSubs", {
                                            onChange: handleCheck("newsletter"),
                                        })}
                                    />
                                    <Form.Label>
                                        メールマガジン購読　
                                    </Form.Label>
                                    <Form.Label className="any-lable"></Form.Label>
                                </div>
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>アンケート　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Select
                                    className="select-area-question"
                                    {...register("referrerId")}
                                    onChange={handleObjChange("referrerId")}
                                    value={JSON.stringify(values.referrerId)}
                                >
                                    <option key="defaultSource" hidden></option>
                                    {source.map((item, index) => {
                                        return (
                                            <option
                                                value={JSON.stringify(item)}
                                                key={`${index}_${item.id}`}
                                            >
                                                {item.keyValueJp}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <h2
                                id="counseling_overview"
                                className="content_title"
                            >
                                <div>請求書送付先情報</div>
                            </h2>
                            <Form.Group className="my-3">
                                <Form.Label className="label-sub-title">
                                    請求書送付先情報を追加する場合はチェックを入れてください。
                                </Form.Label>
                            </Form.Group>

                            <Form.Group className="my-3">
                                <div style={{ display: "flex" }}>
                                    <Form.Check
                                        checked={addAddress}
                                        type="checkbox"
                                        id="addAddress-check"
                                        name="addAddress-check"
                                        onChange={handleCheck("addAddress")}
                                    />
                                    <Form.Label>
                                        請求書送付先情報の追加
                                    </Form.Label>
                                </div>
                            </Form.Group>
                            {addAddress ? (
                                <>
                                    <Form.Group className="my-3">
                                        <Form.Label>会社名　</Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register(
                                                "paymentInfo.company",
                                                {
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "会社名を入力してください。",
                                                    },
                                                    onChange: blockChange,
                                                }
                                            )}
                                        />
                                        {addAddress
                                            ? errors.paymentInfo &&
                                              errors.paymentInfo.company && (
                                                  <ValidationError
                                                      message={
                                                          errors.paymentInfo
                                                              .company.message
                                                      }
                                                  />
                                              )
                                            : null}
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>部署名　</Form.Label>
                                        <Form.Label className="any-lable"></Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register("paymentInfo.dept", {
                                                onChange: blockChange,
                                            })}
                                        />
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>
                                            請求担当者（漢字）　
                                        </Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>
                                        <div style={{ display: "flex" }}>
                                            <Form.Control
                                                type="text"
                                                placeholder="姓"
                                                style={{ marginRight: "8px" }}
                                                {...register(
                                                    "paymentInfo.lastName",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "請求担当者姓名を入力してください。",
                                                        },
                                                        onChange: blockChange,
                                                    }
                                                )}
                                            />
                                            <Form.Control
                                                type="text"
                                                placeholder="名"
                                                {...register(
                                                    "paymentInfo.firstName",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "請求担当者姓名を入力してください。",
                                                        },
                                                        onChange: blockChange,
                                                    }
                                                )}
                                            />
                                        </div>

                                        {addAddress
                                            ? errors.paymentInfo && (
                                                  <ValidationError
                                                      message={validatePaymentMessage(
                                                          errors.paymentInfo
                                                              .firstName,
                                                          errors.paymentInfo
                                                              .lastName
                                                      )}
                                                  />
                                              )
                                            : null}
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>
                                            請求担当者（全角カナ）　
                                        </Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>
                                        <div style={{ display: "flex" }}>
                                            <Form.Control
                                                type="text"
                                                placeholder="セイ"
                                                style={{ marginRight: "8px" }}
                                                {...register(
                                                    "paymentInfo.lastNameKana",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "請求担当者セイメイを入力してください。",
                                                        },
                                                        onChange: blockChange,
                                                    }
                                                )}
                                            />
                                            <Form.Control
                                                type="text"
                                                placeholder="メイ"
                                                {...register(
                                                    "paymentInfo.firstNameKana",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "請求担当者セイメイを入力してください。",
                                                        },
                                                        onChange: blockChange,
                                                    }
                                                )}
                                            />
                                        </div>
                                        {addAddress
                                            ? errors.paymentInfo && (
                                                  <ValidationError
                                                      message={validatePaymentMessageKana(
                                                          errors.paymentInfo
                                                              .firstNameKana,
                                                          errors.paymentInfo
                                                              .lastNameKana
                                                      )}
                                                  />
                                              )
                                            : null}
                                    </Form.Group>

                                    <Form.Group className="my-3">
                                        <Form.Label>郵便番号　</Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>

                                        <div style={{ display: "flex" }}>
                                            <Form.Control
                                                style={{
                                                    marginRight: "8px",
                                                    maxWidth: "380px",
                                                }}
                                                type="text"
                                                className="input-post-code"
                                                {...register(
                                                    "paymentInfo.postcode",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "郵便番号を入力してください。",
                                                        },
                                                        onChange: blockChange,
                                                    }
                                                )}
                                            />
                                            <Button
                                                className="rounded-pill btn-blue-2 button-post"
                                                onClick={() =>
                                                    setAddress("addressTwo")
                                                }
                                            >
                                                郵便番号から住所入力
                                            </Button>
                                        </div>
                                        {addAddress
                                            ? errors.paymentInfo &&
                                              errors.paymentInfo.postcode && (
                                                  <ValidationError
                                                      message={
                                                          errors.paymentInfo
                                                              .postcode.message
                                                      }
                                                  />
                                              )
                                            : null}
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>都道府県　</Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>
                                        <Form.Select
                                            className="select-area"
                                            {...register("paymentInfo.state", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "都道府県を選択してください。",
                                                },
                                                validate: (value) =>
                                                    value !==
                                                        "都道府県を選択する" ||
                                                    "都道府県を選択してください。",
                                            })}
                                        >
                                            <option
                                                key="defaultPrefecture"
                                                hidden
                                            >
                                                都道府県を選択する
                                            </option>
                                            {prefectures.map((pref) => {
                                                return (
                                                    <option value={pref.name}>
                                                        {pref.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        {addAddress
                                            ? errors.paymentInfo &&
                                              errors.paymentInfo.state && (
                                                  <ValidationError
                                                      message={
                                                          errors.paymentInfo
                                                              .state.message
                                                      }
                                                  />
                                              )
                                            : null}
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>市区町村　</Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register("paymentInfo.city", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "市区町村を入力してください。",
                                                },
                                                onChange: blockChange,
                                            })}
                                        />
                                        {addAddress
                                            ? errors.paymentInfo &&
                                              errors.paymentInfo.city && (
                                                  <ValidationError
                                                      message={
                                                          errors.paymentInfo
                                                              .city.message
                                                      }
                                                  />
                                              )
                                            : null}
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>番地等　</Form.Label>
                                        <Form.Label className="required-lable"></Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register(
                                                "paymentInfo.address",
                                                {
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "番地等を入力してください。",
                                                    },
                                                    onChange: blockChange,
                                                }
                                            )}
                                        />
                                        {addAddress
                                            ? errors.paymentInfo &&
                                              errors.paymentInfo.address && (
                                                  <ValidationError
                                                      message={
                                                          errors.paymentInfo
                                                              .address.message
                                                      }
                                                  />
                                              )
                                            : null}
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>
                                            マンション・建物名　
                                        </Form.Label>
                                        <Form.Label className="any-lable"></Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register(
                                                "paymentInfo.buldingName",
                                                {
                                                    onChange: blockChange,
                                                }
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group className="my-3">
                                        <Form.Label>
                                            請求担当電話番号　
                                        </Form.Label>
                                        <Form.Label className="any-lable"></Form.Label>
                                        <Form.Control
                                            type="text"
                                            {...register(
                                                "paymentInfo.contactInfo",
                                                {
                                                    onChange: blockChange,
                                                }
                                            )}
                                        />
                                    </Form.Group>
                                </>
                            ) : null}
                            <Row className="template-customize-area">
                                <Button
                                    className="rounded-pill btn-blue-2"
                                    type="submit"
                                >
                                    会員情報を更新する
                                </Button>
                            </Row>
                            <br />
                            <br />
                            <Form.Group className="edit-form-area">
                                <Form.Label className="label-sub-title">
                                    退会手続き
                                </Form.Label>
                            </Form.Group>
                            <Form className="form-area">
                                <a
                                    href="#"
                                    style={{
                                        alignSelf: "start",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => navigate("/user/cancel")}
                                >
                                    退会をご希望の方はこちらへ
                                </a>
                            </Form>
                        </Form>
                    </section>
                    <br />
                    <br />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Stack>
    );
}
