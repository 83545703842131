import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { axiosPrivate } from "../../utils/axiosPrivate";
import downloadFile from "../../utils/downloadFile";
import fileDownload from "js-file-download";

export default function Manual(props) {
    const handleClick = (bucket, filename) => {
        axiosPrivate
            .get(`/api/document/view/${bucket}/${filename}`, {
                responseType: "blob",
            })
            .then(function (response) {
                if (response.status === 200) {
                    let file = filename.replace(/^.*[\\\/]/, "");
                    fileDownload(response.data, `${file}`);
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
    };

    return (
        <div className="Common">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　ご利用マニュアル</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <br />
                        <br />
                        <Row>
                            <Form.Label style={{ fontSize: "20px" }}>
                                vCISOサービス及びEラーニングのマニュアルです。
                            </Form.Label>
                            <Form.Label style={{ fontSize: "20px" }}>
                                マニュアル記載外のお問い合わせは「よろづ相談」よりお問い合わせください。
                            </Form.Label>
                        </Row>
                        <br />
                        <h2 id="counseling_overview" className="content_title">
                            <div>vCISO操作マニュアル</div>
                        </h2>
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "manual",
                                        "vCISOサービス利用マニュアル.pdf"
                                    )
                                }
                            >
                                vCISO操作マニュアル
                            </a>
                        </div>
                        <br />
                        <br />
                        <h2 id="counseling_overview" className="content_title">
                            <div>Eラーニング（MinaSecure）マニュアル</div>
                        </h2>
                        <div
                            className="my-4"
                            style={{ marginLeft: "20px", fontSize: "20px" }}
                        >
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "manual",
                                        "管理者向け - MinaSecureサービス利用マニュアル.pdf"
                                    )
                                }
                            >
                                管理者向け-MinaSecureサービス利用マニュアル
                            </a>
                        </div>
                        <div
                            className="my-4"
                            style={{ marginLeft: "20px", fontSize: "20px" }}
                        >
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "manual",
                                        "受講者向け - MinaSecureサービス利用マニュアル.pdf"
                                    )
                                }
                            >
                                受講者向け-MinaSecureサービス利用マニュアル
                            </a>
                        </div>
                    </section>
                    <br />
                    <br />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>
    );
}
