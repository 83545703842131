import {
    Row, Col, Button
} from 'react-bootstrap'

export default function Invoice(props) {

    const { invoice, download } = props;

    return (
        <tr >
            <td scope="row" style={{ "verticalAlign": "middle"}}>{invoice.invoiceDate}</td>
            <td style={{ "verticalAlign": "middle"}}>{invoice.method}</td>
            <td style={{ "verticalAlign": "middle"}}>{invoice.plan}</td>
            <td>
                <Row className="g-0" style={{ "borderBottom": "1px solid lightgrey"}}>
                    <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                        <span className="ps-2" style={{ "alignSelf": "start"}}>税抜額</span>
                    </Col>
                    <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                        <span className="pe-2" style={{ "alignSelf": "end"}}>{invoice.amount}</span>
                    </Col>
                </Row>
                <Row className="g-0" style={{ "borderBottom": "1px solid lightgrey"}}>
                    <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                        <span className="ps-2" style={{ "alignSelf": "start"}}>消費税</span>
                    </Col>
                    <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                        <span className="pe-2" style={{ "alignSelf": "end"}}>{invoice.tax}</span>
                    </Col>
                </Row>
                <Row className="g-0" >
                    <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                        <span style={{ "alignSelf": "end"}}>合計額</span>
                    </Col>
                    <Col xs={6} style={{ "display": "flex", "flexDirection": "column"}}>
                        <span className="pe-2" style={{ "alignSelf": "end"}}>{invoice.total}</span>
                    </Col>
                </Row>
            </td>
            <td style={{ "verticalAlign": "middle"}}><Button onClick={download}>ダウンロード</Button></td>
        </tr>
    )
}