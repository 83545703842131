import React, { useState, useEffect } from 'react';
import {
    Row, Col, Container, Stack, Table, Breadcrumb, Form, Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import "../../../stylesheets/RyokinPlan.css";
import { useNavigate, useLocation } from 'react-router';

const planName = {
    "2": "Lite",
    "3": "Standard",
    "4": "Premium"
}

const planCostMonthly = {
    "2": "15,000円",
    "3": "30,000円",
    "4": "50,000円"
}

const planCostYearly = {
    "2": "180,000円",
    "3": "360,000円",
    "4": "600,000円"
}
export default function PaymentPlan(props) {

    const { handleChange, plan, setConfirm, goToPayment, status} = props;
    const navigate = useNavigate();

    //console.log(plan)
    return (
        
                <Stack gap={2} style={{ "width": "100%", "height": "100%", "display": "flex", "alignItems": "center", "alignSelf": "center"}} className="p-4">
                    <Form style={{ "width": "100%"}}>
                        <Table bordered style={{ "border": "1px solid lightgray"}}>
                            <caption style={{ "fontSize": ".8em"}}>※1　利用開始が月の途中の場合は、利用開始月の末日まで無償期間​となります。</caption>
                            <caption style={{ "fontSize": ".8em"}}>※2　無償期間は各サービスのご利用は可能ですが、サイバーリスク保険は適用外となりますのでご注意ください。​</caption>
                            <thead>
                            
                                <tr>
                                    <th colSpan={3} style={{ "borderTopStyle": "hidden", "borderLeftStyle": "hidden"}}/>
                                    <th style={{ "borderTop": "1px solid lightgray"}}>
                                       <Form.Check
                                            name="lite-plan"
                                            type="radio"
                                            value="2"
                                            checked={plan.id === "2"}
                                            onChange={handleChange('id')}
                                       />
                                    </th>
                                    <th style={{ "borderTop": "1px solid lightgray"}}>
                                        <Form.Check
                                            name="standard-plan"
                                            type="radio"
                                            value="3"
                                            checked={plan.id === "3"}
                                            onChange={handleChange('id')}
                                        />
                                    </th>
                                    <th style={{ "borderTop": "1px solid lightgray"}}>
                                        <Form.Check
                                            name="premium-plan"
                                            type="radio"
                                            value="4"
                                            checked={plan.id === "4"}
                                            onChange={handleChange('id')}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan={3} style={{ "textAlign": "left"}}>
                                        <div className="ms-4">サービスメニュー</div>
                                    </th>
                                    <th >
                                        Lite
                                    </th>
                                    <th style={{ "backgroundColor": "lightgreen"}}>
                                        <div style={{ "fontSize": ".7em"}}>おすすめ</div>
                                        <div>Standard</div>
                                    </th>
                                    <th >
                                        Premium
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td scope="row" style={{ "borderTopStyle": "hidden", "borderBottomStyle": "hidden", "borderLeftColor": "lightgray"}}/>
                                <td colSpan={2} >よろづ相談</td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                                <td style={{ "backgroundColor": "lightgreen"}}><FontAwesomeIcon icon={ faCircle } /></td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                            </tr>
                            <tr>
                                <td scope="row" style={{ "borderTopStyle": "hidden", "borderBottomStyle": "hidden"}}/>
                                <td colSpan={2} style={{ "verticalAlign": "middle"}}>文書テンプレート</td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                                <td style={{ "backgroundColor": "lightgreen"}}><FontAwesomeIcon icon={ faCircle } /></td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                            </tr>
                            {
                                /*
                            <tr >
                                <td style={{ "borderTopStyle": "hidden", "borderBottomStyle": "hidden"}}/>
                                <td>テレワーク、CSIRT、クラウド、その他</td>
                                <td>準備中</td>
                                <td style={{ "backgroundColor": "lightgreen"}}>準備中</td>
                                <td>準備中</td>
                            </tr>
                            */
                            }
                            <tr>
                                <td />
                                <td colSpan={2}>Eラーニング（管理者、履歴管理機能付）</td>
                                <td>ー</td>
                                <td style={{ "backgroundColor": "lightgreen"}}><FontAwesomeIcon icon={ faCircle } /></td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                            </tr>
                            <tr>
                                <td style={{ "borderTopStyle": "hidden", "borderBottomStyle": "hidden"}}/>
                                <td colSpan={2}>合同メール訓練</td>
                                <td>ー</td>
                                <td style={{ "backgroundColor": "lightgreen"}}><FontAwesomeIcon icon={ faCircle } /></td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                            </tr>
                            <tr>
                                <td />
                                <td colSpan={2} style={{ "verticalAlign": "middle"}}>セキュリティ健康診断</td>
                                <td style={{ "verticalAlign": "middle"}}>ー</td>
                                <td style={{ "backgroundColor": "lightgreen", "verticalAlign": "middle"}}>ー</td>
                                <td style={{ "verticalAlign": "middle"}}><div><FontAwesomeIcon icon={ faCircle } /></div></td>
                            </tr>
                            <tr>
                                <td style={{ "borderTopStyle": "hidden", "borderBottomStyle": "hidden"}}/>
                                <td colSpan={2}>個別カウンセリング</td>
                                <td>ー</td>
                                <td style={{ "backgroundColor": "lightgreen"}}>ー</td>
                                <td>2回（60分）/年間</td>
                            </tr>
                            <tr style={{ "borderBottom": "2px solid black"}}>
                                <td />
                                <td colSpan={2} style={{ "verticalAlign": "middle"}}>サイバーリスク保険（200万円補償）</td>
                                <td><div><FontAwesomeIcon icon={ faCircle } /></div></td>
                                <td style={{ "backgroundColor": "lightgreen"}}><div><FontAwesomeIcon icon={ faCircle } /></div></td>
                                <td><div><FontAwesomeIcon icon={ faCircle } /></div></td>
                            </tr>
                            <tr style={{ "fontWeight": "bold"}}>
                                <td colSpan={3}>
                                    <div className="ms-4">料金プラン</div>
                                </td>
                                <td>
                                    Lite
                                </td>
                                <td style={{ "backgroundColor": "lightgreen"}}>
                                    Standard
                                </td>
                                <td>
                                    Premium
                                </td>
                            </tr>
                            <tr>
                                <td style={{ "borderTopStyle": "hidden", "borderBottomStyle": "hidden"}}/>
                                <td rowSpan={2} style={{ "verticalAlign": "middle"}}>
                                    <div>契約金額</div>
                                    <div>（税別）</div>
                                </td>
                                <td>
                                    月額
                                </td>
                                <td>
                                    15,000円
                                </td>
                                <td style={{ "backgroundColor": "lightgreen"}}>
                                    30,000円
                                </td>
                                <td>
                                    50,000円
                                </td>
                            </tr>
                            <tr>
                                <td />
                                <td>
                                    年額
                                </td>
                                <td>
                                    180,000円
                                </td>
                                <td style={{ "backgroundColor": "lightgreen"}}>
                                    360,000円
                                </td>
                                <td>
                                    600,000円
                                </td>
                            </tr>
                            <tr>
                                <td style={{ "borderTopStyle": "hidden" }}/>
                                <td colSpan={2}>
                                    対象企業規模（原則300名まで）
                                </td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                                <td style={{ "backgroundColor": "lightgreen"}}><FontAwesomeIcon icon={ faCircle } /></td>
                                <td><FontAwesomeIcon icon={ faCircle } /></td>
                            </tr>

                        </tbody>
                        </Table>
                        <Row className="g-0">
                            <Col xs={12}>
                                <Form.Group>
                                    <Row className="g-0">
                                        <Col xs={1}>
                                            <Form.Check
                                                name="monthly-pay"
                                                type="radio"
                                                value="0"
                                                checked={plan.recurringType === "0"}
                                                onChange={handleChange('recurringType')}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                {`月額でお払い：${planName[plan.id]}　 ${planCostMonthly[plan.id]}/月（税抜）`}
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                   
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col xs={12}>
                                <Form.Group>
                                    <Row className="g-0">
                                        <Col xs={1}>
                                            <Form.Check
                                                name="yearly-pay"
                                                type="radio"
                                                value="1"
                                                checked={plan.recurringType === "1"}
                                                onChange={handleChange('recurringType')}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                {`年額でお払い：${planName[plan.id]}　 ${planCostYearly[plan.id]}/年（税抜）`}
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                   
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row className="g-0">
                            <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                                {status === "PAYMENTPENDING" ? null : <Button variant="outline-primary" style={{ "width": "106px", "alignSelf": "end"}} onClick={() => setConfirm(false)}>戻る</Button> }
                            </Col>
                            <Col style={{ "display": "flex", "flexDirection": "column"}}>
                                <Button style={{ "width": "106px", "alignSelf": "end"}} onClick={goToPayment}>確定</Button>
                            </Col>
                        </Row>
                    </Form>
                </Stack>
    )
}