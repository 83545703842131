import React from "react";
import {
    Row,
    Col,
    Container,
    Stack,
    Accordion,
    Card,
    Button,
} from "react-bootstrap";

export default function Faq(props) {
    return (
        <Row className="g-0 ChatService">
            {/* <h2 className="content_title">
                <div>よくある質問</div>
            </h2> */}
            <section>
            <h2 className="content_title">
                <div>よくある質問</div>
            </h2>
                <div className="sub_title">インシデント対策について</div>

                <Accordion>
                    <Accordion.Item eventKey="0" className="my-2">
                        <Accordion.Header>
                            インシデント対応計画で重要な要素とは何ですか？
                        </Accordion.Header>
                        <Accordion.Body>
                            想定されるシナリオ、優先すべき事項と体制や役割を予め設定しておく事が重要です。
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                        eventKey="1"
                        className="my-2"
                        style={{ borderTop: "1px solid lightgray" }}
                    >
                        <Accordion.Header>
                            インシデント対応プロセスに経営層をどうやって巻き込めばよいですか？
                        </Accordion.Header>
                        <Accordion.Body>
                            インシデント発生時の経営リスク（風評被害や金銭的損失）について経営層に理解して頂く事が必要です。
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                        eventKey="2"
                        className="my-2"
                        style={{ borderTop: "1px solid lightgray" }}
                    >
                        <Accordion.Header>
                            組織がインシデント対応でやってしまいがちな間違いとは何ですか？
                        </Accordion.Header>
                        <Accordion.Body>
                            お互い自身が「担当ではない」と誤認し、主体的な対応者がおらず対応が後手になってしまう事があります。
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br />
                <br />
            </section>
        </Row>
    );
}
