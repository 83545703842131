import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";
import { axiosPrivate } from "../../utils/axiosPrivate";
import AlertModal from "../elements/AlertModal";
import { useNavigate } from "react-router";

export default function SecurityHealthCheck(props) {
    const [registeredDate, setRegisteredDate] = useState("");
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [reports, setReports] = useState([]);
    const [show, setShow] = useState(false);

    const {
        register,
        getValues,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const handleCheckShow = (prop) => setShow(prop);

    const checkSubmit = () => {
        setShow(true);
    };

    const onSubmit = () => {
        setShow(false);
        var data = getValues();
        var body = {
            domain: data.domain,
        };

        axiosPrivate
            .post("/api/seccheck", body)
            .then(function (response) {
                //console.log(response)
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.message,
                    });
                    setValue("domain", "");
                    axiosPrivate
                        .get("/api/seccheck")
                        .then(function (response) {
                            if (
                                response.status === 200 &&
                                response.data.apiCode === 0
                            ) {
                                if (response.data.data !== null) {
                                    setRegisteredDate(response.data.data);
                                }
                            }
                        })
                        .catch(function (response) {
                            //console.log(response)
                        });
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "ドメインの登録が失敗しました。",
                    });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "ドメインの登録が失敗しました。",
                });
            });
    };

    const styles = {
        button: {
            background: registeredDate.length > 0 ? "#cccccc" : "#4d7aba",
        },
    };

    useEffect(() => {
        axiosPrivate
            .get("/api/seccheck")
            .then(function (response) {
                if (response.status === 200 && response.data.apiCode === 0) {
                    if (response.data.data !== null) {
                        setRegisteredDate(response.data.data.registered_date);
                        setValue("domain", response.data.data.domain);
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
            });

        axiosPrivate.get("/api/seccheck/reports").then(function (response) {
            if (response.status === 200 && response.data.apiCode === 0) {
                if (response.data.data.length > 0) {
                    var reports = response.data.data;
                    var sortedReports = reports.sort(function (a, b) {
                        return (
                            new Date(b.report_date) - new Date(a.report_date)
                        );
                    });
                    setReports(sortedReports);
                }
            }
        });
    }, []);

    return (
        <Row className="g-0 SecurityHealthCheck">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Modal show={show} onHide={() => handleCheckShow(false)} centered>
                <Modal.Body
                    className="mt-2"
                    style={{ textAlign: "center", whiteSpace: "pre" }}
                >{`セキュリティ健康診断を行うドメインの申し込みを行います。\nよろしいですか？`}</Modal.Body>
                <Row className="g-0">
                    <Col className="p-4">
                        <Button onClick={() => onSubmit()}>はい</Button>
                    </Col>
                    <Col className="p-4">
                        <Button onClick={() => handleCheckShow(false)}>
                            いいえ
                        </Button>
                    </Col>
                </Row>
            </Modal>

            <div className="under_title g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　セキュリティ健康診断</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <div className="doc_lede">
                            <br />
                            <br />
                            <div>
                                指定して頂いたドメインに対して、攻撃者視点で外部からどのように見えているかを把握することが可能です。
                                <br />
                                継続的にモニタリングし、課題に対して対処を行う事で「攻撃されにくい環境を維持し続ける」ことを可能にします。
                            </div>
                        </div>
                    </section>

                    <section>
                        <ol className="domain_flow">
                            <li>
                                <h2 className="sub_title">
                                    1. ドメインを指定ください。
                                </h2>
                                <p>
                                    一度ドメインを指定するとご契約期間満了まで変更できませんのでご注意ください。
                                </p>

                                <Form
                                    onSubmit={handleSubmit(checkSubmit)}
                                    className="my-4"
                                >
                                    <Form.Group className="my-4">
                                        <Form.Control
                                            disabled={registeredDate.length > 0}
                                            placeholder="例：gsx.co.jp"
                                            className="input-domain-area input-inline"
                                            type="text"
                                            {...register("domain", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "ドメインを入力してください。",
                                                },
                                            })}
                                        />
                                        {errors.domain && (
                                            <ValidationError
                                                message={errors.domain.message}
                                            />
                                        )}
                                        <Form.Label className="show-domain-info-area">
                                            {registeredDate.length > 0
                                                ? `　受付日時: ${registeredDate}`
                                                : ""}
                                        </Form.Label>
                                    </Form.Group>

                                    <h2 className="content_title">
                                        <div>前回ドメイン申し込みの詳細</div>
                                    </h2>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    申し込み日時
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {registeredDate}
                                                </th>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <br />
                                    <div className="g-0 row">
                                        <div className="template-customize-area">
                                            <span>
                                                <Button
                                                    className="btn"
                                                    type="submit"
                                                    style={styles.button}
                                                    disabled={
                                                        registeredDate.length >
                                                        0
                                                    }
                                                >
                                                    このドメインで申し込み
                                                </Button>
                                                <span
                                                // style={{
                                                //     fontSize: ".8rem",
                                                // }}
                                                >
                                                    {/* {registeredDate.length > 0
                                                        ? `受付日時: ${registeredDate}`
                                                        : ""} */}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </li>
                            <br />
                            <br />
                            <h2 className="sub_title">
                                2.
                                数営業日でレポートが登録されますので、以下レポート履歴よりダウンロードください。以後、約6か月に1回レポートが追加されます。
                            </h2>

                            <h2 className="content_title">
                                <div>レポート履歴</div>
                            </h2>

                            <div
                                style={{
                                    height: "250px",
                                    overflowY: "auto",
                                }}
                            >
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                No
                                            </th>
                                            <th
                                                style={{
                                                    textAlign: "left",
                                                }}
                                            >
                                                レポート日時
                                            </th>
                                            <th
                                                style={{
                                                    textAlign: "left",
                                                }}
                                            >
                                                レポートダウンロード
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        style={{
                                            overflowY: "auto",
                                        }}
                                    >
                                        {reports.length > 0 ? (
                                            reports.map((r, index) => {
                                                return (
                                                    <tr
                                                        key={`${index}_${reports[0]}`}
                                                    >
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >{`${index + 1}`}</th>
                                                        <td className="td-1">{`${
                                                            r.report_date
                                                                .replaceAll(
                                                                    "-",
                                                                    "/"
                                                                )
                                                                .split("T")[0]
                                                        }`}</td>
                                                        <td className="td-2">
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    window.open(
                                                                        r.url
                                                                    )
                                                                }
                                                            >
                                                                ダウンロード
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                    colSpan={3}
                                                >
                                                    現在、表示する料金明細はありません。
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>

                            <li>
                                <h2 className="sub_title">
                                    3.
                                    レポートの内容にご質問がある場合は以下ボタンよりお問合せください。
                                </h2>
                                <div className="template-customize-area">
                                    <button
                                        type="button"
                                        disabled=""
                                        className="btn"
                                        onClick={() =>
                                            navigate("/securitycheck/inquiry")
                                        }
                                    >
                                        結果のお問い合わせ
                                    </button>
                                </div>
                            </li>
                        </ol>
                    </section>
                    <br />
                    <br />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Row>
    );
}
