import React, { useState, useEffect } from "react";
import { Row, Col, Toast, ToastContainer } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import EmailTrainingEntry from "../emailtraining/EmailTrainingEntry";
import EmailTrainingCheck from "../emailtraining/EmailTrainingCheck";
import EmailTrainingSent from "../emailtraining/EmailTrainingSent";

import { axiosPrivate } from "../../utils/axiosPrivate";
import * as XLSX from "xlsx";
import AlertModal from "../elements/AlertModal";

export default function EmailTrainingForm(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const [toast, setToast] = useState({
        show: false,
        header: "",
        message: "",
        variant: "success",
    });

    const [selected, setSelected] = useState({
        trainingEvent: {},
        finished: false,
        started: false,
    });

    const [parsed, setParsed] = useState(false);

    const [file, setFile] = useState(null);

    const [check, setCheck] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [emails, setEmails] = useState([]);

    const navigate = useNavigate();

    const { state } = useLocation();

    const onFileChange = (file) => {
        // var path = (window.URL || window.webkitURL).createObjectURL(file);
        console.dir("file : ", file);
        setFile(file);
        parseExcel(file);
    };

    function parseExcel(file) {
        const reader = new FileReader();
        reader.onload = (evt) => {
            try {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const sheetName = wb.SheetNames[0];
                const ws = wb.Sheets[sheetName];
                var range = XLSX.utils.decode_range(ws["!ref"]);
                range.s.c = 1;
                range.s.r = 23;
                range.e.c = 2;
                range.e.r = 323;
                var new_range = XLSX.utils.encode_range(range);
                const json = XLSX.utils.sheet_to_json(ws, {
                    raw: true,
                    header: 1,
                    blankrows: false,
                    range: new_range,
                });
                //console.log(json);
                if (json.length > 300) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "件数が300以上になりました。",
                    });
                    setFile(null);
                    setParsed(false);
                } else if (json.length <= 0) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "ファイルを読み込まれませんでした。",
                    });
                    setFile(null);
                    setParsed(false);
                } else {
                    setEmails(json);
                    setParsed(true);
                }
            } catch (error) {
                //console.log(error)
                setAlert({
                    ...alert,
                    show: true,
                    message: "ファイルを読み込まれませんでした。",
                });
                setFile(null);
                setParsed(false);
            }
        };
        reader.readAsBinaryString(file);
    }

    const handleKakunin = () => {
        setCheck(true);
    };

    const handleKakutei = () => {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("docName", file.name);
        formData.append("emailNumber", emails.length);
        //formData.append("user_email", cookies.get("user"));
        formData.append("event_id", selected.trainingEvent.event_id);
        axiosPrivate
            .post("/email/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(function (response) {
                if (response.status === 200) {
                    setToast({
                        show: true,
                        header: "ファイルアップロード",
                        message: "ファイルがアップロードされました。",
                        variant: "success",
                    });
                    // navigate("/emailtraining");

                    setSubmitted(true);
                }
            })
            .catch(function (response) {
                console.error(response);
                setToast({
                    show: true,
                    header: "エラー発生",
                    message: response.message,
                    variant: "danger",
                });
            });
    };

    const handleReturn = () => {
        setFile(null);
        setCheck(false);
    };

    const toggleToast = () =>
        setToast({
            show: !toast.show,
            message: "",
            variant: "success",
            header: "",
        });

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    useEffect(() => {
        let isSubscribed = true;
        if (state) {
            if (isSubscribed) {
                setSelected({
                    ...selected,
                    trainingEvent: state.trainingEvent,
                    finished: state.finished,
                    started: state.started,
                });
            }
        }
        return () => (isSubscribed = false);
    }, [state]);

    console.log("check" + check)
    console.log("submitted" + submitted)

    return (
        <Row className="g-0 EmailTrainingForm">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            {/* <Col />
            <Col
                sm={12}
                md={10}
                lg={10}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            > */}
            {check && submitted ? (
                <Row className="g-0">
                    <EmailTrainingSent />
                </Row>
            ) : !check && !submitted ? (
                <EmailTrainingEntry
                    selected={selected}
                    file={file}
                    onFileChange={onFileChange}
                    parsed={parsed}
                    handleKakunin={handleKakunin}
                />
            ) : (
                
                    <EmailTrainingCheck
                        handleReturn={handleReturn}
                        emails={emails}
                        handleKakutei={handleKakutei}
                    />
                
            )}
            <ToastContainer position="bottom-center" className="p-5">
                <Toast
                    show={toast.show}
                    onClose={toggleToast}
                    bg={toast.variant}
                    delay={3000}
                    autohide
                >
                    <Toast.Body style={{ color: "white" }}>
                        {toast.message}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {/* </Col>
            <Col /> */}
        </Row>
    );
}
