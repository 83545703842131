import React, { useState } from "react";
import { Row, Form } from "react-bootstrap";

export default function LabelWithInput(props) {

    const [defaultValue, setDefaultValue] = useState();

    const handleObjChange = (prop) => (event) => {
        setDefaultValue(event.target.value);
        props.item.parentFunctionEdit({
            key: props.item.key,
            defaultValue: event.target.value,
        });
    };

    return (
        <Form.Group>
            <Row className="g-0">
                <Form.Label>{props.item.labelName}</Form.Label>
                <Form.Control
                    type="text"
                    className="mypage-margin"
                    defaultValue={props.item.defaultValue}
                    onChange={handleObjChange()}
                />
            </Row>
        </Form.Group>
    );
}
