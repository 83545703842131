import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Stack, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";
import AlertModal from "../elements/AlertModal";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";

function scaleCaptcha() {
    var containerWidth = $("#captcha-container").width();
    console.log(containerWidth);
    var captchaWidth = $("iframe[title='reCAPTCHA']").width();
    console.log(captchaWidth);
    if (captchaWidth > containerWidth) {
        var scale = containerWidth / captchaWidth;
        $("iframe[title='reCAPTCHA']").css({
            transform: "scale(" + scale + ")",
            "-webkit-transform": "scale(" + scale + ")",
            "transform-origin": "0 0",
            "-webkit-transform-origin": "0 0",
        });
    }
}

export default function Signup(props) {
    const [captchaLoad, setCaptchaLoad] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [formError, setFormError] = useState("");
    const [show, setShow] = useState(false);

    const cookies = new Cookies();
    const captchaRef = useRef(null);

    function validateEmail(email) {
        ////console.log(email)
        var validatedEmail = email.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        ////console.log(validatedEmail)
        var error =
            validatedEmail && email.length > 0
                ? ""
                : "メールフォーマットは無効です。";
        setFormError(error);
        return validatedEmail;
    }

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const handleSubmit = (event) => {
        event.preventDefault();
        //const form = event.currentTarget;

        const token = captchaRef.current.getValue();
        if (token.length > 0) {
            if (validateEmail(email)) {
                axios
					.post("https://api.vciso.jp/api/register", {
                        mail: email,
                        recaptchaResponse: token,
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            //  //console.log(response)
                            setSuccess(true);
                            sessionStorage.setItem("vciso_email", email);
                        }
                    })
                    .catch(function (response) {
                        setAlert({
                            ...alert,
                            show: true,
                            message: response.data.message,
                        });
                    });
            } else {
                setShow(true);
            }
        } else {
            setAlert({
                ...alert,
                show: true,
                message: "ロボットでない事の確認チェックを行ってください。",
            });
        }

        captchaRef.current.reset();
    };

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleResend = (event) => {
        event.preventDefault();
        axios
			.put("https://api.vciso.jp/api/register", {
                mail: email,
            })
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.message.includes("invalid")) {
                        setFormError(response.data.message);
                        setShow(true);
                    } else {
                        ////console.log(response)
                        setSuccess(true);
                        sessionStorage.setItem("vciso_email", email);
                        setAlert({
                            ...alert,
                            show: true,
                            message: "仮登録メールを再送しました。",
                        });
                    }
                }
            })
            .catch(function (response) {
                console.error(response);
            });
    };

    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Stack
                gap={4}
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                }}
                className="p-4 LoginForgot"
            >
                <h2>会員登録</h2>

                <Alert
                    show={show}
                    variant="danger"
                    onClose={() => setShow(false)}
                    dismissible
                    style={{ width: "80%", height: "50px" }}
                >
                    <p>{formError}</p>
                </Alert>
                <div className="shadow-lg p-4">
                    {success ? (
                        <Form className="form-area">
                            <Row className="g-0">
                                <Form.Label className="label-forgot-password">
                                    {email}に仮登録メールを送信いたしました。
                                </Form.Label>
                            </Row>
                            <br />
                            <Row className="g-0">
                                <Form.Label className="label-forgot-password">
                                    もし、ご連絡メールが届かない場合には迷惑メールフォルダのチェックをお願いいたします。{" "}
                                </Form.Label>
                            </Row>
                            <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-2 button-size"
                                            onClick={handleResend}
                                        >
                                            再送信する
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </Form>
                    ) : (
                        <Form
                            noValidate
                            validated={validated}
                            className="form-area"
                        >
                            <Form.Group className="edit-forgot-form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password">
                                        会員登録の為のURLを記載したメールを送付致します。​
                                    </Form.Label>
                                    <Form.Label className="label-forgot-password">
                                        ​尚、こちらのアドレスはお客様のログインアカウントと​なりますので、業務用のメールアドレスでご登録ください。​
                                    </Form.Label>
                                </Row>
                            </Form.Group>
                            <br />
                            <Form.Group className="edit-forgot-form-area">
                                <Row className="g-0">
                                    <Form.Label className="label-forgot-password required-lable">
                                        メールアドレス　
                                    </Form.Label>
                                </Row>
                                <Form.Control
                                    className="input-mail"
                                    type="email"
                                    id="signup-mail"
                                    placeholder="メールアドレスを入力してください"
                                    required
                                    value={email}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="edit-roboter-form-area">
                                <Row className="g-0">
                                    <div id="captcha-container">
                                        {window.innerWidth <= 1280 ? (
                                            <ReCAPTCHA
                                                sitekey="6LeObzUhAAAAAMVfgN4V0eFy_Cwm_jxKV_7GdMug"
                                                ref={captchaRef}
                                                size="compact"
                                            />
                                        ) : (
                                            <ReCAPTCHA
                                                sitekey="6LeObzUhAAAAAMVfgN4V0eFy_Cwm_jxKV_7GdMug"
                                                ref={captchaRef}
                                            />
                                        )}
                                    </div>
                                </Row>
                            </Form.Group>
                            <Form.Group className="edit-phone-form-area">
                                <Row className="g-0">
                                    <div className="button-area">
                                        <Button
                                            className="rounded-pill btn-blue-2 button-size"
                                            onClick={handleSubmit}
                                        >
                                            仮登録する
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </Form>
                    )}
                </div>
            </Stack>
        </Row>
    );
}
