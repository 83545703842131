import axios from 'axios';
import fileDownload from 'js-file-download';
import { axiosPrivate } from './axiosPrivate';


export default function downloadFile(bucket, filename) {
 
    let filepath = `/${bucket}/download/${filename}`;

    var test = axiosPrivate.get(`${filepath}`, {
        responseType: 'blob',
    })
    .then(res => {
        let filename = filepath.replace(/^.*[\\\/]/, '')
        fileDownload(res.data, `${filename}`);
    })
    .catch(function(response) {
        console.log(response)
        return response;
    })

    return test;
}