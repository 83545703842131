import React from 'react';
import { Button } from 'react-bootstrap';
import {ReactComponent as Login} from "../../../assets/svg_icons/icon-login.svg";

export default function LoginButton(props) {
    return (
        <div className="login-button navbar">
          <a onClick={() => {props.navFunc("login");}} style={{ "color": "#4383E1", "fontSize": "10px", "display": "flex", "flexDirection":"column", "justifyContent": "center"}}>
            <div style={{ "textAlign": "center" }}><Login /></div><span>ログイン</span>
          </a>
        </div>
    )
}