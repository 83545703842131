import React, { useState } from 'react';
import {
    Row, Col, Stack, Form, Button
} from 'react-bootstrap';

export default function AdminRegisterCheck(props) {

    const { values, handleSubmit, handleClick } = props;

    return (
        <Stack gap={5} style={{ "width": "100%", "height": "100%", "display": "flex", "alignItems": "center", "alignSelf": "center"}} className="p-4">
            <Form style={{"borderRadius": ".5rem", "display": "flex", "flexDirection": "column", "width": "90%" }} className="p-4 shadow-lg">
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                メール（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={7} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.email}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                会社名（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={7} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.company}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                部署名
                            </Form.Label>
                        </Col>
                        <Col xs={7} className="px-4">
                            <Form.Control plaintext readOnly className="mb-3" type="text"  value={values.dept} />
                        </Col>
                        
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                法人代表者姓（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control plaintext readOnly className="mb-3" type="text" value={values.lastName}/>
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                法人代表者名（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.firstName}/>
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                法人代表者セイ（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.lastNameKana}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                法人代表者メイ（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.firstNameKana}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                郵便番号
                            </Form.Label>
                        </Col>
                        <Col xs={4} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.postcode} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                都道府県（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control type="text" className="mb-3" plaintext readOnly value={values.state} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>    
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                市区町村（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.city}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                番地等（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.address}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                マンション・建物名
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.buldingName}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                法人代表電話番号
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control className="mb-3" type="text" plaintext readOnly value={values.contactInfo}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                業種
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control type="text" plaintext readOnly className="mb-3" value={props.objValues.companyTypeId.keyValueJp} />
                                
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                企業規模
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <div className="mb-3">
                            <Form.Control type="text" plaintext readOnly className="mb-3" value={props.objValues.companySizeId.keyValueJp} />
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                メールマガジン購読
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Check
                                readOnly
                                checked={props.subsChecked}
                                className="mb-3"
                                type="checkbox"
                                id="magazine-check"
                                name="magazine-check"
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                アンケート
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control type="text" plaintext readOnly value={props.objValues.referrerId.keyValueJp} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                プラン
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control type="text" plaintext readOnly value={values.planId} />
                        </Col>
                    </Row>
                </Form.Group>
                <hr />
                <div style={{ "alignSelf": "center"}}>ログイン用パスワード設定</div>
                <br />
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                パスワード（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control className="mb-3" type="password" value={values.password} readOnly />
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                パスワード確認用（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control readOnly className="mb-3" type="password" value={values.confirmPass} />
                        </Col>
                    </Row>
                </Form.Group>
                <Row className="g-0" style={{ "justifyContent": "center"}}>
                    <Button size="sm" style={{ "alignSelf" : "center", "width": "40%"}} className="me-4" onClick={handleClick}>再入力をする</Button>
                    <Button size="sm" style={{ "alignSelf" : "center", "width": "40%"}} onClick={handleSubmit}>登録をする</Button>
                </Row> 
            </Form>
        </Stack>
    )
}