import React, { useEffect, useState } from 'react';
import {
    Stack, Row, Col, Form, Button
} from 'react-bootstrap';
import {
    Prompt
} from "react-router-dom"
import axios from 'axios';
import Cookies from 'universal-cookie';
import ValidationError from '../ValidationError';
import { useForm } from "react-hook-form";
import { axiosPrivate } from '../../../utils/axiosPrivate';
import AlertModal from '../AlertModal';
import { Alert } from 'bootstrap';

export default function SubscriberSubEdit(props) {

    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })
    const [block, setBlock] = useState(false)
    const [toggle, setToggle] = useState(false)

    const [validated, setValidated] = useState(false);



    const [values, setValues] = useState({
        userId:"0",
        firstName:"",
        lastName:"",   
        firstNameKana:"",
        lastNameKana:"",
        company:"",
        dept:"",
        password:"", 
        companyTypeId: "",
        newsletterSubs: true,
        companySizeId:{},
        referrerId: {},
        postcode:"",
        state:"",
        city:"",
        address:"",
        buldingName:"",
        contactInfo:"",
        paymentInfo: {
            company:"",
            dept: "",
            firstName: "",
            lastName: "",
            firstNameKana: "",
            lastNameKana: "",
            postcode:"",
            state:"",
            city:"",
            address:"",
            buldingName:"",
            contactInfo:""
        }
    })

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            userId:"0",
            email: "",
            firstName:"",
            lastName:"",   
            firstNameKana:"",
            lastNameKana:"",
            corpName:"",
        }
    });



    const cookies = new Cookies();


    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const blockChange = (event) => {
        setBlock(true)
    }

    const onSubmit = (data) => {
        ////console.log(getValues())
        ////console.log(values)

        var formValues = getValues();
        
        const body = {
            "firstName": formValues.firstName,
            "lastName": formValues.lastName,
            "firstNameKana": formValues.firstNameKana,
            "lastNameKana": formValues.lastNameKana    
        }

        ////console.log(body)

        axiosPrivate.put("/api/user", body)
        .then(function(response) {
           // //console.log(response)
            setAlert({ ...alert, show: true, message: "会員情報を編集しました。" })
            axiosPrivate.get("/api/user/1")
            .then(function(response) {
                if (response.status === 200 && response.data.apiCode === 0) {
                    ////console.log(response)
                    var data = response.data.data
                    setValue("firstName", data.firstName)
                    setValue("lastName", data.lastName)   
                    setValue("firstNameKana", data.firstNameKana)
                    setValue("lastNameKana", data.lastNameKana)              
                    setBlock(false)
                }
            })
            .catch(function(response) {
                console.error(response)
            })
            
        })
        .catch(function(response) {
            console.error(response)
        })
    }

    const { subVals, corpName, editSubmit  } = props;

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setValue("email", subVals.email)
            setValue("firstName", subVals.firstName)
            setValue("lastName", subVals.lastName)   
            setValue("firstNameKana", subVals.firstNameKana)
            setValue("lastNameKana", subVals.lastNameKana)      
            setValue("corpName", corpName)
            setToggle(!toggle)
        }
        return () => isSubscribed = false;
    }, [subVals, corpName])

    return (
        <Stack gap={3} style={{ "width": "100%", "height": "100%", "display": "flex", "alignItems": "center", "alignSelf": "center"}} className="py-4">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Prompt
                when={block}
                message="更新内容が保存されていませんが、移動しますか。"
            />
            <Form onSubmit={handleSubmit(editSubmit)} noValidate validated={validated} style={{"borderRadius": ".5rem", "display": "flex", "flexDirection": "column", "width": "100%" }} className="p-4">
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={2}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                会社名
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                        <Form.Control 
                            className="mb-3" 
                            type="text" 
                            value={getValues("corpName")}
                            readOnly
                            plaintext
                        />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={2}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                メールアドレス
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                        <Form.Control 
                            className="mb-3" 
                            type="text" 
                            value={getValues("email")}
                            readOnly
                            plaintext
                        />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={2}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                姓（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text"
                                {
                                    ...register("lastName", {
                                        required: { value: true, message: "姓を入力してください。"},
                                        onChange: blockChange
                                    })
                                }
                                
                            />
                            {errors.lastName && <ValidationError message={errors.lastName.message}/>} 
                        </Col>
                        <Col xs={2}>
                            <div style={{ "lineHeight": "38px", "fontSize": ".8rem"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row className="g-0">
                        <Col xs={2}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                名（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                {
                                    ...register("firstName", {
                                        required: { value: true, message: "名を入力してください。"},
                                        onChange: blockChange
                                    })
                                } 
                            />
                            {errors.firstName && <ValidationError message={errors.firstName.message}/>} 
                        </Col>
                        <Col xs={2}>
                            <div style={{ "lineHeight": "38px", "fontSize": ".8rem"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>


                <Form.Group>
                    <Row className="g-0">
                        <Col xs={2}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                            セイ（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                {
                                    ...register("lastNameKana", {
                                        required: { value: true, message: "セイを入力してください。"},
                                        onChange: blockChange
                                    })
                                } 
                            />
                            {errors.lastNameKana && <ValidationError message={errors.lastNameKana.message}/>} 
                        </Col>
                        <Col xs={2}>
                            <div style={{ "lineHeight": "38px", "fontSize": ".8rem"}}>（全角カナ）</div>
                        </Col>
                    </Row>
                </Form.Group>
        
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={2}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                            メイ（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text"
                                {
                                    ...register("firstNameKana", {
                                        required: { value: true, message: "メイを入力してください。"},
                                        onChange: blockChange
                                    })
                                } 
                            />
                            {errors.firstNameKana && <ValidationError message={errors.firstNameKana.message}/>}  
                        </Col>
                        <Col xs={2}>
                            <div style={{ "lineHeight": "38px","fontSize": ".8rem"}}>（全角カナ）</div>
                        </Col>
                    </Row>
                </Form.Group>
           
              
                <br />
                <Button  style={{ "alignSelf" : "center", "width": "fit-content"}} type="submit">会員情報を更新する</Button>
            </Form>
        </Stack>
    )
}