import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Stack,
    Form,
    Container,
    Button,
    Tabs,
    Tab,
} from "react-bootstrap";
import TemplateTabContent from "../elements/doctemplates/TemplateTabContent";
import Document from "../elements/doctemplates/Document";
import { useIsOverflow } from "../../utils/useIsOverflow";
import { useNavigate } from "react-router-dom";
import "../../stylesheets/DocTemplates.css";
import downloadFile from "../../utils/downloadFile";
import { axiosPrivate } from "../../utils/axiosPrivate";
import AlertModal from '../elements/AlertModal';

export default function DocTemplates(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [templates, setTemplates] = useState([]);

    const navigate = useNavigate();
    const ref = React.useRef();
    const isOverflow = useIsOverflow(ref);

    let teleworkDocs = [
        <Document
            docTitle="テレワーク規程/テレワーク申請書/指名書（様式）"
            description="本規定は、人事に関する規定の1つとしつつ、情報セキュリティも考慮したテレワーク規定となります。"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="テレワークセキュリティガイドライン（管理者用）"
            description="本ガイドラインでは、管理者向けに安全なテレワークを実施する為の対策事項がまとめられています。"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="テレワークセキュリティガイドライン（利用者用）"
            description="本ガイドラインでは、テレワークを実施するにあたり利用者が注意すべき事項がまとめられています。"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
    ];

    let smartDeviceDocs = [
        <Document
            docTitle="スマートデバイス利用誓約書/申請書​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="スマートデバイス利用変更申請書/解除申請書​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="業務利用向けスマートデバイス管理要件​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
    ];

    let cloudServiceDocs = [
        <Document
            docTitle="クラウドサービス利用規程​​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="クラウドサービス利用ガイドライン​​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="クラウドサービス業務利用登録申請書​​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="クラウドサービスチェックリスト/ユーザ登録申請書​​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="クラウドサービス管理リスト​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="委託先管理チェックリスト（様式）​​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
    ];

    let secPolicyDocs = [
        <Document
            docTitle="情報セキュリティ管理基準​"
            description="情報セキュリティの具体的な管理策をまとめた、情報セキュリティ管理基準が整備可能です。"
            bucket="template"
            filename="情報セキュリティ管理基準.docx"
        />,
        <Document
            docTitle="情報セキュリティ内部監査規程​​​"
            description="情報セキュリティに関わる内部監査実施の際の規定が整備可能です。"
            bucket="template"
            filename="情報セキュリティ内部監査規程.docx"
        />,
        <Document
            docTitle="情報セキュリティインシデント対応規程​"
            description="情報セキュリティのインシデント事件事故管理規定が整備可能です。"
            bucket="template"
            filename="情報セキュリティインシデント対応規程.docx"
        />,
    ];

    let csirtDocs = [
        <Document
            docTitle="CSIRT管理規程"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="CSIRTの体制（例）"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
        <Document
            docTitle="インシデント対応基本フロー​​"
            description="説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト説明ダミーテキスト"
            bucket="template"
            filename="文書テンプレートサンプル.docx"
        />,
    ];

    const handleDownload = (doc) => {
        downloadFile("template", doc)
        .then(function(response) {
            if (response !== null && response !== undefined) {
                if (response.response.status === 403) {
                    setAlert({ ...alert, show: true, message: "ダウンロードが失敗しました。"})
                }
            }
        })
        
    }

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate.get("/api/document/templates")
        .then(function(response) {
            console.log(response)
            if (isSubscribed) {
                var secTemplates = response.data.data.filter(x => x.template_type === "セキュリティポリシー")
                setTemplates(secTemplates);
                console.log(secTemplates)
            }
        })
        .catch(function(response) {
            console.log(response)
        })

        return () => isSubscribed = false;
    }, [])

    //console.log(isOverflow);

    return (
        <Row className="g-0 DocTemplate" ref={ref}>
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　文書テンプレート</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <div className="doc_lede">
                            <br />
                            <br />
                            <div>
                                セキュリティ対策は、これから。というお客様にとって、優先度高く着手しなければならない事のひとつに「ルール整備」「ポリシー整備」があります。
                                企業におけるセキュリティ体制構築の為の第一歩と言っても過言では無い、「セキュリティポリシーの整備」。その基本方針や管理基準、規程類をGSXの長年のコンサルティングノウハウを活かして、中小企業様向けにの文書ひな形（テンプレート）を準備致しました。
                            </div>
                            <br />
                            <div style={{ color: "#EF3851" }}>
                                ※ご注意：本テンプレートは社内でのご利用のみとなり、社外への共有や改変しての外販は禁止行為となりますのでご注意ください。
                            </div>
                        </div>
                    </section>
                    <h2 className="content_title">
                        <div>セキュリティポリシー</div>
                    </h2>
                    <section>
                        <div className="download-area g-0 row">
                            {
                                templates.length > 0 ? 
                                templates.map(x => {
                                    return (
                                        <div className="col-md-4 col-0">
                                            <div className="dl_list">
                                                <h3>{x.document_name.split(".")[0]}</h3>
                                                <div>
                                                    <p>
                                                        {x.description}
                                                    </p>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary btn-sm"
                                                        onClick={() => 
                                                            handleDownload(x.document_name)
                                                        }
                                                    >
                                                        ダウンロード
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                            {/*
                            <div className="col-md-4 col-0">
                                <div className="dl_list">
                                  
                                    <h3>情報セキュリティ管理基準</h3>
                                    <div>
                                        <p>
                                            情報セキュリティの具体的な管理策をまとめた、情報セキュリティ管理基準が整備可能です。
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() =>
                                                downloadFile(
                                                    "template",
                                                    "情報セキュリティ管理基準.docx"
                                                )
                                            }
                                        >
                                            ダウンロード
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-0">
                                <div className="dl_list">
                                    <h3>情報セキュリティ内部監査規程</h3>
                                    <div>
                                        <p>
                                            情報セキュリティに関わる内部監査実施の際の規定が整備可能です。
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() =>
                                                downloadFile(
                                                    "template",
                                                    "情報セキュリティ内部監査規程.docx"
                                                )
                                            }
                                        >
                                            ダウンロード
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-0">
                                <div className="dl_list">
                                    <h3>情報セキュリティ管理基準</h3>
                                    <div>
                                        <p>
                                            情報セキュリティのインシデント事件事故管理規定が整備可能です。
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() =>
                                                downloadFile(
                                                    "template",
                                                    "情報セキュリティインシデント対応規程.docx"
                                                )
                                            }
                                        >
                                            ダウンロード
                                        </button>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                                        
                        <div className="template-customize-area">
                            <div className="sub_title">文書のカスタマイズ</div>
                            <p>
                                文書テンプレートに対するカスタマイズをご要望の場合は有償にて対応後可能ですので、本ページの文書カスタマイズボタンよりお申込みください。
                            </p>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() =>
                                    navigate("/doctemplates/customize")
                                }
                            >
                                文書カスタマイズ（有償）はこちらから
                            </button>
                        </div>
                        <br />
                        <br />
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Row>
    );
}
