import React from "react";
import { Form, Button, Col, Row, Stack } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";
import ValidationError from "../ValidationError";

export default function IncidentEntry(props) {
    const {
        incident,
        handleChange,
        checkIncident,
        handleTime,
        time,
        user,
        register,
        handleSubmit,
        errors,
    } = props;

    return (
        <Stack>
            <br />
            <br />
            <Form onSubmit={handleSubmit(checkIncident)}>
                <section>
                    {!user ? (
                        <>
                            <Form.Group className="my-3">
                                <Form.Label>会社名　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("company", {
                                        required: {
                                            value: true,
                                            message:
                                                "会社名を入力してください。",
                                        },
                                    })}
                                />
                                {errors.company && (
                                    <ValidationError
                                        message={errors.company.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>部署名　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("dept")}
                                />
                                {errors.dept && (
                                    <ValidationError
                                        message={errors.dept.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>住所　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("address", {
                                        required: {
                                            value: true,
                                            message: "住所を入力してください。",
                                        },
                                    })}
                                />
                                {errors.address && (
                                    <ValidationError
                                        message={errors.address.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>名前　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message:
                                                "お名前を入力してください。",
                                        },
                                    })}
                                />
                                {errors.name && (
                                    <ValidationError
                                        message={errors.name.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>メールアドレス　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message:
                                                "メールアドレスを入力してください。",
                                        },
                                        pattern: {
                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message:
                                                "メールフォーマットは無効です。",
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <ValidationError
                                        message={errors.email.message}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>電話番号　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("phoneNumber", {
                                        required: {
                                            value: true,
                                            message:
                                                "電話番号を入力してください。",
                                        },
                                    })}
                                />
                                {errors.phoneNumber && (
                                    <ValidationError
                                        message={errors.phoneNumber.message}
                                    />
                                )}
                            </Form.Group>
                        </>
                    ) : null}
                    <Form.Group className="form_input_area">
                        <Form.Label>インシデントの種類を選択　</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Select
                            className="form-select"
                            {...register("type", {
                                required: {
                                    value: true,
                                    message:
                                        "インシデント種類を選択してください。",
                                },
                                validate: (value) =>
                                    value !== "未選択" ||
                                    "インシデント種類を選択してください。",
                            })}
                        >
                            <option key="defaultChoice" hidden>
                                未選択
                            </option>
                            <option>不正アクセス</option>
                            <option>迷惑メール</option>
                            <option>DoS・DDoS攻撃</option>
                            <option>マルウェア感染</option>
                            <option>情報漏えい</option>
                            <option>情報改ざん</option>
                            <option>記憶媒体の紛失や盗難</option>
                            <option>
                                落雷や地震などによるセキュリティ設備故障
                            </option>
                            <option>その他</option>
                        </Form.Select>
                        {errors.type && (
                            <ValidationError message={errors.type.message} />
                        )}
                    </Form.Group>

                    <Form.Group className="my-3">
                        <Form.Label>
                            インシデントが起きた日時を入力　
                        </Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <div style={{ display: "flex" }}>
                            <div className="input-date">
                                <Form.Control
                                    className="contract-date-input"
                                    type="date"
                                    {...register("occurred_at", {
                                        required: {
                                            value: true,
                                            message: "日付を選択してください",
                                        },
                                    })}
                                />
                                {errors.occurred_at && (
                                    <ValidationError
                                        message={errors.occurred_at.message}
                                    />
                                )}
                            </div>
                            <div className="input-time">
                                <Row className="g-0">
                                    <TimePicker
                                        className="contract-time-input"
                                        start="00:00"
                                        end="23:59"
                                        locale={"ja-jp"}
                                        format={24}
                                        step={30}
                                        onChange={handleTime}
                                        value={time}
                                    />
                                </Row>
                            </div>
                            <div
                                className="incident-time"
                                style={{ marginTop: "16px" }}
                            >
                                <Row className="g-0">時頃</Row>
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group className="my-3">
                        <Form.Label>
                            今の状況や、困っていること等をご入力ください。　
                        </Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Label>
                            また、IPアドレスやシステム環境等の詳細等がありましたら合わせてご入力ください。​　
                        </Form.Label>

                        <Form.Control
                            className="incident-text-area"
                            type="text"
                            as="textarea"
                            rows={5}
                            {...register("content", {
                                required: {
                                    value: true,
                                    message: "状況内容を入力してください。",
                                },
                            })}
                        />
                        {errors.content && (
                            <ValidationError message={errors.content.message} />
                        )}
                    </Form.Group>
                    <br />

                    <Row className="template-customize-area">
                        <Button className="btn btn-primary" type="submit">
                            確認画面へ
                        </Button>
                    </Row>
                    <br />
                    <br />
                </section>
            </Form>
        </Stack>
    );
}
