import React from "react";
import { Row, Col, Form, Button, Stack } from "react-bootstrap";

export default function InquiryEntry(props) {
    const { inquiry, handleConfirm, handleSend } = props;

    return (
        <Stack>
            <br />
            <br />

            <Form noValidate>
                <Form.Group className="my-3">
                    <Form.Label>お問い合わせ区分　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>

                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        value={inquiry.type}
                    />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>会社名　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        value={inquiry.company}
                    />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>部署名　</Form.Label>
                    <Form.Label className="any-lable"></Form.Label>
                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        value={inquiry.dept}
                    />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>お名前　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        value={inquiry.name}
                    />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>メールアドレス　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        value={inquiry.email}
                    />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>電話番号　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        value={inquiry.mobile}
                    />
                </Form.Group>
                <Form.Group className="my-3">
                    <Form.Label>お問い合わせ内容　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                    <Form.Control
                        className="incident-result-text-area"
                        type="text"
                        readOnly
                        as="textarea"
                        rows={6}
                        value={inquiry.content}
                    />
                </Form.Group>
                <div
                    className="my-3"
                    style={{ whiteSpace: "pre-wrap", fontSize: "16px" }}
                >
                    個人情報の取り扱いについて{`\n`}
                    詳しくはGSX「
                    <a
                        href="https://www.gsx.co.jp/privacy.html"
                        target="_blank"
                        // className="mb-1"
                    >
                        個人情報の取り扱いについて
                    </a>
                    」をご覧ください。{`\n`}
                    上記の個人情報のお取り扱いにご同意頂ける場合、下記チェックボックスにチェックをお願い致します。
                </div>
                <Form.Group className="my-3">
                    <Form.Check
                        checked={inquiry.agree}
                        className="check-inline"
                        type="checkbox"
                        id="info-check"
                        name="info-check"
                        disabled
                    />
                    <Form.Label>個人情報の取り扱いに同意します。　</Form.Label>
                    <Form.Label className="required-lable"></Form.Label>
                </Form.Group>
                <Row className="template-customize-area button-flex">
                    <Button
                        className="btn btn-primary btn-margin"
                        onClick={handleConfirm}
                    >
                        再入力
                    </Button>
                    <Button className="btn btn-primary" onClick={handleSend}>
                        送信
                    </Button>
                </Row>

                <br />
                <br />
            </Form>
        </Stack>
    );
}
