import React, { useEffect, useState } from "react";
import LabelWithPulldown from "./../../input/LabelWithPulldown";
import LabelWithInput from "./../../input/LabelWithInput";
import LabelWithTextarea from "./../../input/LabelWithTextarea";

import { Stack, Row, Col, Form, Button } from "react-bootstrap";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import AlertModal from "../AlertModal";
import { useForm } from "react-hook-form";
import { NavigationType, useNavigate } from "react-router-dom";

export default function SecurityAction(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const navigate = useNavigate();
    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };


    const [itemBody, setItemBody] = useState({});
    const [totalScore, setTotalScore] = useState(0);

    const calcScoreHandler = () => {
        let score = 0;
        for (const [key, value] of Object.entries(itemBody)) {
            score += convertOptionToScore(value);
        }
        setTotalScore(score);
    };

    const convertOptionToScore = (selectedValue) => {
        // Todo option setting
        let score = 0;
        if (selectedValue === "実施している") {
            score = 4;
        }
        if (selectedValue === "一部実施している") {
            score = 2;
        }
        if (selectedValue === "実施していない") {
            score = 0;
        }
        if (selectedValue === "わからない") {
            score = -1;
        }
        return score;
    };

    const parentFunction = (x) => {
        if (x.key === "category01") {
            const value = Object.assign(itemBody, {
                category01: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category02") {
            const value = Object.assign(itemBody, {
                category02: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category03") {
            const value = Object.assign(itemBody, {
                category03: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category04") {
            const value = Object.assign(itemBody, {
                category04: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category05") {
            const value = Object.assign(itemBody, {
                category05: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category06") {
            const value = Object.assign(itemBody, {
                category06: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category07") {
            const value = Object.assign(itemBody, {
                category07: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category08") {
            const value = Object.assign(itemBody, {
                category08: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category09") {
            const value = Object.assign(itemBody, {
                category09: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category10") {
            const value = Object.assign(itemBody, {
                category10: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category11") {
            const value = Object.assign(itemBody, {
                category11: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category12") {
            const value = Object.assign(itemBody, {
                category12: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category13") {
            const value = Object.assign(itemBody, {
                category13: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category14") {
            const value = Object.assign(itemBody, {
                category14: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category15") {
            const value = Object.assign(itemBody, {
                category15: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category16") {
            const value = Object.assign(itemBody, {
                category16: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category17") {
            const value = Object.assign(itemBody, {
                category17: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category18") {
            const value = Object.assign(itemBody, {
                category18: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category19") {
            const value = Object.assign(itemBody, {
                category19: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category20") {
            const value = Object.assign(itemBody, {
                category20: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category21") {
            const value = Object.assign(itemBody, {
                category21: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category22") {
            const value = Object.assign(itemBody, {
                category22: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category23") {
            const value = Object.assign(itemBody, {
                category23: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category24") {
            const value = Object.assign(itemBody, {
                category24: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
        if (x.key === "category25") {
            const value = Object.assign(itemBody, {
                category25: x.selectedValue,
            });
            calcScoreHandler(convertOptionToScore(x.selectedValue));
        }
    };

    const items = [
        {
            key: "category01",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１．パソコンやスマホなど情報機器のOSやソフトウェアは常に最新の状態にしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category02",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２．パソコンやスマホなどにはウィルス対策ソフトを導入し、ウィルス定義ファイルは最新の状態にしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category03",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "３．パスワードは破られにくい「長く」「複雑な」パスワードを設定していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category04",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "４．重要情報に対する適切なアクセス制限を行っていますか？",
            defaultValue: "",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category05",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "５．新たな脅威や攻撃の手口を知り対策を社内共有する仕組みはできていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },

        {
            key: "category06",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "６．電子メールの添付ファイルや本文中のURLリンクを介したウィルス感染に気をつけてますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category07",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "７．電子メールやFAXの宛先の送信ミスを防ぐ取り組みを実施していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category08",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "８．重要情報は電子メール本文に書くのではなく、添付するファイルに書いてパスワードなどで保護していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category09",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "９．インターネットを介したウィルス感染やSNSへの書き込みなどのトラブルへの対策をしていますか？",
            defaultValue: "",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category10",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１０．パソコンやサーバーのウィルス感染、故障や誤操作による重要情報の消失に備えてバックアップを取得していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category11",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１１．重要情報が記載された書類や電子会いたいを持ち出す時は、盗難や紛失の対策をしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category12",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１２．離席時にパソコン画面の覗き見や勝手な操作ができないようにしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category13",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１３．関係者以外の事務所のへの立ち入りを制限していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category14",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１４．退社時にノートパソコンや備品を施錠保管するなど盗難防止対策をしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category15",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１５．事務所が無人になるときの施錠忘れ対策を実施していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category16",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１６．重要情報が記載された書類や重要データが保存された媒体を破棄する時は、復元できないようにしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category17",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１７．事務所が無人になるときの施錠忘れ対策を実施していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category18",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１８．重要情報が記載された書類や重要データが保存された媒体を破棄する時は、復元できないようにしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },

        {
            key: "category19",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "１９．従業員に守秘義務を理解してもらい、業務上知り得た情報を外部に漏らさないなどのルールを守らせていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category20",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２０．従業員にセキュリティに関する教育や注意喚起を行っていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category21",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２１．個人所有の情報機器を業務で利用する場合のセキュリティ対策を明確にしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category22",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２２．重要情報の授受を伴う取引先との契約書には、秘密保持条項を規定していますか？",
            defaultValue: "",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category23",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２３．クラウドサービスやウェブサイトの運用等で利用する外部サービスは、安全・信頼性を把握して選定していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category24",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２４．セキュリティ事故が発生した場合に備え、緊急時の体制整備や対応手順を作成するなど準備をしていますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "category25",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "２５．情報セキュリティ対策（上記1～24）をルール化し、従業員に明示していますか？",
            pulldownValues: [
                { value: "実施している" },
                { value: "一部実施している" },
                { value: "実施していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
    ];

    const [securityActionInfos, setSecurityActionInfo] = useState(items);
    const [hasData, setHasData] = useState(false);

    const getSecurityActionInfo = () => {
        axiosPrivate
            .get("/api/user/securityaction")
            .then(function (response) {
                if (response.status === 200 && response.data.data != null) {
                    setItem(response.data.data);
                    setItemBody(
                        Object.assign(itemBody, {
                            company_id: response.data.data.company_id,
                        })
                    );
                }
                setHasData(true);
                calcScoreHandler();
            })
            .catch(function (err) {
                alert(err);
            });
    };

    const setItem = (data) => {
        items.forEach((item, index) => {
            if (items[index].key === "category01") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category01: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category02") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category02: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category03") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category03: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category04") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category04: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category05") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category05: items[index].selectedValue,
                    })
                );
            }

            if (items[index].key === "category06") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category06: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category07") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category07: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category08") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category08: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category09") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category09: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category10") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category10: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category11") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category11: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category12") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category12: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category13") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category13: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category14") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category14: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category15") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category15: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category16") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category16: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category17") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category17: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category18") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category18: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category19") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category19: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category20") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category20: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category21") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category21: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category22") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category22: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category23") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category23: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category24") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category24: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "category25") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        category25: items[index].selectedValue,
                    })
                );
            }
        });
        setSecurityActionInfo([].concat(items));
    };

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const updateItem = () => {
        console.log("***", itemBody);
        axiosPrivate
            .put("/api/user/securityaction/update", itemBody)
            .then(function (response) {
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "SECURIY ACTION情報を更新しました。",
                    });
                }
            })
            .catch(function (response) {
                console.error(response);
            });
    };

    useEffect(() => {
        getSecurityActionInfo();
    }, []);

    const totalScoreResult = totalScore < 0 ? 0 : totalScore;

    return (
        <Stack className="Common">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　SECURITY ACTION</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
            <br />
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section style={{ maxWidth: "1000px" }}>
                        <div
                            className="template-customize-mypage-area"
                            style={{
                                justifyContent: "right",
                                display: "grid",
                            }}
                        >
                            <Button
                                className="rounded-pill btn-blue-2"
                                onClick={handleMypage}
                            >
                                マイページへ戻る
                            </Button>
                        </div>
                        <br />

                        <div
                            style={{
                                justifyContent: "right",
                                display: "flex",
                                fontSize: "20px",
                            }}
                        >
                            採点結果: {totalScoreResult} / 100
                        </div>
                        <br />
                        <div className="ELearning-guide-line">
                            <ul className="contents-area">
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                基本的対策
                                            </div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <Form
                                                noValidate
                                                className="form-area"
                                                type="text"
                                            >
                                                {securityActionInfos.map(
                                                    (item, index) => {
                                                        if (index < 5) {
                                                            return (
                                                                <item.component
                                                                    key={`inquiry_${index}`}
                                                                    item={item}
                                                                />
                                                            );
                                                        }
                                                    }
                                                )}
                                            </Form>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                従業員としての対策
                                            </div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <Form
                                                noValidate
                                                className="form-area"
                                                type="text"
                                            >
                                                {securityActionInfos.map(
                                                    (item, index) => {
                                                        if (
                                                            index > 4 &&
                                                            index < 19
                                                        ) {
                                                            return (
                                                                <item.component
                                                                    key={`inquiry_${index}`}
                                                                    item={item}
                                                                />
                                                            );
                                                        }
                                                    }
                                                )}
                                            </Form>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                組織としての対策
                                            </div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <Form
                                                noValidate
                                                className="form-area"
                                                type="text"
                                            >
                                                {securityActionInfos.map(
                                                    (item, index) => {
                                                        if (
                                                            index > 18 &&
                                                            index < 25
                                                        ) {
                                                            return (
                                                                <item.component
                                                                    key={`inquiry_${index}`}
                                                                    item={item}
                                                                />
                                                            );
                                                        }
                                                    }
                                                )}
                                            </Form>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                採点
                                            </div>
                                            {/* <div className="num">4</div> */}
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <h3>セキュリティ点数の確認</h3>
                                            <p>
                                                「セキュリティ対策を更新する」ボタンを押下すると、セキュリティ点数を確認できます。
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <div className="template-customize-area">
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                onClick={() => {
                                    updateItem();
                                }}
                            >
                                {hasData
                                    ? "SECURITY ACTIONを更新する"
                                    : "SECURITY ACTIONを更新する"}
                            </Button>
                        </div>
                        <br />
                        <br />
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Stack>
    );
}
