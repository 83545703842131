import React, { useState, useEffect } from 'react';
import {
    Row, Col, Stack, Form, Button, Alert
} from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useForm } from 'react-hook-form';
import ValidationError from '../elements/ValidationError';
import { useNavigate, useLocation } from 'react-router';
import AlertModal from '../elements/AlertModal';



export default function SavePassword(props) {

    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })
    const [validated, setValidated] = useState(false);
    const [success, setSuccess] = useState(false)
    const [pass, setPass] = useState("");
    const [email, setEmail] = useState("")
    
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    const navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies();

    const onSubmit = (event) => {
    //const form = event.currentTarget;
    
        var token = location.search.split("=")[1];

        var body = {
            "newPassword": getValues("newPass")
        }

       // //console.log(body)
		const passAxios = axios.create({ baseUrl: "https://api.vciso.jp"})
        passAxios.post("/api/user/savepassword", body, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
        .then(function(response) {
            if (response.status === 200) {
            //    //console.log(response)
                if (response.data.apiCode === 0) {
                    setSuccess(true)
                } else if (response.data.apiCode === 1001) {
                    setAlert({ ...alert, show: true, message: "パスワードのリセットが失敗しました。トークンは無効です。" })
                }
            }
        })
        .catch(function(response) {
            console.error(response)
            setAlert({ ...alert, show: true, message: "パスワードのリセットが失敗しました。" })
        })
    
    
   
    };

    const handleChange = (event) => {
        //console.log("change")
        setPass(event.target.value)
    }

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    useEffect(() => {
        let isSubscribed = true;
		const passAxios = axios.create({ baseUrl: "https://api.vciso.jp"})
        passAxios.get("/api/account/resetpassword", {
            headers: {
                "Authorization": "Bearer " + location.search.split("=")[1]
            }
        })
        .then(function(response) {
            if (isSubscribed) {
                if (response.status === 200 && response.data.apiCode !== 0) {
                    setAlert({ ...alert, show: true, message: "パスワードはリセット済みです。"})
                    navigate("/")
                    window.location.reload();
                }
            }
        })
        .catch(function(response) {
            navigate("/")
            window.location.reload();
        })
        return () => isSubscribed = false;
    }, [])
  

    return (
        <Row className="g-0 fill-height-sm fill-height-md fill-height-xl">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={6} lg={6} className="h-100" style={{ "display": "flex", "flexDirection": "column", "justifyContent":"center", "alignItems": "center"}}>
                
                <Stack gap={4} style={{ "width": "100%", "height": "100%", "display": "flex", "alignItems": "center", "alignSelf": "center"}} className="p-4">
                    <h2>パスワードリセット</h2>
                    <div style={{ "width": "80%", "borderRadius": ".5rem", "display": "flex", "flexDirection": "column", "justifyContent": "center"}} className="shadow-lg p-4">
                        { success ?
                        <div style={{ "height": "160px", "display": "flex", "flexDirection": "column", "textAlign": "justify", "justifyContent": "center"}}>
                            <div>パスワードをリセットできました。</div>
                            <br />
                            <Button onClick={() => navigate("/login", { replace: true})}>ログイン画面へ</Button>
                        </div> :
                        <Form noValidate validated={validated} style={{ "width": "100%", "display": "flex", "flexDirection": "column", "alignSelf": "center" }} onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3">
                                <Row className="g-0">
                                    <Col xs={2}>
                                        <Form.Label style={{ "fontSize": ".8rem" }}>新しいパスワード</Form.Label>
                                    </Col>
                                    <Col xs={8} className="px-4">
                                        <Form.Control 
                                            type="password" 
                                            {
                                                ...register("newPass", 
                                                { 
                                                    required: {value: true, message: "英数特殊記号混在8文字以上を入力してください。"},
                                                    onChange: (e) =>  handleChange(e),
                                                    minLength: { value: 8, message: "英数特殊記号混在8文字以上を入力してください。"},
                                                    pattern: { value: /^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/, message: "英数特殊記号混在8文字以上を入力してください。"}
                                                })
                                            }
                                        />
                                        {errors.newPass && <ValidationError message={errors.newPass.message}/>}
                                    </Col>
                                    <Col>
                                        <div style={{ "lineHeight": "38px", "fontSize": ".8rem"}}>（半角英数）</div>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Row className="g-0">
                                    <Col xs={2}>
                                        <Form.Label style={{ "fontSize": ".8rem" }}>新しいパスワード確認用</Form.Label>
                                    </Col>
                                    <Col xs={8} className="px-4">
                                        <Form.Control 
                                            type="password" 
                                            {
                                                ...register("confirmPass", {
                                                    required: { value: true, message: "確認用パスワードを入力してください。"},
                                                    validate: value => value === pass || "パスワードと一致していません。"
                                                })
                                            }
                                        />
                                        {errors.confirmPass && <ValidationError message={errors.confirmPass.message}/>}
                                    </Col>
                                    <Col>
                                        <div style={{ "lineHeight": "38px", "fontSize": ".8rem"}}>（半角英数）</div>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Button size="sm" style={{ "alignSelf": "center", "width": "40%", "marginTop": "1rem"}} type="submit">送信する</Button>
                        </Form>
                        }
                    </div>
                </Stack>
            </Col>
            <Col  />
        </Row>
    )
}