import React from 'react';
import {
    Row, Col, Container, Button
} from 'react-bootstrap';
import { useNavigate } from 'react-router';

export default function Admin(props) {

    const navigate = useNavigate();

    return (
        <div>
            <Row className="g-0">
                <Col />
                <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center"}}>管理</h2>
                    <hr style={{ "width": "100%"}}/>
                    <Row className="g-0">
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>ゲスト登録</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1rem", 
                                            "color": "#385093", "display": "-webkit-box",
                                            "WebkitBoxOrient": "vertical"}}>
                                            クレジットカード決済なしで直接にゲスト登録。
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/register")}>登録する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>顧客一覧</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            登録済みの顧客一覧及び顧客編集画面。
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/userlist")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>インシデント対応</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            インシデント対応状況等を確認する画面。
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/incidents")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>文書カスタマイズ依頼一覧</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            文書カスタマイズ依頼申し込みを確認する画面。
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/doccustomize")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>文書テンプレート登録</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            文書テンプレート一覧を確認したり新規登録したりする画面。
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/doctemplates")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>メール訓練受付一覧</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            メール訓イベントの状況を確認したり受付一覧を確認したりする画面
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/emailregistrations")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>お問い合わせ一覧</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            お問い合わせ一覧を確認する画面
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/inquiries")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>個別カウンセリング一覧</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            個別カウンセリング一覧を確認する画面
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/counselingsessions")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                        <Col className="px-2" xs={3}>
                            <Container style={{ 'padding': '0', "display": "flex", "flexDirection": "column", "height": "98%","width": "100%", "border": "2px solid #cdcdcd","box-shadow": "5px 5px 5px #cdcdcd"}}>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <h4 style={{"margin": "10px auto"}}>セキュリティ健康診断</h4>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <div className="m-3" style={{ "fontSize": "1.1rem", 
                                        "color": "#385093", "display": "-webkit-box",
                                        "WebkitBoxOrient": "vertical"}}>
                                            セキュリティ健康診の利用申請とお問い合わせ一覧を確認する画面。
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-0">
                                    <Col xs={12} style={{ "display": "flex", "flexDirection": "column"}}>
                                        <Button style={{ "margin": "10px", "width": "60%", "alignSelf": "center"}} onClick={() => navigate("/admin/seccheck")}>表示する</Button>
                                    </Col>
                                </Row>
                                
                            </Container>
                        </Col>
                    </Row>
                </div>
                </Col>
                <Col />
            </Row>
        </div>
    )
}