import { Button } from "react-bootstrap";

export default function SubAccountRow(props) {
    const { account, handleEdit, handleDelete } = props;

    var mobileNumber = account.mobile.includes("-")
        ? account.mobile.split("-")[1]
        : account.mobile;
    return (
        <tr>
            <td
                scope="row"
                style={{ textAlign: "center", verticalAlign: "middle" }}
            >
                {`${account.lastName} ${account.firstName}`}
            </td>
            <td style={{ verticalAlign: "middle" }}>{account.mailId}</td>
            <td style={{ verticalAlign: "middle" }}>
                {account.mobile.length > 0 ? mobileNumber : "-"}
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <Button
                    className="btn rounded-pill btn-blue-2"
                    style={{
                        fontSize: "12px",
                        width: "70px",
                    }}
                    onClick={() => handleEdit()}
                >
                    編集
                </Button>
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <Button
                    className="btn rounded-pill btn-blue-2"
                    style={{
                        fontSize: "12px",
                        width: "70px",
                    }}
                    onClick={() => handleDelete()}
                >
                    削除
                </Button>
            </td>
        </tr>
    );
}
