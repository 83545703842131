import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Registration } from '../../../assets/svg_icons/icon-registration.svg';

export default function registrationButton(props) {
    return (
        <div className="registration-button navbar">
          <a onClick={() => {props.navFunc("signup");}} style={{ "color": "#FFAA02", "fontSize": "10px", "display": "flex", "flexDirection":"column", "justifyContent": "center"}}>
            <div style={{ "textAlign": "center" }}><Registration /></div><span>会員登録</span>
          </a>
        </div>
    )
}