import React from 'react'
import {
    Button, Row, Col
} from 'react-bootstrap';
import { Link } from "react-router-dom";

export default function RegisterComplete(props) {
    return (
        <Row className="g-0">
            <Col />
            <Col sm={12} md={10} lg={10} style={{ "display": "flex", "flexDirection": "column", "justifyContent":"center" }}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center"}} className="p-4 fill-height-sm fill-height-md fill-height-xl">
                    <div className="fill-height-incident-sm fill-height-incident-md fill-height-incident-xl">
                        <h3>登録完了</h3>
                        <br />
                        <br />
                        <div>本登録が完了しました。</div>
                        <Link to="/login">
                            <Button style={{ "width": "fit-content"}}>ログイン画面へ</Button>
                        </Link>
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    )
}