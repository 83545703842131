import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Alert, Modal
} from 'react-bootstrap';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from 'react-hook-form';
import AlertModal from '../AlertModal';
import { ReactComponent as Download } from '../../../assets/svg_icons/download-icon.svg';
import { ReactComponent as Upload } from '../../../assets/svg_icons/upload_icon.svg';
import downloadFile from '../../../utils/downloadFile';
import { data } from 'jquery';

const placeholderGenerator = quantity => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
        items.push({ id: i, companyName: " ", eventName: " ", uploaded_at: " "})
    }
    return items;
}

export default function EmailRegistrationList(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [trainings, setTrainings] = useState([])
    const [registrations, setRegistrations] = useState([])
    const [events, setEvents] = useState([])
    const [filteredRegistrations, setFilteredRegistrations] = useState(() => placeholderGenerator(25))

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({})

    const navigate = useNavigate();

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const eventStatus = (e, row, rowIndex) => {

        var now = new Date();
        var recep_end = new Date(row.recep_end)
        var recep_start = new Date(row.recep_start)
        console.log(now)
        console.log(recep_start)
        console.log(recep_end)

        var result;
        if (now >= recep_start && now <= recep_end) {
            result = "受付中"
        } else if (now < recep_start) {
            result = "受付前"
        } else {
            result = "受付終了"
        }
        return (
            <div>{result}</div>
        )
    }

    const downloadButton = (e, row, rowIndex) => {

        /*
        var incident = incidents.find(x => x.id === row.id);
        console.log(incident)
        if (incident === undefined) {
            return;
        }
        */
        //onClick={() => downloadFile("template", row.document_name)}
        return (
            
            <div style={{ "cursor": "pointer"}}>
                <Download style={{ "height": "20px"}} onClick={() => downloadFile("email", row.fileName)}/>
            </div>               
            
        )
    }

    const handleUpload = (row) => (event) => {
        //console.log(row)
       //console.log(event.target.files[0])
        //console.log(event.target.files[0].type)

        var type = event.target.files[0].type;
        if (type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || type === "application/pdf") {
            var formData = new FormData();
            formData.append("file", event.target.files[0]);
            axiosPrivate.post(`/api/admin/emailregist/${row.id}/report`, formData)
            .then(function(response) {
                setAlert({ ...alert, show: true, message: response.data.message})
            })
            .catch(function(response) {
                setAlert({ ...alert, show: true, message: "問題が発生しました。"})
            })
        } else {
            setAlert({ ...alert, show: true, message: "ファイルフォーマットは正しくありません。PDFまたはXLSXのファイルをアップロードしてください。"})
        }
        
        
        
    }

    const uploadButton = (e, row, rowIndex) => {

        
        var regist = registrations.find(x => x.id === row.id);
       // console.log(regist)
        if (regist === undefined) {
            return;
        }
        return (
            
            <div style={{ "cursor": "pointer"}} >
                <label htmlFor='report-input' style={{ "cursor": "pointer"}}>
                    <Upload style={{ "height": "20px"}}/>
                </label>
                
                <input type="file" hidden id="report-input" onChange={handleUpload(row)} />
            </div>               
            
        )
    }

    const handleStopRecep = (row) => {
        axiosPrivate.put(`/api/admin/emailtraining/${row.id}/stopreg`)
        .then(function(response) {
            setAlert({ ...alert, show: true, message: response.data.message})
            axiosPrivate.get("/api/admin/emailtraining")
            .then(function(response) {
              //  console.log(response)
                if (response.status === 200) {
                    if (response.data.apiCode === 0) {
                      //  console.log(response.data)
                        
                        var trainings = eventRowGenerator(response.data.data);
                        //console.log(trainings)
                        setTrainings(trainings)
                    
                    }
                }
            })
            .catch(function(response) {
                //console.log(response)
            })
        })
        .catch(function(response) {
            //console.log(response)
            setAlert({ ...alert, show: true, message: "受付の停止が失敗しました。"})
        })
    }

    const stopRecepButton = (e, row, rowIndex) => {

        return (
            <Button onClick={() => handleStopRecep(row)}>受付を停止する</Button>
        )
    }

    const eventColumns = [
        {
            dataField: "id",
            text: "id",
            hidden: true
        },
        {
            dataField: "event_name",
            text: "タイトル",
            sort: true,
            headerStyle: () => {
                return { width: "15%"}
            }
        },
        {
            dataField: "recep_start",
            text: "募集開始日",
            sort: true,
            headerStyle: () => {
                return { width: "15%"}
            }
        },
        {
            dataField: "recep_end",
            text: "募集終了日",
            sort: true,
            headerStyle: () => {
                return { width: "15%"}
            }
        },
        {
            dataField: "event_period",
            text: "実施期間",
            sort: true,
            headerStyle: () => {
                return { width: "20%"}
            }
        },
        {
            dataField: "participants",
            text: "応募数",
            sort: true,
            headerStyle: () => {
                return { width: "10%"}
            }
        },
        {
            dataField: "status",
            text: "受付の状態",
            sort: true,
            formatter: eventStatus,
            headerStyle: () => {
                return { width: "10%"}
            }
        },
        {
            dataField: "stop_recep",
            text: "受付停止",
            formatter: stopRecepButton,
            headerStyle: () => {
                return { width: "15%" }
            }
        }
    ]

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true
        },
        {
            dataField: "companyName",
            text: "会社名",
            sort: true,
            headerStyle: () => {
                return { width: "30%" }
            }
        },
        {
            dataField: "eventName",
            text: "タイトル",
            sort: true,
            headerStyle: () => {
                return { width: "20%" }
            }
        },
        {
            dataField: "download",
            text: "訓練者リストDL",
            formatter: downloadButton,
            headerStyle: () => {
                return { width: "15%" }
            }
        },
        {
            dataField: "uploaded_at",
            text: "申し込み日時",
            sort: true,
            headerStyle: () => {
                return { width: "20%" }
            }
        },
        {
            dataField: "upload",
            text: "レポートアップロード",
            formatter: uploadButton,
            headerStyle: () => {
                return { width: "15%"}
            }
        }
    ]

    const handleReset = () => {
        setValue("eventName", "タイトルを選択")
        setValue("companyName", "")
        setFilteredRegistrations(rowGenerator(registrations));
    }

    const onSubmit = (data) => {
        var values = getValues();

        var filtered = JSON.parse(JSON.stringify(registrations));

        if (values.eventName !== "タイトルを選択") {
            filtered = filtered.filter(x => x.eventName === values.eventName)
        }

        if (values.companyName !== "") {
            filtered = filtered.filter(x => x.companyName.toLowerCase() === values.companyName.toLowerCase())
        }

        setFilteredRegistrations(rowGenerator(filtered))
    }

    const rowEvents = {
        /*
        onClick: (e, row, rowIndex) => {
            
            var incident = incidents.find(x => x.id === row.id);
            navigate("details", {
                state: {
                    incident: incident
                },
                reaplce: true                
            })
        }
        */
    }

    const rowGenerator = (data) => {
        const items = [];
        data.forEach(regist => {
            items.push({ id: regist.id, companyName: regist.companyName, eventName: regist.eventName, uploaded_at: regist.uploaded_at, fileName: regist.fileName})
        })
        return items;
    }

    const eventRowGenerator = (data) => {
        const items = [];
        
        data.forEach(event => {
            items.push({ id: event.id, event_name: event.event_name, event_period: `${event.event_start.split(" ")[0].replaceAll("-", "/")}~${event.event_end.split(" ")[0].replaceAll("-", "/")}`, recep_start: event.recep_start.split(" ")[0].replaceAll("-", "/"), recep_end: event.recep_end.split(" ")[0].replaceAll("-", "/"), participants: event.participants})
        })
        return items
    }

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate.get("/api/admin/emailregistrations")
        .then(function(response1) {
            //console.log(response1)
            if (response1.status === 200) {
                if (response1.data.apiCode === 0) {
                    if (isSubscribed) {
                        //console.log(response1.data.data)
                        var regists = rowGenerator(response1.data.data)
                        //console.log(regists)
                        setRegistrations(response1.data.data)
                        var uniqueEvents = [...new Set(response1.data.data.map(item => item.eventName))];
                        setEvents(uniqueEvents);
                        setFilteredRegistrations(regists)
                    }
                }
            }
        })
        .catch(function(response1) {
          //  console.log(response1)
        })

        axiosPrivate.get("/api/admin/emailtraining")
        .then(function(response) {
            //console.log(response)
            if (response.status === 200) {
                if (response.data.apiCode === 0) {
                   // console.log(response.data)
                    if (isSubscribed) {
                        var trainings = eventRowGenerator(response.data.data);
                        //console.log(trainings)
                        setTrainings(trainings)
                    }
                }
            }
        })
        .catch(function(response) {
           // console.log(response)
        })

        return () => isSubscribed = false;

    }, [])

    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <br />
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <Row className="g-0">
                        <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                            <h4 style={{ "alignSelf": "left" }}>募集管理</h4>
                        </Col>
                        <Col xs={4} style={{ "display": "flex", "flexDirection": "column"}}>
                            <Button style={{ "alignSelf": "end"}} onClick={() => navigate("registration")}>メール訓練のイベントを追加する</Button>
                        </Col>
                    </Row>
                    
                    <hr style={{ "width": "100%" }} />
                    <div style={{ "minHeight": "200px" }}>
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="id"
                            data={trainings}
                            columns={eventColumns}
                            pagination={paginationFactory({ sizePerPage: 25 })}
                            rowStyle={{ overflowWrap: "break-word" }}
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <Row className="g-0">
                        <Col xs={8} style={{ "display": "flex", "flexDirection": "column"}}>
                            <h4 style={{ "alignSelf": "left" }}>メール訓練受付一覧</h4>
                        </Col>
                        <Col />
                    </Row>
                    
                    <hr style={{ "width": "100%" }} />
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row classname="g-0">
                            <Col xs={4}>
                                <Form.Group className="my-4">
                                    <Form.Label>
                                        会社名を入力
                                    </Form.Label>
                                    <Form.Control type="text" { ...register("companyName")} />
                                </Form.Group>
                            </Col>
                            <Col xs={5}>
                                <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                    <Form.Label>
                                        タイトルで絞り込み
                                    </Form.Label>
                                    <Form.Select { ...register("eventName")}>
                                        <option>タイトルを選択</option>
                                        {
                                            events.map(event => {
                                                return <option>{event}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col />
                        </Row>
                        
                        <Row className="g-0">
                            <Col xs={2}>
                                <Button className="my-4" type="submit">検索する</Button>
                            </Col>
                            <Col xs={2}>
                                <Button className="my-4" onClick={handleReset} variant="outline-primary">リセット</Button>
                            </Col>
                        </Row>
                        </Form>
                    </Container>
                    <div style={{ "minHeight": "600px" }}>
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="id"
                            data={filteredRegistrations}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 25 })}
                            rowStyle={{ overflowWrap: "break-word" }}
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    )
}