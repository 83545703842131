import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Alert, Modal
} from 'react-bootstrap';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import { useNavigate } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from 'react-hook-form';
import AlertModal from '../AlertModal';
import { ReactComponent as Edit } from '../../../assets/svg_icons/edit_icon.svg';

const placeholderGenerator = quantity => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
        items.push({ id: i, companyName: " ", type: " ", content: " ", status: " ", pic: " ", memo: " ", created_at: " "})
    }
    return items;
}

export default function IncidentList(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [modal, setModal] = useState({
        open: false,
        message: ""
    })

    const [pics, setPICS] = useState([])
    const [incidents, setIncidents] = useState([])
    const [filteredIncidents, setFilteredIncidents] = useState(() => placeholderGenerator(25))

    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({
        defaultValues: {
            terms: ""
        }
    })

    const navigate = useNavigate();

    const handleModalClose = () => setModal({ open: false, message: ""});
    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const memoModalButton = (memo) => {
        console.log(memo)
        var text = memo === null || memo.length < 1 ? "未入力" : memo
        return (
            <Button onClick={() => setModal({ ...modal, show: true, message: text})}>
                メモ
            </Button>
        )
    }

    const picMenu = (pic, id, picList) => {
        return (
            <Form.Select {...register(`type_${id}`)} defaultValue={pic}>
                <option>未選択</option>
                {
                    picList.map(pic => {
                        return <option value={pic.id}>{`${pic.last_name} ${pic.first_name}`}</option>
                    })
                }
            </Form.Select>
        )
    }

    const editButton = (e, row, rowIndex) => {

        var incident = incidents.find(x => x.id === row.id);
        console.log(incident)
        if (incident === undefined) {
            return;
        }
        return (
            
            <div onClick={() => navigate("details", { state: { incident: incident} })} style={{ "cursor": "pointer"}}>
                <Edit />
            </div>               
            
        )
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true
        },
        {
            dataField: "companyName",
            text: "会社名",
            sort: true,
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "type",
            text: "インシデントの種類",
            sort: true,
            headerStyle: () => {
                return { width: "13%" }
            }
        },
        {
            dataField: "content",
            text: "インシデントのタイトル",
            headerStyle: () => {
                return { width: "32%" }
            }
        },
        {
            dataField: "status",
            text: "対応状況",
            sort: true,
            headerStyle: () => {
                return { width: "8%" }
            }
        },
        {
            dataField: "pic",
            text: "対応者",
            sort: true,
            headerStyle: () => {
                return { width: "10%" }
            }
        },
        {
            dataField: "memo",
            text: "メモ",
            headerStyle: () => {
                return { width: "7%" }
            }
        },
        {
            dataField: "created_at",
            text: "日付",
            sort: true,
            headerStyle: () => {
                return { width: "15%" }
            }
        },
        {
            dataField: "edit",
            text: "詳細",
            formatter: editButton,
            headerStyle: () => {
                return { width: "5%" }
            }
        }
    ]

    const handleReset = () => {
        setValue("status", "未選択")
        setValue("pic", "未選択")
        setValue("terms", "")
        setValue("startDate", "")
        setValue("endDate", "")
        setFilteredIncidents(rowGenerator(incidents));
    }

    const onSubmit = (data) => {
        var values = getValues();

        var filtered = JSON.parse(JSON.stringify(incidents));

        if (values.status !== "未選択") {

            filtered = filtered.filter(x => x.status === values.status)

        }

        if (values.pic !== "未選択") {
            console.log(filtered)
            filtered = filtered.filter(x => x.pic !== null && x.pic.last_name.toLowerCase() + " " + x.pic.first_name.toLowerCase() === values.pic.toLowerCase())
        }
   

        if (values.terms !== "") {
            var terms = values.terms.toLowerCase();
            filtered = filtered.filter(x => x.content.toLowerCase().includes(terms) || x.status.toLowerCase().includes(terms) || (x.pic !== null && x.pic.last_name.toLowerCase().includes(terms)) || (x.pic !== null && x.pic.first_name.toLowerCase().includes(terms)) || x.type.toLowerCase().includes(terms) || (x.companyName !== null && x.companyName.toLowerCase().includes(terms)))
        }
      

        if (values.startDate !== "") {
            var start = new Date(values.startDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) >= start)
            
        }


        if (values.endDate !== "") {
            var end = new Date(values.endDate).setHours(0,0,0,0)
            filtered = filtered.filter(x => new Date(x.created_at).setHours(0,0,0,0) <= end)
        }

        //console.log(filtered);
        setFilteredIncidents(rowGenerator(filtered));
        /*
        if (filtered.length === 0 || filtered.length === incidents.length) {
            setAlert({ ...alert, show: true, message: "キーワード検索に合致するインシデントが見つかりませんでした。"})
        } else {
            setFilteredIncidents(filtered);
        }
        */
    }

    const rowEvents = {
        /*
        onClick: (e, row, rowIndex) => {
            
            var incident = incidents.find(x => x.id === row.id);
            navigate("details", {
                state: {
                    incident: incident
                },
                reaplce: true                
            })
        }
        */
    }

    const rowGenerator = (data) => {
        const items = [];
        data.forEach(incident => {
           // console.log(incident.memo)
            var pic = incident.pic;
            items.push({ id: incident.id, companyName: incident.companyName, type: incident.type, content: incident.content, status: incident.status, pic: pic !== null ? `${incident.pic.last_name} ${incident.pic.first_name}` : "未選択", memo: memoModalButton(incident.memo), created_at: incident.created_at})
        })
        return items;
    }

    useEffect(() => {
        axiosPrivate.get("/api/admin/incidents")
        .then(function(response1) {
            //console.log(response1)
            if (response1.status === 200) {
                if (response1.data.apiCode === 0) {
                   // console.log(response1.data.data)
                    var incidents = rowGenerator(response1.data.data)
                    //console.log(incidents)
                    setIncidents(response1.data.data)
                    setFilteredIncidents(incidents)
                }
            }
        })
        .catch(function(response1) {
           // console.log(response1)
        })

        axiosPrivate.get("/api/pic")
        .then(function(response) {
            if (response.status === 200) {
               // console.log(response)
                setPICS(response.data)
            }
        })
        .catch(function(response) {
           // console.log(response)
        })
    }, [])

    return (
        <Row className="g-0">
            <Modal show={modal.show} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>インシデント対応メモ</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.message}</Modal.Body>
            </Modal>
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center" }}>インシデント対応</h2>
                    <hr style={{ "width": "100%" }} />
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row classname="g-0">
                                <Col xs={6}>
                                    <Form.Group className="my-4" style={{ "width": "50%"}}>
                                        <Form.Label>絞り込みたい文字列を入力</Form.Label>
                                        <Form.Control type="text" {...register("terms")}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応状況
                                        </Form.Label>
                                        <Form.Select { ...register("status")}>
                                            <option hidden>未選択</option>
                                            <option>未対応</option>
                                            <option>対応中</option>
                                            <option>対応済み</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="my-4" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応者
                                        </Form.Label>
                                        <Form.Select { ...register("pic")}>
                                            <option hidden>未選択</option>
                                            {
                                                pics.map(pic => {
                                                    return <option>{`${pic.last_name} ${pic.first_name}`}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col xs={6}>
                                    
                                    <div className="my-4">
                                        <Form.Group style={{ "width": "fit-content", "float": "left"}}>
                                            <Form.Label>期間で絞り込み</Form.Label>
                                            <Form.Control type="date" {...register("startDate")} />
                                        </Form.Group>
                                        <div style={{ "float": "left", "marginTop": "32px"}}>
                                            <div className="mx-2" style={{ "float": "left", "lineHeight": "38px"}}>〜</div>
                                            <Form.Group style={{ "width": "fit-content","float": "left"}}>
                                                <Form.Control type="date" {...register("endDate")} />
                                            </Form.Group>
                                        </div>
                                    </div> 
                                                                            
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col xs={2}>
                                    <Button className="my-4" type="submit">検索する</Button>
                                </Col>
                                <Col xs={2}>
                                    <Button className="my-4" onClick={handleReset} variant="outline-primary">リセット</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                    <div style={{ "minHeight": "600px" }}>
                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="id"
                            data={filteredIncidents}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 25 })}
                            rowStyle={{ overflowWrap: "break-word" }}
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
            </Col>
            <Col />
        </Row>
    )
}