import React, { useEffect, useState } from "react";
import LabelWithPulldown from "./../../input/LabelWithPulldown";
import LabelWithInput from "./../../input/LabelWithInput";
import LabelWithTextarea from "./../../input/LabelWithTextarea";

import { Stack, Form, Button } from "react-bootstrap";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import AlertModal from "../AlertModal";
import { Link, useNavigate } from "react-router-dom";

export default function Chart() {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const [itemBody, setItemBody] = useState({});

    const parentFunctionEdit = (x) => {
        if (x.key === "employees_number") {
            const value = Object.assign(itemBody, {
                employees_number: x.defaultValue,
            });
        }
        if (x.key === "device_number") {
            const value = Object.assign(itemBody, {
                device_number: x.defaultValue,
            });
        }
    };
    const parentFunctionEditArea = (x) => {
        if (x.key === "incidents_detail") {
            setItemBody(
                Object.assign(itemBody, {
                    incidents_detail: x.defaultValue,
                })
            );
        }
    };

    const parentFunction = (x) => {
        // Todo add function
        if (x.key === "tele_work") {
            const value = Object.assign(itemBody, {
                tele_work: x.selectedValue,
            });
        }
        if (x.key === "isms_pmark") {
            setItemBody(
                Object.assign(itemBody, {
                    isms_pmark: x.selectedValue,
                })
            );
        }
        if (x.key === "personal_information") {
            setItemBody(
                Object.assign(itemBody, {
                    personal_information: x.selectedValue,
                })
            );
        }
        if (x.key === "connectable_network") {
            setItemBody(
                Object.assign(itemBody, {
                    connectable_network: x.selectedValue,
                })
            );
        }
        if (x.key === "pc_os") {
            setItemBody(
                Object.assign(itemBody, {
                    pc_os: x.selectedValue,
                })
            );
        }
        if (x.key === "incidents") {
            setItemBody(
                Object.assign(itemBody, {
                    incidents: x.selectedValue,
                })
            );
        }
    };

    const items = [
        {
            key: "tele_work",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName: "テレワークは実施されていますか？",
            pulldownValues: [
                { value: "している" },
                { value: "していない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "isms_pmark",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName: "ISMS又はPマークの認証を取得されていますか？",
            pulldownValues: [
                { value: "はい" },
                { value: "いいえ" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "personal_information",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "業務上個人情報（個人名やメールアドレス等）の取り扱いはありますか？",
            pulldownValues: [
                { value: "はい" },
                { value: "いいえ" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "employees_number",
            labelName: "社員数",
            defaultValue: "",
            component: LabelWithInput,
            parentFunctionEdit: parentFunctionEdit,
        },
        {
            key: "connectable_network",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "社外から会社のネットワークへ接続できますか？（ご自宅から会社のサーバーにあるファイルが見れますか）",
            pulldownValues: [
                { value: "できる" },
                { value: "VPNを使用している" },
                { value: "できない" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "pc_os",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "社員の方が利用されるPCはメーカーのサポート期間のもののみですか？（Windows７、8.1やXPは使用されていませんか）",
            pulldownValues: [
                { value: "はい" },
                { value: "いいえ" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "device_number",
            labelName: "端末台数は何台ありますか？",
            defaultValue: "",
            component: LabelWithInput,
            parentFunctionEdit: parentFunctionEdit,
        },
        {
            key: "incidents",
            placeHolder: "選択して下さい",
            selectedValue: "選択して下さい",
            labelName:
                "直近1年間にセキュリティ事故（ウィルス感染等）や情報漏洩に関する事故は起こっていませんか？",
            pulldownValues: [
                { value: "はい" },
                { value: "いいえ" },
                { value: "わからない" },
            ],
            component: LabelWithPulldown,
            parentFunction: parentFunction,
        },
        {
            key: "incidents_detail",
            labelName: "上記が「はい」の場合、どのような事故が発生しましたか？",
            defaultValue: "",
            component: LabelWithTextarea,
            parentFunctionEditArea: parentFunctionEditArea,
        },
    ];

    const [chartInfos, setChartInfo] = useState(items);
    const [hasData, setHasData] = useState(false);

    const getChartInfo = () => {
        axiosPrivate
            .get("/api/user/chart")
            .then(function (response) {
                if (response.status === 200 && response.data.data != null) {
                    setItem(response.data.data);
                    setItemBody(
                        Object.assign(itemBody, {
                            company_id: response.data.data.company_id,
                        })
                    );
                }
                setHasData(true);
            })
            .catch(function (err) {
                alert(err);
            });
    };

    const setItem = (data) => {
        items.forEach((item, index) => {
            if (items[index].key === "tele_work") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        tele_work: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "isms_pmark") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        isms_pmark: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "personal_information") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        personal_information: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "employees_number") {
                items[index] = Object.assign({}, item, {
                    defaultValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        employees_number: items[index].defaultValue,
                    })
                );
            }
            if (items[index].key === "connectable_network") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        connectable_network: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "pc_os") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        pc_os: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "device_number") {
                items[index] = Object.assign({}, item, {
                    defaultValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        device_number: items[index].defaultValue,
                    })
                );
            }
            if (items[index].key === "incidents") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        incidents: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "incidents_occure") {
                items[index] = Object.assign({}, item, {
                    selectedValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        incidents_occure: items[index].selectedValue,
                    })
                );
            }
            if (items[index].key === "incidents_detail") {
                items[index] = Object.assign({}, item, {
                    defaultValue: data[items[index].key],
                });
                setItemBody(
                    Object.assign(itemBody, {
                        incidents_detail: items[index].defaultValue,
                    })
                );
            }
        });
        setChartInfo([].concat(items));
    };

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const updateItem = () => {
        console.log("999", itemBody);
        axiosPrivate
            .put("/api/user/chart/update", itemBody)
            .then(function (response) {
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "カルテ情報を更新しました。",
                    });
                }
            })
            .catch(function (response) {
                console.error(response);
            });
    };
    const navigate = useNavigate();
    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };

    useEffect(() => {
        getChartInfo();
    }, []);

    return (
        <Stack className="Common">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　カルテ</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
            <br />
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section style={{ maxWidth: "800px" }}>
                        <div
                            className="template-customize-mypage-area"
                            style={{
                                justifyContent: "right",
                                display: "grid",
                            }}
                        >
                            <Button
                                className="rounded-pill btn-blue-2"
                                onClick={handleMypage}
                            >
                                マイページへ戻る
                            </Button>
                        </div>
                        <Form noValidate className="form-area" type="text">
                            {chartInfos.map((item, index) => {
                                return (
                                    <item.component
                                        key={`inquiry_${index}`}
                                        item={item}
                                    />
                                );
                            })}
                        </Form>
                        <br />
                        <div className="template-customize-area">
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                onClick={() => {
                                    updateItem();
                                }}
                            >
                                {hasData
                                    ? "カルテ情報を更新する"
                                    : "カルテ情報を更新する"}
                            </Button>
                        </div>
                        <br />
                        <br />
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Stack>
    );
}
