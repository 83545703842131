import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Stack } from "react-bootstrap";
import ValidationError from "../ValidationError";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import Cookies from "universal-cookie";
import AlertModal from "../AlertModal";
import { Link, useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";

export default function ChangePassword(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [block, setBlock] = useState(false);
    const [validated, setValidated] = useState(false);

    const [pass, setPass] = useState("");

    const navigate = useNavigate();
    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const cookies = new Cookies();

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const blockChange = (event) => {
        setBlock(true);
    };

    const handlePass = (event) => {
        setPass(event.target.value);
        setBlock(true);
    };

    const passSubmit = (data) => {
        var body = {
            oldPassword: data.password,
            newPassword: data.newPass,
        };
        axiosPrivate
            .put("/api/user/changepassword", body)
            .then(function (response) {
                if (response.status === 200 && response.data.apiCode === 0) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "パスワードを変更しました。",
                    });
                    setValue("password", "");
                    setValue("newPass", "");
                    setValue("confirmPass", "");
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.message,
                    });
                }
            })
            .catch(function (response) {
                setAlert({
                    ...alert,
                    show: true,
                    message: "パスワードの変更が失敗しました。",
                });
            });
    };

    return (
        <>
            <Stack className="Common">
                <AlertModal
                    show={alert.show}
                    setShow={handleShow}
                    message={alert.message}
                />

                <div
                    className="under_title g-0 row"
                    style={{ textAlign: "left" }}
                >
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <h1>　パスワード変更</h1>
                    </div>
                    <div className="col-lg-1"></div>
                </div>

                <div className="g-0 row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <section style={{ maxWidth: "600px" }}>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit(passSubmit)}
                            >
                                <div
                                    className="template-customize-mypage-area"
                                    style={{
                                        justifyContent: "right",
                                        display: "grid",
                                    }}
                                >
                                    <Button
                                        className="rounded-pill btn-blue-2"
                                        onClick={handleMypage}
                                    >
                                        マイページへ戻る
                                    </Button>
                                </div>

                                <Form.Group className="my-3">
                                    <Form.Label>現在のパスワード　</Form.Label>
                                    <Form.Label className="required-lable"></Form.Label>
                                    <Form.Control
                                        type="password"
                                        {...register("password", {
                                            required: {
                                                value: true,
                                                message:
                                                    "既存のパスワードを入力してください。",
                                            },
                                            onChange: blockChange,
                                        })}
                                    />
                                    {errors.password && (
                                        <ValidationError
                                            message={errors.password.message}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group className="my-3">
                                    <Form.Label>新しいパスワード　</Form.Label>
                                    <Form.Label className="required-lable"></Form.Label>
                                    <Form.Control
                                        type="password"
                                        {...register("newPass", {
                                            required: {
                                                value: true,
                                                message:
                                                    "英数特殊記号混在8文字以上を入力してください。",
                                            },
                                            onChange: (e) => handlePass(e),
                                            minLength: {
                                                value: 8,
                                                message:
                                                    "英数特殊記号混在8文字以上を入力してください。",
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/,
                                                message:
                                                    "英数特殊記号混在8文字以上を入力してください。",
                                            },
                                        })}
                                    />
                                    {errors.newPass && (
                                        <ValidationError
                                            message={errors.newPass.message}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group className="my-3">
                                    <Form.Label>
                                        新しいパスワード確認用　
                                    </Form.Label>
                                    <Form.Label className="required-lable"></Form.Label>
                                    <Form.Control
                                        type="password"
                                        {...register("confirmPass", {
                                            required: {
                                                value: true,
                                                message:
                                                    "確認用パスワードを入力してください。",
                                            },
                                            validate: (value) =>
                                                value === pass ||
                                                "パスワードと一致していません。",
                                            onChange: blockChange,
                                        })}
                                    />
                                    {errors.confirmPass && (
                                        <ValidationError
                                            message={errors.confirmPass.message}
                                        />
                                    )}
                                </Form.Group>

                                <div className="template-customize-area">
                                    <Button
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        パスワードを変更する
                                    </Button>
                                </div>
                            </Form>
                        </section>
                        <br />
                        <br />
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </Stack>
        </>
    );
}
