import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Stack } from "react-bootstrap";
import InquiryEntry from "../elements/inquiry/InquiryEntry";
import InquiryCheck from "../elements/inquiry/InquiryCheck";
import InquirySent from "../elements/inquiry/InquirySent";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import ValidationError from "../elements/ValidationError";
import { axiosPrivate } from "../../utils/axiosPrivate";

export default function Inquiry(props) {
    const [sent, setSent] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [inquiry, setInquiry] = useState({
        type: "",
        company: "",
        dept: "",
        name: "",
        email: "",
        content: "",
        agree: false,
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            company: "",
            dept: "",
            name: "",
            email: "",
            content: "",
            agree: false,
        },
    });

    const { state } = useLocation();

    const handleCheck = (event) => {
        setInquiry({ ...inquiry, agree: event.target.checked });
        setValue("agree", event.target.checked);
    };

    const handleConfirm = () => {
        //console.log("confirm clicked")
        setConfirm(!confirm);
        window.scrollTo(0, 0);
    };

    const handleSend = () => {
        var values = getValues();
        const body = JSON.stringify({
            type: values.type,
            company: values.company,
            dept: values.dept,
            name: values.name,
            email: values.email,
            mobile: values.mobile,
            content: values.content,
        });
        axios
			.post("https://api.vciso.jp/api/inquiry", body, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(function (response) {
                if (response.status === 200) {
                    setSent(true);
                    window.scrollTo(0, 0);
                }
            })
            .catch(function (response) {
                console.error(response);
            });
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (state && state.type !== "undefined") {
            //console.log(state)
            if (isSubscribed) {
                setValue("type", state.type);
            }
        }

        return () => (isSubscribed = false);
    }, [state]);

    useEffect(() => {
        if (props.user) {
            axiosPrivate
                .get("/api/user/1")
                .then(function (response) {
                    //console.log(response)
                    if (
                        response.status === 200 &&
                        response.data.apiCode === 0
                    ) {
                        const data = response.data.data;
                        setValue("company", data.corpName);
                        setValue("dept", data.dept);
                        setValue("name", `${data.lastName} ${data.firstName}`);
                        setValue("email", props.user);
                        setValue("mobile", data.contactInfo);
                    }
                })
                .catch(function (response) {
                    //console.log(response)
                });
        }
    }, [props.user]);

    return (
        <Row className="g-0 Common">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    {confirm && sent ? (
                        <h1>　　お問い合わせ結果</h1>
                    ) : confirm && !sent ? (
                        <h1>　　お問い合わせ確認</h1>
                    ) : (
                        <h1>　　お問い合わせ</h1>
                    )}
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
            <br />

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        {sent ? (
                            <InquirySent />
                        ) : confirm ? (
                            <InquiryCheck
                                inquiry={getValues()}
                                handleConfirm={handleConfirm}
                                handleSend={handleSend}
                                user={sessionStorage.getItem("user")}
                            />
                        ) : (
                            <InquiryEntry
                                handleCheck={handleCheck}
                                register={register}
                                handleSubmit={handleSubmit}
                                errors={errors}
                                inquiry={inquiry}
                                handleConfirm={handleConfirm}
                                user={sessionStorage.getItem("user")}
                            />
                        )}
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Row>
    );
}
