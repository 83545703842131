import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import { useNavigate  } from 'react-router-dom';

import logo from '../../../assets/white_logo.png';

export default function Plans(props) {
  const navigate = useNavigate();
  return (
    <Container id="plans">
      <div className="section-subtitle-block">
        <h2 className="section-title">
          料金プラン
        </h2>
        <p className="subtext">
          プランごとに利用可能な機能が変わります。<br />
          自社に必要な機能が含まれたプランをお選びください。</p>
      </div>

      <Row className="g-0 plans-list">
        <Col md={4} xs={12} className="list-item plan-lite">

            <div className="item-title">Liteプラン</div>
            <div className="item-body">
              <p>とにかくリーズナブルにセキュリティ対策の第一歩を踏み出したいというお客様にピッタリ</p>
              <div className="price">
                <span style={{ "fontSize":"24px" }}>¥</span>
                <span style={{ "fontSize":"40px" }}>15,000</span>
                <span style={{ "fontSize":"18px", "color": "#444" }}> / 月</span>
              </div>
              <div className="price">
                <span style={{ "fontSize":"24px" }}>¥</span>
                <span style={{ "fontSize":"40px" }}>180,000</span>
                <span style={{ "fontSize":"18px", "color": "#444" }}> / 年</span>
              </div>
              <Button className="btn-arrow-plan-lite rounded-pill" style={{ "margin": "auto 0 0 auto" }} onClick={() => navigate("ryokinplan")}>プラン詳細</Button>
            </div>

        </Col>
        <Col md={4} xs={12} className="list-item plan-standard plan-recommend">

            <div className="item-title">Standardプラン</div>
            <div className="item-body">
              <p>Liteプランに加えて当社自信の「教育メニュー」を加えたバランスの取れたオススメプラン</p>
              <div className="price">
                <span style={{ "fontSize":"24px" }}>¥</span>
                <span style={{ "fontSize":"40px" }}>30,000</span>
                <span style={{ "fontSize":"18px", "color": "#444" }}> / 月</span>
              </div>
              <div className="price">
                <span style={{ "fontSize":"24px" }}>¥</span>
                <span style={{ "fontSize":"40px" }}>360,000</span>
                <span style={{ "fontSize":"18px", "color": "#444" }}> / 年</span>
              </div>
              <Button className="btn-arrow-plan-standard rounded-pill" style={{ "margin": "auto 0 0 auto" }} onClick={() => navigate("ryokinplan")}>プラン詳細</Button>
            </div>

        </Col>
        <Col md={4} xs={12} className="list-item plan-premium">

            <div className="item-title">Premiumプラン</div>
            <div className="item-body">
              <p>外部評価やより深いコンサルティングのご検討があるお客様向け</p>
              <div className="price">
                <span style={{ "fontSize":"24px" }}>¥</span>
                <span style={{ "fontSize":"40px" }}>50,000</span>
                <span style={{ "fontSize":"18px", "color": "#444" }}> / 月</span>
              </div>
              <div className="price">
                <span style={{ "fontSize":"24px" }}>¥</span>
                <span style={{ "fontSize":"40px" }}>600,000</span>
                <span style={{ "fontSize":"18px", "color": "#444" }}> / 年</span>
              </div>
              <Button className="btn-arrow-plan-premium rounded-pill" style={{ "margin": "auto 0 0 auto" }} onClick={() => navigate("ryokinplan")}>プラン詳細</Button>
            </div>

        </Col>
      </Row>
      <br/>
      {
        !sessionStorage.getItem("user") ?

          <Row className="g-0">
            <Container>
              <Button className="btn-arrow-yellow" style={{ "width": "100%", "display":"flex", "alignItems":"center" }} onClick={() => navigate("signup")}>
                <img src={logo} alt="vCISO" className="vciso-logo" />
                <span>安心相談サービス</span>
                <span className="subtext">（サイバーリスク保険付き）</span>
                <span>を申し込む</span>
              </Button>
            </Container>
          </Row> :
          null
      }
    </Container>
  )
}