import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container, Stack, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-regular-svg-icons";
import Course from "../elements/elearning/Course";
import AlertModal from "../elements/AlertModal";
import Modal from "react-bootstrap/Modal";
import { axiosPrivate } from '../../utils/axiosPrivate';

export default function ELearning(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
	});

	const [url, setUrl] = useState("")
    const handleShow = (prop) => setAlert({ ...alert, show: prop });


    const handleClick = () => {

        axiosPrivate
			.get("https://api.vciso.jp/api/lms/accesstoken")
            .then(function (response) {
                //console.log(response.data)
                if (response.status === 200 ) {
                // setLmsToken(response.data);
                window.open("https://minasecure.vciso.jp/login/index.php?accessid="+ response.data, "_blank");
                }else{
                // console.log("no token");
                setAlert({ ...alert, show: true, message:"問題が発生しました。管理者にご連絡ください。" });
                // setLmsToken('');
                }
            })
            .catch(function (response) {
                //console.log(response)
                console.log("no token");
                setAlert({ ...alert, show: true, message:"問題が発生しました。管理者にご連絡ください。" });
                // setLmsToken('');
            });

      };

    const lbToBr = (txt) => {
        return txt.split(/(\n)/g).map((t) => (t === "\n" ? <br /> : t));
    };

    const icon = <FontAwesomeIcon icon={faPaste} />;

    const [isShowModal, setIsShowModal] = useState(false);

    const elearnServiceModal = () => {
        return (
            <Modal
                show={isShowModal}
                onHide={setIsShowModal}
                size="xl"
                centered
                className="p-4"
            >
                <Modal.Body className="chat-service-modal">
                    <h2 className="content_title fst_text">
                        <div style={{ textAlign: "center" }}>サンプル文面</div>
                    </h2>
                    <section>
                        <div className="chat-column-height-sm">
                            <div className="g-0 row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div>
                                        <div className="col-sm-12 col-sm pr-sm-2">
                                            <table className="table table-striped table-bordered table-hover">
                                                <thead></thead>
                                                <br />
                                                従業員　各位
                                                <br />
                                                <br />
                                                サイバーセキュリティ対策Eラーニング受講のお願い
                                                <br />
                                                昨今、ニュース等で耳にする「サイバーセキュリティリスク」は、私共にとっても他人事ではございません。業務中や通勤途中の不注意はもちろん、Eメールやインターネットの利用方法について、少し気を配るだけで被害拡大を防ぐ事が可能です。
                                                <br />
                                                <br />
                                                下記、案内をご確認いただき、最近のサイバーセキュリティ対策を学びましょう。
                                                <br />
                                                <br />
                                                <b>【重要】</b>
                                                <br />
                                                ※下記URLよりEラーニングサイトにアクセスしてください。
                                                <br />
                                                ※サイトへアクセス後、Eメールアドレス、パスワードを入力して、次へ進んでください。
                                                <br />
                                                ※すべてのコンテンツを閲覧して学習した後、それぞれの理解度テストを受験してください。
                                                <br />
                                                ※コンテンツ受講、理解度テスト受験が完了すると、管理者側のシステムに完了履歴が残ります。
                                                <br />
                                                <br />
                                                コンテンツ一覧（それぞれ理解度テスト付）
                                                <br />
                                                はじめに『あなたと組織を守るために』
                                                <br />
                                                ランサムウェアによる被害
                                                <br />
                                                標的型攻撃による機密情報の漏えい
                                                <br />
                                                テレワーク等のニューノーマルな働き方を狙った攻撃
                                                <br />
                                                <br />
                                                ■コンテンツ閲覧は、コチラから：https://（お客様用URL）
                                                <br />
                                                <br />
                                                {/* <tr>
                                                従業員　各位\n\nサイバーセキュリティ対策Eラーニング受講のお願い<br />昨今、ニュース等で耳にする「サイバーセキュリティリスク」は、私共にとっても他人事ではございません。業務中や通勤途中の不注意はもちろん、Eメールやインターネットの利用方法について、少し気を配るだけで被害拡大を防ぐ事が可能です。\n下記、案内をご確認いただき、最近のサイバーセキュリティ対策を学びましょう。\n\n【重要】\n※下記URLよりEラーニングサイトにアクセスしてください。\n※サイトへアクセス後、Eメールアドレス、パスワードを入力して、次へ進んでください。\n※すべてのコンテンツを閲覧して学習した後、それぞれの理解度テストを受験してください。\n※コンテンツ受講、理解度テスト受験が完了すると、管理者側のシステムに完了履歴が残ります。\n\nコンテンツ一覧（それぞれ理解度テスト付）\nはじめに『あなたと組織を守るために』\nランサムウェアによる被害\n標的型攻撃による機密情報の漏えい\nテレワーク等のニューノーマルな働き方を狙った攻撃\n\n■コンテンツ閲覧は、コチラから：https://（お客様用URL）\n",

                                                    <th>受付時間</th>
                                                    <td>
                                                        チャット窓口対応時間帯：平日10時～17時
                                                        <br />
                                                        問合せフォーム：随時受付
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>ご相談内容</th>
                                                    <td>
                                                        原則、情報セキュリティ・サイバーセキュリティに関する内容に限ります。特定のセキュリティ製品、IT製品の挙動、仕様に関する内容については、お答えしかねますので、ご容赦ください。
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>対応方法 回答時間</th>
                                                    <td>
                                                        チャット窓口対応時間帯であれば、ご相談を受け付けてから5分以内を目処に受付のご連絡、ご相談内容の確認をし、即時回答もしくは、専門担当者からご連絡する旨のご案内を致します。問い合わせフォームからのご相談は、ご相談を受け付けた後、平日10時～17時の時間帯でメールでご連絡差し上げます。
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        専門担当者からの連絡
                                                    </th>
                                                    <td>
                                                        専門担当者からはご相談を受け付けてから12時間以内を目処に電子メールにてご一報差し上げます。出来る限り、速やか且つ適切なアドバイスを心がけますが、場合によっては、お時間を要する事もありますので、ご了承ください。
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>注意事項</th>
                                                    <td>
                                                        本サービスは、お客様企業における情報セキュリティ・サイバーセキュリティに関するお悩み、課題に対するアドバイス（助言）をするサービスです。課題に対する根本解決を導くのは、お客様となりますので、ご承知おきください。
                                                    </td>
                                                </tr> */}
                                            </table>
                                        </div>
                                        <tr
                                            style={{
                                                backgroundColor: "lightgray",
                                            }}
                                        ></tr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div
                        className="chat-service-confrim-btn"
                        onClick={() => setIsShowModal(false)}
                    >
                        確認
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

	useEffect(() => {
		axiosPrivate.get("/api/user/elearning")
			.then(function (response) {
				if (response.status === 200) {
					if (response.data.apiCode === 0) {
						console.log(response.data)
						setUrl(response.data.data);
					}
				}
			})
			.catch(function (response) {
				//console.log(response)
			})
	}, [])

    return (
        <Row className="g-0 ELearning">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={lbToBr(alert.message)}
            />

            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　Eラーニング</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            {elearnServiceModal()}

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <div className="doc_lede">
                            <br />
                            <br />
                            <div>
                                Eラーニングは、皆様の日常業務の中でご留意を頂きたいセキュリティ対策をまとめた「情報セキュリティ研修コース」、IPAの情報セキュリティ10大脅威について分かり易く学ぶことができる「IPA情報セキュリティ10大脅威2022
                                組織編」より基本的且つ重要度の高いものを抜粋しております。
                            </div>
                            <br />
                            <div style={{ color: "#EF3851" }}>
                                ※ご注意：発行されたURLは貴社内の従業員の皆様に共有頂く事が可能ですが、社外への共有やいかなるコンテンツのコピーは禁止行為となりますのでご注意ください。
                            </div>
                        </div>
                    </section>

                    <h2 className="content_title">
                        <div>Eラーニングご利用手順</div>
                    </h2>
                    <section>
                        <div className="ELearning-guide-line">
                            <ul className="contents-area">
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                STEP
                                            </div>
                                            <div className="num">1</div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <h3>貴社専用URLをご確認ください</h3>
                                            <p>
                                                GSXが提供するセキュリティEラーニング”MinaSecure
                                                ”の サイトにアクセスします。
                                            </p>
                                            <div className="white_cont">
                                                貴社専用URL：
                                                
                                                <button type="button" onClick={handleClick}>
													{url}
                                                </button>
                                               
                                            </div>
                                            <p>
                                                このURLは、貴社専用です。貴社の従業員様以外の方へのご案内は、お控えください。
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                STEP
                                            </div>
                                            <div className="num">2</div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <h3>従業員の皆さまの登録</h3>
                                            <p>
                                                管理者権限にてログイン頂き、ご利用ください。
                                                <br />
                                                従業員の皆さまにご利用頂くにあたり、事前に皆様のアカウントの作成及び講座の登録が必要となります。
                                            </p>
                                            <p>
                                                登録マニュアルはこちら　
                                                <a href="https://anshin.vciso.jp/manual">
                                                    https://anshin.vciso.jp/manual
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                STEP
                                            </div>
                                            <div className="num">3</div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <h3>URLを従業員の皆さまに案内</h3>
                                            <div className="white_cont">
                                                <h4>
                                                    従業員様各自で受講及び理解度テストの実施
                                                </h4>
                                                <ol>
                                                    <li>
                                                        はじめに『あなたと組織を守るために』
                                                    </li>
                                                    <li>
                                                        ランサムウェアによる被害
                                                    </li>
                                                    <li>
                                                        標的型攻撃による機密情報の漏えい
                                                    </li>
                                                    <li>
                                                        テレワーク等のニューノーマルな働き方を狙った攻撃
                                                    </li>
                                                </ol>
                                            </div>
                                            <p>
                                                下記にご案内メール文面サンプルをご用意しております。コピー&ペーストして、ご利用ください。
                                            </p>
                                            {/* <p><a href="#" className="modal_open link">サンプル文面へ</a></p> */}
                                            <div
                                                className="chat-link"
                                                onClick={() =>
                                                    setIsShowModal(true)
                                                }
                                            >
                                                サンプル文面はこちら
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="step">
                                        <div className="step_box">
                                            <div className="step_head">
                                                STEP
                                            </div>
                                            <div className="num">4</div>
                                        </div>
                                    </div>
                                    <div className="step_contents">
                                        <div>
                                            <h3>受講履歴の確認</h3>
                                            <p>
                                                管理者権限にてログイン頂き、ご利用ください。
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>

            {/* <Row className="g-0">
                <Col />
                <Col
                    sm={12}
                    md={10}
                    lg={10}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                        }}
                        className="py-4"
                    >
                        <h2 style={{ alignSelf: "center" }}>Eラーニング</h2>
                        <hr style={{ width: "100%" }} />
                        <div>
                            Eラーニングは、皆様の日常業務の中でご留意を頂きたいセキュリティ対策をまとめた「情報セキュリティ研修コース」、IPAの情報セキュリティ10大脅威について分かり易く学ぶことができる「IPA情報セキュリティ10大脅威2022
                            組織編」より基本的且つ重要度の高いものを抜粋しております。
                        </div>
                        <br />
                        <div style={{ color: "red" }}>
                            ※ご注意：発行されたURLは貴社内の従業員の皆様に共有頂く事が可能ですが、社外への共有やいかなるコンテンツのコピーは禁止行為となりますのでご注意ください。
                        </div>
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className="ELearning-guide-line">
                        <div className="title-area">
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    padding: "10px",
                                    marginBottom: "1px",
                                    backgroundColor: "#00008B",
                                }}
                            >
                                <h3>Eラーニングご利用手順</h3>
                            </div>
                        </div>
                        <div className="content-area">
                            <div className="content-1">
                                <div className="content-left-1 content-layout">
                                    <h3>❶貴社専用URLをご確認ください</h3>
                                    <p>
                                        ※GSXが提供するセキュリティEラーニング”MinaSecure
                                        ”の サイトにアクセスします。
                                    </p>
                                </div>
                                <div className="content-right-1 content-layout">
                                    <p className="content-url">
                                        貴社専用URL：
                                        <font color="#FF0000">
                                            <b>URL準備中です。</b>
                                        </font>
                                    </p>
                                    <p>
                                        ※このURLは、貴社専用です。貴社の従業員様以外の方へのご案内は、お控えください。
                                    </p>
                                </div>
                            </div>
                            <div className="content-arrow">
                                <div className="arrow_down"></div>
                            </div>

                            <div className="content-2">
                                <div className="content-left-2 content-layout">
                                    <h3>➋従業員の皆さまの登録</h3>
                                    <p>
                                        ※管理者権限にてログイン頂き、ご利用ください。
                                    </p>
                                </div>
                                <div className="content-right-2 content-layout">
                                    <div>
                                        <p>
                                            従業員の皆さまにご利用頂くにあたり、事前に皆様のアカウントの作成及び講座の登録が必要となります。
                                        </p>
                                        <p className="url-text">
                                            ※登録マニュアルはこちら
                                            <a href="https://anshin.vciso.jp/manual">
                                                https://anshin.vciso.jp/manual
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="content-arrow">
                                <div className="arrow_down"></div>
                            </div>

                            <div className="content-3">
                                <div className="content-left-3 content-layout">
                                    <h3>❸URLを従業員の皆さまに案内</h3>
                                    <div>
                                        <h4>
                                            従業員様各自で受講及び理解度テストの実施
                                        </h4>
                                        <ul>
                                            <p>
                                                　1.はじめに『あなたと組織を守るために』
                                            </p>
                                            <p>　2.ランサムウェアによる被害</p>
                                            <p>
                                                　3.標的型攻撃による機密情報の漏えい
                                            </p>
                                            <p>
                                                　4.テレワーク等のニューノーマルな働き方を狙った攻撃
                                            </p>
                                        </ul>
                                    </div>
                                </div>
                                <div className="content-right-3 content-layout">
                                    <div>
                                        ※下記にご案内メール文面サンプルをご用意しております。コピー&amp;ペーストして、ご利用ください。
                                        <div
                                            className="container"
                                            onClick={() => {
                                                setAlert({
                                                    show: true,
                                                    message:
                                                        "従業員　各位\n\nサイバーセキュリティ対策Eラーニング受講のお願い\n昨今、ニュース等で耳にする「サイバーセキュリティリスク」は、私共にとっても他人事ではございません。業務中や通勤途中の不注意はもちろん、Eメールやインターネットの利用方法について、少し気を配るだけで被害拡大を防ぐ事が可能です。\n下記、案内をご確認いただき、最近のサイバーセキュリティ対策を学びましょう。\n\n【重要】\n※下記URLよりEラーニングサイトにアクセスしてください。\n※サイトへアクセス後、Eメールアドレス、パスワードを入力して、次へ進んでください。\n※すべてのコンテンツを閲覧して学習した後、それぞれの理解度テストを受験してください。\n※コンテンツ受講、理解度テスト受験が完了すると、管理者側のシステムに完了履歴が残ります。\n\nコンテンツ一覧（それぞれ理解度テスト付）\nはじめに『あなたと組織を守るために』\nランサムウェアによる被害\n標的型攻撃による機密情報の漏えい\nテレワーク等のニューノーマルな働き方を狙った攻撃\n\n■コンテンツ閲覧は、コチラから：https://（お客様用URL）\n",
                                                });
                                            }}
                                        >
                                            <a
                                                href="#"
                                                className="modal_open link"
                                            >
                                                サンプル文面へ
                                            </a>
                                        </div>
                                        <div className="modal">
                                            <p className="modal_title">
                                                modal title
                                            </p>
                                            <p className="modal_text">
                                                モーダルのテキストが表示されます。
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-arrow">
                                <div className="arrow_down"></div>
                            </div>
                            <div className="content-4">
                                <div className="content-left-4 content-layout">
                                    <h3>❹受講履歴の確認</h3>
                                    <p>
                                        ※管理者権限にてログイン頂き、ご利用ください。
                                    </p>
                                </div>
                                <div className="content-right-3 content-layout">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col />
            </Row> */}
        </Row>
    );
}
