import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function InquirySent(props) {
    const navigate = useNavigate();
    const handleTop = () => {
        // setSuccess(false)
        navigate("/", { replace: true });
        window.location.reload();
    };
    return (
        <Row className="g-0 ">
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box"></div>

                <section style={{ padding: "0 10px" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="inquiry-width customize-width"
                    >
                        <br />
                        <br />
                        <div>お問い合わせの登録が完了しました。</div>
                        <br />
                        <div>
                            ご回答につきましてはメールにて返信させて頂きます。​
                        </div>
                        <br />
                        <div>
                            尚、ご回答までにお時間がかかる場合がございますので、ご了承ください。
                        </div>
                        <br />
                        <br />

                        <Row className="template-customize-area button-flex">
                            <Button
                                className="btn btn-primary"
                                onClick={handleTop}
                            >
                                トップページへ
                            </Button>
                        </Row>
                        <br />
                        <br />
                    </div>
                </section>
            </div>
        </Row>
    );
}
