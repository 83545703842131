import React, { useEffect, useState, useMemo} from 'react';
import Article from '../elements/home/Article';
import {
    Row, Col, Placeholder, Container
} from 'react-bootstrap';
import getRSSFeed from '../../utils/getRSSFeed';


function PlaceholderArticle() {
    return (
        <div style={{ "height": "340px", "width": "270px"}}>
            <Placeholder as="div" animation="glow" >
                <Placeholder xs={12} style={{ "height": "150px", "width": "250px"}}/>
            </Placeholder>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={5} size="sm"/>
            </Placeholder>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={7} size="sm" className="me-1"/>
                <Placeholder xs={3} size="sm" className="me-1"/>
                <Placeholder xs={6} size="sm" className="me-1"/>
                <Placeholder xs={5} size="sm" className="me-1"/>
                <Placeholder xs={4} size="sm" className="me-1"/>
            </Placeholder>
        </div>
    )
}

export default function Interviews(props) {
    const [articleList, setArticleList] = useState([]);
    const [results, setResults] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const { articles } = props;

    const placeholders = Array(4).fill(<PlaceholderArticle />);

    useMemo(() => {
        var articleList = [];
        let feed = getRSSFeed("https://articles.vciso.jp/feed");
        console.log(feed)
        feed.then(feed => {
            var shortenedList = feed.items.slice(0, 8)
            shortenedList.forEach((item, index) => {
                var imgRegex = /([^\s]+)/
                var imgSrc = item.description.substring(item.description.indexOf("src")).match(imgRegex)[1]
                var img = imgSrc.substring(imgSrc.indexOf("=") + 2, imgSrc.length - 1)
                var articleObj = {
                    key: 20000 + index,
                    image: img,
                    title: item.title,
                    date: item.isoDate.split("T")[0],
                    link: item.link
                }
            
                setArticleList(existing => [...existing, articleObj])
                
            })
        })
    
        
    }, [])

    useEffect(() => {
        let isSubscribed = true;
        if (articleList.length > 0) {
            console.log(articleList)
            //console.log("checking")
            var sortedArticles = articleList.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
              });
            var temparticles = []
            sortedArticles.forEach(article => {
                let newArticle = <Article image={article.image} title={article.title} date={article.date} link={article.link} companyName={article.companyName}/>
                temparticles.push(newArticle)
            })
            if (isSubscribed) {
                setResults(temparticles)
                setLoaded(true)
            }
        }

        return () => isSubscribed = false;
    }, [articleList])

    console.log(articleList)
    console.log(results)
    return (
        <Row className="g-0 py-3 fill-height-sm fill-height-md fill-height-xl">
            <Col />
            <Col lg={8} md={10} sm={10} xs={10}>
            <Row className="g-0">
                <Container className="article-list">
                    <Row className="g-4 articles" style={{ "marginBottom": "60px" }}>
                        { loaded ?
                            
                            results.map((article, index) => (
                                <Col xs={6} md={4} lg={3} xl={3} className="px-2" key={`${article.title}_${index}`}>
                                    {article}
                                </Col>
                            )) :
                            placeholders.map((article, index) => (
                                <Col xs={6} md={4} lg={3} xl={3} className="px-2" key={`${article.title}_${index}`}>
                                    {article}
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </Row>
            </Col> 
            <Col />
        </Row>
    )
}