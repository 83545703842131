import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import CustomizeSent from "../elements/doctemplates/CustomizeSent";
import AlertModal from "../elements/AlertModal";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";

export default function TemplateCustomize(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [request, setRequest] = useState({
        subject: "",
        content_id_parent: "",
        content_id_child: "",
        request: "",
    });

    const [check, setCheck] = useState(false);
    const [sent, setSent] = useState(false);

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            content_id_parent: "テンプレートの種類を選択する",
            content_id_child: "",
        },
    });

    const cookies = new Cookies();
    const navigate = useNavigate();
    const { state } = useLocation();

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    /*
    const docs = {
        テレワーク規程及びテレワークガイドラインテンプレート: [
            "テレワーク規程​",
            "テレワーク申請書/指名書（様式）",
            "テレワークセキュリティガイドライン(管理者用)​",
            "テレワークセキュリティガイドライン(利用者用)​"
        ],
        スマートデバイス管理: [
            "スマートデバイス利用誓約書/申請書​",
            "スマートデバイス利用変更申請書/解除申請書​",
            "業務利用向けスマートデバイス管理要件​"
        ],
        クラウドサービス利用及び委託先管理: [
            "クラウドサービス利用規程​",
            "クラウドサービス利用ガイドライン​",
            "クラウドサービス業務利用登録申請書​",
            "クラウドサービスチェックリスト/ユーザ登録申請書​",
            "クラウドサービス管理リスト​",
            "委託先管理チェックリスト（様式）​"
        ],
        セキュリティポリシー: [
            '情報セキュリティ管理規定',
            '情報セキュリティ内部監査規定',
            '情報セキュリティインシデント対応規定'
        ],
        CSIRT文書: [
            'CSIRT管理規程',
            'CSIRTの体制（例）',
            'インシデント対応基本フロー'
        ]
    }
*/
    const docs = {
        セキュリティポリシー: [
            "情報セキュリティ管理規定",
            "情報セキュリティ内部監査規定",
            "情報セキュリティインシデント対応規定",
        ],
    };

    const onSubmit = () => {
        setCheck(true);
    };

    const handleSend = () => {
        var values = getValues();
        //console.log(request)

        var formData = new FormData();
        formData.append("subject", values.subject);
        formData.append("content_id_parent", request.content_id_parent);
        formData.append("content_id_child", request.content_id_child);
        formData.append("request", values.request);
        formData.append("email", sessionStorage.getItem("user"));

        axios
            .post(
                "https://api.vciso.jp/api/doc/customize",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then(function (response) {
                if (response.status === 200) {
                    setSent(true);
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "送信が失敗しました。",
                    });
                }
            })
            .catch(function (response) {
                console.error(response);
                setAlert({
                    ...alert,
                    show: true,
                    message: "送信が失敗しました。",
                });
            });
    };

    const handleChange = (prop) => (event) => {
        //console.log(event.target.value)
        setRequest({ ...request, [prop]: event.target.value });
    };

    return (
        <div>
            <Row className="g-0 DocTemplateCustomize">
                <AlertModal
                    show={alert.show}
                    setShow={handleShow}
                    message={alert.message}
                />
                <div
                    className="under_title g-0 row"
                    style={{ textAlign: "left" }}
                >
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        {sent ? (
                            <h1>　セキュリティ文書カスタマイズ依頼完了</h1>
                        ) : null}
                        {!sent && check ? (
                            <h1>　セキュリティ文書カスタマイズ依頼確認</h1>
                        ) : (
                            null
                        )}

                        {!sent && !check ? (
                            <h1>　セキュリティ文書カスタマイズ依頼</h1>
                        ) : (
                            null
                        )}
                    </div>
                    <div className="col-lg-1"></div>
                </div>

                {sent ? (
                    <CustomizeSent/>
                ) : (
                    <div className="g-0 row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10 box">
                            <br />
                            <br />
                            <section>
                                <div className="doc_lede">
                                    <div>
                                        こちらでは本サービスよりご提供しているセキュリティ文書テンプレートに対して貴社運用や規定、業界特性に合わせたカスタマイズ（有償）の受付となります。カスタマイズを行いたい文書とカスタマイズ概要を記載し登録頂けましたら、弊社担当者より登録されているメールアドレス又は電話へご連絡させて頂きます。
                                    </div>
                                </div>
                            </section>

                            <section>
                                <Form
                                    className="inquiry-width customize-width"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Form.Group className="form_input_area">
                                        <Form.Label className="form-label">
                                            件名を入力してください
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="例：テレワークセキュリティガイドライン第3版作成について"
                                            readOnly={check}
                                            {...register("subject", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "件名を入力してください。",
                                                },
                                            })}
                                        />
                                        {errors.subject && (
                                            <ValidationError
                                                message={errors.subject.message}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group className="form_input_area">
                                        <Form.Label className="form-label">
                                            カスタマイズを依頼する文書を選択してください
                                        </Form.Label>
                                        <div className="g-0 row form_select">
                                            <div
                                                className="me-3"
                                                style={{ width: "490px" }}
                                            >
                                                <Form.Select
                                                    className="form-select"
                                                    value={
                                                        request.content_id_parent
                                                    }
                                                    onChange={handleChange(
                                                        "content_id_parent"
                                                    )}
                                                    disabled={check}
                                                >
                                                    <option hidden="">
                                                        テンプレートの種類を選択する
                                                    </option>
                                                    <option value="セキュリティポリシー">
                                                        セキュリティポリシー
                                                    </option>
                                                </Form.Select>
                                            </div>
                                            <div style={{ width: "490px" }}>
                                                <select
                                                    disabled={check}
                                                    className="form-select"
                                                >
                                                    <option hidden="">
                                                        文書を選択する
                                                    </option>
                                                    {request.content_id_parent
                                                        .length > 0
                                                        ? docs[
                                                              request
                                                                  .content_id_parent
                                                          ].map(
                                                              (doc, index) => {
                                                                  return (
                                                                      <option
                                                                          value={
                                                                              doc
                                                                          }
                                                                          key={`${doc}_${index}`}
                                                                      >
                                                                          {doc}
                                                                      </option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="form_input_area">
                                        <Form.Label className="form-label">
                                            依頼内容を入力してください
                                        </Form.Label>
                                        <Form.Control
                                            className="form-control"
                                            as="textarea"
                                            rows={5}
                                            type="text"
                                            {...register("request", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "依頼内容を入力してください。",
                                                },
                                            })}
                                            readOnly={check}
                                        />
                                        {errors.request && (
                                            <ValidationError
                                                message={errors.request.message}
                                            />
                                        )}
                                        <br />
                                        <br />

                                        <Row className="template-customize-area button-flex">
                                            {check ? (
                                                <Button
                                                    className="btn btn-primary btn-margin"
                                                    onClick={() =>
                                                        setCheck(false)
                                                    }
                                                >
                                                    再入力
                                                </Button>
                                            ) : null}
                                            {check ? (
                                                <Button
                                                    className="btn btn-primary"
                                                    onClick={handleSend}
                                                >
                                                    送信
                                                </Button>
                                            ) : (
                                                <Button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                >
                                                    確認画面へ
                                                </Button>
                                            )}
                                        </Row>
                                    </Form.Group>
                                </Form>

                                <br />
                                <br />
                            </section>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                )}
            </Row>
        </div>
    );
}
