import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Placeholder } from "react-bootstrap";
import Article from "./Article";
import thumbnail from "../../../assets/thumbnail_sample.jpg";
import getRSSFeed from "../../../utils/getRSSFeed";
import { useNavigate } from "react-router-dom";

function PlaceholderArticle() {
    return (
        <div style={{}}>
            <Placeholder as="div" animation="glow">
                <Placeholder
                    xs={12}
                    style={{ height: "150px", width: "100%" }}
                />
            </Placeholder>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={5} size="sm" />
            </Placeholder>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={7} size="sm" className="me-1" />
                <Placeholder xs={3} size="sm" className="me-1" />
                <Placeholder xs={6} size="sm" className="me-1" />
                <Placeholder xs={5} size="sm" className="me-1" />
                <Placeholder xs={4} size="sm" className="me-1" />
            </Placeholder>
        </div>
    );
}

export default function ArticleGrid(props) {
    const [articles, setArticles] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const { gridTitle, buttonText, category, loaded, setLoaded, buttonPath } =
        props;

    const navigate = useNavigate();

    //const sampleArticle = <Article link="#" date="2022/05/10" title="Corporate Sample Title" image={thumbnail} />
    //let articleList = Array(articleNumber).fill(sampleArticle);

    /*
    useEffect(() => {
       
            let feed = getRSSFeed("https://vciso.gsx-its.com/feed");
            feed.then(feed => {
                var articleList = []
                //console.log(feed)
                feed.items.forEach(item => {
                    //console.log(item)
                    if (item.categories.includes(category)) {
                        var imgRegex = /([^\s]+)/
                        var imgSrc = item.description.substring(item.description.indexOf("src")).match(imgRegex)[1]
                        var img = imgSrc.substring(imgSrc.indexOf("=") + 2, imgSrc.length - 1)
                        var dateRegex = /[^\s]*\s[^\s]*\s[^\s]*\s[^\s]*\s/
                        //console.log(item.pubDate);
                        var date = item.pubDate.match(dateRegex)[0].trim()
                        //console.log(date);
                        //console.log(imgSrc);
                        let article = <Article image={img} title={item.title} date={date} link={item.link} />
                        articleList.push(article);
                    }
                    
                })
                setArticles(articleList);
            })

    }, [])
*/
    const articleToggle = (event) => {
        event.preventDefault();
        setShowAll(!showAll);
    };

    const placeholders = Array(4).fill(<PlaceholderArticle />);

    /*
    articles.map((article, index) => (
        <Col xs={6} md={4} lg={3} xl={3} className="px-2" key={`${article.title}_${index}`}>
            {article}
        </Col>
    ))
    */
    useEffect(() => {
        let isSubscribed = true;
        if (props.articleList.length > 0) {
            var sortedArticles = props.articleList.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            var articles = [];
            sortedArticles.forEach((article) => {
                let newArticle = (
                    <Article
                        image={article.image}
                        title={article.title}
                        date={article.date.split("T")[0]}
                        link={article.link}
                        companyName={article.companyName}
                    />
                );
                articles.push(newArticle);
            });
            if (isSubscribed) {
                setArticles(articles);
                setLoaded(true);
            }
        }
        return () => (isSubscribed = false);
    }, [props.articleList]);
    return gridTitle !== "GSXオリジナルコンテンツ" ? (
        <Container className="article-list">
            <div className="section-title-block">
                <h2 className="section-title">{gridTitle}</h2>
                <p className="subtext">
                    自衛力を向上すべく
                    <br />
                    セキュリティNewsをチェックする習慣をつけましょう
                </p>
            </div>
            <Row className="g-4 articles" style={{ marginBottom: "60px" }}>
                {loaded
                    ? articles.slice(0, 8).map((article, index) => (
                          <Col
                              className="article-item"
                              xs={6}
                              md={4}
                              lg={3}
                              xl={3}
                              key={`${article.title}_${index}`}
                          >
                              {article}
                          </Col>
                      ))
                    : placeholders.map((article, index) => (
                          <Col
                              xs={6}
                              md={4}
                              lg={3}
                              xl={3}
                              key={`${article.title}_${index}`}
                          >
                              {article}
                          </Col>
                      ))}
            </Row>
            <Row>
                <Container
                    className="justify-content-center"
                    style={{ display: "flex" }}
                >
                    {loaded ? (
                        <a
                            className="rounded-pill btn-arrow-blue"
                            onClick={() => navigate(buttonPath)}
                        >
                            {buttonText}
                        </a>
                    ) : null}
                </Container>
            </Row>
        </Container>
    ) : (
        <Container className="py-3 article-list">
            <div className="section-title-block">
                <h2 className="section-title">{gridTitle}</h2>
                <p className="subtext">
                    日本企業の自衛力向上に向けて
                    <br />
                    GSXが独自で積み上げてきたノウハウを惜しげもなくお伝えします
                </p>
            </div>
            {/* コンテンツ形式不明のため、GSXオリジナルコンテンツの場合はimage, title, linkのみのデータを想定してArticleに実装しております */}
            <Row className="g-4 articles" style={{ marginBottom: "60px" }}>
                {loaded
                    ? articles.slice(0, 8).map((article, index) => (
                          <Col
                              className="article-item"
                              xs={6}
                              md={4}
                              lg={3}
                              xl={3}
                              key={`${article.title}_${index}`}
                          >
                              {article}
                          </Col>
                      ))
                    : placeholders.map((article, index) => (
                          <Col
                              xs={6}
                              md={4}
                              lg={3}
                              xl={3}
                              key={`${article.title}_${index}`}
                          >
                              {article}
                          </Col>
                      ))}
            </Row>

            {/*
              <Row className="g-0">
                  <Col xs={4} />
                  <Col xs={4} style={{ "textAlign": "center"}}>
                      <h3 className="my-4">準備中</h3>
                  </Col>
                  <Col xs={4} />
              </Row>
              */}

            <Row>
                <Container
                    className="justify-content-center"
                    style={{ display: "flex" }}
                >
                    {loaded ? (
                        <a
                            className="rounded-pill btn-arrow-blue"
                            onClick={() => navigate(buttonPath)}
                        >
                            {buttonText}
                        </a>
                    ) : null}
                </Container>
            </Row>
        </Container>
    );
}
