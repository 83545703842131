import React from 'react'
import {
    Row, Col, Stack
} from 'react-bootstrap';
import { useNavigate } from 'react-router';


export default function FAQ(props) {
    const navigate = useNavigate();

    return (
        <Row className="g-0">
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}} className="py-4">
                    <h2 style={{ "alignSelf": "center"}}>よくある質問</h2>
                    <hr style={{ "width": "100%"}}/>
                    <Stack gap={3} className="p-3">
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"Q.    月額いくらですか？"}</h5>
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"A.    プラン毎に異なりますので、"}<a href="#" onClick={() => navigate("/ryokinplan")}>料金プランページ</a>をご確認ください。</h5>
                        <hr style={{ "width": "100%"}} />
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"Q.    途中解約できますか？"}</h5>
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"A.    申し訳ございませんが、年間のご契約となります。尚、契約期間満了の1ヶ月前までにお申し入れがあった場合は翌年の更新はございません。"}</h5>
                        <hr style={{ "width": "100%"}} />
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"Q.    途中でアップグレードもしくはダウングレードできますか？"}</h5>
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"A.    アップグレードの場合、別途差額のお支払いにより可能です。尚、期中のダウングレードはお受け出来ませんので、ご了承ください。"}</h5>
                        <hr style={{ "width": "100%"}} />
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"Q.    1アカウントで複数⼈使えますか？"}</h5>
                        <h5 style={{ "whiteSpace": "pre-wrap"}}>{"A.    アカウントの共有は出来ませんが、1契約につきサブアカウントが4つまで追加可能ですので、複数でご利用の際はこちらの機能をご利用ください。"}</h5>
                        <br />
                        <br />
                    </Stack>
                </div>
            </Col>
            <Col />
        </Row>
    )
}