import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, Container, Form
} from 'react-bootstrap'
import AlertModal from '../AlertModal';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { axiosPrivate } from '../../../utils/axiosPrivate';

export default function IncidentDetails(props) {

    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })

    const [pics, setPICS] = useState([])

    const [incident, setIncident] = useState({})

    const { state } = useLocation();
    const { register, handleSubmit, setValue, getValues, formState: { errors }} = useForm({
        defaultValues: {
            pic: "",
            status: "",
            memo: "",
            content: "",
            type: ""
        }
    });

    const handleStatusChange = (event) => {
        setValue("status", event.target.value)
       // console.log(getValues())
    }
    const handlePICChange = (event) => {
        console.log("triggered")
        setValue("pic", event.target.value)
        //console.log(getValues())
    }

    const handleShow = (prop) => setAlert({ ...alert, show: prop})

    const onSubmit = (data) => {
      //  console.log(incident)
       // console.log(data)

        var body = {
            "pic": data.pic,
            "status": data.status,
            "memo": data.memo
        }

        axiosPrivate.put(`/api/admin/incidents/${incident.id}`, body)
        .then(function(response) {
            if (response.status === 200) {
                if (response.data.apiCode === 0) {
                    setAlert({ ...alert, show: true, message: response.data.message})
                }
            }
        })
        .catch(function(response) {
            setAlert({ ...alert, show: true, message: response.message })
        })
    }

    useEffect(() => {
        let isSubscribed = true;
        if (state && state.incident !== 'undefined') {
           // console.log(state)
            if (isSubscribed) {
                setIncident(state.incident)
                setValue("status", state.incident.status)

                if (state.incident.memo !== null) {
                    setValue("memo", state.incident.memo)
                }
            }
        }

        if (isSubscribed) {
            axiosPrivate.get("/api/pic")
            .then(function(response) {
                if (response.status === 200) {
                    setPICS(response.data)
                   
                    //console.log(response.data)
                }
            })
            .catch(function(response) {
                //console.log(response)
            })
        }

        return () => isSubscribed = false;
    }, [state])

    useEffect(() => {
        if (incident.type !== null && pics.length > 0) {
            if (incident.pic !== null) {
                setValue("pic", `${incident.pic.pic_id}`)
            } else {
                setValue("pic", `0`)
            }
        }
    }, [incident, pics])

    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={10}>
                <div style={{ "width": "100%", "display": "flex", "flexDirection": "column"}}>
                    <h2 style={{ "alignSelf": "left"}}>インシデント対応詳細</h2>
                    <br />
                    <br />
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-0">
                            <Col xs={6}>
                                <Container>
                                    <h4>{incident.companyName}</h4>
                                    <Row className="g-0">
                                        <Col xs={3}>
                                            <h6 className="my-2"><strong>代表者氏名</strong></h6>
                                        </Col>
                                        <Col>
                                            <h6 className="m-2">{incident.userName}</h6>
                                        </Col>
                                    </Row>
                                    {
                                        incident.userNameKana !== null ?
                                        
                                        <Row className="g-0">
                                            <Col xs={3}>
                                                <h6 className="my-2"><strong>代表者氏名（カナ）</strong></h6>
                                            </Col>
                                            <Col>
                                                <h6 className="m-2">{incident.userNameKana}</h6>
                                            </Col>
                                        </Row> :
                                        null
                                    }
                                    <Row className="g-0">
                                        <Col xs={3}>
                                            <h6 className="my-2"><strong>メールアドレス</strong></h6>
                                        </Col>
                                        <Col>
                                            <h6 className="m-2">{incident.email}</h6>
                                        </Col>
                                    </Row>
                                    <Row className="g-0">
                                        <Col xs={3}>
                                            <h6 className="my-2"><strong>電話番号</strong></h6>
                                        </Col>
                                        <Col>
                                            <h6 className="m-2">{incident.phone}</h6>
                                        </Col>
                                    </Row>
                                    
                                </Container>
                            </Col>
                            <Col xs={3}>
                                <Container>
                                    <Form.Group className="my-2" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応状況
                                        </Form.Label>
                                        <Form.Select { ...register("status")}>
                                            <option>未対応</option>
                                            <option>対応中</option>
                                            <option>対応済み</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Container>
                            </Col>
                            <Col xs={3}>
                                <Container>
                                    <Form.Group className="my-2" style={{ "width": "fit-content"}}>
                                        <Form.Label>
                                            対応者
                                        </Form.Label>
                                        <Form.Select {...register("pic", { onChange: handlePICChange})}>
                                            <option value={0}>未選択</option>
                                            {
                                                pics.map((pic, index) => {
                                                    return <option key={index} value={`${pic.pic_id}`}>{`${pic.last_name} ${pic.first_name}`}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Container>
                            </Col>
                        </Row>
                        <div className="my-4 px-2" style={{ "minHeight": "300px"}}>
                            <Row className="g-0">
                              
                                <Col xs={10}>
                                    <Row className="g-0">
                                        <Col xs={3}>
                                            <h6><strong>インシデントの種類</strong></h6>
                                        </Col>
                                        <Col xs={4}>
                                            <h6>{incident.type}</h6>
                                        </Col>
                                        <Col xs={4}>
                                            <h6>{`${incident.occurred_at}頃`}</h6>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col />
                                
                            </Row>
                            <br />
                            <Row className="g-0">
                                <Col xs={10}>
                                    <h6><strong>状況</strong></h6>
                                    <div>{incident.content}</div>
                                </Col>
                                <Col />
                            </Row>
                            <Row className="g-0">
                                <Col xs={10}>
                                    <Form.Group className="my-4">
                                        <Row className="g-0">
                                            <Col xs={6}>
                                                <strong>メモ</strong>
                                            </Col>
                                            <Col xs={6} style={{ "textAlign": "right", "fontSize": ".9rem"}}>
                                                {incident.memo_updated_at !== null ? `メモの更新日時: ${incident.memo_updated_at}`: ""}
                                            </Col>
                                        </Row>
                                        <Form.Control as="textarea" rows={10} { ...register("memo")}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-0">
                                <Col xs={12}>
                                    <Button type="submit">保存する</Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    )
}