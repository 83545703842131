import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AlertModal(props) {
    const { show, setShow, message } = props;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Modal
            size="lg"
            centered
            className="p-5"
            show={show}
            onHide={handleClose}
        >
            <Modal.Body className="chat-service-modal">{message}</Modal.Body>
            
            <Button
                onClick={handleClose}
                className="modal-confrim-btn"
            >
                確認
            </Button>
            <br />
            <br />
        </Modal>
    );
}
