import mem from "mem";
import Cookies from 'universal-cookie';
import { axiosPublic } from "./axiosPublic";
import axios from 'axios';

const cookies = new Cookies();
const refreshTokenFn = async () => {
  const refreshToken = sessionStorage.getItem("refresh");
  const accessToken = sessionStorage.getItem("access_token");
    ////console.log(refreshToken)
    ////console.log(accessToken)
  try {
	  const refreshAxios = axios.create({ baseURL: "https://api.vciso.jp" })
    return await refreshAxios.get("/api/token/refresh", {
        headers: {
            "Authorization": "Bearer " + refreshToken,
        }
    })
    .then(response => {
       // //console.log(response)
        if (response.status === 200) {
            var data = response.data;
            sessionStorage.setItem("refresh", data.refresh_token)
            sessionStorage.setItem("access_token", data.access_token)
            return data;
        }
    })
    .catch(response => {
        //console.log(response)
        //console.log("Refresh error")
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("refresh")
        sessionStorage.removeItem("access_token")
        window.history.pushState({}, '', "/login")
        window.location.reload();
    })
   
  } catch (error) {
    //console.log(error)
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("access_token")
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});