import axios from 'axios';
import Cookies from 'universal-cookie';
import { axiosPrivate } from './axiosPrivate';

export default async function getIncidents(email) {

    var results;
    const cookies = new Cookies();
    //console.log("get incidents called")
    return axiosPrivate.get("/api/incidents/user")
    .then(function(response) {
        results = response.data;
        ////console.log(results)
        return results;
    })
    .catch(function(response) {
        console.error(response)
    })
    /*
    return axios({
        method: "get",
        url: "https://14rphqgebl.execute-api.us-east-1.amazonaws.com/dev/?url=https://vcisoapi.gsx-its.com/api/incidents/user?email=" + email,
        headers: {
            "Authorization": "Bearer " + cookies.get("access_token")
        }
    })
    .then(function(response) {
        results = response.data;
        return results;
    })
    .catch(function(response) {
        console.error(response)
    })
    */
}