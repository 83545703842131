import axios from 'axios';

export default function signupPayment(userId, planId, recurringType, token) {

    var results;
    const body = JSON.stringify({
        "userId": userId,
        "planId": planId,
        "recurringType": recurringType
    })

    const headers = {
        headers: {
            "X-Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        }
    }

	return axios.post("https://api.vciso.jp/api/signup/payment", body, headers)
    .then(function(response) {
        results = response;
        return results;
    })
    .catch(function(error) {
        console.error(error)
    })
}