import {
    Row, Col, Container, Table
} from 'react-bootstrap'

export default function Tokusyou(props) {

    return (
        <div className="py-4 px-5 Tokusyou" style={{ "width": "100%", "display": "flex", "flexDirection": "column" }}>
            <h2 style={{ "alignSelf": "center" }}>特定商取引法に基づく表記​</h2>
            <br />
            <Row>
                <Col />
                <Col sm={12} md={10} lg={10}>
                    <div style={{ "width": "100%", "display": "flex", "flexDirection": "column" }} className="py-4">
                        <Table id="tokusyouTable">
                            <tbody>
                                <tr>
                                    <td className="tokusyou-column-name">
                                        販売業者
                                    </td>
                                    <td>
                                        グローバルセキュリティエキスパート株式会社
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        代表責任者
                                    </td>
                                    <td>
                                        青柳　史郎（戸籍上の氏名　鱸　史郎）
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        所在地
                                    </td>
                                    <td>
                                        <div>〒105-0022</div>
                                        <div>東京都港区海岸1-15-1　スズエベイディアム4F</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        電話番号
                                    </td>
                                    <td>
                                        03-3578-9001
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        電話受付時間
                                    </td>
                                    <td>
                                        9:00～17:30
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        公開メールアドレス
                                    </td>
                                    <td>
                                        <a href="mailto:vCISOJimukyoku@gsx.co.jp">vCISOJimukyoku@gsx.co.jp</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ホームページURL
                                    </td>
                                    <td>
                                        <a href="https://www.gsx.co.jp/" target="_blank" rel="noreferrer">https://www.gsx.co.jp/</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        サービスの対価
                                    </td>
                                    <td>
                                        料金プランページをご参照ください。
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        対価の支払い時期
                                    </td>
                                    <td>
                                        <div>クレジットカード：翌月1日</div>
                                        <div>請求書　　　　　：お取引条件により異なります</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        対価以外の必要料金
                                    </td>
                                    <td>
                                        消費税
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        引き渡し時期
                                    </td>
                                    <td>
                                        クレジットカード決済登録完了後すぐにご利用可能です。
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        お支払方法
                                    </td>
                                    <td>
                                        クレジットカード、請求書
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        契約期間
                                    </td>
                                    <td>
                                        1年間、契約期間満1ヶ月前までに解約登録があれば翌年は契約更新無
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        返品・変更・キャンセル等
                                    </td>
                                    <td>
                                        お支払い後のキャンセルは一切お受けできません
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        解約条件/方法
                                    </td>
                                    <td>
                                        契約期間満了の1ヶ月前までに本サービス画面からの解約の申し込み
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col />
            </Row>

        </div>
    )
}