import React from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as Clock } from '../../../assets/icons8-clock.svg';
import { ReactComponent as Pen} from '../../../assets/svg_icons/pen.svg';
import arrow from '../../../assets/arrow.png';

export default function Article(props) {

    const { title, image, date, link } = props
    return (
        <>
            <Container className="article-box h-100">
                <a href={link} target="_blank">
                    <div className="thumbnail" style={{ "backgroundImage": "url(" + image + ")" }}></div>
                </a>
                <div className="article-body" style={{ "display": "flex", "flexDirection":"column", "height": "100%" }}>
                    { props.companyName ? <div className="source-name"><Pen style={{ "marginRight": "5px" }} />{props.companyName}</div> : null  }
                    <div className="mt-2 article-title">{title}</div>
                    { props.date ? <div className="mt-2 date">
                        <Clock style={{ "marginRight": "5px", "fill": "#777", "width": "19px", "height": "auto" }} />{date}
                    </div> : null }
                    <div className="mb-2 link-arrow" style={{"marginLeft":"5px","marginTop":"auto"}}>
                        <a href={link} target="_blank"><img src={arrow} alt="続きを読む" /></a>
                    </div>
                </div>
            </Container>
        </>
    )
}