import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Button,
    Tabs,
    Tab
} from "react-bootstrap";
import SecCheckInquiryList from "./SecCheckInquiryList";
import SecCheckList from "./SecCheckList";



export default function SecCheckAdmin(props) {

    return (
        <Row className="g-0">
            <Col />
            <Col
                sm={12}
                md={10}
                lg={10}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                
                    <div
                        id="tab-container"
                        style={{
                            width: "100%",
                            background: "read",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Tabs
                            fill
                            justify
                            className="my-4 userinfo-tab"
                            style={{
                                flexWrap: "nowrap",
                                width: "30%",
                                display: "flex",
                                alignItems: "stretch",
                                alignSelf: "center"
                            }}
                        >
                            <Tab
                                eventKey="secCheckList"
                                title="利用申請受付"
                                className=""
                                style={{
                                    fontSize: "1.1rem",
                                    width: "100%",
                                }}
                            >
                                <SecCheckList />
                            </Tab>
                            <Tab
                                eventKey="secCheckInquiry"
                                title="お問い合わせ"
                                className=""
                                style={{
                                    fontSize: "1.1rem",
                                    width: "100%",
                                }}
                            >
                                <SecCheckInquiryList />
                            </Tab>
                        </Tabs>
                    </div>
               
            </Col>
            <Col />
        </Row>
    )
}