import React, { useState, useEffect, useMemo } from 'react';
import Navigation from '../elements/Navigation';
import ServiceNav from '../elements/ServiceNav';
import HomeCarousel from '../elements/home/HomeCarousel';
import ArticleGrid from '../elements/home/ArticleGrid';
import Article from '../elements/home/Article';
import Footer from '../elements/Footer';
import WebServiceGrid from '../elements/home/WebServiceGrid';
import Plans from '../elements/home/Plans';

import contentImage from '../../assets/thumbnail_sample.jpg';
import guyImage from '../../assets/japanese_businessman.jpg';
import logo from '../../assets/logo.png';
import chevron from '../../assets/chevron-up.svg';

import { Row, Col, Button } from 'react-bootstrap';
import getRSSFeed from '../../utils/getRSSFeed';
import getImageFromRSS from '../../utils/getImageFromRSS';
import { useScrollToTop } from '../../utils/useScrollToTop';

import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router';
import { ReactComponent as Headset } from '../../assets/svg_icons/fab-headset.svg'
import { ReactComponent as HeadsetWhite } from '../../assets/svg_icons/fab-headset-white.svg'

import readMessage from '../../utils/readMessage';


function filterArray(array) {
    const uniqueValuesSet = new Set();

    // array of objects with duplicate values

    const filteredArr = array.filter((obj) => {
        // check if name property value is already in the set
        const isPresentInSet = uniqueValuesSet.has(obj.link);

        // add name property value to Set
        uniqueValuesSet.add(obj.link);

        // return the negated value of
        // isPresentInSet variable
        return !isPresentInSet;
    });

    return filteredArr;
}

const companyNames = new Map();
companyNames.set("netsecurity", "ScanNetSecurity");
companyNames.set("itmedia", "ITmedia NEWS");
companyNames.set("mynavi", "マイナビニュース");
companyNames.set("jpcert", "JPCERT")

export default function Home(props) {
    const [message, setMessage] = useState("")
    const [genArticles, setGenArticles] = useState([]);
    const [intArticles, setIntArticles] = useState([]);
    const [artLoaded, setArtLoaded] = useState(false);

    const [isHovering, setIsHovering] = useState(false);
    const [isFocusing, setIsFocusing] = useState(false);

    const handleButtonFocused = () => {
        setIsFocusing(true);
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const sampleArticle1 = <Article link="#" date="2022/05/10" title="Corporate Sample Title" image={contentImage} />
    const sampleArticle2 = <Article link="#" date="2022/05/11" title="Corporate Sample Title" image={guyImage} />

    const cookies = new Cookies();

    const navigate = useNavigate();

    const handleScroll = () => {
        //console.log("scroll called")
        document.getElementById("top").scrollIntoView();
    }

    useEffect(() => {
        if (sessionStorage.getItem("access_token")) {
            readMessage("message", "message.txt", setMessage)
        }
        
        let isSubscribed = true;
        window.history.replaceState("", "", "/")
        let feed = getRSSFeed("https://articles.vciso.jp/feed");

        if (isSubscribed) {
            /*
            setGenArticles(filterArray(genArticles))
            setIntArticles(filterArray(intArticles))
            feed.then(feed => {

                var genArticleList = JSON.parse(JSON.stringify(genArticles))
                ////console.log(genArticleList)
                var intArticleList = JSON.parse(JSON.stringify(intArticles))
                //console.log(feed)
                feed.items.forEach((item, index) => {
                    ////console.log(item)
                    var imgRegex = /([^\s]+)/
                    var imgSrc = item.description.substring(item.description.indexOf("src")).match(imgRegex)[1]
                    var img = imgSrc.substring(imgSrc.indexOf("=") + 2, imgSrc.length - 1)
                    // var dateRegex = /[^\s]*\s[^\s]*\s[^\s]*\s[^\s]*\s/
                    // //console.log(item.pubDate);
                    //var date = item.pubDate.match(dateRegex)[0].trim()
                    ////console.log(date);
                    ////console.log(imgSrc);
                    var articleObj = {
                        key: 20000 + index,
                        image: img,
                        title: item.title,
                        date: item.isoDate.split("T")[0],
                        link: item.link
                    }
                    ////console.log(articleObj)
                    //let article = <Article image={img} title={item.title} date={date} link={item.link} />
                    if (item.categories.includes("interviews")) {
                        if (!intArticles.some(x => x.link === articleObj.link)) {
                            setIntArticles(articles => [...articles, articleObj]);
                        }


                    } else {
                        ////console.log(genArticles)
                        ////console.log(genArticleList)
                        // //console.log(genArticles.some(x => x.link === articleObj.link))
                        if (!genArticles.some(x => x.link === articleObj.link)) {
                            setGenArticles(articles => [...articles, articleObj])
                        }
                    }
                })
                ////console.log(genArticleList)    
                //setGenArticles(articles => [...articles, genArticleList.flat()])
            })
            */
            var webRegex = /(\.)(.*?)\1/;
            const testFeed = async (articleObj) => {
                var result = await getImageFromRSS(articleObj.companyName, articleObj.link);
                if (result) {
                    articleObj.image = result
                } else {
                    articleObj.image = logo
                }
                setGenArticles(articles => [...articles, articleObj])
                ////console.log(articleObj)
            }

            let secondFeed = getRSSFeed("https://articles.vciso.jp/wprss");
            secondFeed.then(feed => {
                ////console.log(feed)
                var genArticleList = JSON.parse(JSON.stringify(genArticles))
                var sliced = feed.items.slice(0, 8)
                sliced.forEach((item, index) => {
                    var companyName = item.link.match(webRegex);
                    //var itemImage = getImageFromRSS(companyNames.get(companyName[2]), item.link)
                    ////console.log(itemImage)
                    var articleObj = {
                        key: 10000 + index,
                        title: item.title,
                        image: '',
                        date: item.pubDate.split("T")[0],
                        link: item.link,
                        companyName: companyNames.get(companyName[2])
                    }

                    if (!genArticles.some(x => x.link === articleObj.link)) {
                        testFeed(articleObj).catch(console.error);
                    }
                })
            })
        }

        return () => isSubscribed = false;

        ////console.log(genArticleList)
    }, [])

    filterArray(genArticles)
    return (
        <div className="Home" ref={useScrollToTop()} >
            <Row className="g-0">
                <Col lg={12} md={12} sm={12}>
                    <HomeCarousel user={sessionStorage.getItem("user")} message={message} />
                </Col>
            </Row>
            {
                !sessionStorage.getItem("user") ?
                    <div className="inquiry-area">
                        <a className="" id="fab" onClick={() => navigate("/incident")}>
                            <div className="fab-title pc">緊急対応サービス<Headset style={{ "marginLeft": "5px" }} /></div>
                            <div className="fab-body sp">
                                <Row className="g-0" style={{ "padding": "10px 20px" }}>
                                    <Col xs={2}><HeadsetWhite style={{ "marginTop": "5px" }} /></Col>
                                    <Col xs={10} style={{ "textAlign": "left", "fontSize": "15px" }}>緊急対応サービス<br />
                                        <span style={{ "fontSize": "10px" }}>セキュリティインシデント発生時の初動対応はこちら</span></Col>
                                </Row>
                            </div>
                            <div className="fab-body pc">
                                セキュリティインシデント<br />発生時の初動対応はこちら
                            </div>
                            {/*<Row className="g-0">*/}
                            {/*    <Col xs={2} style={{ "justifyContent": "center" }}>*/}
                            {/*        <div style={{ "height": "100%", "width": "auto", "display": "flex", "flexDirection": "column", "justifyContent": "center" }}>*/}
                            {/*            <Headset style={{ "height": "70%", "width": "auto", "alignSelf": "center" }} />*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*    <Col xs={10}>*/}
                            {/*        <Row className="g-0">*/}
                            {/*            <div className="fab-text" style={{ "fontSize": ".95rem", "fontWeight": "bold" }}>緊急対応サービス</div>*/}
                            {/*            <div className="hide-fb-text" style={{ "fontWeight": "bold", "fontSize": "1.2rem" }}>セキュリティインシデント発生時の初動対応はこちら</div>*/}
                            {/*        </Row>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </a>
                        {/*<Button className="rounded-circle me-4 button-top" variant="outline-dark"*/}
                        {/*    style={{ "backgroundColor": isHovering || isFocusing ? 'white' : '', "height": "70px", "width": "70px" }}*/}
                        {/*    onMouseEnter={handleMouseEnter}*/}
                        {/*    onMouseLeave={handleMouseLeave}*/}
                        {/*    onFocus={handleButtonFocused}*/}
                        {/*    onClick={() => window.scrollTo(0, 0)}><img src={chevron} style={{ "height": "2rem" }} />*/}
                        {/*</Button>*/}
                    </div>
                    :
                    null
            }
            <Row className="g-0" id="security-articles">
                <Col lg={1} />
                <Col lg={10} md={12} sm={12} xs={13}>
                    <div >
                        <ArticleGrid buttonPath="cyberarticles"
                            setLoaded={setArtLoaded}
                            loaded={artLoaded}
                            gridTitle="サイバーセキュリティNEWS"
                            buttonText="NEWS一覧へ"
                            articleList={filterArray(props.articles)}
                            articleNumber={8}
                            category="general"
                        />
                    </div>
                </Col>
                <Col lg={1} style={{ "justifyContent": "center", "alignItems": "end" }} className="show-button-md hide-button-sm">
                </Col>
            </Row>
            <Row className="g-0" id="interviews">
                <Col lg={1} />
                <Col lg={10} md={12} sm={12} xs={13}>
                    <div>
                        <ArticleGrid buttonPath="originalcontent"
                            setLoaded={setArtLoaded}
                            loaded={artLoaded}
                            id="interviews"
                            gridTitle="GSXオリジナルコンテンツ"
                            buttonText="GSXオリジナルコンテンツ一覧へ"
                            articleList={filterArray(props.originalContent)}
                            sampleArticle={sampleArticle2}
                            articleNumber={4}
                            category="interviews"
                        />
                    </div>
                </Col>
                <Col lg={1} style={{ "justifyContent": "center", "alignItems": "end" }} className="show-button-md hide-button-sm">
                </Col>
            </Row>

            <Row className="g-0" id="web-service">
                <Col lg={1} />
                <Col lg={10} md={12} sm={12} xs={13}>
                    <WebServiceGrid />
                    <hr className="section-hr" />
                    <Plans />
                </Col>
                <Col lg={1} style={{ "justifyContent": "center", "alignItems": "end" }} className="show-button-md hide-button-sm">
                </Col>
            </Row>

        </div>
    )
}

