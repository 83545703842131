import React from "react";
import { useNavigate } from "react-router-dom";

export default function ImageButton(props) {
    const data = props.data;
    const navigate = useNavigate();

    return (
        <div className="mypage-button-area">
            <div className="image-area">
                <data.icon />
            </div>
            <div className="text-area">
                <p>{data.menu_name}</p>
            </div>
            <div
                className="link-area"
                onClick={() => {
                    navigate(data.link);
                }}
            ></div>
        </div>
    );
}
