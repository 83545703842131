import React from "react";
import { Row, Col, Container, Stack, Table, Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import "../../stylesheets/RyokinPlan.css";

export default function RyokinPlan(props) {
    return (
        <div className="RyokinPlan">
            <Row className="g-0">
                {/* <div className="under_title g-0 row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <h1>料金プラン</h1>
                    </div>
                    <div className="col-lg-1"></div>
                </div> */}

                <div className="g-0 row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 box">
                        <br />
                        <section>
                            <ul className="price">
                                <li className="price_header g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        サービスメニュー
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <div className="plan_box">
                                            <h2 className="plan_name">
                                                <div>Liteプラン</div>
                                            </h2>
                                            <div className="plan_body">
                                                <p>
                                                    とにかくリーズナブルにセキュリティ対策の第一歩を踏み出したいというお客様にピッタリ
                                                </p>
                                                <div className="monthly">
                                                    15,000
                                                </div>
                                                <div className="yearly">
                                                    180,000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <div className="plan_box">
                                            <h2 className="plan_name">
                                                <div>
                                                    Standard
                                                    <br />
                                                    プラン
                                                </div>
                                            </h2>
                                            <div className="plan_body">
                                                <p>
                                                    Liteプランに加えて当社自信の「教育メニュー」を加えたバランスの取れたオススメプラン
                                                </p>
                                                <div className="monthly">
                                                    30,000
                                                </div>
                                                <div className="yearly">
                                                    360,000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <div className="plan_box">
                                            <h2 className="plan_name">
                                                <div>
                                                    Premium
                                                    <br />
                                                    プラン
                                                </div>
                                            </h2>
                                            <div className="plan_body">
                                                <p>
                                                    より規模の大きなお客様、外部評価や本格対策のご検討があるお客様向け
                                                </p>
                                                <div className="monthly">
                                                    50,000
                                                </div>
                                                <div className="yearly">
                                                    600,000
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        よろづ相談
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        文書テンプレート
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        Eラーニング
                                        <br />
                                        （管理者、履歴管理機能付）
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        合同メール訓練
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        セキュリティ健康診断
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        個別カウンセリング
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="line"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        2回（60分）/年間
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        サイバーリスク保険
                                        <br />
                                        （200万円補償）
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                                <li className="price_table g-0 row">
                                    <div className="plan_category col-lg-3 col-12">
                                        対象企業規模
                                        <br />
                                        （原則300名まで）
                                    </div>
                                    <div className="plan_lite col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_standard col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                    <div className="plan_premium col-lg-3 col-4">
                                        <span className="ring"></span>
                                    </div>
                                </li>
                            </ul>
                            <ul className="note">
                                <li>
                                    ※1　年額/月額に関わらず、契約期間は1年間としておりますので
                                    <strong>途中解約はできません。</strong>
                                </li>
                                <li>
                                    ※2　月額の場合、利用開始月の末日までが無償期間​となります。
                                </li>
                                <li>
                                    ※3　年額の場合、利用開始月は無償期間として翌月より1年間の課金となります。
                                </li>
                                <li>
                                    ※4　無償期間は各サービスのご利用は可能ですが、サイバーリスク保険は適用外となりますのでご注意ください。​
                                </li>
                            </ul>
                            <br />
                            <br />
                        </section>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </Row>
        </div>
    );
}
