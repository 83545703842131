import axios from 'axios';
import { axiosPrivate } from './axiosPrivate';


export default async function readMessage(bucket, filename, setMessage) {
 
    let filepath = `/${bucket}/download/${filename}`;

    axiosPrivate.get(`${filepath}`, {
        responseType: 'blob',
    })
    .then(async response => {
        const blob = new Blob([response.data], {
          type: response.data.type
        });
        const text = await new Response(blob).text()
        //console.log(text)
        setMessage(text)
    })
}