import React from "react";
import { Form, Button, Col, Row, Stack } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";

export default function IncidentCheck(props) {
    const {
        incident,
        time,
        submitIncident,
        checkIncident,
        user,
        handleSubmit,
    } = props;
    return (
        <Stack>
            <br />
            <br />
            <Form onSubmit={handleSubmit(submitIncident)}>
                <section>
                    {!user ? (
                        <>
                            <Form.Group className="my-3">
                                <Form.Label>会社名　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={incident.company}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>部署名　</Form.Label>
                                <Form.Label className="any-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={incident.dept}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>住所　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={incident.address}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>名前　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={incident.name}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>メールアドレス　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={incident.email}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="my-3">
                                <Form.Label>電話番号　</Form.Label>
                                <Form.Label className="required-lable"></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={incident.phoneNumber}
                                    readOnly
                                />
                            </Form.Group>
                        </>
                    ) : null}

                    <Form.Group className="form_input_area">
                        <Form.Label>インシデントの種類を選択　</Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Control
                            className="form-select"
                            type="text"
                            readOnly
                            value={incident.type}
                        />
                    </Form.Group>
                    <Form.Group className="my-3">
                        <Form.Label>
                            インシデントが起きた日時を入力　
                        </Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <div style={{ display: "flex" }}>
                            <div className="input-date">
                                <Form.Group>
                                    <Form.Control
                                        type="date"
                                        value={incident.occurred_at}
                                        readOnly
                                    />
                                </Form.Group>
                            </div>
                            <div className="input-time">
                                <Row className="g-0">
                                    <TimePicker
                                        start="00:00"
                                        end="23:59"
                                        locale={"ja-jp"}
                                        format={24}
                                        step={30}
                                        readOnly
                                        disabled
                                        value={time}
                                    />
                                </Row>
                            </div>
                            <div
                                className="incident-time"
                                style={{ marginTop: "16px" }}
                            >
                                <Row className="g-0">時頃</Row>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group className="my-3">
                        <Form.Label>
                            今の状況や、困っていること等をご入力ください。　
                        </Form.Label>
                        <Form.Label className="required-lable"></Form.Label>
                        <Form.Label>
                            また、IPアドレスやシステム環境等の詳細等がありましたら合わせてご入力ください。​　
                        </Form.Label>
                        <Form.Control
                            className="incident-text-area"
                            type="text"
                            as="textarea"
                            rows={5}
                            value={incident.content}
                            readOnly
                        />
                    </Form.Group>
                    <br />

                    <Row className="template-customize-area">
                        <Button
                            className="btn btn-primary btn-margin"
                            onClick={checkIncident}
                        >
                            再入力
                        </Button>
                        <Button className="btn btn-primary" type="submit">
                            送信
                        </Button>
                    </Row>
                    <br />
                </section>
            </Form>
        </Stack>
    );
}
