import React, { useState, useEffect } from 'react';
import {
    Table, Row, Col, Form, Button, Container, Alert, Modal
} from 'react-bootstrap';
import { FileUploader } from "react-drag-drop-files";
import { useForm } from 'react-hook-form';
import ValidationError from "../ValidationError";
import { axiosPrivate } from '../../../utils/axiosPrivate';

export default function SecCheckReportModal(props) {
    const [fileError, setFileError] = useState({
        error: false,
        message: ""
    })

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({});

    const { id, company, name, nameKana, email, show, handleModalClose, onFileChange, file, setAlert, setUploadModal } = props;

    const fileTypes = ["xlsx", "docx", "pdf"]


    const onSubmit = (data) => {
        if (file === null) {
            setFileError({ error: true, message: "ファイルを選択してください"})
        } else {

            var date = data.upload_date;
            var formData = new FormData();
            formData.append("file", file)
            formData.append("upload_date", date)

            axiosPrivate.post(`/api/admin/seccheck/${id}/upload`, formData)
            .then(function(response) {
                setUploadModal({ show: false})
                if (response.status === 200 && response.data.apiCode === 0) {
                    setAlert({ ...alert, show: true, message: "レポートをアップロードしました。"})
                } else {
                    setAlert({ ...alert, show: true, message: "レポートのアップロードが失敗しました。"})
                }
            })
            .catch(function(response) {
                setUploadModal({ show: false})
                setAlert({ ...alert, show: true, message: "レポートのアップロードが失敗しました。"})
            })
        }
    }
   // console.log(id)
    return (
        <Modal show={show} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>レポートアップロード</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                会社名：
                            </Col>
                            <Col>
                                {company}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                法人代表者名：
                            </Col>
                            <Col>
                                {name}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                法人代表者名（カナ）：
                            </Col>
                            <Col>
                                {nameKana}
                            </Col>
                        </Row>
                    </p>
                    <p>
                        <Row className="g-0">
                            <Col xs={5}>
                                メールアドレス：
                            </Col>
                            <Col>
                                {email}
                            </Col>
                        </Row>
                    </p>
                    <div className="my-4" id="secreport-upload">
                        <FileUploader
                            
                            handleChange={onFileChange}
                            types={fileTypes}
                            style={{ "width": "100%" }}
                        >
                            <Form>
                                <Form.Group>
                                    <Form.Control onChange={() => null} value={file ? file.name : "ファイルを選択してください"} 
                                
                                    style={{ "width": "100%", "cursor": "pointer" }} 
                                    />
                                </Form.Group>
                                
                            </Form>
                        </FileUploader>
                        <div className="error-message-left">
                            {fileError.error && <ValidationError message={fileError.message}/>}
                        </div>
                        
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-4">
                            <Form.Control
                                style={{ "width": "50%"}}
                                placeholder="YYYY/MM/DD"
                                { 
                                ...register(
                                        "upload_date", 
                                        {
                                            required: {
                                                value: true, 
                                                message: "YYYY/MM/DDのフォーマットで入力してください"
                                            },
                                            pattern: { 
                                                value: /[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])/,
                                                message: "YYYY/MM/DDのフォーマットで入力してください"
                                            }
                                        }
                                    )
                                }
                                />
                                <div id="secreport-date-error" style={{ "textAlign": "left"}}>
                                {errors.upload_date && <ValidationError message={errors.upload_date.message} /> }
                                </div>
                            
                        </Form.Group>
                        <Button type="submit">アップロード</Button>
                    </Form>
                </Modal.Body>
            </Modal>
    )
}