import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import ServiceCard from "../elements/services/ServiceCard";
import serviceplan from "../../assets/service_plan.png";

export default function About(props) {
    return (
        <div className="About">
            <div>
                <br />
                <br />

                <p>
                    サイバー攻撃は、
                    大企業や有名企業だけの問題ではなくなってきました。
                    攻撃者は、 対策の充分で無いところを狙って侵入し、
                    侵入先はもちろん、
                    そこから侵攻出来るグループ企業や取引企業も探して攻撃を拡大します。
                    一方、
                    サイバーセキュリティ対策の導入やサイバーセキュリティ専任人材の採用は決して容易では無く、
                    中小企業にとっては大きな負担となっています。
                    そのようなお客様の課題を解決すべく、 この度新たなサービス「
                    vCISO安心相談サービス」 をリリースすることになりました。
                </p>
            </div>
            <div
                style={{
                    textAlign: "center",
                    marginBottom: "12px",
                }}
            >
                <img
                    src={serviceplan}
                    style={{
                        // "height": "100%",
                        maxHeight: "900px",
                        maxWidth: "100%",
                    }}
                />
            </div>
            <br />
            <br />
        </div>
    );
}
