import React from "react";

export default function ServiceCard(props) {
    const { imageSrc, title } = props;

    return (
        <div className="service-card">
            <h3 className="service-title">{title}</h3>
            <hr />
            <div className="service-content">{props.children}</div>
            {imageSrc ? (
                <div className="service-img" style={{ marginTop: "48px" }}>
                    <img src={imageSrc} />
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
