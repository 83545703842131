import React, { useState } from "react";
import {
    Col,
    Row,
    Stack,
    Form,
    Container,
    InputGroup,
    Button,
    Modal,
    Spinner,
} from "react-bootstrap";
import axios from "axios";
import "../../stylesheets/EmailTraining.css";
import downloadFile from "../../utils/downloadFile";
import { FileUploader } from "react-drag-drop-files";
import { axiosPrivate } from "../../utils/axiosPrivate";
import { event } from "jquery";
import AlertModal from "../elements/AlertModal";
import fileDownload from "js-file-download";

export default function EmailTrainingEntry(props) {
    const { trainings, clickEvent, user } = props;

    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [show, setShow] = useState(false);

    const {
        selected,
        file,
        onFileChange,
        parsed,
        handleKakunin,
        handleReturn,
    } = props;

   

    const handleAlertShow = (prop) => setAlert({ ...alert, show: prop });
    const handleShow = () => setShow(true);
    const handleCloseModal = () => setShow(false);

    const handleClick = (bucket, filename) => {
        axiosPrivate
            .get(`/api/document/view/${bucket}/${filename}`, {
                responseType: "blob"
            })
            .then(function (response) {
                if (response.status === 200) {
                    let file = filename.replace(/^.*[\\\/]/, "");
                    fileDownload(response.data, `${file}`);
                }
            })
            //.then(function (response) {
            //    if (response.status === 200) {
            //        window.open(`${response.data}`);
            //    }
            //})
            .catch(function (response) {
                //console.log(response)
            });
    };

    const sendTestMail = () => {
        axiosPrivate
            .post("/api/emailtraining/testmail")
            .then(function (response) {
                //console.log(response)
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.data.message,
                    });
                } else {
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.data.message,
                    });
                }
            })
            .catch(function (response) {
                setAlert({
                    ...alert,
                    show: true,
                    message: response.response.data.data.message,
                });
            });
    };

    const fileTypes = ["xlsx"];

    const shorten = () => {
        if (!selected.trainingEvent.recep_start) {
            return;
        }
        let res = selected.trainingEvent.recep_start
            .replaceAll("-", "/")
            .split("T")[0];
        return res;
    };

    return (
        <div>
            <AlertModal show={alert.show} setShow={handleAlertShow} message={alert.message} />
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>
                        {selected.finished || !selected.started
                            ? `　現在、こちらのメール訓練は応募受付前です。受付は ${shorten()} から可能です。 `
                            : "　" + selected.trainingEvent.event_name}
                    </h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h2 className="content_title fst_text">
                        <br />
                        <br />
                        <div>合同メール訓練依頼の手順</div>
                    </h2>
                    <section>
                        <ol className="email_method_list">
                            <li>
                                <div className="sub_title">
                                    1.
                                    メール訓練の受付用テンプレートをダウンロードしてください。
                                </div>
                                <div className="g-0 row">
                                    <Form.Group>
                                        <Form.Control
                                            type="form-control-plaintext"
                                            plaintext
                                            value="メール訓練の受付用テンプレート"
                                        />

                                        <Button
                                            className="btn btn-primary"
                                            // disabled={
                                            //     selected.finished ||
                                            //     !selected.started
                                            // }
                                            onClick={() =>
                                                downloadFile(
                                                    "email",
                                                    "vCISO合同メール訓練送信リスト.xlsx"
                                                )
                                            }
                                        >
                                            ダウンロード
                                        </Button>
                                    </Form.Group>
                                    <div className="col-lg-4 col-md-4"></div>
                                </div>
                            </li>
                            <li>
                                <div className="sub_title">
                                    2.
                                    以下ボタンよりテストメールがご担当者へ送信されますので、受信出来ているか確認します。
                                </div>
                                <p>
                                    こちらのテストメールにて、合同訓練メールのイメージもご確認可能です。
                                    <br />
                                    受信しない、迷惑メールに振り分けられる場合は、
                                    <a
                                        href="#"
                                        onClick={() =>
                                            handleClick(
                                                "email",
                                                "うまく受信できないときの設定方法.pdf"
                                            )
                                        }
                                    >
                                        こちら
                                    </a>
                                    の操作を実施して頂くか、「よろづ相談」よりその旨ご連絡ください。
                                </p>
                                <div className="g-0 row">
                                    <div className="col-lg-4 col-md-4"></div>
                                    <div className="col-lg-4 col-md-4">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={sendTestMail}
                                        >
                                            テストメールを送信する
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-4"></div>
                                </div>
                            </li>
                            <li>
                                <div className="sub_title">
                                    3.
                                    ご入力後、Excelテンプレートをアップロードして送信してください。
                                </div>
                                <div className="e_file_area">
                                    <label className="sc-bczRLJ kEeNDb">
                                        <div className="file-uploader">
                                            <FileUploader
                                                handleChange={onFileChange}
                                                types={fileTypes}
                                                style={{ width: "100%" }}
                                                disabled={
                                                    selected.finished ||
                                                    !selected.started
                                                }
                                            >
                                                <Form>
                                                    <Form.Group>
                                                        <Form.Control
                                                            defaultValue=""
                                                            className={`file_btn ${
                                                                selected.finished ||
                                                                !selected.started
                                                                    ? "disabled"
                                                                    : ""
                                                            }`}
                                                            type="button"
                                                            disabled={
                                                                selected.finished ||
                                                                !selected.started
                                                            }
                                                            value={
                                                                file
                                                                    ? "ファイルを選択する"
                                                                    : "ファイルを選択する"
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </FileUploader>
                                        </div>

                                        <div className="file-uploader-input-area">
                                            <Form className="file-uploader-form">
                                                <Form.Group>
                                                    <Form.Control
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            file
                                                                ? file.name
                                                                : "ファイルを選択する"
                                                        }
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </label>
                                </div>
                                <div className="template-customize-area">
                                    <button
                                        disabled={
                                            selected.finished ||
                                            !file ||
                                            !selected.started
                                        }
                                        className={file ? "btn active" : "btn"}
                                        onClick={handleKakunin}
                                    >
                                        {!parsed && file ? (
                                            <Spinner
                                                animation="border"
                                                style={{
                                                    height: "1.1rem",
                                                    width: "1.1rem",
                                                    fontSize: ".75rem",
                                                }}
                                            />
                                        ) : (
                                            "確認画面へ"
                                        )}
                                    </button>
                                </div>
                            </li>
                        </ol>
                        <br />
                        <br />
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>
    );
}
