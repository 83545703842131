import React, { useState, useEffect } from 'react';
import { 
    Navbar, Container, Nav, Col, Row, Button 
} from 'react-bootstrap';
import getWindowDimensions from '../../utils/getWindowDimenions';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as VCISO} from '../../assets/svg_icons/vCISO_logo.svg';
import { ReactComponent as Information } from '../../assets/svg_icons/information.svg';
import { ReactComponent as Calculator} from '../../assets/svg_icons/calculator.svg';
import { ReactComponent as Folder} from '../../assets/svg_icons/folder.svg';
import { ReactComponent as QA} from '../../assets/svg_icons/q_a.svg';
import withRouter from '../withRouter';
import $ from 'jquery';

function PreloginServiceNav(props) {
    

    const {height, width} = getWindowDimensions();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if ($("chat").length > 0 && location.pathname != "chatservice") {
            //console.log("chat is displayed")
            $("chat").css("display","none")
        }
        window.scrollTo(0,0);
    }, [location])
    return (
        <>
            {
                <Navbar className="navbar-sp">
                    <Row className="g-0" style={{ 'width': '100%', 'justifyContent': 'center'}}>
                        <Col md={10}>
                            <div className="gsx-beforeapp-nav">
                                <Nav style={{ 'width': '100%', 'dislpay': 'flex'}} activeKey={location.pathname}>
                                    <Nav.Link className="custom-nav" onClick={() => navigate("about")} eventKey="/about">
                                        <VCISO style={{ "alignSelf": "center" }} />
                                        <div className="nav-div">vCISOとは</div>
                                    </Nav.Link>
                                    <Nav.Link className="custom-nav" onClick={() => navigate("services")} eventKey="/services">
                                        <Information style={{ "alignSelf": "center"}} />
                                        <div className="nav-div">サービス詳細</div>
                                    </Nav.Link>
                                    <Nav.Link className="custom-nav" onClick={() => navigate("ryokinplan")} eventKey="/ryokinplan">
                                        <Calculator style={{ "alignSelf": "center"}} />
                                        <div className="nav-div">料金プラン</div>
                                    </Nav.Link>
                                    <Nav.Link className="custom-nav disabled" onClick={() => navigate("cases")} eventKey="/cases">
                                        <Folder style={{ "alignSelf": "center"}} />
                                        <div className="nav-div">導入事例</div>
                                    </Nav.Link>
                                    <Nav.Link className="custom-nav" onClick={() => navigate("faq")} eventKey="/faq">
                                        <QA style={{ "alignSelf": "center"}} />
                                        <div className="nav-div">よくある質問</div>
                                    </Nav.Link>
                                </Nav>
                            </div>
                        </Col>
                    </Row>
                </Navbar>
            }
        </>
    )
}

export default withRouter(PreloginServiceNav)