import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";
import ValidationError from "../ValidationError";

export default function CounselingEntry(props) {
    const {
        onSubmit,
        handleSubmit,
        register,
        errors,
        handleTime,
        firstTime,
        secondTime,
    } = props;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-0">
                <div>
                    <Form.Group className="my-4 me-4">
                        <Row className="g-0">
                            <div className="contract-info-label">
                                <Form.Label className="pe-3">
                                    第1希望日時
                                </Form.Label>
                            </div>
                            <div className="input-date">
                                <Form.Control
                                    className="contract-date-input"
                                    type="date"
                                    {...register("firstDate", {
                                        required: {
                                            value: true,
                                            message:
                                                "第2希望日時を選択してください",
                                        },
                                        validate: {
                                            weekday: (value) =>
                                                (new Date(value).getDay() !==
                                                    0 &&
                                                    new Date(value).getDay() !==
                                                        6) ||
                                                "平日の10時から17時までを選択してください。",
                                            future: (value) =>
                                                new Date(value) > new Date() ||
                                                "将来の日時を選択してください。",
                                        },
                                    })}
                                />
                                {errors.firstDate && (
                                    <ValidationError
                                        message={errors.firstDate.message}
                                    />
                                )}
                            </div>
                            <div className="input-time">
                                <Form.Group>
                                    <Form.Select
                                        className="contract-time-input"
                                        onChange={handleTime("first")}
                                    >
                                        <option value={36000}>
                                            10:00 〜 11:00
                                        </option>
                                        <option value={39600}>
                                            11:00 〜 12:00
                                        </option>
                                        <option value={43200}>
                                            12:00 〜 13:00
                                        </option>
                                        <option value={46800}>
                                            13:00 〜 14:00
                                        </option>
                                        <option value={50400}>
                                            14:00 〜 15:00
                                        </option>
                                        <option value={54000}>
                                            15:00 〜 16:00
                                        </option>
                                        <option value={57600}>
                                            16:00 〜 17:00
                                        </option>
                                        <option value={61200}>
                                            17:00 〜 18:00
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Row>
                    </Form.Group>
                </div>
            </Row>
            <Row className="g-0">
                <div>
                    <Form.Group className="my-4 me-4">
                        <Row className="g-0">
                            <div className="contract-info-label">
                                <Form.Label className="pe-3">
                                    第2希望日時
                                </Form.Label>
                            </div>
                            <div className="input-date">
                                <Form.Control
                                    className="contract-date-input"
                                    type="date"
                                    {...register("secondDate", {
                                        required: {
                                            value: true,
                                            message:
                                                "第2希望日時を選択してください",
                                        },
                                        validate: {
                                            weekday: (value) =>
                                                (new Date(value).getDay() !==
                                                    0 &&
                                                    new Date(value).getDay() !==
                                                        6) ||
                                                "平日の10時から17時までを選択してください。",
                                            future: (value) =>
                                                new Date(value) > new Date() ||
                                                "将来の日時を選択してください。",
                                        },
                                    })}
                                />
                                {errors.secondDate && (
                                    <ValidationError
                                        message={errors.secondDate.message}
                                    />
                                )}
                            </div>
                            <div className="input-time">
                                <Form.Group>
                                    <Form.Select
                                        className="contract-time-input"
                                        onChange={handleTime("second")}
                                    >
                                        <option value={36000}>
                                            10:00 〜 11:00
                                        </option>
                                        <option value={39600}>
                                            11:00 〜 12:00
                                        </option>
                                        <option value={43200}>
                                            12:00 〜 13:00
                                        </option>
                                        <option value={46800}>
                                            13:00 〜 14:00
                                        </option>
                                        <option value={50400}>
                                            14:00 〜 15:00
                                        </option>
                                        <option value={54000}>
                                            15:00 〜 16:00
                                        </option>
                                        <option value={57600}>
                                            16:00 〜 17:00
                                        </option>
                                        <option value={61200}>
                                            17:00 〜 18:00
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </Row>
                    </Form.Group>
                </div>
            </Row>
            <Row className="g-0">
                <Form.Group className="my-4 me-4">
                    <Row className="g-0">
                        <div className="contract-info-label">
                            <Form.Label className="pe-3">
                                事務局への伝達事項
                            </Form.Label>
                        </div>
                        {/* <div className="contract-info-textarea"> */}
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={5}
                            {...register("content")}
                        />
                        {/* </div> */}
                    </Row>
                </Form.Group>
            </Row>
            <Row
                className="template-customize-area button-flex"
                style={{ padding: "0 10px" }}
            >
                <Button className="btn btn-primary" type="submit">
                    確認画面へ
                </Button>
            </Row>
            <br />
            <br />
        </Form>
    );
}
