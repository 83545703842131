import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import downloadFile from "../../utils/downloadFile";
import { axiosPrivate } from "../../utils/axiosPrivate";
import fileDownload from "js-file-download";

export default function Yakkan(props) {
    const handleClick = (bucket, filename) => {
        axiosPrivate
            .get(`/api/document/view/${bucket}/${filename}`, {
                responseType: "blob",
            })
            .then(function (response) {
                if (response.status === 200) {
                    let file = filename.replace(/^.*[\\\/]/, "");
                    fileDownload(response.data, `${file}`);
                    /*
                var win = window.open(`${response.data.data}`);
                console.log(win)
                win.location.href("https://google.com")
                win.history.replaceState("Object", "title", "https://google.com")
                */
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
    };

    return (
        <div className="Common">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　サービス/サイバーリスク保険約款</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <br />
                        <br />
                        <Row>
                            <Form.Label style={{ fontSize: "20px" }}>
                                vCISOサービス及びサイバーリスク保険に関わる約款は以下となります。
                            </Form.Label>
                            <Form.Label style={{ fontSize: "20px" }}>
                                必ず内容のご確認をお願い致します。
                            </Form.Label>
                        </Row>
                        <br />
                        <h2 id="counseling_overview" className="content_title">
                            <div>サービス約款およびサービス仕様書</div>
                        </h2>
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "vCISOサービス約款.pdf"
                                    )
                                }
                            >
                                vCISOサービス約款
                            </a>
                        </div>
                        <br />
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "vCISOサービス仕様書.pdf"
                                    )
                                }
                            >
                                vCISOサービス仕様書
                            </a>
                        </div>
                        <br />
                        <br />

                        <h2 id="counseling_overview" className="content_title">
                            <div>サイバーリスク保険約款</div>
                        </h2>
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "①賠償責任保険普通保険約款.pdf"
                                    )
                                }
                            >
                                ①賠償責任保険普通保険約款
                            </a>
                        </div>
                        <br />
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "②情報通信技術特別約款.pdf"
                                    )
                                }
                            >
                                ②情報通信技術特別約款
                            </a>
                        </div>
                        <br />
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "③サイバーセキュリティ事故対応費用担保特約条項.pdf"
                                    )
                                }
                            >
                                ③サイバーセキュリティ事故対応費用担保特約条項
                            </a>
                        </div>
                        <br />
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "④IT業務不担保特約条項.pdf"
                                    )
                                }
                            >
                                ④IT業務不担保特約条項
                            </a>
                        </div>
                        <br />
                        <div style={{ marginLeft: "20px", fontSize: "20px" }}>
                            <a
                                href="#"
                                onClick={() =>
                                    handleClick(
                                        "yakkan",
                                        "⑤金融機関特定危険不担保特約条項.pdf"
                                    )
                                }
                            >
                                ⑤金融機関特定危険不担保特約条項
                            </a>
                        </div>
                    </section>
                    <br />
                    <br />
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>
    );
}
