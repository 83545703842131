import React from "react";
import { Row, Col } from "react-bootstrap";
import Chat from "../../assets/service-chat.png";

import ServiceCard from "../elements/services/ServiceCard";
import man from "../../assets/japanese_businessman.jpg";
import chat from "../../assets/chatservice_2.png";
import doctemplate from "../../assets/doctemplate_1.png";
import elearning from "../../assets/elearn_2.png";
import emailImg from "../../assets/mail_2.png";
import insurance from "../../assets/insurance_3.png";
import seccheck from "../../assets/seccheck_1.png";
import counseling from "../../assets/counseling.png";

export default function Services(props) {
    return (
        <div className="Services">
            <Row className="g-0">
                <Col />
                <Col
                    sm={12}
                    md={10}
                    lg={10}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="py-4" style={{ width: "100%" }}>
                        <ServiceCard title="よろづ相談">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                {
                                    "サイバーセキュリティに関する相談相手がいないという実態を踏まえ、サイバーセキュリティにまつわる様々なお悩み、課題をGSXコンサルタントにチャットで相談出来る窓口をご用意しています。脅威がますます拡大しているサイバー攻撃対応や、攻撃にあわないための予防的対策、内部不正対策、社内ルール整備、体制構築などサイバーセキュリティに関する大きなことから小さなことまでお気軽にご相談ください。"
                                }
                            </div>
                        </ServiceCard>
                        <div id="service-2" className="service-detail">
                            <Row
                                className="g-0"
                                style={{ justifyContent: "center" }}
                            >
                                <Col
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    className="item-box"
                                    style={{ backgroundColor: "#D2E1F6" }}
                                >
                                    <Row
                                        className="g-0"
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        <Col xs={2}>
                                            <img src={Chat} alt="" />
                                        </Col>
                                        <Col xs={9} className="title">
                                            平常時
                                        </Col>
                                        <Col xs={1}></Col>
                                    </Row>
                                    <ul className="faq-list">
                                        <li className="item">
                                            PCはアンチウイルスソフトだけ入れていますが、大丈夫でしょうか？
                                        </li>
                                        <li className="item">
                                            メール訓練って何の為にするの？
                                        </li>
                                        <li className="item">
                                            ランサムウェア対策は何をしたらいいですか？
                                        </li>
                                        <li className="item">
                                            クラウドを利用する際の注意点は何でしょうか？
                                        </li>
                                        <li className="item">
                                            セキュリティ対策はまず何から手をつければいいでしょうか？
                                        </li>
                                        <li className="item">
                                            サイバー攻撃によるサービス停止を防ぐにはどうしたらいいでしょうか？
                                        </li>
                                        <li className="item">
                                            サーバーや業務システムの不正アクセスを防ぐための対策はどんなものがあるでしょうか？
                                        </li>
                                        <li className="item">
                                            機密情報を盗み取るのはどんな攻撃をしかけられるのでしょうか？
                                        </li>
                                        <li className="item">
                                            社内のセキュリティ体制はどう作ったらいいでしょうか？
                                        </li>
                                        <li className="item">
                                            セキュリティ対策はどこまでやったらいいでしょうか？
                                        </li>
                                        <li className="item">
                                            自社Webサイトのセキュリティ対策は何から実施したらよいか？
                                        </li>
                                        <li className="item">
                                            ISMSやPマーク等あるが当社が優先してとるべきものはなにか？
                                        </li>
                                    </ul>
                                </Col>
                                <Col
                                    xs={12}
                                    md={{ span: 5, offset: 1 }}
                                    lg={5}
                                    className="item-box"
                                    style={{ backgroundColor: "#F9CBEB" }}
                                >
                                    <Row
                                        className="g-0"
                                        style={{ marginBottom: "2rem" }}
                                    >
                                        <Col xs={10} className="title">
                                            サイバーインシデント
                                            <br />
                                            （事故）発生時
                                        </Col>
                                        <Col xs={2}>
                                            <img src={Chat} alt="" />
                                        </Col>
                                    </Row>
                                    <ul className="faq-list">
                                        <li className="item">
                                            インターネットを見ていたら「申し込み完了しました」の画面が出たけど大丈夫？
                                        </li>
                                        <li className="item">
                                            おたくの会社から変なメールが大量に届くと連絡をもらったが、何が起きているのか？
                                        </li>
                                        <li className="item">
                                            メールを誤送信したが、どうしたらいい？
                                        </li>
                                        <li className="item">
                                            Emoteメールを開いてしまったようだが、何をしたらいいでしょうか？
                                        </li>
                                        <li className="item">
                                            不審メールを受信したが、どうしたらいいか？
                                        </li>
                                        <li className="item">
                                            PCやサーバーのファイルが暗号化されてしまって開ける事ができない。
                                        </li>
                                        <li className="item">
                                            顧客情報を誤って別の顧客に送信してしまったが、どうしたらいいか？
                                        </li>
                                        <li className="item">
                                            従業員がPCを紛失したのだが、世間に公表した方がよいか？
                                        </li>
                                        <li className="item">
                                            当社のファイルが搾取され、身代金を払わないと公開すると脅されている。どうしたらいいか？
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        <ServiceCard title="文書テンプレート">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                {`​セキュリティ対策のためには指針となるルール整備を欠かすことができません。一方で、ルール整備をゼロから考え作り上げるには、ノウハウが必要になります。GSXの長年のコンサルティングノウハウを活かし基本方針や管理基準、規程などサイバーセキュリティ対策に必要な文書ひな形（テンプレート）を提供しています。`}
                            </div>
                        </ServiceCard>
                        <div id="service-3" className="service-detail">
                            <Row className="g-0 policy-title">
                                <Col xs={12} sm={12} md={6} className="main">
                                    セキュリティポリシーテンプレート
                                </Col>
                                <Col xs={12} sm={12} md={6} className="sub">
                                    （※本サービスでご提供する文書テンプレート）
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    className="policy-list"
                                >
                                    <div className="title">
                                        <span>情報セキュリティ管理規定</span>
                                    </div>
                                    <div className="body">
                                        <ul>
                                            <li>1.目的</li>
                                            <li>2.適用範囲</li>
                                            <li>3.用語および定義</li>
                                            <li>
                                                4.情報セキュリティのための組織
                                            </li>
                                            <li>5.人的セキュリティ</li>
                                            <li>6.資産の管理</li>
                                            <li>7.技術的対策</li>
                                            <li>
                                                8.物理的及び環境的セキュリティ
                                            </li>
                                            <li>9.運用のセキュリティ</li>
                                            <li>10.通信のセキュリティ</li>
                                            <li>
                                                11.システムの取得、開発及び保守
                                            </li>
                                            <li>12.サプライチェーン管理</li>
                                            <li>
                                                13.情報セキュリティインシデント管理
                                            </li>
                                            <li>14.事業継続</li>
                                            <li>15.自己点検・内部監査</li>
                                            <li>付録：用語集</li>
                                            <li>
                                                参考：情報セキュリティ基本方針
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    className="policy-list"
                                >
                                    <div className="title">
                                        <span>
                                            情報セキュリティ内部監査規定
                                        </span>
                                    </div>
                                    <div className="body">
                                        <ul>
                                            <li>1.目的</li>
                                            <li>2.対象者</li>
                                            <li>3.適用範囲</li>
                                            <li>4.用語および定義</li>
                                            <li>5.監査体制</li>
                                            <li>6.年間の計画</li>
                                            <li>7.内部監査活動の実施</li>
                                            <li>
                                                8.内部監査活動の見直しおよび改善
                                            </li>
                                            <li>9.記録</li>
                                            <li>10.報告</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    className="policy-list"
                                >
                                    <div className="title">
                                        <span>
                                            情報セキュリティインシデント
                                            <br />
                                            対応規定
                                        </span>
                                    </div>
                                    <div className="body">
                                        <ul>
                                            <li>1.目的</li>
                                            <li>2.適用範囲</li>
                                            <li>3.用語および定義</li>
                                            <li>
                                                4.情報セキュリティ事件・事故及びセキュリティ事象
                                            </li>
                                            <li>5.役割および責任</li>
                                            <li>6.発見時の対応</li>
                                            <li>7.対応</li>
                                            <li>8.恒久処置</li>
                                            <li>9.監視</li>
                                            <li>10.教育・訓練</li>
                                            <li>11.記録</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <ServiceCard title="Eラーニング">
                            サイバー攻撃から会社を守るためには、
                            社員1人1人が正しい知識を持つことが欠かせません。
                            日常業務のなかで、
                            留意を頂きたいセキュリティ対策をまとめた「
                            情報セキュリティ研修コース」、
                            IPAの情報セキュリティについて分かり易く学ぶ事ができる「
                            IPA情報セキュリティ10大脅威2022組織編」
                            から抜粋版を、
                            オンラインで学ぶことができるEラーニングを提供しています。
                        </ServiceCard>
                        <div id="service-4" className="service-detail">
                            <Row className="g-3">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="policy-menu"
                                >
                                    <div className="wrapper-box">
                                        <p className="title">
                                            1.はじめに「あなたと組織を守るために」
                                        </p>
                                        <span className="time">
                                            所要時間：約15分
                                        </span>
                                        <div className="description">
                                            1-1.登場人物の紹介
                                            <br />
                                            1-2.なぜセキュリティ意識を持つ必要があるのか
                                            <br />
                                            1-3.なぜインターネット犯罪は増え続けるのか
                                            <br />
                                            1-4.漏えい事件やセキュリティインシデントがあるとどうなるか
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="policy-menu"
                                >
                                    <div className="wrapper-box">
                                        <p className="title">
                                            2.ランサムウェアによる被害（IPAセキュリティ10代脅威2022
                                            組織編第1位）
                                        </p>
                                        <span className="time">
                                            所要時間：約9分
                                        </span>
                                        <div className="description">
                                            ファイルの暗号化や画面ロック等を行うランサムウェアに感染し、PCに保存されているファイルを利用できない状態にされ、
                                            復旧と引き換えに金銭を要求される被害が発生しています。
                                        </div>
                                    </div>
                                </Col>
                                {/* </Row>
                            <Row className="g-3"> */}
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="policy-menu"
                                >
                                    <div className="wrapper-box">
                                        <p className="title">
                                            3.標的型攻撃による機密情報の窃盗（IPAセキュリティ10代脅威2022
                                            組織編第2位）
                                        </p>
                                        <span className="time">
                                            所要時間：約15分
                                        </span>
                                        <div className="description">
                                            企業や民間団体そして官公庁等、特定の組織に対して、機密情報等を窃盗することを目的とした標的型攻撃が発生しています。
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    className="policy-menu"
                                >
                                    <div className="wrapper-box">
                                        <p className="title">
                                            4.テレワーク等のニューノーマルな働き方を狙った攻撃（IPAセキュリティ10代脅威2022
                                            組織編第4位）
                                        </p>
                                        <span className="time">
                                            所要時間：約9分
                                        </span>
                                        <div className="description">
                                            新型コロナウイルス感染症の世界的な蔓延により、政府機関から組織に対してニューノーマルな働き方の一つであるテレワークが推奨されました。
                                            組織のテレワークへの移行に伴い、Web会議サービスやVPNを狙った攻撃が発生しています。
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <ServiceCard title="合同メール訓練" imageSrc={emailImg}>
                            日本では1日に約13 .7
                            億回ものサイバー攻撃が発生しており、
                            メールを経由したサイバーインシデント(事故)
                            は留まることを知りません。 経営者・ 社員含め、
                            emotetなどに代表されるサイバー攻撃メールを見極める知識が求められます。
                            従業員のメールに対する知識や経験を積むための集合型模擬メール訓練を提供します。
                            複数の企業が同時に訓練を実施することで、
                            自社の相対的な態勢についても評価をすることができます。
                        </ServiceCard>
                        <ServiceCard
                            title="セキュリティ健康診断"
                            imageSrc={seccheck}
                        >
                            自社のサイバーセキュリティ上の課題を把握できていなければ対策を行うことは適いません。
                            自社のサイバーセキュリティ態勢を簡易的に評価するツール(SecurityScorecard)
                            を提供しています。
                            自社ドメインの登録だけでセキュリティ対策状況を評価する簡易レポートを通じ課題が明確にすることができます。
                            評価結果に対するお問い合わせ窓口も用意しております。
                        </ServiceCard>
                        <ServiceCard
                            title="個別カウンセリング"
                            imageSrc={counseling}
                        >
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                {
                                    "「vCISO安心相談サービス PREMIUM」をご契約のお客様向けに、GSXの専門コンサルタントと直接会話して、サイバーセキュリティに関するお悩み・ご相談をお聞きする場を設けます。セキュリティ用語は、慣れないと難しいもので、チャットやメールではなかなか表現が難しいと思います。また、日々忙しい最中セキュリティの勉強をする時間もない。という方、是非、マンツーマンのミーティングの場をご活用ください。"
                                }
                            </div>
                        </ServiceCard>

                        <ServiceCard title="サイバーリスク保険">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                                {`サイバー攻撃にあった際、原因調査や改善にあたっては思わぬ出費が伴うことになり、サイバーリスク保険が助けになります。ご契約のすべてのお客様に国内大手損害保険会社の商品であるサイバーリスク保険(*1)が自動付帯されます。​ サイバー攻撃は、いつ襲ってくるかわかりません。サイバーリスクの兆候を発見した際、迅速に初動対応を取ることで後々の被害を最小限に抑えることにつながります。\n\n*1.東京海上日動火災保険株式会社との連携により、vCSIO安心相談サービスには同社が提供するサイバーリスク保険が全件付帯されております。​​\n*2.事象によっては、補償適用外となる場合があります。​​`}
                            </div>
                        </ServiceCard>
                        <div id="service-1" className="service-detail">
                            <Row
                                className="g-0 service-list"
                                style={{ borderTop: "1px #444 solid" }}
                            >
                                <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className="item-title"
                                >
                                    保険種類
                                </Col>
                                <Col
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    className="item-body"
                                >
                                    サイバーリスク保険
                                </Col>
                            </Row>
                            <Row className="g-0 service-list">
                                <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className="item-title"
                                >
                                    保険発動要件
                                </Col>
                                <Col
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    className="item-body"
                                >
                                    ネットワーク起因のインシデントによる個人情報/法人情報かつ電子情報漏えいまたはそのおそれ
                                </Col>
                            </Row>
                            <Row className="g-0 service-list">
                                <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className="item-title"
                                >
                                    保険対象行為
                                </Col>
                                <Col
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    className="item-body"
                                >
                                    被保険者が行うITユーザー行為（※）
                                    <br />
                                    ※記名被保険者の業務における次の行為をいいます。
                                    <br />
                                    ア.コンピュータシステム（他人に使用させる目的のものを除きます。注）の所有・使用・管理
                                    <br />
                                    イ.アのコンピュータシステムにおけるプログラム・データ（他人のために製造・販売したものを除きます。）の提供
                                    （被保険者が所有・使用・管理するコンピュータシステムで直接処理を行った記録媒体によって提供された場合を含みます。）
                                </Col>
                            </Row>
                            <Row className="g-0 service-list">
                                <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className="item-title"
                                >
                                    1社あたりの支払限度額
                                </Col>
                                <Col
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    className="item-body"
                                    style={{ padding: "0" }}
                                >
                                    <span
                                        style={{
                                            padding: "10px 20px",
                                            display: "block",
                                        }}
                                    >
                                        200万円
                                    </span>
                                    <Row
                                        className="g-0"
                                        style={{
                                            borderTop: "1px solid #707070",
                                            borderBottom: "1px solid #707070",
                                        }}
                                    >
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{
                                                backgroundColor: "#CCCCCC",
                                                textAlign: "center",
                                                borderRight:
                                                    "1px solid #707070",
                                                padding: "10px 20px",
                                            }}
                                        >
                                            詳細費目
                                        </Col>
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{
                                                backgroundColor: "#CCCCCC",
                                                textAlign: "center",
                                                padding: "10px 20px",
                                            }}
                                        >
                                            支払限度額
                                        </Col>
                                    </Row>
                                    <Row
                                        className="g-0"
                                        style={{
                                            borderBottom: "1px solid #707070",
                                        }}
                                    >
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{
                                                borderRight:
                                                    "1px solid #707070",
                                                padding: "10px 20px",
                                            }}
                                        >
                                            個人情報漏えい見舞費用支払限度額（1名）
                                        </Col>
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{ padding: "10px 20px" }}
                                        >
                                            1,000円
                                        </Col>
                                    </Row>
                                    <Row
                                        className="g-0"
                                        style={{
                                            borderBottom: "1px solid #707070",
                                        }}
                                    >
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{
                                                borderRight:
                                                    "1px solid #707070",
                                                padding: "10px 20px",
                                            }}
                                        >
                                            法人見舞費用支払限度額（1法人）
                                        </Col>
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{ padding: "10px 20px" }}
                                        >
                                            50,000円
                                        </Col>
                                    </Row>
                                    <Row
                                        className="g-0"
                                        style={{
                                            borderBottom: "1px solid #707070",
                                        }}
                                    >
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{
                                                borderRight:
                                                    "1px solid #707070",
                                                padding: "10px 20px",
                                            }}
                                        >
                                            サイバー攻撃対応費用、原因・被害調査費用、相談費用（A）
                                        </Col>
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{ padding: "10px 20px" }}
                                        >
                                            200万円の100%
                                        </Col>
                                    </Row>
                                    <Row className="g-0">
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{
                                                borderRight:
                                                    "1px solid #707070",
                                                padding: "10px 20px",
                                            }}
                                        >
                                            サイバー攻撃対応費用、原因・被害調査費用、相談費用（B）
                                        </Col>
                                        <Col
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{ padding: "10px 20px" }}
                                        >
                                            200万円の90%
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="g-0 service-list">
                                <Col
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className="item-title"
                                >
                                    不担保
                                </Col>
                                <Col
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    className="item-body"
                                >
                                    データ等復旧費用、再発防止費用、訴訟対応費用など
                                </Col>
                            </Row>
                            <p style={{ fontSize: "14px", marginTop: "20px" }}>
                                サイバーセキュリティ事故対応費用内サイバー攻撃対応費用、原因・被害調査費用、相談費用については、セキュリティ事故の発生またはそれの事実が公表等の措置として客観的に明らかになった場合（不正アクセス等の対応費用については、かつ、結果として不正アクセス等が生じていた場合）は（A）欄、それ以外の場合は（B）欄の縮小支払割合が適用されます。
                            </p>
                        </div>
                    </div>
                </Col>
                <Col />
            </Row>
        </div>
    );
}
