import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Table,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    Stack,
    Form,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import SubAccountRow from "./SubAccountRow";
import SubscriberSubEdit from "./SubscriberSubEdit";
import AlertModal from "../AlertModal";

export default function SubAccounts(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [editShow, setEditShow] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [deleteVals, setDeleteVals] = useState({
        show: false,
        name: "",
        id: "",
    });
    const [subVals, setSubVals] = useState({
        email: "",
        firstName: "",
        lastName: "",
        firstNameKana: "",
        lastNameKana: "",
    });
    const navigate = useNavigate();

    const handleDelete = (prop) => {
        ////console.log(prop)
        setDeleteVals({
            show: true,
            name: `${prop.lastName} ${prop.firstName}`,
            id: prop.userId,
        });
    };

    const handleShow = (prop) => setAlert({ ...alert, show: prop });

    const handleDeleteSubAccount = () => {
        setDeleteVals({ show: false, name: "", id: "" });
        axiosPrivate
            .delete("/api/user/" + deleteVals.id)
            .then(function (response) {
                // //console.log(response)
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "サブアカウントを削除しました。",
                    });
                    axiosPrivate
                        .get("/api/user/list")
                        .then(function (response) {
                            //      //console.log(response)
                            setAccounts(response.data.data);
                        })
                        .catch(function (response) {
                            //console.log(response)
                        });
                }
            })
            .catch(function (response) {
                //console.log(response)
                setAlert({
                    ...alert,
                    show: true,
                    message: "サブアカウントの削除が失敗しました。",
                });
            });
    };

    const handleEdit = (prop) => {
        ////console.log(prop)
        setSubVals({
            ...subVals,
            email: prop.mailId,
            firstName: prop.firstName,
            lastName: prop.lastName,
            firstNameKana: prop.firstNameKana,
            lastNameKana: prop.lastNameKana,
            userId: prop.userId,
        });
        setEditShow(true);
    };

    const editSubmit = (data) => {
        const body = {
            firstName: data.firstName,
            lastName: data.lastName,
            firstNameKana: data.firstNameKana,
            lastNameKana: data.lastNameKana,
        };
        ////console.log(subVals)
        // //console.log(body)
        axiosPrivate
            .put("/api/user/" + subVals.userId, body)
            .then(function (response) {
                ////console.log(response)
                if (response.status === 200) {
                    setAlert({
                        ...alert,
                        show: true,
                        message: "サブアカウントを編集しました。",
                    });
                    setEditShow(false);
                    axiosPrivate
                        .get("/api/user/list")
                        .then(function (response) {
                            ////console.log(response)
                            if (response.status === 200) {
                                setAccounts(response.data.data);
                            }
                        })
                        .catch(function (response) {
                            //console.log(response)
                        });
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
    };

    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };


    const handleCloseDelete = () =>
        setDeleteVals({ show: false, name: "", id: "" });
    const handleEditClose = () => setEditShow(false);

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate
            .get("/api/user/list")
            .then(function (response) {
                ////console.log(response)
                if (response.status === 200) {
                    if (isSubscribed) {
                        setAccounts(response.data.data);
                    }
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
        return () => (isSubscribed = false);
    }, []);
    return (
        <Stack className="Common">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　サブアカウント</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
            <br />
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />
            <Modal
                show={deleteVals.show}
                onHide={handleCloseDelete}
                size="lg"
                centered
                className="p-5"
            >
                <Modal.Header>
                    <h5>アカウントを削除</h5>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="mb-4" style={{ fontWeight: "bold" }}>
                        {deleteVals.name}様
                    </div>
                    <div className="mb-4">
                        上記のアカウントを削除してもよろしいですか。
                    </div>
                    <Row className="g-0">
                        <Col xs={3} />
                        <Col xs={3}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={handleCloseDelete}
                            >
                                キャンセル
                            </Button>
                        </Col>
                        <Col xs={3}>
                            <Button size="sm" onClick={handleDeleteSubAccount}>
                                削除する
                            </Button>
                        </Col>
                        <Col xs={3} />
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={editShow}
                onHide={handleEditClose}
                size="lg"
                centered
                className="p5"
            >
                <Modal.Body>
                    <SubscriberSubEdit
                        corpName={props.corpName}
                        subVals={subVals}
                        editSubmit={editSubmit}
                    />
                </Modal.Body>
            </Modal>
            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section style={{ maxWidth: "1000px" }}>
                        <div
                            className="template-customize-mypage-area"
                            style={{
                                justifyContent: "right",
                                display: "grid",
                            }}
                        >
                            <Button
                                className="rounded-pill btn-blue-2"
                                onClick={handleMypage}
                            >
                                マイページへ戻る
                            </Button>
                        </div>
                        <br />
                        <Row>
                            <Form.Label>
                                <h3 style={{ textAlign: "center" }}>
                                    サブアカウント一覧
                                </h3>
                            </Form.Label>

                            <Form.Label>
                                サブアカウントは４つまで作成可能です。
                            </Form.Label>
                            <Form.Label>
                                サブアカウントについても新規登録後に多要素認証の登録が可能です。
                            </Form.Label>
                        </Row>

                        <br />

                        <Table bordered hover>
                            <thead style={{ backgroundColor: "lightgrey" }}>
                                <tr>
                                    <th>氏名</th>
                                    <th>メールアドレス</th>
                                    <th>多要素認証電話番号</th>
                                    <th>編集</th>
                                    <th>削除</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accounts && accounts.length ? (
                                    accounts.map((account) => {
                                        return (
                                            <SubAccountRow
                                                key={account.userId}
                                                account={account}
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                            />
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            style={{ textAlign: "center" }}
                                            colSpan={5}
                                        >
                                            サブアカウントはありません。
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <div className="template-customize-area">
                            <Button
                                className="btn btn-primary"
                                disabled={accounts.length >= 4}
                                onClick={() => navigate("/user/subAccounts")}
                            >
                                サブアカウントを追加する
                            </Button>
                        </div>
                        <br />
                        <br />
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Stack>
    );
}
