import React, { useState, useEffect } from 'react';
import {
    Row, Col, Form, Stack, Button
} from 'react-bootstrap';
import ValidationError from '../ValidationError';
import { axiosPrivate } from '../../../utils/axiosPrivate';

export default function AdminRegisterEntry(props) {

    const [compSize, setCompSize] = useState([]);
    const [source, setSource] = useState([]);
    const [industryType, setIndustryType] = useState([]);
    const [pass, setPass] = useState("");

    const {
        errors,
        register,
        handleObjChange,
        values, 
        subsChecked,
        handleCheck,
        handleSubmit,
        onSubmit,
        setAddress
    } = props;

    var jpPrefecture = require("jp-prefecture");
    const prefectures = jpPrefecture.getAll("pref", ["id", "name"]);

    useEffect(() => {
        let isSubscribed = true;
        axiosPrivate.get("/api/master/datasource/0")
        .then(function(response) {
            if (response.status === 200) {
                var data = response.data.data;
               // //console.log(data)
                let sizes = [];
                let sources = [];
                let types = [];
                data.forEach(item => {
                    if (item.typeId === 1) {
                        types.push(item)
                    } else if (item.typeId === 2) {
                        sizes.push(item)
                    } else if (item.typeId === 3) {
                        sources.push(item)
                    }
                })
                if (isSubscribed) {
                    setCompSize(sizes);
                    setSource(sources);
                    setIndustryType(types);
                }
            }
        })
        .catch(function(response) {
            console.error(response)
        })
        return () => isSubscribed = false;
    }, [])

    return (
        <Stack gap={5} style={{ "width": "100%", "height": "100%", "display": "flex", "alignItems": "center", "alignSelf": "center"}} className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)} style={{"borderRadius": ".5rem", "display": "flex", "flexDirection": "column", "width": "90%" }} className="p-4 shadow-lg">
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                メール（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={7} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text"
                                autoComplete='new-password' 
                                { 
                                    ...register("email", { required: {value: true, message: "メールを入力してください。"}, pattern: {value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "メールフォーマットは無効です。"}})
                                }/>
                                {errors.email && <ValidationError message={errors.email.message}/>}
                        </Col>
                        <Col xs={2}>
                        </Col>
                    </Row>
                    
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                会社名（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={7} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text"
                                autoComplete='new-password' 
                                { 
                                    ...register("company", { required: {value: true, message: "会社名を入力してください。"}, pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}})
                                }/>
                                {errors.company && <ValidationError message={errors.company.message}/>}
                        </Col>
                        <Col xs={2}>
                            <div style={{ "lineHeight": "38px"}}>（全角）</div>
                        </Col>
                    </Row>
                    
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                部署名
                            </Form.Label>
                        </Col>
                        <Col xs={7} className="px-4">
                            <Form.Control className="mb-3" type="text" autoComplete='new-password' { ...register("dept", {pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}})} />
                        </Col>
                        <Col xs={2}>
                            <div style={{ "lineHeight": "38px"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                担当者姓（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text"
                                autoComplete='new-password'
                                {
                                    ...register("lastName", {
                                        required: { value: true, message: "担当者姓を入力してください。"}, 
                                        pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}
                                    })
                                }
                                
                            />
                            {errors.lastName && <ValidationError message={errors.lastName.message}/>} 
                        </Col>
                        <Col xs={4}>
                            <div style={{ "lineHeight": "38px"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                担当者名（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                autoComplete='new-password'
                                {
                                    ...register("firstName", {
                                        required: { value: true, message: "担当者名を入力してください。"}, 
                                        pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}

                                    })
                                } 
                            />
                            {errors.firstName && <ValidationError message={errors.firstName.message}/>} 
                        </Col>
                        <Col xs={4}>
                            <div style={{ "lineHeight": "38px"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                            担当者セイ（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                autoComplete='new-password'
                                {
                                    ...register("lastNameKana", {
                                        required: { value: true, message: "担当者セイを入力してください。"}, 
                                        pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}
                                    })
                                } 
                            />
                            {errors.lastNameKana && <ValidationError message={errors.lastNameKana.message}/>} 
                        </Col>
                        <Col xs={4}>
                            <div style={{ "lineHeight": "38px"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                            担当者メイ（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text"
                                autoComplete='new-password'
                                {
                                    ...register("firstNameKana", {
                                        required: { value: true, message: "担当者メイを入力してください。"}, 
                                        pattern: {value: /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/, message: "全角で入力してください。"}
                                    })
                                } 
                            />
                            {errors.firstNameKana && <ValidationError message={errors.firstNameKana.message}/>}  
                        </Col>
                        <Col xs={4}>
                            <div style={{ "lineHeight": "38px"}}>（全角）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                郵便番号
                            </Form.Label>
                        </Col>
                        <Col xs={4} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                autoComplete='new-password'
                                {
                                    ...register("postcode", {
                                        required: { value: true, message: "郵便番号を入力してください。"}
                                    })
                                } 
                            />
                            {errors.postcode && <ValidationError message={errors.postcode.message}/>}
                        </Col>
                        <Col xs={5}>
                            <Button onClick={() => setAddress("addressOne")}>郵便番号から住所入力</Button>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                都道府県（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Select 
                                className="mb-3"
                                {
                                    ...register("state", {          
                                        validate: value => value !== "都道府県を選択する" || "都道府県を選択してください。"
                                    })
                                } 
                            >
                                <option key="defaultPrefecture" hidden>都道府県を選択する</option>
                                {
                                    prefectures.map(pref => {
                                        return <option value={pref.name}>{pref.name}</option>
                                    })
                                }
                            </Form.Select>
                            {errors.state && <ValidationError message={"都道府県を入力してください。"}/>}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                市区町村（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                autoComplete='new-password'
                                {
                                    ...register("city", {
                                        required: { value: true, message: "市区町村を入力してください。"}
                                    })
                                } 
                            />
                            {errors.city && <ValidationError message={errors.city.message}/>}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                番地等（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="text" 
                                autoComplete='new-password'
                                {
                                    ...register("address", {
                                        required: { value: true, message: "番地等を入力してください。"}
                                    })
                                } 
                            />
                            {errors.address && <ValidationError message={errors.address.message}/>}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                マンション・建物名
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control className="mb-3" type="text" autoComplete='new-password' {...register("buldingName")}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                法人代表電話番号
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Control className="mb-3" type="text" autoComplete='new-password'{...register("contactInfo", { pattern: { value: /^[0-9]*$/, message: "ハイフンなしの電話番号を入力してください。"}})} />
                            {errors.contactInfo && <ValidationError message={errors.contactInfo.message}/>}
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                業種
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Select className="mb-3" {...register("companyTypeId")} onChange={handleObjChange("companyTypeId")} value={JSON.stringify(values.companyTypeId)}>
                                <option key="defaultIndustryType" hidden>業種を選択する</option>
                                {
                                    industryType.map( item => {
                                        return <option value={JSON.stringify(item)}>{item.keyValueJp}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                企業規模
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <div className="mb-3">
                            <Form.Select className="mb-3" {...register("companySizeId")} onChange={handleObjChange("companySizeId")} value={JSON.stringify(values.companySizeId)}>
                                <option key="defaultSize" hidden>企業規模を選択する</option>
                                {
                                    compSize.map(item => {
                                        return <option value={JSON.stringify(item)}>{item.keyValueJp}</option>
                                    })
                                }
                            </Form.Select>
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                メールマガジン購読
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Check
                                checked={subsChecked}
                                className="mb-3"
                                type="checkbox"
                                id="magazine-check"
                                name="magazine-check"
                                { ...register("newsletterSubs", { onChange: handleCheck("newsletter") })}
                               
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                アンケート
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <Form.Select className="mb-3" {...register("referrerId")} onChange={handleObjChange("referrerId")} value={JSON.stringify(values.referrerId)}>
                                <option key="defaultSource" hidden>当サイトをどこでお知りになりましたか</option>
                                {
                                    source.map(item => {
                                        return <option value={JSON.stringify(item)}>{item.keyValueJp}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                プラン
                            </Form.Label>
                        </Col>
                        <Col xs={9} className="px-4">
                            <div className="mb-3">
                            <Form.Select className="mb-3" {...register("planId", { validate: value => value !== "プランを選択する" || "プランを選択してください"})}>
                                <option key="defaultPlan" hidden>プランを選択する</option>
                                <option key="litePlan" value="Lite">Lite</option>
                                <option key="standardPlan" value="Standard">Standard</option>
                                <option key="premiumPlan" value="Premium">Premium</option>
                            </Form.Select>
                            {errors.planId && <ValidationError message={errors.planId.message}/>}
                            </div>
                        </Col>
                    </Row>
                </Form.Group>
                <hr />
                <div style={{ "alignSelf": "center"}}>ログイン用パスワード設定</div>
                <br />
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                パスワード（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control className="mb-3" type="password" autoComplete='new-password'
                            {
                                ...register("password", 
                                { 
                                    required: {value: true, message: "英数特殊記号混在8文字以上を入力してください。"},
                                    onChange: (e) => setPass(e.target.value),
                                    minLength: { value: 8, message: "英数特殊記号混在8文字以上を入力してください。"},
                                    pattern: { value: /^(?=.*[a-zA-Z])(?=.*?[0-9])(?=.*?[\W|_]).*$/, message: "英数特殊記号混在8文字以上を入力してください。"}
                                })
                            } />
                            {errors.password && <ValidationError message={errors.password.message}/>}
                        </Col>
                        <Col xs={4}>
                            <div style={{ "lineHeight": "38px"}}>（半角英数）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row className="g-0">
                        <Col xs={3}>
                            <Form.Label style={{ "fontSize": ".8rem"}}>
                                パスワード確認用（必須）
                            </Form.Label>
                        </Col>
                        <Col xs={5} className="px-4">
                            <Form.Control 
                                className="mb-3" 
                                type="password"
                                autoComplete='new-password'
                                {
                                    ...register("confirmPass", {
                                        required: { value: true, message: "パスワードと一致していません。"},
                                        validate: value => value === pass || "パスワードと一致していません。"
                                    })
                                }
                            />
                            {errors.confirmPass && <ValidationError message={errors.confirmPass.message}/>}
                        </Col>
                        <Col xs={4}>
                            <div style={{ "lineHeight": "38px"}}>（半角英数）</div>
                        </Col>
                    </Row>
                </Form.Group>
                <Button size="sm" style={{ "alignSelf" : "center", "width": "40%"}} type="submit">確認画面に進む</Button>
            </Form>
        </Stack>
    )
}