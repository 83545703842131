import axios from 'axios';
import Cookies from 'universal-cookie';
import { memoizedRefreshToken } from './refreshToken';

//https://14rphqgebl.execute-api.us-east-1.amazonaws.com/dev/?url=
//http://localhost:8081
//https://vcisoapidev.gsx-its.com
//https://api.vciso.jp
axios.defaults.baseURL = "https://api.vciso.jp"

axios.interceptors.request.use(
    async (config) => {
        const cookies = new Cookies();
        const accessToken = sessionStorage.getItem("access_token")
        ////console.log("request being tried")
        ////console.log(config)
        if (accessToken) {
            config.headers = {
            ...config.headers,
            };
            config.headers.Authorization = `Bearer ${accessToken}`
        } 
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config;
        //console.log("there's an axios error")
        //console.log(error.response)
      if (!config?.sent) {
        config.sent = true;
  
        const result = await memoizedRefreshToken();
       // //console.log(result)
        if (result?.access_token) {
          config.headers = {
            ...config.headers,
          };
          config.headers.Authorization = `Bearer ${result?.access_token}`
        }
       // //console.log(config)
        return axios(config);
      }
      return Promise.reject(error);
    }
);
  
export const axiosPrivate = axios;