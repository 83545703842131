import React from 'react';
import {
    Col, Row, Container
} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function WebService(props) {

    const { image, title, text, link, plans } = props;

    const styles = {
        links: {
            'textDecoration': 'none',
            '&:hover': {
                'color': 'red'
            }
        }
    }
    return (
        <Container className="wrapper" >
          <div id={props.class} className="mb-3">{image}</div>
          <div style={{'fontSize': '20px', 'fontWeight': 'bold'}} className="mb-3">{title}</div>
          <div className="mb-3" style={{ "fontSize": "14px", "color": "#777777" }}>{text}</div>
          <div>
            <h4 className=""></h4>
          </div>
          <Row className="plan-point-list">
              { plans.map((plan, index) => (
              <Col xs={1} md={1} key={index}>
                  <span className={ 'plan-point ' + plan }></span>
              </Col>
              )) }
          </Row>
        </Container>
    )
}