//import './App.css';
import "./stylesheets/scss/custom.scss";
import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Navigation from "./components/elements/Navigation";
import ServiceNav from "./components/elements/ServiceNav";
import Footer from "./components/elements/Footer";
import Signup from "./components/pages/Signup";
import MFAConfirm from "./components/pages/MFAConfirm";
import Register from "./components/pages/Register";
import PaymentPlan from "./components/elements/register/PaymentPlan";
import EmailTraining from "./components/pages/EmailTraining";
import EmailTrainingForm from "./components/pages/EmailTrainingForm";
import ELearning from "./components/pages/ELearning";
import DocTemplates from "./components/pages/DocTemplates";
import { useReactPath } from "./utils/useReactPath";
import ChatService from "./components/pages/ChatService";
import RyokinPlan from "./components/pages/RyokinPlan";
import CreditCardDetails from "./components/pages/CreditCardDetails";
import $ from "jquery";
import BreadcrumbNav from "./components/elements/BreadcrumbNav";
import PreloginServiceNav from "./components/elements/PreloginServiceNav";
import { PrivateRoute } from "./components/PrivateRoute";
import Cookies from "universal-cookie";
import Checklist from "./components/pages/Checklist";
import IncidentResponse from "./components/pages/IncidentResponse";
import TemplateCustomize from "./components/pages/TemplateCustomize";
import Services from "./components/pages/Services";
import Inquiry from "./components/pages/Inquiry";
import RegisterComplete from "./components/elements/creditcard/RegisterComplete";
import About from "./components/pages/About";
import getImageFromRSS from "./utils/getImageFromRSS";
import getRSSFeed from "./utils/getRSSFeed";
import logo from "./assets/logo.png";
import SecArticles from "./components/pages/SecArticles";
import Interviews from "./components/pages/Interviews";
import UserInfo from "./components/pages/UserInfo";
import RestrictedRoute from "./components/RestrictedRoute";
import { axiosPrivate } from "./utils/axiosPrivate";
import { set } from "react-hook-form";
import SubAccountSignup from "./components/elements/userinfo/SubAccountSignup";
import FAQ from "./components/pages/FAQ";
import Cases from "./components/pages/Cases";
import ForgotPassword from "./components/pages/ForgotPassword";
import SavePassword from "./components/pages/SavePassword";
import CancelSub from "./components/pages/CancelSub";
import Tokusyou from "./components/pages/Tokusyou";
import Admin from "./components/pages/Admin";
import AdminRegister from "./components/elements/admin/AdminRegister";
import Counseling from "./components/pages/Counseling";
import { propTypes } from "react-bootstrap/esm/Image";
import Manual from "./components/pages/Manual";
import Yakkan from "./components/pages/Yakkan";
import UserList from "./components/elements/admin/UserList";
import SecurityHealthCheck from "./components/pages/SecurityHealthCheck";
import SecCheckInquiry from "./components/elements/seccheck/SecCheckInquiry";
import Magazine from "./components/pages/Magazine";
import IncidentList from "./components/elements/admin/IncidentList";
import IncidentDetails from "./components/elements/admin/IncidentDetails";
import DocumentCustomizeList from "./components/elements/admin/DocumentCustomizeList";
import DocumentCustomizeDetails from "./components/elements/admin/DocumentCustomizeDetails";
import DocTemplatesList from "./components/elements/admin/DocTemplatesList";
import DocTemplateRegister from "./components/elements/admin/DocTemplateRegister";
import DocTemplateEdit from "./components/elements/admin/DocTemplateEdit";
import EmailRegistrationList from "./components/elements/admin/EmailRegistrationList";
import EmailTrainingEventRegister from "./components/elements/admin/EmailTrainingEventRegister";
import InquiryList from "./components/elements/admin/InquiryList";
import InquiryDetails from "./components/elements/admin/InquiryDetails";
import CounselingList from "./components/elements/admin/CounselingList";
import SecCheckAdmin from "./components/elements/admin/SecCheckAdmin";
import SecCheckInquiryDetails from "./components/elements/admin/SecCheckInquiryDetails";
import Mypage from "./components/pages/MyPage";

import EditUser from "./components/elements/userinfo/EditUser";
import ChangePassword from "./components/elements/userinfo/ChangePassword";
import SetMFA from "./components/elements/userinfo/SetMFA";
import UserPlan from "./components/elements/userinfo/UserPlan";
import PaymentInfo from "./components/elements/userinfo/PaymentInfo";
import SubAccounts from "./components/elements/userinfo/SubAccounts";
import Chart from "./components/elements/userinfo/Chart";
import SecurityAction from "./components/elements/userinfo/SecurityAction";
import ScrollToTop from "./utils/scrollToTop";

const companyNames = new Map();
companyNames.set("netsecurity", "ScanNetSecurity");
companyNames.set("itmedia", "ITmedia NEWS");
companyNames.set("mynavi", "マイナビニュース");
companyNames.set("jpcert", "JPCERT");
companyNames.set("ipa", "IPA");

function App() {
    const cookies = new Cookies();
    const [userInfo, setUserInfo] = useState({
        name: "",
        plan: "",
        roles: [],
    });
    const [test, setTest] = useState("1");

    const [login, setLogin] = useState(false);

    useEffect(() => {
        if (
            sessionStorage.getItem("access_token") &&
            sessionStorage.getItem("access_token") !== "undefined"
        ) {
            //console.log(sessionStorage.getItem("user"));
            //console.log(sessionStorage.getItem("access_token"));
            //console.log(sessionStorage.getItem("refresh"));
            axiosPrivate
                .get("/api/user/0")
                .then(function (response) {
                    //console.log(response)
                    //console.log(response.headers)
                    //console.log(response.cookies)
                    if (
                        response.status === 200 &&
                        response.data.apiCode === 0
                    ) {
                        setUserInfo({
                            ...userInfo,
                            name: `${response.data.data.lastName} ${response.data.data.firstName}`,
                            plan: response.data.data.planName,
                            roles: response.data.data.roles,
                        });
                        //window.updateChat({name: response.data.data.username, id: response.data.data.userId})
                        sessionStorage.setItem(
                            "userId",
                            response.data.data.userId
                        );
                        // var chatScript = $("#chatScript").html();
                        // var index = chatScript.indexOf(`d["__cp_c"]="9da4a496_1";`)
                        // var newChatScript = chatScript.slice(0, index) + `d["__cp_p"] = { "externalKey": "57", "chatName": "test2" };\n` + chatScript.slice(index)
                        // //console.log(newChatScript)
                        // $("#chatScript").html(newChatScript);
                        setLogin(true);
                    }
                })
                .catch(function (response) {
                    console.error(response);
                });
        } else {
            setUserInfo({ name: "", plan: "" });
        }
    }, [sessionStorage.getItem("user")]);

    const userInformation = useMemo(() => {
        //console.log("use memo userinfo")
        if (
            sessionStorage.getItem("access_token") &&
            sessionStorage.getItem("access_token") !== "undefined"
        ) {
            axiosPrivate
                .get("/api/user/0")
                .then(function (response) {
                    //console.log(response)
                    if (
                        response.status === 200 &&
                        response.data.apiCode === 0
                    ) {
                        //console.log(response)
                        setUserInfo({
                            ...userInfo,
                            name: `${response.data.data.lastName} ${response.data.data.firstName}`,
                            plan: response.data.data.planName,
                            roles: response.data.data.roles,
                        });
                        sessionStorage.setItem(
                            "userId",
                            response.data.data.userId
                        );
                        ////console.log("userinfo set usememo")
                        //window.updateChat({name: response.data.data.username, id: response.data.data.userId})
                        return `${response.data.data.lastName} ${response.data.data.firstName}`;
                    }
                })
                .catch(function (response) {
                    console.error(response);
                });
        } else {
            setUserInfo({ name: "", plan: "" });
        }
    }, []);

    const homeArticles = useMemo(() => {
        //console.log("Home Articles called")
        var articleList = [];
        var webRegex = /(\.)(.*?)\1/;
        const testFeed = async (articleObj) => {
            var result = await getImageFromRSS(
                articleObj.companyName,
                articleObj.link
            );
            if (result) {
                articleObj.image = result;
            } else {
                articleObj.image = logo;
            }
            articleList.push(articleObj);
            ////console.log(articleObj)
        };

        let secondFeed = getRSSFeed("https://articles.vciso.jp/wprss");
        secondFeed.then((feed) => {
            ////console.log(feed)

            var shortenedList = feed.items.slice(0, 8);
            shortenedList.forEach((item, index) => {
                var companyName = item.link.match(webRegex);
                //var itemImage = getImageFromRSS(companyNames.get(companyName[2]), item.link)
                ////console.log(itemImage)
                var articleObj = {
                    key: 30000 + index,
                    title: item.title,
                    image: "",
                    date: item.pubDate,
                    link: item.link,
                    companyName: companyNames.get(companyName[2]),
                };

                if (!articleList.some((x) => x.link === articleObj.link)) {
                    testFeed(articleObj).catch(console.error);
                }
            });
        });

        return articleList;
    }, [test]);

    const originalContent = useMemo(() => {
        var articleList = [];
        let feed = getRSSFeed("https://articles.vciso.jp/feed");

        feed.then((feed) => {
            var shortenedList = feed.items.slice(0, 8);
            shortenedList.forEach((item, index) => {
                var imgRegex = /([^\s]+)/;
                var imgSrc = item.description
                    .substring(item.description.indexOf("src"))
                    .match(imgRegex)[1];
                var img = imgSrc.substring(
                    imgSrc.indexOf("=") + 2,
                    imgSrc.length - 1
                );
                var articleObj = {
                    key: 20000 + index,
                    image: img,
                    title: item.title,
                    date: item.isoDate.split("T")[0],
                    link: item.link,
                };

                articleList.push(articleObj);
            });
        });

        return articleList;
    }, [test]);

    return (
        <div style={{ height: "100%" }}>
            <BrowserRouter>
                <ScrollToTop />
                <div id="header" className="Header">
                    <Navigation
                        userInfo={userInfo}
                        setLogin={setLogin}
                        setUserInfo={setUserInfo}
                    />
                    {login ? (
                        <ServiceNav userInfo={userInfo} />
                    ) : (
                        <PreloginServiceNav />
                    )}
                    <BreadcrumbNav />
                </div>
                <div id="main">
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <Home
                                    articles={homeArticles}
                                    originalContent={originalContent}
                                />
                            }
                        />
                        <Route
                            path="/login"
                            element={<Login setLogin={setLogin} />}
                        />
                        <Route path="/login/mfa" element={<MFAConfirm />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route
                            path="/register"
                            element={
                                <Register
                                    user={sessionStorage.getItem("user")}
                                />
                            }
                        />
                        <Route
                            path="/register/complete"
                            element={<RegisterComplete />}
                        />
                        <Route path="/services" element={<Services />} />
                        <Route
                            path="/inquiry"
                            element={
                                <Inquiry
                                    user={sessionStorage.getItem("user")}
                                />
                            }
                        />
                        <Route
                            path="/payment"
                            element={<CreditCardDetails />}
                        />
                        <Route path="/about" element={<About />} />
                        <Route
                            path="/cyberarticles"
                            element={<SecArticles articles={homeArticles} />}
                        />
                        <Route
                            path="/originalcontent"
                            element={<Interviews />}
                        />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/tokusyou" element={<Tokusyou />} />
                        <Route path="/cases" element={<Cases />} />
                        <Route
                            path="/forgotpassword"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="/resetPassword"
                            element={<SavePassword />}
                        />
                        <Route path="/manual" element={<Manual />} />
                        <Route path="/yakkan" element={<Yakkan />} />
                        <Route path="/magazine" element={<Magazine />} />
                        <Route
                            path="/mypage"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <Mypage />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/mypage/useredit"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <EditUser />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/mypage/changepassword"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <ChangePassword />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/mypage/setMFA"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <SetMFA />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/mypage/userplan"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <UserPlan />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/mypage/paymentInfo"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <PaymentInfo />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/mypage/subAccounts"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <SubAccounts />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/mypage/chart"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <Chart />
                                </PrivateRoute>
                            }
                        />
                                                <Route
                            path="/mypage/securityAction"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <SecurityAction />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/counseling"
                            element={
                                userInfo.plan === "PREMIUM" ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <Counseling />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/securitycheck"
                            element={
                                userInfo.plan === "PREMIUM" ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <SecurityHealthCheck />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/securitycheck/inquiry"
                            element={
                                userInfo.plan === "PREMIUM" ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <SecCheckInquiry />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <Admin />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/register"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <AdminRegister />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/userlist"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <UserList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/incidents"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <IncidentList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/incidents/details"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <IncidentDetails />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/doccustomize"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <DocumentCustomizeList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/doccustomize/details"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <DocumentCustomizeDetails />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/doctemplates"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <DocTemplatesList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/doctemplates/upload"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <DocTemplateRegister />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/doctemplates/details"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <DocTemplateEdit />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/emailregistrations"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <EmailRegistrationList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/emailregistrations/registration"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <EmailTrainingEventRegister />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/counselingsessions"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <CounselingList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/seccheck"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <SecCheckAdmin />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/seccheck/details"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <SecCheckInquiryDetails />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/inquiries"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <InquiryList />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/admin/inquiries/details"
                            element={
                                userInfo.roles &&
                                userInfo.roles.includes("ADMINISTRATOR") ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <InquiryDetails />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/emailtraining"
                            element={
                                userInfo.plan === "PREMIUM" ||
                                userInfo.plan === "STANDARD" ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <EmailTraining />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/emailtraining/registration"
                            element={
                                userInfo.plan === "PREMIUM" ||
                                userInfo.plan === "STANDARD" ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <EmailTrainingForm />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/elearning"
                            element={
                                userInfo.plan === "PREMIUM" ||
                                userInfo.plan === "STANDARD" ? (
                                    <PrivateRoute
                                        user={sessionStorage.getItem("user")}
                                    >
                                        <ELearning />
                                    </PrivateRoute>
                                ) : (
                                    <RestrictedRoute />
                                )
                            }
                        />
                        <Route
                            path="/doctemplates"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <DocTemplates />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/doctemplates/customize"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <TemplateCustomize />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/chatservice"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <ChatService
                                        user={sessionStorage.getItem("user")}
                                        id={sessionStorage.getItem("userId")}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/securitycheck"
                            element={<RestrictedRoute />}
                        />
                        <Route
                            path="/user"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <UserInfo />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/cancel"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <CancelSub />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/subAccounts"
                            element={
                                <PrivateRoute
                                    user={sessionStorage.getItem("user")}
                                >
                                    <SubAccountSignup />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="incident"
                            element={
                                <IncidentResponse
                                    user={sessionStorage.getItem("user")}
                                />
                            }
                        />
                        <Route path="/ryokinplan" element={<RyokinPlan />} />
                    </Routes>
                </div>
                <Footer
                    user={sessionStorage.getItem("user")}
                    userInfo={userInfo}
                />
            </BrowserRouter>
        </div>
    );
}

export default App;
