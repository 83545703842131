import React, { useState, useEffect} from 'react';
import {
    Row, Col, Form, Button
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AdminRegisterEntry from './AdminRegisterEntry';
import AdminRegisterCheck from './AdminRegisterCheck';
import { axiosPrivate } from '../../../utils/axiosPrivate';
import AlertModal from '../AlertModal';
import { useNavigate } from 'react-router';

const plans = {
    "Lite": 2,
    "Standard": 3,
    "Premium": 4
}

export default function AdminRegister(props) {
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    })
    const [subsChecked, setSubsChecked] = useState(true);
    const [confirm, setConfirm] = useState(false);
    const [values, setValues] = useState({
        userId:"0",
        firstName:"",
        lastName:"",   
        firstNameKana:"",
        lastNameKana:"",
        company:"",
        dept:"",
        password:"", 
        companyTypeId:{},
        newsletterSubs: true,
        companySizeId:{},
        referrerId: {},
        postCode:"",
        state:"",
        city:"",
        address:"",
        buldingName:"",
        contactInfo:"",
        paymentInfo: {
            company:"",
            dept: "",
            firstName: "",
            lastName: "",
            firstNameKana: "",
            lastNameKana: "",
            postCode:"",
            state:"",
            city:"",
            address:"",
            buldingName:"",
            contactInfo:""
        }
    })

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    const navigate = useNavigate();
    const handleShow = (prop) => {
        setAlert({ ...alert, show: prop}) 
        navigate(-1)
    }
    const handleCheck = (prop) => (event) => {
        if (prop === "newsletter") {
            setValue("newsletterSubs", event.target.checked)
            setSubsChecked(event.target.checked)
        } 
    }

    const onSubmit = (data) => {
        //console.log(data)
        setConfirm(true)
        window.scrollTo(0,0)
    }

    const handleSubmitConfirm = () =>{
        ////console.log(getValues())
        const formValues = getValues();
        const body = {
            "userId": values.userId,
            "email": formValues.email,
            "firstName": formValues.firstName,
            "lastName": formValues.lastName,
            "firstNameKana": formValues.firstNameKana,
            "lastNameKana": formValues.lastNameKana,
            "corpName": formValues.company,
            "dept": formValues.dept,
            "password": formValues.password,
            "corpTypeId": typeof values.companyTypeId.id === 'undefined' ? null : `${values.companyTypeId.id}`,
            "corpSizeId": typeof values.companySizeId.id === 'undefined' ? null : `${values.companySizeId.id}`,
            "referrerId": typeof values.referrerId.id === 'undefined' ? null : `${values.referrerId.id}`,
            "newsletterSubs": `${formValues.newsletterSubs}`,
            "postcode": formValues.postcode, 
            "state": formValues.state,
            "city": formValues.city,
            "address": formValues.address,
            "buldingName": formValues.buldingName,
            "contactInfo": formValues.contactInfo,
            "paymentInfo": null,
            "planId": plans[formValues.planId]           
        }

        
        axiosPrivate.post("/api/admin/register", body)
        .then(function(response) {
            if (response.status === 200) {
                setAlert({ ...alert, show: true, message: response.data.message})
            }
        })
        .catch(function(response) {
            //console.log(response)
            setAlert({ ...alert, show: true, message: "ゲスト登録が失敗しました。"})
        })
        
     
    }



    const setAddress = (prop) => {

        var postal_code = require('japan-postal-code');
        var zip = prop === "addressOne" ? getValues("postcode") : getValues("paymentInfo.postcode");
        postal_code.get(zip, function(address) {
            if (prop === "addressOne") {
               // //console.log("addressOne")
                setValue("state", address.prefecture)
                setValue("city", address.city)
                setValue("address", address.area)
                
            } //else {
              //  //console.log("paymentInfo")
              //  setValue("paymentInfo.state", address.prefecture)
              //  setValue("paymentInfo.city", address.city)
              //  setValue("paymentInfo.address", address.area)
           // }
        });
    }

    const handleObjChange = (prop) => (event) => {
        let obj = JSON.parse(event.target.value);
        setValues({...values, [prop]: obj})
        setValue(prop, obj)
    }

    const handleReentry = (event) => {
        event.preventDefault();
        setConfirm(false);
        setValue("password", "")
        setValue("confirmPass", "")
        window.scrollTo(0,0)
    }

    return (
        <Row className="g-0">
            <AlertModal show={alert.show} setShow={handleShow} message={alert.message} />
            <Col />
            <Col sm={12} md={10} lg={6} style={{ "display": "flex", "flexDirection": "column", "justifyContent":"center", "alignItems": "center", "height": "fit-content", "minHeight": "750px"}}>
                <h2>{confirm? "ゲスト登録情報確認" : "ゲスト登録"}</h2>
                { confirm ?
                <AdminRegisterCheck
                    values={getValues()}
                    subsChecked={subsChecked}
                    objValues={values}
                    handleSubmit={handleSubmitConfirm} 
                    handleClick={handleReentry} 
                />
                :
                <AdminRegisterEntry
                    setConfirm={setConfirm}
                    checkValues={getValues()}
                    objValues={values}
                    values={values}
                    setAddress={setAddress}
                    handleObjChange={handleObjChange}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    handleCheck={handleCheck}
                    register={register}
                    errors={errors}
                    subsChecked={subsChecked}
                />
                }
            </Col>
            <Col />
        </Row>
    )
}