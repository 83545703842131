import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button, Stack } from "react-bootstrap";
import { axiosPrivate } from "../../../utils/axiosPrivate";
import Invoice from "./Invoice";
import downloadInvoice from "../../../utils/downloadInvoice";
import { NavigationType, useNavigate } from "react-router-dom";

export default function PaymentInfo(props) {
    const [invoices, setInvoices] = useState([]);

    const handleDownload = (filename) => {
        downloadInvoice(filename);
    };

    const navigate = useNavigate();
    const handleMypage = () => {
        navigate("/Mypage", { replace: true });
    };

    useEffect(() => {
        let isSubscribed = true;
        /*
        axiosPrivate.get("/api/invoice/list")
        .then(function(response) {
            //console.log("Invoice List Response")
            ////console.log(response)
            if (response.status === 200 && response.data.apiCode === 0) {
                if (isSubscribed) {
                    setInvoices(response.data.data)
                }
            }
        })
        .catch(function (response) {
            //console.log(response)
        })  
        */
        return () => (isSubscribed = false);
    }, []);

    return (
        <Stack className="Common">
            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　料金明細</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>
            <br />
            <br />

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section style={{ maxWidth: "1000px" }}>
                        <div
                            className="template-customize-mypage-area"
                            style={{
                                justifyContent: "right",
                                display: "grid",
                            }}
                        >
                            <Button
                                className="rounded-pill btn-blue-2"
                                onClick={handleMypage}
                            >
                                マイページへ戻る
                            </Button>
                        </div>
                        <br />
                        <div style={{ height: "400px", overflowY: "auto" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        {/* <th
                                            className="td-1"
                                            style={{ textAlign: "center" }}
                                        >
                                            No
                                        </th> */}
                                        <th
                                            className="td-5"
                                            style={{ textAlign: "left" }}
                                        >
                                            請求日
                                        </th>
                                        <th
                                            className="td-5"
                                            style={{ textAlign: "left" }}
                                        >
                                            支払い手段
                                        </th>
                                        <th
                                            className="td-5"
                                            style={{ textAlign: "left" }}
                                        >
                                            プラン
                                        </th>
                                        <th
                                            className="td-5"
                                            style={{ textAlign: "left" }}
                                        >
                                            ご利用料金
                                        </th>
                                        <th
                                            className="td-5"
                                            style={{ textAlign: "left" }}
                                        >
                                            領収書
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices && invoices.length ? (
                                        invoices.map((invoice) => {
                                            return (
                                                <Invoice
                                                    key={invoice.id}
                                                    invoice={invoice}
                                                    download={() =>
                                                        handleDownload(
                                                            invoice.id
                                                        )
                                                    }
                                                />
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                style={{ textAlign: "center" }}
                                                colSpan={6}
                                            >
                                                現在、表示する料金明細はありません。
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Stack>
    );
}
