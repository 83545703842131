import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ValidationError from "../elements/ValidationError";
import { axiosPrivate } from "../../utils/axiosPrivate";
import TimePicker from "react-bootstrap-time-picker";
import CounselingEntry from "../elements/counseling/CounselingEntry";
import CounselingCheck from "../elements/counseling/CounselingCheck";
import CounselingSent from "../elements/counseling/CounselingSent";
import AlertModal from "../elements/AlertModal";

export default function Counseling(props) {
    const [contractPeriod, setContractPeriod] = useState("");
    const [sessions, setSessions] = useState(["未実施", "未実施"]);
    const [firstTime, setFirstTime] = useState(36000);
    const [secondTime, setSecondTime] = useState(36000);
    const [confirm, setConfirm] = useState(false);
    const [sentMessage, setSentMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstDate: "",
            secondDate: "",
            content: "",
        },
    });

    const handleConfirm = () => setConfirm(!confirm);

    const handleShow = (prop) => {
        setAlert({ ...alert, show: prop });
        setConfirm(false);
    };

    const handleReturn = () => {
        setSuccess(false);
        setConfirm(false);
        setValue("firstDate", "");
        setValue("secondDate", "");
        setValue("content", "");
        setFirstTime(36000);
        setSecondTime(36000);
        axiosPrivate
            .get("/api/counseling")
            .then(function (response) {
                if (response.data.apiCode === 0) {
                    var sessionData = [];
                    response.data.data.forEach((x, index) => {
                        if (x.status === "申込中") {
                            sessionData.push("申込中");
                        } else {
                            sessionData.push(x.session_date);
                        }
                    });
                    setSessions(sessionData);
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
    };

    const handleTime = (prop) => (event) => {
        //console.log(prop)
        var time = event.target.value;
        //console.log(time)
        var date = new Date();
        //date.setHours(0,0,0,0)
        //date.setSeconds(time);
        // //console.log(date.toLocaleTimeString('ja'))
        if (prop === "first") {
            setFirstTime(time);
        } else {
            setSecondTime(time);
        }
    };

    const onSubmit = () => {
        setConfirm(true);
    };

    const onSubmitConfirm = (data) => {
        ////console.log(data)
        var values = getValues();
        ////console.log(values.firstDate)
        ////console.log(firstTime)
        ////console.log(values.secondDate)
        ////console.log(secondTime)
        var newDate1 = new Date(values.firstDate);
        newDate1.setHours(0, 0, 0, 0);
        newDate1.setSeconds(firstTime);

        ////console.log(newDate1)

        var newDate2 = new Date(values.secondDate);
        newDate2.setHours(0, 0, 0, 0);
        newDate2.setSeconds(secondTime);

        ////console.log(newDate2)
        var body = {
            content: values.content,
            firstChoice: newDate1,
            secondChoice: newDate2,
        };

        //console.log(sessions)
        if (sessions.length >= 2 && sessions.every((x) => x === "申込中")) {
            setAlert({
                ...alert,
                show: true,
                message: "ご契約期間での2回分のお申込みは受付済みです。",
            });
            return;
        }

        axiosPrivate
            .post("/api/counseling", body)
            .then(function (response) {
                //console.log(response)
                //console.log(response.data.message)
                //console.log(response.data.apiCode)
                if (response.data.apiCode !== 0) {
                    //console.log(response.data.message)
                    //console.log(response.data.apiCode)
                    setAlert({
                        ...alert,
                        show: true,
                        message: response.data.message,
                    });
                } else {
                    setSentMessage(response.data.message);
                    setSuccess(true);
                    window.scrollTo(0, 0);
                }
            })
            .catch(function (response) {
                setAlert({
                    ...alert,
                    show: true,
                    message: "個別カウンセリングの登録が失敗しました。",
                });
            });
    };

    useEffect(() => {
        axiosPrivate
            .get("/api/user/1")
            .then(function (response) {
                if (response.data.apiCode === 0) {
                    setContractPeriod(
                        response.data.data.contractPeriod
                            .split("：")[1]
                            .slice(0, -1)
                    );
                }
            })
            .catch(function (response) {
                //console.log(response)
            });

        axiosPrivate
            .get("/api/counseling")
            .then(function (response) {
                if (response.data.apiCode === 0) {
                    var sessionData = [];
                    response.data.data.forEach((x, index) => {
                        if (x.status === "申込中") {
                            sessionData.push("申込中");
                        } else {
                            sessionData.push(x.session_date);
                        }
                    });
                    setSessions(sessionData);
                }
            })
            .catch(function (response) {
                //console.log(response)
            });
    }, []);

    const counselingOverview = useRef();
    const counselingEntryRef = useRef();
    const customerInformationRef = useRef();

    const scrollHandler = (navRef) => {
        document.documentElement.scrollTop =
            window.pageYOffset +
            navRef.current.getBoundingClientRect().top -
            120;
        // header.getBoundingClientRect().bottom;
    };

    return (
        <Row className="g-0 Counseling">
            <AlertModal
                show={alert.show}
                setShow={handleShow}
                message={alert.message}
            />

            <div className="under_title g-0 row" style={{ textAlign: "left" }}>
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <h1>　個別カウンセリング</h1>
                </div>
                <div className="col-lg-1"></div>
            </div>

            <div className="g-0 row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 box">
                    <section>
                        <div className="doc_lede">
                            <br />
                            <br />
                            <div>
                                年間ご契約期間で2回、GSXの専門コンサルタントと直接会話して、サイバーセキュリティに関するお悩み・ご相談をお聞きする場を設けます。
                                <br />
                                本画面よりお客様の実施希望日時を第２希望までご登録ください。ご登録頂けましたら、vCISO事務局にて日程調整致します。
                            </div>
                        </div>
                    </section>

                    <section>
                        <ul className="anchor_link">
                            <li
                                className="counseling-nav"
                                onClick={() => {
                                    scrollHandler(counselingOverview);
                                }}
                            >
                                カウンセリング概要
                            </li>
                            <li
                                className="counseling-nav"
                                onClick={() => {
                                    scrollHandler(counselingEntryRef);
                                }}
                            >
                                お客様の情報
                            </li>
                            <li
                                className="counseling-nav"
                                onClick={() => {
                                    scrollHandler(customerInformationRef);
                                }}
                            >
                                カウンセリングのお申し込み
                            </li>
                        </ul>
                    </section>

                    <h2
                        id="counseling_overview"
                        className="content_title"
                        ref={counselingOverview}
                    >
                        <div>カウンセリング概要</div>
                    </h2>
                    <section>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>実施方法</th>
                                    <td>
                                        GSXで準備するWebミーティングツールを利用し、リモートミーティング形式で行います。
                                    </td>
                                </tr>
                                <tr>
                                    <th>ツールについて</th>
                                    <td>
                                        <ul className="table_list">
                                            <li>
                                                Webミーティングツールは、2022年10月時点ではZoomを利用致します。お客様側PCで必要なソフトウェアは、お客様でインストールしていただきます。
                                            </li>
                                            <li>
                                                お客様側のPC端末、カメラ、スピーカー、インタネット回線等は、お客様にご用意いただきます。
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th>所要時間</th>
                                    <td>
                                        カウンセリングミーティング自体は、基本60分間を目安にしております。
                                    </td>
                                </tr>
                                <tr>
                                    <th>実施時間帯</th>
                                    <td>平日の10時～17時</td>
                                </tr>
                                <tr>
                                    <th>カウンセリング担当者</th>
                                    <td>
                                        カウンセリングにあたる要員は、GSXのコンサルタントを想定しておりますが、どの者が対応するか、をお約束する事は出来かねます。
                                    </td>
                                </tr>
                                <tr>
                                    <th>ご相談内容</th>
                                    <td>
                                        基本的には、情報セキュリティ、サイバーセキュリティに関する内容を承ります。
                                    </td>
                                </tr>
                                <tr>
                                    <th>本サービスの目的</th>
                                    <td>
                                        本サービスにおいては、お客様の課題解決の為の自助努力を支援する事が目的であり、当社が主体的に課題解決に導く事が目的ではありません。
                                    </td>
                                </tr>
                                <tr>
                                    <th>追加サービス</th>
                                    <td>
                                        基本的に持ち帰り作業は含まれておりません。作業が必要な場合、別途ご提案を見積りを差し上げます。
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        {success ? (
                            <CounselingSent
                                handleReturn={handleReturn}
                                sentMessage={sentMessage}
                            />
                        ) : (
                            <>
                                <h2
                                    id="counseling_entry"
                                    className="content_title"
                                    ref={counselingEntryRef}
                                >
                                    <div>お客様情報</div>
                                </h2>

                                <Form.Group>
                                    <Row className="g-0">
                                        <div className="contract-info-label">
                                            <Form.Label
                                                style={{
                                                    lineHeight: "38px",
                                                    wordBreak: "keep-all",
                                                }}
                                            >
                                                ご契約期間：
                                            </Form.Label>
                                        </div>
                                        <div className="contract-label-content">
                                            <Form.Control
                                                type="text"
                                                readOnly
                                                plaintext
                                                value={contractPeriod}
                                            />
                                        </div>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row className="g-0">
                                        <div className="contract-info-label">
                                            <Form.Label
                                                style={{
                                                    lineHeight: "38px",
                                                    wordBreak: "keep-all",
                                                }}
                                            >
                                                実施状況：
                                            </Form.Label>
                                        </div>
                                        <div className="contract-label-content">
                                            <Form.Control
                                                type="text"
                                                readOnly
                                                plaintext
                                                value={
                                                    sessions.length > 0
                                                        ? "第1回　" +
                                                          sessions[0]
                                                        : "第1回　未実施"
                                                }
                                            />
                                        </div>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row className="g-0">
                                        <div className="contract-info-label"></div>
                                        <div className="contract-label-content">
                                            <Form.Control
                                                type="text"
                                                readOnly
                                                plaintext
                                                value={
                                                    sessions.length > 1
                                                        ? "第2回　" +
                                                          sessions[1]
                                                        : "第2回　未実施"
                                                }
                                            />
                                        </div>
                                    </Row>
                                </Form.Group>

                                <h2
                                    id="counseling_entry"
                                    className="content_title"
                                    ref={customerInformationRef}
                                >
                                    <div>カウンセリングお申し込み</div>
                                </h2>

                                {confirm ? (
                                    <CounselingCheck
                                        values={getValues()}
                                        handleConfirm={handleConfirm}
                                        firstTime={firstTime}
                                        secondTime={secondTime}
                                        handleSubmit={handleSubmit}
                                        onSubmit={onSubmitConfirm}
                                    />
                                ) : (
                                    <CounselingEntry
                                        register={register}
                                        errors={errors}
                                        handleSubmit={handleSubmit}
                                        onSubmit={onSubmit}
                                        handleTime={handleTime}
                                        firstTime={firstTime}
                                        secondTime={secondTime}
                                    />
                                )}
                            </>
                        )}
                    </section>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </Row>
    );
}
